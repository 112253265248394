import {
  EmployeesCreateRequest,
  EmployeesGetPageRequest,
  EmployeesPatchRequest,
} from '@/api/Dictionary/Employees/types';
import { useMutation, useQuery, useQueryClient } from 'react-query';
import { Api } from '@/api';
import { RecordId } from '@/api/common/types';
import { employeesQueryKeys } from '@/hooks/directory/employees/queryKeys';
import { createUseFetchingStateObserver } from '@/hooks/utils';

export const useGetEmployeesPageQ = (params: EmployeesGetPageRequest) => {
  return useQuery(
    employeesQueryKeys.page(params),
    () => {
      return Api.dictionary.employees.get(params);
    },
    {
      select: (data) => data.data,
    },
  );
};

export const useEmployeesPageFetchingState = createUseFetchingStateObserver(
  employeesQueryKeys.pages(),
);

export const useGetAllEmployeesQ = () => {
  const params = { page: 1, limit: 1000 };
  return useQuery(
    employeesQueryKeys.page(params),
    () => {
      return Api.dictionary.employees.get(params);
    },
    {
      select: (data) => data.data.data,
    },
  );
};

export const useGetEmployeeByIdQ = (id: RecordId) => {
  return useQuery(
    employeesQueryKeys.detail(id),
    () => {
      return Api.dictionary.employees.getById(id);
    },
    {
      select: (data) => data.data.data,
    },
  );
};

//Mutations

export const useCreateEmployeeM = () => {
  const queryClient = useQueryClient();
  return useMutation(
    employeesQueryKeys.create(),
    (data: EmployeesCreateRequest) => {
      return Api.dictionary.employees.create(data);
    },
    {
      onSuccess: async (data) => {
        await queryClient.invalidateQueries(employeesQueryKeys.pages());
        queryClient.setQueriesData(employeesQueryKeys.detail(data.data.data._id), data);
      },
    },
  );
};

export const useDeleteEmployeeM = () => {
  const queryClient = useQueryClient();
  return useMutation(
    employeesQueryKeys.delete(),
    (id: RecordId) => {
      return Api.dictionary.employees.delete(id);
    },
    {
      onSuccess: async (_, variables) => {
        await Promise.all([
          queryClient.invalidateQueries(employeesQueryKeys.pages()),
          queryClient.invalidateQueries(employeesQueryKeys.detail(variables)),
        ]);
      },
    },
  );
};

export const usePatchEmployeeM = () => {
  const queryClient = useQueryClient();
  return useMutation(
    employeesQueryKeys.patch(),
    (data: EmployeesPatchRequest) => {
      return Api.dictionary.employees.patch(data);
    },
    {
      onSuccess: async (data) => {
        await queryClient.invalidateQueries(employeesQueryKeys.pages());
        queryClient.setQueriesData(employeesQueryKeys.detail(data.data.data._id), data);
      },
    },
  );
};
