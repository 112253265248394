import React from 'react';
import cn from 'classnames';
import './styles.scss';

export type GuestLayoutTitleProps = {
  className?: string;
  style?: React.CSSProperties;
  children?: React.ReactNode;
};

export const GuestLayoutTitle: React.FC<GuestLayoutTitleProps> = (props) => {
  const { className, style, children } = props;

  return (
    <div className={cn('guest-layout-title', className)} style={style}>
      {children}
    </div>
  );
};
