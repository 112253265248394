import React from 'react';
import { Checkbox, Col, Form, Input, InputNumber, Row } from 'antd';
import { errorMessages } from '@/utils/errorMessages';
import { ColumnTypeSelect } from '@/components/modals/columns/common/ColumnTypeSelect';
import { ColumnGroupSelect } from '@/components/modals/columns/common/ColumnGroupSelect';
import { ColumnContractTypeSelect } from '@/components/modals/columns/common/ColumnContractTypeSelect';

export const ColumnFields: React.FC = React.memo(() => {
  return (
    <>
      <Row gutter={16}>
        <Col span={24}>
          <Form.Item
            name={'name'}
            label={'Наименование'}
            rules={[{ required: true, message: errorMessages.required }]}
          >
            <Input />
          </Form.Item>
        </Col>
      </Row>
      <Row gutter={16}>
        <Col span={14}>
          <Form.Item
            name={'dataIndex'}
            label={'Ключ Актусленд'}
            rules={[{ required: true, message: errorMessages.required }]}
          >
            <Input style={{ width: '100%' }} />
          </Form.Item>
        </Col>
        <Col span={10}>
          <Form.Item
            name={'type'}
            label={'Тип колонки'}
            rules={[{ required: true, message: errorMessages.required }]}
          >
            <ColumnTypeSelect />
          </Form.Item>
        </Col>
      </Row>
      <Row gutter={16}>
        <Col span={14}>
          <Form.Item name={'groupId'} label={'Группа колонок'}>
            <ColumnGroupSelect />
          </Form.Item>
        </Col>
        <Col span={10}>
          <Form.Item
            name={'contractType'}
            label={'Типы контрактов'}
            rules={[{ required: true, message: errorMessages.required }]}
          >
            <ColumnContractTypeSelect />
          </Form.Item>
        </Col>
      </Row>
      <Row gutter={16}>
        <Col span={14}>
          <Form.Item label={'Ширина'} name={'width'}>
            <InputNumber
              min={0}
              placeholder='Введите ширину в пикселях'
              style={{ width: '100%' }}
            />
          </Form.Item>
        </Col>
        <Col span={10}>
          <Form.Item
            name={'order'}
            label={'Порядок'}
            rules={[
              { required: true, message: errorMessages.required },
              { type: 'integer', message: errorMessages.integer },
            ]}
          >
            <InputNumber style={{ width: '100%' }} />
          </Form.Item>
        </Col>
      </Row>
      <Row gutter={16}>
        <Col span={8}>
          <Form.Item name={'isEditable'} valuePropName={'checked'}>
            <Checkbox>Редактируемое</Checkbox>
          </Form.Item>
        </Col>
        <Col span={8}>
          <Form.Item name={'isSortable'} valuePropName={'checked'}>
            <Checkbox>Сортируемое</Checkbox>
          </Form.Item>
        </Col>
        <Col span={8}>
          <Form.Item name={'isFilterable'} valuePropName={'checked'}>
            <Checkbox>Фильтруемое</Checkbox>
          </Form.Item>
        </Col>
      </Row>
      <Row gutter={16}>
        <Col span={8}>
          <Form.Item name={'isRequired'} valuePropName={'checked'}>
            <Checkbox>Обязательное</Checkbox>
          </Form.Item>
        </Col>
        <Col span={8}>
          <Form.Item name={'isFixed'} valuePropName={'checked'}>
            <Checkbox>Зафиксированное</Checkbox>
          </Form.Item>
        </Col>
      </Row>
    </>
  );
});
