import React, { useCallback, useState } from 'react';
import { MainLayout } from '@/components/layouts/MainLayout';
import { SubHeader } from '@/components/SubHeader';
import { Space } from 'antd';
import { DashboardButton } from '@/components/DasboardButton';
import { PlusCircleOutlined, ReloadOutlined } from '@ant-design/icons/lib';
import {
  CreateCoordinateSystemModal,
  CreateCoordinateSystemModalProps,
} from '@/components/modals/coordinateSystems/CreateCoordinateSystemModal';
import { useCoordinateSystemPageFetchingState } from '@/hooks/coordinates/coordinateSystems';
import { CoordinateSystemsTable } from '@/pages/Coordinates/CoordinateSystemsPage/CoordinateSystemsTable';

export const CoordinateSystemsPage: React.FC = React.memo(() => {
  const [createCoordinateSystemModalState, setCreateCoordinateSystemModalState] =
    useState<CreateCoordinateSystemModalProps>({
      visible: false,
    });

  const coordinateSystemPageFetchingState = useCoordinateSystemPageFetchingState();

  const onClickCreateNewColumnGroupHandler = useCallback(() => {
    setCreateCoordinateSystemModalState({
      visible: true,
      onClose: () => setCreateCoordinateSystemModalState({ visible: false }),
    });
  }, []);
  return (
    <MainLayout>
      <SubHeader>
        <SubHeader.Left>
          <Space size={10}>
            <DashboardButton
              icon={<PlusCircleOutlined />}
              onClick={onClickCreateNewColumnGroupHandler}
            >
              Добавить систему координат
            </DashboardButton>
            <DashboardButton
              icon={<ReloadOutlined />}
              loading={coordinateSystemPageFetchingState.isFetching}
              onClick={() => coordinateSystemPageFetchingState.refresh()}
            >
              Обновить таблицу
            </DashboardButton>
          </Space>
        </SubHeader.Left>
      </SubHeader>
      <CoordinateSystemsTable />
      <CreateCoordinateSystemModal {...createCoordinateSystemModalState} />
    </MainLayout>
  );
});
