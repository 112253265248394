import { RecordId } from '@/api/common/types';
import { UpdateUserRequest } from '@/api/Users/types';
import { useGetUserDetailQ, useUpdateUserM } from '@/hooks/users';
import { Form, message, Modal } from 'antd';
import React, { useCallback } from 'react';
import { UserFields } from '../common/UserFields';

export type EditUserModalProps =
  | {
      visible: false;
    }
  | ({ visible: true } & VisibleProps);

type VisibleProps = {
  userId: RecordId;
  onClose: () => void;
};
export const EditUserModal: React.FC<EditUserModalProps> = React.memo((props) => {
  const { visible } = props;

  if (!visible) {
    return null;
  }
  const { visible: _, ...visibleProps } = props;
  return <VisibleModal {...visibleProps} />;
});

export const VisibleModal: React.FC<VisibleProps> = React.memo((props) => {
  const { onClose, userId } = props;

  const { mutateAsync: updateUserM, isLoading: isLoadingUpdate } = useUpdateUserM();
  const { isLoading, data: user } = useGetUserDetailQ(userId);

  const [changes, setChanges] = React.useState<UpdateUserRequest['patch'] | null>(null);

  const [form] = Form.useForm();
  React.useEffect(() => {
    if (user) {
      form.setFieldsValue(user);
    }
  }, [form, user]);

  const onFormFinishHandler = useCallback(async () => {
    if (!changes) {
      return;
    }

    try {
      await updateUserM({ id: userId, patch: changes });
      message.success('Запись пользователя успешно изменена');
    } catch {
      message.error('Произошла ошибка при редактировании пользователя');
    }
  }, [changes, updateUserM, userId]);

  const onFormChangeHandler = (changedValues: UpdateUserRequest['patch']) => {
    setChanges((prev) => ({ ...prev, ...changedValues }));
  };

  return (
    <Modal
      visible={true}
      width={1000}
      title={
        isLoading ? 'Загрузка...' : `Редактировать пользователя (${user?.name} ${user?.secondName})`
      }
      okText={'Сохранить изменения'}
      onOk={form.submit}
      okButtonProps={{ disabled: changes === null, loading: isLoadingUpdate }}
      cancelText={'Отмена'}
      onCancel={onClose}
    >
      <Form
        form={form}
        onFinish={onFormFinishHandler}
        layout={'vertical'}
        onValuesChange={onFormChangeHandler}
      >
        <UserFields editProps={{ userId }} />
      </Form>
    </Modal>
  );
});
