import { coordinateService } from '@/globalStorage/coordinates/newIndex';
import { UiPointRecord, UiPolylineRecord } from '@/globalStorage/coordinates/types';
import { CloseOutlined } from '@ant-design/icons';
import { Button, Dropdown, InputNumber, Menu } from 'antd';
import cn from 'classnames';
import { useStore } from 'effector-react';
import React from 'react';
import './styles.scss';

export type PolylinePointItemProps = {
  point: UiPointRecord;
  polyline: UiPolylineRecord;
};

const _PolylinePointItem: React.FC<PolylinePointItemProps> = (props) => {
  const { point, polyline } = props;
  const selectedPointId = useStore(coordinateService.$selectedPointId);

  const isSelectedPoint = selectedPointId === point.id;

  return (
    <div
      className={cn('polyline-point-item', { 'polyline-point-item--selected': isSelectedPoint })}
      key={point.id}
      onClick={(e) => {
        e.stopPropagation();
        coordinateService.selectPoint({ polylineId: polyline.id, pointId: point.id });
      }}
    >
      <div className='polyline-point-item__cords-container'>
        <div className='polyline-point-item__cord'>
          <div className='polyline-point-item__cord-name'>X:</div>
          <div className='polyline-point-item__cord-value'>
            <InputNumber
              size='small'
              style={{ width: '100%' }}
              value={point.mskX}
              disabled={!isSelectedPoint}
              onChange={(value) => {
                if (value !== null) {
                  coordinateService.editPolylinePoint({
                    polylineId: polyline.id,
                    pointId: point.id,
                    patch: { mskX: value },
                  });
                }
              }}
            />
          </div>
        </div>
        <div className='polyline-point-item__cord'>
          <div className='polyline-point-item__cord-name'>Y:</div>
          <div className='polyline-point-item__cord-value'>
            <InputNumber
              size='small'
              style={{ width: '100%' }}
              value={point.mskY}
              disabled={!isSelectedPoint}
              onChange={(value) => {
                if (value !== null) {
                  coordinateService.editPolylinePoint({
                    polylineId: polyline.id,
                    pointId: point.id,
                    patch: { mskY: value },
                  });
                }
              }}
            />
          </div>
        </div>
      </div>

      <div className='polyline-point-item__actions'>
        <Dropdown
          overlay={
            <Menu>
              <Menu.Item
                key={'add-before'}
                onClick={() =>
                  coordinateService.addPointBeforePoint({
                    polylineId: polyline.id,
                    pointId: point.id,
                  })
                }
              >
                Сверху
              </Menu.Item>
              <Menu.Item
                key={'add-after'}
                onClick={() =>
                  coordinateService.addPointAfterPoint({
                    polylineId: polyline.id,
                    pointId: point.id,
                  })
                }
              >
                Снизу
              </Menu.Item>
            </Menu>
          }
        >
          <Button size='small' type='dashed'>
            Добавить точку
          </Button>
        </Dropdown>
        <Button
          icon={<CloseOutlined />}
          size='small'
          type='text'
          onClick={() => {
            coordinateService.deletePoint({ polylineId: polyline.id, pointId: point.id });
          }}
        />
      </div>
    </div>
  );
};
export const PolylinePointItem = React.memo(_PolylinePointItem);
