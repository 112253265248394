import { CadObjectStatus } from '@/api/CadObjects/types';
import { statusValueLabelMap } from '@/components/ServerConfigTable/StatusTag/statusValueLabelMap';
import { setTableFilterStatus } from '@/pages/MainTablePage/model';
import { Button } from 'antd';
import React from 'react';

export type StatusPropItemProps = {
  status: CadObjectStatus;
  count: number;
  percent: number;
  comment?: string;
};

const _StatusPropItem: React.FC<StatusPropItemProps> = (props) => {
  const { count, status, percent, comment } = props;

  return (
    <div className='contract-statistic-overlay__stat-item'>
      <div className='contract-statistic-overlay__stat-item-header'>
        {statusValueLabelMap.get(status)}
      </div>
      <div className='contract-statistic-overlay__stat-item-count'>{count} Шт.</div>
      <div className='contract-statistic-overlay__stat-item-percent'>({percent}%)</div>
      {count > 0 && (
        <Button
          className='contract-statistic-overlay__stat-item-show-btn'
          onClick={() => setTableFilterStatus(status)}
          type='link'
          size='small'
          block
        >
          Показать
        </Button>
      )}
      {comment && <div className='contract-statistic-overlay__stat-item-comment'>{comment}</div>}
    </div>
  );
};
export const StatusPropItem = React.memo(_StatusPropItem);
