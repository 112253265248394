import React from 'react';
import { Checkbox, Col, Form, Input, Row } from 'antd';
import { errorMessages } from '@/utils/errorMessages';
import { SelectContract } from '@/components/inputs/SelectContract';
import { SectionHeader } from '@/components/modals/common/SectionHeader';
import { CoordsTable } from './CoordsTable';

const _TpFields: React.FC<{
  onChangeExoticFormValues?: (changes: Record<string, any>) => void; // Не обращайте внимание на это убожество...
}> = () => {
  return (
    <>
      <Row gutter={18}>
        <Col span={10}>
          <Form.Item
            name={'title'}
            label={'Название ТП'}
            rules={[{ required: true, message: errorMessages.required }]}
          >
            <Input />
          </Form.Item>
        </Col>
        <Col span={10}>
          <Form.Item
            name={'contract'}
            label={'Контракт'}
            rules={[{ required: true, message: errorMessages.required }]}
          >
            <SelectContract />
          </Form.Item>
        </Col>
        <Col span={4}>
          <Form.Item
            name={'isActive'}
            label={' '}
            rules={[{ required: true, message: errorMessages.required }]}
            valuePropName={'checked'}
          >
            <Checkbox>Активность</Checkbox>
          </Form.Item>
        </Col>
      </Row>
      <SectionHeader
        rightAddition={
          <Form.Item name={'isAutoCalculateWgs'} valuePropName={'checked'} noStyle>
            <Checkbox>Автоматически рассчитать WGS после сохранения</Checkbox>
          </Form.Item>
        }
      >
        Координаты точек
      </SectionHeader>
      <Form.Item dependencies={['coords', 'isAutoCalculateWgs']}>
        {({ getFieldValue }) => {
          const isAutoCalculateWgs = getFieldValue('isAutoCalculateWgs');
          return <CoordsTable isAutoGenWgs={isAutoCalculateWgs} />;
        }}
      </Form.Item>
    </>
  );
};
export const TpFields = React.memo(_TpFields);
