import { DateFilterDropdown } from '@/components/filterDropdowns/DateFilterDropdown';
import { DateFilterValue } from '@/components/filterDropdowns/DateFilterDropdown/types';
import { errorMessages } from '@/utils/errorMessages';
import { DateView, EditableDate } from '@uspect-team/ant-ui-kit';
import { ControlledTableChildType } from '@uspect-team/ant-ui-kit/dist/Tables/ControlledTable/types';
import React from 'react';
import * as yup from 'yup';
import {
  BaseServerChildColumnType,
  DateServerChildColumnAddition,
  ServerConfigTableContext,
  TableRecordType,
} from '../../types';
import { dataIndexToStringKey } from '../dataIndexToStringKey';

export const getDateColumnAddition = (
  strSerCol: BaseServerChildColumnType & DateServerChildColumnAddition,
  context: ServerConfigTableContext,
  editable: boolean,
): Partial<ControlledTableChildType<TableRecordType>> => {
  let validationSchema = yup.date();
  if (strSerCol.isRequired) {
    validationSchema = validationSchema.required(errorMessages.required);
  }
  if (strSerCol.validation?.min) {
    validationSchema = validationSchema.min(
      strSerCol.validation.min,
      errorMessages.minDate(strSerCol.validation.min),
    );
  }
  if (strSerCol.validation?.max) {
    validationSchema = validationSchema.max(
      strSerCol.validation.max,
      errorMessages.maxDate(strSerCol.validation.max),
    );
  }

  const { dataIndex, isEditable, isFilterable, width } = strSerCol;
  const onEdit = context.onEdit;

  return {
    align: 'left',
    width: width ?? 200,
    filterDropdown: isFilterable
      ? ({ currentFilterState: _currentFilterState, setFilterValue, removeFilter }) => {
          const filter = _currentFilterState as DateFilterValue;
          return (
            <DateFilterDropdown
              filter={filter}
              onChangeFilter={setFilterValue}
              onResetFilter={removeFilter}
              hasBetween
              hasEqual
              hasExist
            />
          );
        }
      : undefined,
    render: (value, record) => {
      return isEditable && editable && record.status !== 'excluded' ? (
        <EditableDate
          value={value}
          validationSchema={validationSchema}
          onChange={
            onEdit
              ? (value) =>
                  onEdit({
                    patch: {
                      [dataIndexToStringKey(dataIndex)]: value ?? null,
                    },
                    oldRecord: record,
                  })
              : undefined
          }
        />
      ) : (
        <DateView value={value} />
      );
    },
  };
};
