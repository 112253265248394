import { CheckOutlined, CloseOutlined } from '@ant-design/icons';
import { FilterTypeOption } from '../common/FilterTypeSelect';
import { StringFilterType } from './types';
import React from 'react';

export const includesOption: FilterTypeOption<StringFilterType> = {
  value: 'includes',
  icon: <span style={{ fontSize: 8 }}>..|***|..</span>,
  label: 'Найти по подстроке',
};
export const equalOption: FilterTypeOption<StringFilterType> = {
  value: 'equal',
  icon: '=',
  label: 'Точное совпадение',
};
export const existOption: FilterTypeOption<StringFilterType> = {
  value: 'exist',
  icon: <CheckOutlined />,
  label: 'Заполнено',
};
export const notExistOption: FilterTypeOption<StringFilterType> = {
  value: 'notExist',
  icon: <CloseOutlined />,
  label: 'Не заполнено',
};
