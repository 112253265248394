import { coordinateService } from '@/globalStorage/coordinates/newIndex';
import { Button } from 'antd';
import { useStore } from 'effector-react';
import React from 'react';
import { CoordsImportModal, CoordsImportModalProps } from './CoordsImportModal';
import { PolylineItem } from './PolylineItem';
import './styles.scss';

export type PolylineListProps = {
  className?: string;
  style?: React.CSSProperties;
};

const _PolylineList: React.FC<PolylineListProps> = () => {
  const polylineList = useStore(coordinateService.$polylineList);

  const [coordsImportModalState, setCoordsImportModalState] =
    React.useState<CoordsImportModalProps>({ visible: false });

  const openCoordsImport = () => {
    setCoordsImportModalState({
      visible: true,
      onClose: () => setCoordsImportModalState({ visible: false }),
      onSuccess: (coords) => coordinateService.addNewPolylineWithCords(coords),
    });
  };

  return (
    <div className='polyline-list'>
      <div className='polyline-list__header'>Список линий</div>
      <div className='polyline-list__items-container'>
        {polylineList.map((poly, index) => (
          <PolylineItem
            key={poly.id}
            polyline={poly}
            number={index + 1}
            className={'polyline-list__polyline-item'}
          />
        ))}
      </div>
      <div className='polyline-list__add-polyline-zone'>
        <Button
          className='polyline-list__add-polyline'
          onClick={() => coordinateService.addNewPolyline()}
        >
          Добавить линию
        </Button>
        <Button onClick={openCoordsImport}>Импортировать линию</Button>
      </div>

      <CoordsImportModal {...coordsImportModalState} />
    </div>
  );
};
export const PolylineList = React.memo(_PolylineList);
