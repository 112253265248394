import React, { useCallback } from 'react';
import { Select, SelectProps } from 'antd';
import { RecordId } from '@/api/common/types';
import { ContractRecordResponse } from '@/api/Contracts/types';
import { useGetContractsAllQ } from '@/hooks/contracts';

type Props = {
  value?: RecordId | null;
  onChange?: (value?: RecordId) => void;
  onChangeContract?: (value?: ContractRecordResponse) => void;
} & Omit<SelectProps<string>, 'value' | 'onChange'>;
const _SelectContract: React.FC<Props> = (props) => {
  const { className, style, value, onChange, onChangeContract, ...selectProps } = props;
  const { data: allContracts, isLoading, isSuccess } = useGetContractsAllQ();
  const onChangeHandler = useCallback(
    (value: RecordId) => {
      onChange && onChange(value);
      onChangeContract && onChangeContract(allContracts?.find((x) => x._id === value));
    },
    [onChange, onChangeContract, allContracts],
  );

  return (
    <Select
      loading={isLoading}
      value={isSuccess ? value : undefined}
      onChange={onChangeHandler}
      optionFilterProp='children'
      filterOption={(input, option) => {
        const childrenText = option?.children as string | null | undefined;
        return childrenText?.toLowerCase().includes(input.toLowerCase()) || false;
      }}
      {...selectProps}
    >
      {allContracts?.map((x) => (
        <Select.Option key={x._id} value={x._id}>
          {x.title}
        </Select.Option>
      ))}
    </Select>
  );
};
export const SelectContract = React.memo(_SelectContract);
