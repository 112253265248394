import { EmployeesApi } from '@/api/Dictionary/Employees';
import { OrganizationsApi } from '@/api/Dictionary/Organizations';
import { ColumnGroupsApi } from '@/api/Dictionary/ColumnGroups';
import { ColumnsApi } from '@/api/Dictionary/Columns';
import { CptApi } from '@/api/Dictionary/Cpt';
import { DisplayModsApi } from '@/api/Dictionary/DisplayMods';

export class DictionaryApi {
  cpt = new CptApi();
  employees = new EmployeesApi();
  organizations = new OrganizationsApi();
  columnGroups = new ColumnGroupsApi();
  columns = new ColumnsApi();
  displayMods = new DisplayModsApi();
}
