import { GetFilesPageRequest, GetCoupleFilesPageRequest } from '@/api/Files/types';
import { useMutation, useQuery, useQueryClient } from 'react-query';
import { Api } from '@/api';
import { filesQueryKeys } from '@/hooks/files/queryKeys';

export const useGetFilesPageQ = (params: GetFilesPageRequest = {}) => {
  return useQuery(filesQueryKeys.page(params), () => {
    return Api.files.get(params);
  });
};

export const useGetCoupleFilesPageQ = (data: GetCoupleFilesPageRequest) => {
  return useQuery(
    filesQueryKeys.couplePage(data),
    () => {
      return Api.files.getCouples(data);
    },
    {
      select: (resp) => resp.data,
    },
  );
};

export const useGetFileByIdQ = (id: string) => {
  return useQuery(filesQueryKeys.detail(id), () => {
    return Api.files.getById(id);
  });
};

export const useUploadFileM = () => {
  const queryClient = useQueryClient();
  return useMutation(filesQueryKeys.upload(), Api.files.upload, {
    onSuccess: async () => {
      await Promise.all([
        queryClient.invalidateQueries(filesQueryKeys.pages()),
        queryClient.invalidateQueries(filesQueryKeys.couplePages()),
      ]);
    },
  });
};
