import React, { useCallback, useEffect, useMemo, useState } from 'react';
import { ControlledTable } from '@uspect-team/ant-ui-kit';
import { ControlledTableProps } from '@uspect-team/ant-ui-kit/dist/Tables/ControlledTable/ControlledTable';
import { ServerConfigTableConfig, ServerConfigTableContext, ServerConfigTableState } from './types';
import {
  ControlledTableColumns,
  ControlledTableState,
} from '@uspect-team/ant-ui-kit/dist/Tables/ControlledTable/types';
import { getControlledTableColumns } from './utils/getControledTableColumns';
import {
  mapControlledStateToServer,
  mapServerStateToControlled,
} from '@/components/ServerConfigTable/utils/tableStateMapping';
import './styles.scss';
import { getRowClassByCadObject } from '@/components/ServerConfigTable/utils/getRowClassByCadObject';

const defaultPageSizeOptions = [10, 20, 30, 50, 100];

type Props = Omit<
  ControlledTableProps<any>,
  'columns' | 'pagination' | 'tableState' | 'onChangeTableState'
> & {
  serverConfig: ServerConfigTableConfig;
  clientColumns?: ControlledTableColumns<any>;
  tableContext: ServerConfigTableContext;
  tableState: ServerConfigTableState;
  onChangeTableState: (state: ServerConfigTableState) => void;
  editable: boolean;
};

export const ServerConfigTable: React.FC<Props> = (props) => {
  const {
    clientColumns,
    serverConfig,
    tableContext,
    tableState: _tableState,
    onChangeTableState,
    editable,
    ...directProps
  } = props;
  const [tableState, setTableState] = useState(_tableState);
  const [sortString, setSortString] = useState<undefined | string>(undefined);
  const [filterString, setFilterString] = useState<undefined | string>(undefined);

  const columns = getControlledTableColumns(
    clientColumns || [],
    serverConfig.columns,
    tableContext,
    editable,
  );

  // const columns = useMemo(() => {
  //   return getControlledTableColumns(clientColumns || [], serverConfig.columns, tableContext);
  // }, [clientColumns, serverConfig.columns, tableContext]);

  const controlledTableState = mapServerStateToControlled(tableState);

  // const controlledTableState = useMemo(() => {
  //   return mapServerStateToControlled(tableState);
  // }, [tableState]);

  const onChangeControlledTableStateHandler = useCallback(
    (cTableState: ControlledTableState) => {
      const { sort, filter } = cTableState;

      const isSortChanges =
        JSON.stringify(sort) !==
          (sortString === undefined ? JSON.stringify(sortString) : sortString) &&
        Object.keys(sort ?? {}).length > 0;

      const isFilterChanges =
        JSON.stringify(filter) !==
          (filterString === undefined ? JSON.stringify(filterString) : filterString) &&
        Object.keys(filter ?? {}).length > 0;

      if (isSortChanges || isFilterChanges) {
        setSortString(JSON.stringify(sort));
        setFilterString(JSON.stringify(filter));
        onChangeTableState(
          mapControlledStateToServer({
            filter,
            sort,
            pagination: { pageSize: cTableState.pagination?.pageSize ?? 10, currentPage: 1 },
          }),
        );
      } else {
        onChangeTableState(mapControlledStateToServer(cTableState));
      }
    },
    [filterString, onChangeTableState, sortString],
  );

  const pagination = useMemo(() => {
    return {
      type: serverConfig.paginationType,
      pageSizeOptions: defaultPageSizeOptions,
    };
  }, [serverConfig.paginationType]);
  useEffect(() => {
    if (_tableState) setTableState(_tableState);
  }, [_tableState]);
  return (
    <ControlledTable
      className='server-config-table'
      columns={columns}
      tableState={controlledTableState}
      onChangeTableState={onChangeControlledTableStateHandler}
      {...directProps}
      pagination={pagination}
      rowClassName={getRowClassByCadObject}
      tableLayout='fixed'
      scroll={{ x: '100%' }}
    />
  );
};
