import { ForwardButton } from '@/components/ForwardButton';
import { AppInput } from '@/components/inputs/AppInput';
import { AddressNameInput } from '@/components/inputs/special/dadata/AddressNameInput';
import { OrganizationNameInput } from '@/components/inputs/special/dadata/OrganizationNameInput';
import { registerStateService } from '@/globalStorage/register';
import { errorMessages } from '@/utils/errorMessages';
import { Checkbox, Form } from 'antd';
import { Rule } from 'antd/lib/form';
import React from 'react';
import { DaDataParty, DaDataSuggestion } from 'react-dadata';
import './styles.scss';

import { RegisterJuridicalInfoForm } from './types';

const requiredRules: Rule[] = [{ required: true, message: errorMessages.required }];

const _RegisterJuridicalInfoStep: React.FC = () => {
  const [form] = Form.useForm<RegisterJuridicalInfoForm>();

  const onSubmitForm = (values: RegisterJuridicalInfoForm) => {
    registerStateService.finishSecondStep(
      values.isJuridicalAndRealAddressEqual
        ? { ...values, realAddress: values.juridicalAddress }
        : values,
    );
  };

  const onOrganizationChangeHandler = (
    value: string,
    suggestion?: DaDataSuggestion<DaDataParty>,
  ) => {
    let director: null | {
      firstName: string | null;
      secondName: string | null;
      patronymic: string | null;
    } = null;
    if (suggestion?.data.management) {
      const [secondName, firstName, patronymic]: (string | undefined)[] =
        suggestion.data.management.name.split(' ');

      director = {
        firstName: firstName ?? null,
        secondName: secondName ?? null,
        patronymic: patronymic ?? null,
      };
    }

    form.setFieldsValue({
      companyName: value,
      inn: suggestion?.data.inn,
      ogrn: suggestion?.data.ogrn,
      juridicalAddress: suggestion?.data.address.value,
      directorSecondName: director?.secondName ?? undefined,
      directorFirstName: director?.firstName ?? undefined,
      directorPatronymic: director?.patronymic ?? undefined,
    });
  };

  return (
    <Form form={form} onFinish={onSubmitForm} autoComplete={'off'}>
      <Form.Item name={'companyName'} rules={requiredRules}>
        <OrganizationNameInput
          className='register-second-step__input-org'
          placeholder='Наименование юр.лица*'
          onChange={onOrganizationChangeHandler}
        />
      </Form.Item>
      <Form.Item name={'inn'} rules={requiredRules}>
        <AppInput className='register-second-step__input' placeholder='ИНН*' />
      </Form.Item>
      <Form.Item name={'ogrn'} rules={requiredRules}>
        <AppInput className='register-second-step__input' placeholder='ОГРН*' />
      </Form.Item>
      <Form.Item name={'juridicalAddress'} rules={requiredRules}>
        <AddressNameInput
          className='register-second-step__input-address'
          placeholder='Юридический адрес*'
        />
      </Form.Item>
      <Form.Item noStyle dependencies={['isJuridicalAndRealAddressEqual']}>
        {({ getFieldValue }) => {
          const isJuridicalAndRealAddressEqual = getFieldValue('isJuridicalAndRealAddressEqual');
          if (!isJuridicalAndRealAddressEqual) {
            return (
              <Form.Item name={'realAddress'} rules={requiredRules}>
                <AddressNameInput
                  className='register-second-step__input-address'
                  placeholder='Фактический адрес*'
                />
              </Form.Item>
            );
          }
          return null;
        }}
      </Form.Item>
      <Form.Item name={'isJuridicalAndRealAddressEqual'} valuePropName='checked'>
        <Checkbox style={{ color: 'white' }}>Фактический адрес совпадает с юридическим</Checkbox>
      </Form.Item>
      <Form.Item name={'bik'} rules={requiredRules}>
        <AppInput className='register-second-step__input' placeholder='БИК*' />
      </Form.Item>
      <Form.Item name={'settlementAccount'} rules={requiredRules}>
        <AppInput className='register-second-step__input' placeholder='Расчетный счет*' />
      </Form.Item>
      <Form.Item name={'correctionAccount'} rules={requiredRules}>
        <AppInput className='register-second-step__input' placeholder='Корр. счет*' />
      </Form.Item>
      <Form.Item name={'directorSecondName'} rules={requiredRules}>
        <AppInput
          className='register-second-step__input'
          placeholder='Фамилия ген. директора*'
          autoComplete='off'
        />
      </Form.Item>
      <Form.Item name={'directorFirstName'} rules={requiredRules}>
        <AppInput
          className='register-second-step__input'
          placeholder='Имя ген. директора*'
          autoComplete='off'
        />
      </Form.Item>
      <Form.Item name={'directorPatronymic'} rules={requiredRules}>
        <AppInput
          className='register-second-step__input'
          placeholder='Отчество ген. директора*'
          autoComplete='off'
        />
      </Form.Item>
      <ForwardButton htmlType='submit' block size='large' type='primary'>
        Далее
      </ForwardButton>
    </Form>
  );
};
export const RegisterJuridicalInfoStep = React.memo(_RegisterJuridicalInfoStep);
