import { MaskedStringInput } from '@uspect-team/ant-ui-kit';
import React from 'react';
import { MaskedStringInputProps } from '@uspect-team/ant-ui-kit/dist/Inputs/MaskedStringInput';

const mask: MaskedStringInputProps['mask'] = [/\d/, /\d/, ' ', /\d/, /\d/];

type PassportSeriesInputProps = Omit<MaskedStringInputProps, 'mask'>;

const _PassportSeriesInput: React.FC<PassportSeriesInputProps> = (props) => {
  return <MaskedStringInput mask={mask} placeholder={'12 34'} {...props} />;
};

export const PassportSeriesInput = React.memo(_PassportSeriesInput);
