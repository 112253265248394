import React from 'react';
import { Col, DatePicker, Form, Input, Row } from 'antd';
import { errorMessages } from '@/utils/errorMessages';
import { AddressSearchSelect, OrganizationSearchSelect } from '@uspect-team/ant-ui-kit';
import moment from 'moment';
import { SectionHeader } from '@/components/modals/common/SectionHeader';

export const OrganizationFields: React.FC<{
  onChangeExoticFormValues?: (changes: Record<string, any>) => void; // Не обращайте внимание на это убожество...
}> = React.memo((props) => {
  const { onChangeExoticFormValues } = props;
  return (
    <>
      <Form.Item noStyle dependencies={['title']}>
        {({ setFieldsValue, getFieldValue }) => {
          const title = getFieldValue('title');
          return (
            <Form.Item
              name={'title'}
              rules={[{ required: true, message: errorMessages.required }]}
              label={'Наименование'}
              dependencies={['title']}
              getValueFromEvent={(x) => x?.value}
            >
              <OrganizationSearchSelect
                inputProps={{
                  placeholder: title,
                }}
                onChange={(suggestion) => {
                  let changes = {
                    title: suggestion?.value,
                    address: suggestion?.data.address,
                    inn: suggestion?.data.inn,
                    ogrn: suggestion?.data.ogrn,
                    regDate: suggestion?.data.ogrn_date
                      ? moment(suggestion.data.ogrn_date)
                      : undefined,
                    okato: suggestion?.data.address.data.okato,
                    kladr: suggestion?.data.address.data.kladr_id,
                    oktmo: suggestion?.data.address.data.oktmo,
                    regionCode: suggestion?.data.address.data.region_kladr_id.replace(/0+$/, ''),
                    directorFirstName: '',
                    directorSecondName: '',
                    directorPatronimic: '',
                  };

                  if (suggestion?.data.management?.post === 'ГЕНЕРАЛЬНЫЙ ДИРЕКТОР') {
                    const fullName = suggestion.data.management.name;
                    const [secondName, firstName, patronimic] = fullName.match(/([a-яa-z]+)/gi) ?? [
                      null,
                      null,
                      null,
                    ];
                    changes = {
                      ...changes,
                      directorFirstName: firstName ?? '',
                      directorSecondName: secondName ?? '',
                      directorPatronimic: patronimic ?? '',
                    };
                  }
                  setFieldsValue(changes);
                  onChangeExoticFormValues && onChangeExoticFormValues(changes);
                }}
              />
            </Form.Item>
          );
        }}
      </Form.Item>
      <Form.Item
        name={'address'}
        label={'Адрес'}
        rules={[{ required: true, message: errorMessages.required }]}
      >
        <AddressSearchSelect block />
      </Form.Item>
      <Row gutter={16}>
        <Col span={8}>
          <Form.Item
            name={'inn'}
            label={'ИНН'}
            rules={[{ required: true, message: errorMessages.required }]}
          >
            <Input style={{ width: '100%' }} placeholder={'Например: 3664069397'} />
          </Form.Item>
        </Col>
        <Col span={8}>
          <Form.Item
            name={'ogrn'}
            label={'ОГРН'}
            rules={[{ required: true, message: errorMessages.required }]}
          >
            <Input style={{ width: '100%' }} placeholder={'Например: 1053600591197'} />
          </Form.Item>
        </Col>
        <Col span={8}>
          <Form.Item
            name={'regDate'}
            label={'Дата регистрации организации'}
            rules={[{ required: true, message: errorMessages.required }]}
          >
            <DatePicker style={{ width: '100%' }} />
          </Form.Item>
        </Col>
      </Row>
      <Row gutter={16}>
        <Col span={6}>
          <Form.Item
            name={'okato'}
            label={'ОКАТО'}
            rules={[{ required: true, message: errorMessages.required }]}
          >
            <Input style={{ width: '100%' }} placeholder={'12345678910'} />
          </Form.Item>
        </Col>
        <Col span={6}>
          <Form.Item
            name={'kladr'}
            label={'КЛАДР'}
            rules={[{ required: true, message: errorMessages.required }]}
          >
            <Input style={{ width: '100%' }} placeholder={'0123456789012'} />
          </Form.Item>
        </Col>
        <Col span={6}>
          <Form.Item
            name={'oktmo'}
            label={'ОКТМО'}
            rules={[{ required: true, message: errorMessages.required }]}
          >
            <Input style={{ width: '100%' }} placeholder={'01234567890'} />
          </Form.Item>
        </Col>
        <Col span={6}>
          <Form.Item
            name={'regionCode'}
            label={'Код региона'}
            rules={[{ required: true, message: errorMessages.required }]}
          >
            <Input style={{ width: '100%' }} placeholder={'0123456789012'} />
          </Form.Item>
        </Col>
      </Row>
      {/* <Form.Item
        label={'XML Область "Локация"'}
        rules={[{ required: true, message: errorMessages.required }]}
        name={'location'}
      >
        <Input.TextArea rows={3} allowClear />
      </Form.Item> */}
      <SectionHeader>Генеральный директор</SectionHeader>
      <Row gutter={16}>
        <Col span={8}>
          <Form.Item
            name={'directorSecondName'}
            label={'Фамилия'}
            rules={[{ required: true, message: errorMessages.required }]}
          >
            <Input style={{ width: '100%' }} />
          </Form.Item>
        </Col>
        <Col span={8}>
          <Form.Item
            name={'directorFirstName'}
            label={'Имя'}
            rules={[{ required: true, message: errorMessages.required }]}
          >
            <Input style={{ width: '100%' }} />
          </Form.Item>
        </Col>
        <Col span={8}>
          <Form.Item
            name={'directorPatronimic'}
            label={'Отчество'}
            rules={[{ required: true, message: errorMessages.required }]}
          >
            <Input style={{ width: '100%' }} />
          </Form.Item>
        </Col>
      </Row>
    </>
  );
});
