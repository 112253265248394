import { Row } from 'antd';
import React from 'react';
import { ManagementFinanceLeftCard } from './ManagementFinanceLeftCard';
import { ManagementFinanceRightCard } from './ManagementFinanceRightCard';

const _ManagementFinance: React.FC = () => {
  return (
    <div className='management-item__wrapper'>
      <Row gutter={20}>
        <ManagementFinanceLeftCard />
        <ManagementFinanceRightCard />
      </Row>
    </div>
  );
};
export const ManagementFinance = React.memo(_ManagementFinance);
