import React from 'react';
import cn from 'classnames';
import { TdRecordResponse } from '@/api/Td/types';
import { formatDateToString } from '@uspect-team/ant-ui-kit';
import './styles.scss';

type Props = {
  className?: string;
  style?: any;
  record: TdRecordResponse;
};
export const RecordPopover: React.FC<Props> = (props) => {
  const { className, style, record } = props;

  return (
    <div className={cn('pd-record-popover', className)} style={style}>
      <div className='pd-record-popover__desc-item'>
        <div className='pd-record-popover__prop-name'>Серия:</div>
        <div className='pd-record-popover__prop-value'>{record.series}</div>
      </div>
      <div className='pd-record-popover__desc-item'>
        <div className='pd-record-popover__prop-name'>Номер:</div>
        <div className='pd-record-popover__prop-value'>{record.number}</div>
      </div>
      <div className='pd-record-popover__desc-item'>
        <div className='pd-record-popover__prop-name'>Код документа технокад:</div>
        <div className='pd-record-popover__prop-value'>{record.technokadCode}</div>
      </div>
      <div className='pd-record-popover__desc-item'>
        <div className='pd-record-popover__prop-name'>Исполнительный орган:</div>
        <div className='pd-record-popover__prop-value'>{record.ispOrg ?? '-'}</div>
      </div>
      <div className='pd-record-popover__desc-item'>
        <div className='pd-record-popover__prop-name'>Дата создания:</div>
        <div className='pd-record-popover__prop-value'>
          {formatDateToString(new Date(record.createdAt))}
        </div>
      </div>
    </div>
  );
};
