import { RecordId } from '@/api/common/types';
import { useGetContractStatistic } from '@/hooks/statistics';
import { ReloadOutlined } from '@ant-design/icons';
import { Button, message } from 'antd';
import cn from 'classnames';
import { useStore } from 'effector-react';
import React from 'react';
import { $isCanEditContract } from '../model';
import { DetailStatisticButton } from './DetailStatisticButton';
import { NotifyButton } from './NotifyButton';
import { PropItem } from './PropItem';
import './styles.scss';

type StatisticBarProps = {
  className?: string;
  style?: any;
  contractId: RecordId;
};

const _StatisticBar: React.FC<StatisticBarProps> = (props) => {
  const { className, style, contractId } = props;
  const { data, isSuccess, isFetching, refetch } = useGetContractStatistic(contractId);

  const isCanEditContract = useStore($isCanEditContract);

  const onRefreshClick = React.useCallback(async () => {
    try {
      await refetch();
      message.success('Статистика обновлена');
    } catch {
      message.error('Не удалось обновить статистику');
    }
  }, [refetch]);

  if (data && isSuccess) {
    return (
      <div className={cn('statistic-bar', className)} style={style}>
        <div className='statistic-bar__prop-list'>
          <div className='statistic-bar__prop statistic-bar__prop--all-objects'>
            <div className='statistic-bar__prop-name'>Всего объектов:</div>
            <div className='statistic-bar__prop-value'>
              <div className='statistic-bar__prop-value-count'>{data.allCadObjectsInContracts}</div>
            </div>
          </div>

          {data.common.map((prop) => {
            return (
              <PropItem
                key={prop.header}
                header={prop.header}
                count={prop.count}
                percent={prop.percent}
                comment={prop.comment}
              />
            );
          })}
        </div>
        <DetailStatisticButton contractId={contractId} />

        <div className='statistic-bar__right'>
          <Button
            className={'statistic-bar__btn'}
            type={'text'}
            icon={<ReloadOutlined spin={isFetching} />}
            onClick={onRefreshClick}
            shape='circle'
          />
          {isCanEditContract && <NotifyButton contractId={contractId} />}
        </div>
      </div>
    );
  } else {
    return null;
  }
};

export const StatisticBar = React.memo(_StatisticBar);
