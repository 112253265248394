import { sleep } from './../../utils/sleep';
import { createDomain, sample } from 'effector';
import { createVarStore } from '../utils/createVarStore';
import { DEFAULT_REGISTER_STATE } from './constants';
import {
  RegisterCompanyInfo,
  RegisterJuridicalInfo,
  RegisterPersonalInfo,
  RegisterState,
} from './types';

const registerDomain = createDomain();

const {
  $store: $registerState,
  setStore: setRegisterState,
  resetStore: resetRegisterState,
} = createVarStore<RegisterState>(DEFAULT_REGISTER_STATE);

const finishFirstStep = registerDomain.createEvent<RegisterPersonalInfo>();
sample({
  clock: finishFirstStep,
  source: $registerState,
  filter: (registerState) => registerState.step === 1,
  fn: (_, personalInfo) => {
    return { step: 2, data: { personalInfo } } as RegisterState;
  },
  target: setRegisterState,
});

const finishSecondStep = registerDomain.createEvent<RegisterJuridicalInfo>();
sample({
  clock: finishSecondStep,
  source: $registerState,
  filter: (registerState) => registerState.step === 2,
  fn: (registerState, juridicalInfo) => {
    if (registerState.step === 2) {
      return {
        step: 3,
        data: { ...registerState.data, juridicalInfo },
      } as RegisterState;
    }

    return registerState;
  },
  target: setRegisterState,
});

const finishThirdStep = registerDomain.createEvent<RegisterCompanyInfo>();
sample({
  clock: finishThirdStep,
  source: $registerState,
  filter: (registerState) => registerState.step === 3,
  fn: (registerState, companyInfo) => {
    if (registerState.step === 3) {
      return {
        step: 4,
        data: { ...registerState.data, companyInfo },
      } as RegisterState;
    }

    return registerState;
  },
  target: setRegisterState,
});

const finishFourStep = registerDomain.createEvent();
sample({
  clock: finishFourStep,
  source: $registerState.map((x) => x.step),
  filter: (step) => step === 4,
  fn: () => {
    return {
      step: 5,
    } as RegisterState;
  },
  target: setRegisterState,
});

const registerFx = registerDomain.createEffect(async () => {
  await sleep(6000);
  finishFourStep();
});

sample({
  clock: $registerState,
  filter: (registerState) => registerState.step === 4,
  target: registerFx,
});

export const registerStateService = {
  $registerState,
  finishFirstStep,
  finishSecondStep,
  finishThirdStep,
  resetRegisterState,
};
