import { CadObjectResponse } from '@/api/CadObjects/types';
import { RecordId } from '@/api/common/types';
import { FileRecordResponse, FileType } from '@/api/Files/types';
import { FileCard } from '@/components/FileCard';
import { EditFileCollectionBase } from '@/components/modals/common/EditFileCollectionBase';
import { getFileColumns } from '@/components/modals/utils/fileColumns';
import { openSelectAlreadyExistFileModal } from '@/globalStorage/file';
import {
  useCadObjectFilesQ,
  useDeleteFileInCadObjectCollectionM,
  usePatchCadObjectM,
} from '@/hooks/cadastralObjects';
import { useUploadFileM } from '@/hooks/files';
import { getAllowedExtByFileType } from '@/utils/fileUtils';
import { ControlledTable, FileLoader } from '@uspect-team/ant-ui-kit';
import { ControlledTableState } from '@uspect-team/ant-ui-kit/dist/Tables/ControlledTable/types';
import { Button, message, Modal } from 'antd';
import { RcFile } from 'antd/lib/upload';
import { AxiosError } from 'axios';
import React, { useCallback, useMemo, useState } from 'react';

export type EditFileCollectionInCadModalProps =
  | { visible: false }
  | ({ visible: true } & VisibleProps);

type VisibleProps = {
  contractId: RecordId;
  onClose: () => void;
  fileType: FileType;
  dataIndex: string;
  name: string;
  cadObjectId: string;
};
export const EditFileCollectionInCadModal: React.FC<EditFileCollectionInCadModalProps> = React.memo(
  (props) => {
    const { visible } = props;

    if (!visible) {
      return null;
    }

    const { visible: _, ...visibleProps } = props;

    return <VisibleModal {...visibleProps} />;
  },
);
const VisibleModal: React.FC<VisibleProps> = React.memo((props) => {
  const { onClose, fileType, dataIndex, name, cadObjectId, contractId } = props;
  const uploadFileM = useUploadFileM();
  const deleteFileM = useDeleteFileInCadObjectCollectionM();
  const patchCadObjectM = usePatchCadObjectM();
  const { data: currentFiles = [], isLoading } = useCadObjectFilesQ({
    id: cadObjectId,
    dataIndex: dataIndex as keyof CadObjectResponse,
  });

  const [tableState, setTableState] = useState<ControlledTableState>({
    pagination: { currentPage: 1, pageSize: 10 },
  });
  const [uploadFile, setUploadFile] = useState<RcFile | null>(null);
  const [uploadAlreadyLoaded, setUploadAlreadyLoaded] = useState<FileRecordResponse | null>(null);

  const allowedExts = useMemo(() => {
    return getAllowedExtByFileType(fileType);
  }, [fileType]);

  const title = React.useMemo(() => {
    return `${name}: Редактирование коллекции файлов`;
  }, [name]);

  const uploadFileHandler = useCallback((file: RcFile) => {
    setUploadFile(file);
    setUploadAlreadyLoaded(null);
  }, []);

  const selectAlreadyLoadedFileHandler = useCallback((file: FileRecordResponse) => {
    setUploadFile(null);
    setUploadAlreadyLoaded(file);
  }, []);

  const clickSelectAlreadyExistHandler = useCallback(() => {
    openSelectAlreadyExistFileModal({
      contractId,
      title: `${name}: Выбор уже загруженных файлов`,
      fileType: fileType,
      onSelectFile: selectAlreadyLoadedFileHandler,
    });
  }, [contractId, name, fileType, selectAlreadyLoadedFileHandler]);

  const onOkHandler = useCallback(async () => {
    try {
      if (uploadFile || uploadAlreadyLoaded) {
        if (uploadFile) {
          const uploadRes = await uploadFileM.mutateAsync({
            type: fileType,
            files: [uploadFile],
            contractId,
          });
          const newFileRes = uploadRes.data.data[0];

          const newFileIds = currentFiles.map((x) => x._id);
          newFileIds.push(newFileRes._id);
          await patchCadObjectM.mutateAsync({
            id: cadObjectId,
            patch: { [dataIndex]: newFileIds },
          });
        } else if (uploadAlreadyLoaded) {
          const newFileIds = currentFiles.map((x) => x._id);
          newFileIds.push(uploadAlreadyLoaded._id);
          await patchCadObjectM.mutateAsync({
            id: cadObjectId,
            patch: { [dataIndex]: newFileIds },
          });
        }

        setUploadAlreadyLoaded(null);
        setUploadFile(null);
      }
    } catch (e) {
      if ((e as AxiosError).response?.status === 409) {
        message.error(
          'Файл с таким именем уже загружен. Выберите его или переименуйте и загрузите этот файл',
        );
      } else {
        message.error('При загрузке файла произошла ошибка');
      }
    }
  }, [
    contractId,
    cadObjectId,
    currentFiles,
    fileType,
    dataIndex,
    uploadFile,
    uploadAlreadyLoaded,
    patchCadObjectM,
    uploadFileM,
  ]);

  const columns = useMemo(() => {
    return getFileColumns({
      onDelete: async (fileId) => {
        await deleteFileM.mutateAsync({
          id: cadObjectId,
          dataIndex: dataIndex as keyof CadObjectResponse,
          deleteFileId: fileId,
        });
      },
    });
  }, [cadObjectId, dataIndex, deleteFileM]);

  return (
    <Modal
      title={title}
      visible={true}
      onCancel={onClose}
      width={1000}
      onOk={onOkHandler}
      okText={'Сохранить новый файл'}
      okButtonProps={{
        loading: uploadFileM.isLoading || patchCadObjectM.isLoading,
        disabled: !uploadAlreadyLoaded && !uploadFile,
      }}
      cancelText={'Отмена'}
    >
      <EditFileCollectionBase>
        <EditFileCollectionBase.Table>
          <ControlledTable
            rowKey={(x) => x._id}
            tableState={tableState}
            onChangeTableState={setTableState}
            pagination={{ type: 'client' }}
            dataSource={currentFiles}
            columns={columns}
            loading={isLoading}
          />
        </EditFileCollectionBase.Table>
        {(uploadFile || uploadAlreadyLoaded) && (
          <EditFileCollectionBase.NewFile>
            {uploadFile && (
              <FileCard
                title={'Новый файл'}
                file={{
                  name: uploadFile.name,
                  size: uploadFile.size,
                  uploadAt: 'now',
                }}
                onDelete={() => setUploadFile(null)}
              />
            )}
            {uploadAlreadyLoaded && (
              <FileCard
                title={'Уже существующий'}
                file={{
                  name: uploadAlreadyLoaded.fileName,
                  size: uploadAlreadyLoaded.size,
                  uploadAt: new Date(uploadAlreadyLoaded.createdAt),
                }}
                onDelete={() => setUploadAlreadyLoaded(null)}
              />
            )}
          </EditFileCollectionBase.NewFile>
        )}

        <EditFileCollectionBase.Upload>
          <FileLoader allowedFiles={[allowedExts]} onUpload={uploadFileHandler} />
          <Button block type={'link'} onClick={clickSelectAlreadyExistHandler}>
            Или выберите среди уже загруженных
          </Button>
        </EditFileCollectionBase.Upload>
      </EditFileCollectionBase>
    </Modal>
  );
});
