import { RecordId } from '@/api/common/types';
import { ColumnRecordResponse } from '@/api/Dictionary/Columns/types';
import { AntdLabelMimic } from '@/components/formHelpers/AntdLabelMimic';
import { CadObjectNumbersTextarea } from '@/components/inputs/CadObjectNumbersTextarea';
import { DatePicker } from '@/components/inputs/DatePicker';
import { StepContent } from '@/components/StepContent';
import { useCadObjectsAutocompleteM } from '@/hooks/cadastralObjects';
import { useGetContractAutocompletableColumnsByIdQ } from '@/hooks/contracts';
import { errorMessages } from '@/utils/errorMessages';
import { AddressSearchSelect } from '@uspect-team/ant-ui-kit';
import {
  Button,
  Checkbox,
  Form,
  Input,
  InputNumber,
  message,
  Modal,
  Popconfirm,
  Space,
  Steps,
  Transfer,
} from 'antd';
import { Rule } from 'antd/lib/form';
import React from 'react';
import './styles.scss';
import { FinalFieldFillingWizardForm } from './types';

export type FieldFillingWizardModalProps =
  | {
      visible: false;
    }
  | ({ visible: true } & VisibleProps);
type VisibleProps = {
  contractId: RecordId;
  initialCadObjectNumbers?: number[];
  onClose: () => void;
};

const _FieldFillingWizardModal: React.FC<FieldFillingWizardModalProps> = (props) => {
  const { visible } = props;

  if (!visible) {
    return null;
  }

  const { visible: _, ...visibleProps } = props;
  return <VisibleModal {...visibleProps} />;
};
export const FieldFillingWizardModal = React.memo(_FieldFillingWizardModal);

const _VisibleModal: React.FC<VisibleProps> = (props) => {
  const { onClose, contractId, initialCadObjectNumbers } = props;
  const autocompleteM = useCadObjectsAutocompleteM();
  const [currentStep, setCurrentStep] = React.useState(0);
  const onClickNextStepHandler = React.useCallback(() => {
    return setCurrentStep((prevState) => prevState + 1);
  }, []);

  const [cadObjectNumbers, setCadObjectNumbers] = React.useState<number[]>([]);
  const [autocompleteAll, setAutocompleteAll] = React.useState<boolean>(false);
  const [selectedColumns, setSelectedColumns] = React.useState<ColumnRecordResponse[]>([]);

  const { data: contractColumns, isLoading: isLoadingContractColumns } =
    useGetContractAutocompletableColumnsByIdQ(contractId);

  const onChangeSelectedColumnIds = React.useCallback(
    (ids: RecordId[]) => {
      const newSelectedColumns = ids
        .map((id) => contractColumns?.find((x) => x._id === id))
        .filter((x) => x !== undefined) as ColumnRecordResponse[];

      setSelectedColumns(newSelectedColumns);
    },
    [contractColumns],
  );
  const [finalForm] = Form.useForm<FinalFieldFillingWizardForm>();

  const onFinishForm = React.useCallback(
    async (data: FinalFieldFillingWizardForm) => {
      try {
        const fields: Record<string, any> = {};
        data.fields.forEach((field) => {
          fields[field.dataIndex] = field.isCleared ? null : field.value;
        });

        await autocompleteM.mutateAsync({
          contractId,
          fields,
          items: cadObjectNumbers,
          autocompleteAll,
        });
        onClose();
        message.success('Поля в объектах успешно автозаполнены');
      } catch {
        message.error('При автозаполнении возникла ошибка');
      }
    },
    [autocompleteM, cadObjectNumbers, contractId, autocompleteAll, onClose],
  );

  const footer = React.useMemo(() => {
    switch (currentStep) {
      case 0:
        return (
          <Space>
            <Button onClick={onClose}>Отмена</Button>
            <Button
              type={'primary'}
              onClick={onClickNextStepHandler}
              disabled={!autocompleteAll && cadObjectNumbers.length === 0}
            >
              Следующий шаг
            </Button>
          </Space>
        );
      case 1:
        return (
          <Space>
            <Button onClick={onClose}>Отмена</Button>
            <Button
              type={'primary'}
              onClick={onClickNextStepHandler}
              disabled={selectedColumns.length === 0}
            >
              Следующий шаг
            </Button>
          </Space>
        );
      case 2:
        return (
          <Space>
            <Button onClick={onClose}>Отмена</Button>
            <Popconfirm title={'Вы точно уверены?'} onConfirm={finalForm.submit}>
              <Button
                type={'primary'}
                onClick={() => finalForm.validateFields()}
                loading={autocompleteM.isLoading}
              >
                Завершить
              </Button>
            </Popconfirm>
          </Space>
        );
    }
  }, [
    currentStep,
    onClickNextStepHandler,
    autocompleteAll,
    cadObjectNumbers,
    onClose,
    selectedColumns.length,
    finalForm,
    autocompleteM.isLoading,
  ]);

  return (
    <Modal
      width={1000}
      visible={true}
      onCancel={onClose}
      title={'Мастер заполнения полей'}
      footer={footer}
    >
      <Steps size='small' current={currentStep}>
        <Steps.Step title='Выбор объектов' />
        <Steps.Step title='Выбор полей' />
        <Steps.Step title='Заполнение' />
      </Steps>
      {currentStep === 0 && (
        <StepContent>
          <AntdLabelMimic>Заполните номера объектов (или вставте их из Excel)</AntdLabelMimic>
          <CadObjectNumbersTextarea
            onChange={setCadObjectNumbers}
            initValues={initialCadObjectNumbers}
            disabled={autocompleteAll}
          />
          <div className='field-filling-wizard-modal__selected-items-addition'>
            {autocompleteAll ? (
              <div className='field-filling-wizard-modal__selected-items'>
                Выбраны все объекты в контракте
              </div>
            ) : (
              cadObjectNumbers.length > 0 && (
                <div className='field-filling-wizard-modal__selected-items'>
                  Выбрано объектов: {cadObjectNumbers.length}
                </div>
              )
            )}

            <Checkbox
              className={'field-filling-wizard-modal__autocompleteAll-cbx'}
              checked={autocompleteAll}
              onChange={(e) => setAutocompleteAll(e.target.checked)}
            >
              Выбрать все объекты контракта
            </Checkbox>
          </div>
        </StepContent>
      )}
      {currentStep === 1 && (
        <StepContent>
          <div className={'field-filling-wizard-modal__transfer-container'}>
            <AntdLabelMimic>Выберите поля для заполнения</AntdLabelMimic>

            <Transfer
              targetKeys={selectedColumns.map((x) => x._id)}
              className={'field-filling-wizard-modal__transfer'}
              listStyle={{ width: '250px', height: '400px' }}
              dataSource={contractColumns}
              disabled={isLoadingContractColumns}
              rowKey={(x) => x._id}
              render={(x) => x.name}
              onChange={onChangeSelectedColumnIds}
              showSearch
            />
          </div>
        </StepContent>
      )}
      {currentStep === 2 && (
        <StepContent>
          <Form form={finalForm} onFinish={onFinishForm}>
            <AntdLabelMimic>Заполните значения полей (выбранных на шаге 2)</AntdLabelMimic>
            <Form.List
              name={'fields'}
              initialValue={selectedColumns.map((x) => ({
                name: x.name,
                dataIndex: x.dataIndex,
                value: undefined as any,
                isCleared: false,
                type: x.type,
              }))}
            >
              {(fields) => {
                return (
                  <div className={'field-filling-wizard-modal__field-list'}>
                    {fields.map(({ key, name }) => (
                      <div key={key} className={'field-filling-wizard-modal__field-list-item'}>
                        <div className={'field-filling-wizard-modal__field-list-item-number'}>
                          {name + 1}
                        </div>
                        <div className={'field-filling-wizard-modal__field-list-item-field-name'}>
                          <Form.Item dependencies={[['fields', name, 'name']]} noStyle>
                            {({ getFieldValue }) => {
                              return (
                                <>
                                  <span
                                    className={
                                      'field-filling-wizard-modal__field-list-item-field-name-prefix'
                                    }
                                  >
                                    Поле:
                                  </span>
                                  {getFieldValue(['fields', name, 'name'])}
                                </>
                              );
                            }}
                          </Form.Item>
                        </div>
                        <div className={'field-filling-wizard-modal__field-list-item-input'}>
                          <Form.Item
                            noStyle
                            dependencies={[
                              ['fields', name, 'type'],
                              ['fields', name, 'isCleared'],
                              ['fields', name, 'value'],
                            ]}
                          >
                            {({ getFieldValue }) => {
                              const isCleared = getFieldValue(['fields', name, 'isCleared']);
                              const type = getFieldValue(['fields', name, 'type']);

                              const defaultRules: Rule[] = [
                                { required: !isCleared, message: errorMessages.required },
                              ];

                              switch (type) {
                                case 'string':
                                  return (
                                    <Form.Item name={[name, 'value']} rules={defaultRules}>
                                      <Input disabled={isCleared} />
                                    </Form.Item>
                                  );
                                case 'integer':
                                  return (
                                    <Form.Item
                                      name={[name, 'value']}
                                      rules={[
                                        ...defaultRules,
                                        { type: 'integer', message: errorMessages.integer },
                                      ]}
                                    >
                                      <InputNumber disabled={isCleared} style={{ width: '100%' }} />
                                    </Form.Item>
                                  );
                                case 'decimal':
                                  return (
                                    <Form.Item name={[name, 'value']} rules={defaultRules}>
                                      <InputNumber disabled={isCleared} style={{ width: '100%' }} />
                                    </Form.Item>
                                  );
                                case 'date':
                                  return (
                                    <Form.Item name={[name, 'value']} rules={defaultRules}>
                                      <DatePicker disabled={isCleared} style={{ width: '100%' }} />
                                    </Form.Item>
                                  );
                                case 'address':
                                  return (
                                    <Form.Item name={[name, 'value']} rules={defaultRules}>
                                      <AddressSearchSelect
                                        block
                                        inputProps={{ disabled: isCleared }}
                                      />
                                    </Form.Item>
                                  );
                                default:
                                  return (
                                    <Form.Item name={[name, 'value']}>
                                      Ошибка. Автозаполнение для данного типа колонки не
                                      поддерживается
                                    </Form.Item>
                                  );
                              }
                            }}
                          </Form.Item>
                        </div>
                        <div className='field-filling-wizard-modal__field-list-item-clear'>
                          <Form.Item name={[name, 'isCleared']} valuePropName={'checked'}>
                            <Checkbox>Отчистить поле</Checkbox>
                          </Form.Item>
                        </div>
                      </div>
                    ))}
                  </div>
                );
              }}
            </Form.List>
          </Form>
          <div className='field-filling-wizard-modal__final-form-warning'>
            <span className={'field-filling-wizard-modal__final-form-warning-alert'}>ВНИМАНИЕ</span>
            : Проверьте правильность заполнения полей перед сохранением. Значения выше применятся ко
            ВСЕМ выбранным на шаге 1 объектам. Вернуть прежние значения будет невозможно!
          </div>
        </StepContent>
      )}
    </Modal>
  );
};
const VisibleModal = React.memo(_VisibleModal);
