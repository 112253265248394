import { CoupleFileType, FileType } from '@/api/Files/types';
import { DocumentCollectionFilterDropdown } from '@/components/filterDropdowns/DocumentCollectionFilterDropdown';
import { DocumentCollectionFilterValue } from '@/components/filterDropdowns/DocumentCollectionFilterDropdown/types';
import { dataIndexToStringKey } from '@/components/ServerConfigTable/utils/dataIndexToStringKey';
import { openGenFileWizardModalFromCell } from '@/pages/MainTablePage/model';
import { ForwardOutlined, UnorderedListOutlined, UploadOutlined } from '@ant-design/icons';
import { EmptyValue } from '@uspect-team/ant-ui-kit';
import { ControlledTableChildType } from '@uspect-team/ant-ui-kit/dist/Tables/ControlledTable/types';
import { Button, Space } from 'antd';
import cn from 'classnames';
import React from 'react';
import {
  BaseServerChildColumnType,
  DocumentCollectionServerChildColumnAddition,
  ServerConfigTableContext,
  TableRecordType,
} from '../../types';

export const getDocumentCollectionColumnAddition = (
  strSerCol: BaseServerChildColumnType & DocumentCollectionServerChildColumnAddition,
  context: ServerConfigTableContext,
  editable: boolean,
): Partial<ControlledTableChildType<TableRecordType>> => {
  const { dataIndex, name, width } = strSerCol;
  const onClick = context?.document_collection?.onClick;

  const dataIndexStr = dataIndexToStringKey(dataIndex);
  const fileType = dataIndexStr as FileType | CoupleFileType;

  return {
    align: 'left',
    width: width ?? 250,
    onCell: (record) => ({
      className: cn('data-cell', {
        'with-data': record[dataIndexStr] && record[dataIndexStr]?.length > 0,
      }),
    }),
    filterDropdown: ({ currentFilterState: _currentFilterState, removeFilter, setFilterValue }) => {
      const filter = _currentFilterState as DocumentCollectionFilterValue;
      return (
        <DocumentCollectionFilterDropdown
          filter={filter}
          onChangeFilter={setFilterValue}
          onResetFilter={removeFilter}
        />
      );
    },

    render: (value = [], record) => {
      const isNotEmpty = value && value.length > 0;

      const showGen =
        !isNotEmpty &&
        (dataIndexStr === 'kpt' || dataIndexStr === 'rasters') &&
        record['status'] === 'geodesy';

      const onClickGen = showGen
        ? () =>
            openGenFileWizardModalFromCell({
              initialCadObjectNumbers: [record.num],
              initialFileTypes: [dataIndexStr],
            })
        : undefined;

      return (
        <Space>
          <Button
            disabled={!editable}
            style={{ width: 170 }}
            icon={isNotEmpty ? <UnorderedListOutlined /> : <UploadOutlined />}
            size='small'
            onClick={
              onClick && record.status !== 'excluded'
                ? () =>
                    onClick({
                      dataIndex: dataIndexStr,
                      oldRecord: record,
                      fileType,
                      name,
                      documents: value,
                    })
                : undefined
            }
          >
            {isNotEmpty ? 'Перейти к списку' : 'Загрузить'}
          </Button>
          {showGen && (
            <Button
              disabled={!editable}
              icon={<ForwardOutlined />}
              size='small'
              onClick={onClickGen}
            >
              Сгенерировать
            </Button>
          )}

          {isNotEmpty ? <div>({value.length} шт.)</div> : <EmptyValue />}
        </Space>
        // <EditableDocumentCollection
        //   onClick={
        //     onClick && record.status !== 'excluded'
        //       ? () =>
        //           onClick({
        //             dataIndex: dataIndexStr,
        //             oldRecord: record,
        //             fileType,
        //             name,
        //             documents: value,
        //           })
        //       : undefined
        //   }
        //   totalItems={value.length}
        // />
      );
    },
  };
};
