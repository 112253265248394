import React, { useCallback } from 'react';
import { Form, message, Modal } from 'antd';
import { CptFields } from '@/components/modals/cpts/common/CptFields';
import { CreateCptFormFields } from '@/components/modals/cpts/CreateCptModal/types';
import { CptCreateRequest } from '@/api/Dictionary/Cpt/types';
import { useCreateCptM } from '@/hooks/directory/cpt';

export type CreateCptModalProps =
  | {
      visible: false;
    }
  | ({ visible: true } & VisibleProps);
type VisibleProps = {
  onClose: () => void;
};

export const CreateCptModal: React.FC<CreateCptModalProps> = React.memo((props) => {
  const { visible } = props;

  if (!visible) {
    return null;
  }

  const { visible: _, ...visibleProps } = props;
  return <VisibleModal {...visibleProps} />;
});

export const VisibleModal: React.FC<VisibleProps> = React.memo((props) => {
  const { onClose } = props;

  const { mutateAsync: createCptM, isLoading: isLoadingCreateCpt } = useCreateCptM();
  const [form] = Form.useForm<CreateCptFormFields>();
  const onFinishFormHandler = useCallback(
    async (fields: CreateCptFormFields) => {
      try {
        const createReq = {
          ...fields,
          fileXML: fields.fileXML?._id,
          fileMIF: fields.fileMIF?._id,
        } as CptCreateRequest;
        await createCptM(createReq);
        onClose();
        message.success('Объект КПТ создан');
      } catch {
        message.error('При создании объекта КПТ произошла ошибка');
      }
    },
    [createCptM, onClose],
  );

  return (
    <Modal
      visible={true}
      onCancel={onClose}
      title={'Создание КПТ объекта'}
      okText={'Создать'}
      okButtonProps={{ loading: isLoadingCreateCpt }}
      onOk={form.submit}
    >
      <Form
        form={form}
        layout={'vertical'}
        onFinish={onFinishFormHandler}
        requiredMark={'optional'}
      >
        <CptFields />
      </Form>
    </Modal>
  );
});
