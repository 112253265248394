import {
  CadObjectPatchRequest,
  CadObjectResponse,
  GetCadObjectsPageResponse,
  MapProvider,
} from '@/api/CadObjects/types';
import { RecordId } from '@/api/common/types';
import { ContractRecordResponse } from '@/api/Contracts/types';
import { CoupleFileType, FileType } from '@/api/Files/types';
import { ExternalLink } from '@/components/ExternalLink';
import {
  EditCoupleFileCollectionInCadModal,
  EditCoupleFileCollectionInCadModalProps,
} from '@/components/modals/cadObjects/EditCoupleFileCollectionInCadModal';
import {
  EditFileCollectionInCadModal,
  EditFileCollectionInCadModalProps,
} from '@/components/modals/cadObjects/EditFileCollectionInCadModal';
import {
  EditSingleFileInCadModal,
  EditSingleFileInCadModalProps,
} from '@/components/modals/cadObjects/EditSingleFileInCadModal';
import { ServerConfigTable } from '@/components/ServerConfigTable';
import {
  ServerColumnsConfig,
  ServerConfigTableConfig,
  ServerConfigTableContext,
  ServerConfigTableState,
} from '@/components/ServerConfigTable/types';
import { mapFilterStateToServer } from '@/components/ServerConfigTable/utils/tableStateMapping';
import { openSelectAlreadyExistFileModal } from '@/globalStorage/file';
import {
  useCadObjectsGenKptM,
  useCadObjectsGenRastersM,
  useDeleteCadObjectM,
  useDeleteFileInCadObjectM,
  useGetCadObjectsPageQ,
  usePatchCadObjectM,
} from '@/hooks/cadastralObjects';
import { useGetContractByIdQ, useGetGroupedContactColumnsQ } from '@/hooks/contracts';
import { getClientColumns } from '@/pages/MainTablePage/MainTablePageTable/clientColumns';
import {
  getTerritoryUrl,
  getTerritoryUrlNew,
} from '@/pages/MainTablePage/MainTablePageTable/utils';
import { routes } from '@/resources/routes';
import { Menu, message, Modal, Spin } from 'antd';
import { AxiosResponse } from 'axios';
import { useStore } from 'effector-react';
import React, { useEffect, useMemo, useState } from 'react';
import { Link, useHistory, useLocation, useParams } from 'react-router-dom';
import {
  $isCanEditContract,
  $selectedCadObjects,
  $selectedDisplayModeId,
  $tableState,
  openAutoRunWizardModalForCadObject,
  openAutoSignWizardModalForCadObject,
  openGenKptAndRastersModalForCadObject,
  openGenKptModalForCadObject,
  openPdCollectionModalFromCell,
  setSelectedCadObjects,
  setTableState,
} from '../model';
import { MainTableSelectedItemsActions } from './MainTableSelectedItemsActions';
import './styles.scss';

const scroll = { x: true } as { x: true };

const _MainTablePageTable: React.FC = () => {
  const { id: contractId } = useParams<{ id: RecordId }>();
  const displayModeId = useStore($selectedDisplayModeId);
  const location = useLocation();
  const { data: contract, isLoading: contractIsLoading } = useGetContractByIdQ(contractId);

  const { data: columns, isLoading: columnsIsLoading } = useGetGroupedContactColumnsQ({
    contractId,
    displayModeId: displayModeId ?? undefined,
  });

  const { mutateAsync: deleteCadObjectM } = useDeleteCadObjectM();
  const { mutateAsync: deleteFileInCadObjectM } = useDeleteFileInCadObjectM();
  const { mutateAsync: patchCadObjectM } = usePatchCadObjectM();
  const { mutateAsync: genKptM } = useCadObjectsGenKptM();
  const { mutateAsync: genRastersM } = useCadObjectsGenRastersM();

  const tableState = useStore<ServerConfigTableState>($tableState);

  const { data: cadObjectPage, isFetching: cadObjectPageIsLoading } = useGetCadObjectsPageQ({
    ...tableState,
    filter: { ...mapFilterStateToServer(tableState.filter, true), contractId },
  });

  const [singleFileEditInCadModalState, setSingleFileEditInCadModalState] =
    useState<EditSingleFileInCadModalProps>({ visible: false });
  const [editFileCollectionInCadModalState, setEditFileCollectionInCadModalState] =
    useState<EditFileCollectionInCadModalProps>({ visible: false });

  const [editCoupleFileCollectionModalState, setEditCoupleFileCollectionModalState] =
    useState<EditCoupleFileCollectionInCadModalProps>({ visible: false });

  const openSingleFileEdit = React.useCallback(
    (params: { name: string; cadObjectId: RecordId; fileType: FileType; dataIndex: string }) => {
      const { name, cadObjectId, fileType, dataIndex } = params;
      setSingleFileEditInCadModalState({
        visible: true,
        contractId,
        title: `${name}: Загрузка файла`,
        cadObjectId,
        fileType,
        dataIndex,
        onClose: () => setSingleFileEditInCadModalState({ visible: false }),
      });
    },
    [contractId],
  );

  const openFileCollectionFileEdit = React.useCallback(
    (params: { cadObjectId: RecordId; fileType: FileType; dataIndex: string; name: string }) => {
      const { cadObjectId, fileType, dataIndex, name } = params;
      setEditFileCollectionInCadModalState({
        visible: true,
        contractId,
        cadObjectId,
        fileType,
        dataIndex,
        name,
        onClose: () => setEditFileCollectionInCadModalState({ visible: false }),
      });
    },
    [contractId],
  );

  const openSelectAlreadyExistFile = React.useCallback(
    (params: { name: string; cadObjectId: RecordId; fileType: FileType; dataIndex: string }) => {
      const { cadObjectId, fileType, dataIndex, name } = params;
      openSelectAlreadyExistFileModal({
        contractId,
        fileType,
        title: `${name}: Выбор из существующих файлов`,
        onSelectFile: (file) =>
          patchCadObjectM({ id: cadObjectId, patch: { [dataIndex]: file._id } }),
      });
    },
    [contractId, patchCadObjectM],
  );

  const openEditCoupleFileCollectionModal = React.useCallback(
    (params: {
      name: string;
      cadObjectId: RecordId;
      fileType: CoupleFileType;
      dataIndex: string;
    }) => {
      const { cadObjectId, fileType, dataIndex, name } = params;
      setEditCoupleFileCollectionModalState({
        visible: true,
        contractId,
        fileType,
        dataIndex,
        cadObjectId,
        name,
        onClose: () => setEditCoupleFileCollectionModalState({ visible: false }),
      });
    },
    [contractId],
  );

  const deleteCadObject = React.useCallback(
    (cadObjId: RecordId) => {
      return deleteCadObjectM(cadObjId);
    },
    [deleteCadObjectM],
  );
  const patchCadObject = React.useCallback(
    async (req: CadObjectPatchRequest) => {
      try {
        await patchCadObjectM(req);
      } catch {
        message.error('При изменении кадастрового объекта произошла ошибка');
      }
    },
    [patchCadObjectM],
  );

  const deleteFileInCadObject = React.useCallback(
    (cadObjId: RecordId, dataIndex: string) => {
      return deleteFileInCadObjectM({ id: cadObjId, dataIndex });
    },
    [deleteFileInCadObjectM],
  );

  const genKpt = React.useCallback(
    async (cadObjectNumber: number) => {
      const hideLoadingMessage = message.loading('Генерация КПТ...', 0);

      try {
        await genKptM({ cadObjectNumber, contractId });
        message.success('КПТ сгенерирован');
      } catch {
        message.error('При генерации КПТ произошла ошибка');
      } finally {
        hideLoadingMessage();
      }
      return;
    },
    [contractId, genKptM],
  );

  const genRasters = React.useCallback(
    async (cadObjectNumber: number, mapProvider: MapProvider) => {
      const hideLoadingMessage = message.loading('Генерация растров...', 0);

      try {
        await genRastersM({ cadObjectNumber, contractId, mapProvider });
        message.success('Растры сгенерированы');
      } catch {
        message.error('При генерации растров произошла ошибка');
      } finally {
        hideLoadingMessage();
      }
      return;
    },
    [contractId, genRastersM],
  );

  const onExcludeCadObject = React.useCallback(
    async (id: RecordId) => {
      try {
        await patchCadObjectM({ id, patch: { status: 'excluded' } });
        message.success('Объект исключен');
      } catch {
        message.error('Произошла ошибка при исключении объекта');
      }
    },
    [patchCadObjectM],
  );

  const onBackToWorkCadObject = React.useCallback(
    async (id: RecordId) => {
      try {
        await patchCadObjectM({ id, patch: { status: 'noData' } });
        message.success('Объект возвращен в работу');
      } catch {
        message.error('Произошла ошибка при исключении объекта');
      }
    },
    [patchCadObjectM],
  );

  const isLoading = contractIsLoading || columnsIsLoading;

  useEffect(() => {
    setTableState({ page: 1, limit: 10 });
  }, [location.pathname]);

  return (
    <>
      {!isLoading && contract && columns ? (
        <MainTablePageTableView
          contract={contract}
          columns={columns}
          deleteCadObject={deleteCadObject}
          patchCadObject={patchCadObject}
          deleteFileInCadObject={deleteFileInCadObject}
          genKpt={genKpt}
          genRasters={genRasters}
          tableState={tableState}
          setTableState={setTableState}
          openSingleFileEdit={openSingleFileEdit}
          openFileCollectionFileEdit={openFileCollectionFileEdit}
          openSelectAlreadyExistFile={openSelectAlreadyExistFile}
          openEditCoupleFileCollectionModal={openEditCoupleFileCollectionModal}
          cadObjectPage={cadObjectPage}
          cadObjectPageIsLoading={cadObjectPageIsLoading}
          onExcludeCadObject={onExcludeCadObject}
          onBackToWorkCadObject={onBackToWorkCadObject}
        />
      ) : (
        <div className={'main-table-page-table__loader-block'}>
          <Spin />
        </div>
      )}

      <EditSingleFileInCadModal {...singleFileEditInCadModalState} />
      <EditFileCollectionInCadModal {...editFileCollectionInCadModalState} />
      <EditCoupleFileCollectionInCadModal {...editCoupleFileCollectionModalState} />
    </>
  );
};
export const MainTablePageTable = React.memo(_MainTablePageTable);

type MainTablePageTableViewProps = {
  contract: ContractRecordResponse;
  columns: ServerColumnsConfig;

  cadObjectPage?: GetCadObjectsPageResponse;
  cadObjectPageIsLoading: boolean;

  deleteCadObject: (cadObjId: RecordId) => Promise<AxiosResponse<void>>;
  patchCadObject: (req: CadObjectPatchRequest) => Promise<void>;
  genKpt: (cadObjectNumber: number) => Promise<void>;
  genRasters: (cadObjectNumber: number, mapProvider: MapProvider) => Promise<void>;
  deleteFileInCadObject: (cadObjId: RecordId, dataIndex: string) => Promise<void>;

  tableState: ServerConfigTableState;
  setTableState: (tableState: ServerConfigTableState) => void;

  openSingleFileEdit: (params: {
    name: string;
    cadObjectId: RecordId;
    fileType: FileType;
    dataIndex: string;
  }) => void;
  openFileCollectionFileEdit: (params: {
    cadObjectId: RecordId;
    fileType: FileType;
    dataIndex: string;
    name: string;
  }) => void;
  openSelectAlreadyExistFile: (params: {
    name: string;
    cadObjectId: RecordId;
    fileType: FileType;
    dataIndex: string;
  }) => void;
  openEditCoupleFileCollectionModal: (params: {
    name: string;
    cadObjectId: RecordId;
    fileType: CoupleFileType;
    dataIndex: string;
  }) => void;
  onExcludeCadObject: (id: RecordId) => void;
  onBackToWorkCadObject: (id: RecordId) => void;
};

const _MainTablePageTableView: React.FC<MainTablePageTableViewProps> = (props) => {
  const {
    contract,
    columns,
    cadObjectPage,
    cadObjectPageIsLoading,
    deleteCadObject,
    deleteFileInCadObject,
    patchCadObject,
    genRasters,
    tableState,
    setTableState,

    openSingleFileEdit,
    openFileCollectionFileEdit,
    openEditCoupleFileCollectionModal,
    openSelectAlreadyExistFile,
    onExcludeCadObject,
    onBackToWorkCadObject,
  } = props;

  const history = useHistory();

  const copyId = async (id: string) => {
    try {
      await navigator.clipboard.writeText(id);
      message.success('ID скопирован!');
    } catch (error) {
      message.error('При копировании произошла ошибка');
    }
  };

  const isCanEditContract = useStore($isCanEditContract);

  const isBoundaryPlan = contract.type === 'boundary_plan';

  const serverConfig = useMemo(() => {
    return {
      paginationType: 'server',
      columns: columns || [],
    } as ServerConfigTableConfig;
  }, [columns]);

  const clientColumns = useMemo(() => {
    return getClientColumns({
      actionMenu: (record) => (
        <Menu>
          <ExternalLink
            path={getTerritoryUrlNew({ actusCode: contract.actusCode, cadObjId: record._id })}
          >
            <Menu.Item key={'open_in_territory'}>Открыть в Territory (New)</Menu.Item>
          </ExternalLink>
          <ExternalLink
            path={getTerritoryUrl({ actusCode: contract.actusCode, cadObjId: record._id })}
          >
            <Menu.Item key={'open_in_territory_old'}>Открыть в Territory (Old)</Menu.Item>
          </ExternalLink>
          <Menu.Item onClick={() => copyId(record._id)} key={'copy_id'}>
            Скопировать ID
          </Menu.Item>

          {isBoundaryPlan && (
            <Menu.Item key='edit' onClick={() => history.push(routes.boundaryEdit(record._id))}>
              Редактировать
            </Menu.Item>
          )}
          {isCanEditContract && (
            <>
              {record.status !== 'excluded' ? (
                <Menu.Item
                  key='exclude'
                  className='main-table-page-table__exclude'
                  onClick={() => onExcludeCadObject(record._id)}
                >
                  Исключить
                </Menu.Item>
              ) : (
                <Menu.Item key='exclude' onClick={() => onBackToWorkCadObject(record._id)}>
                  Вернуть в работу
                </Menu.Item>
              )}

              <Menu.SubMenu key={'gen'} title={'Генерация'}>
                <Menu.Item
                  key={'gen_kpt_and_rasters'}
                  onClick={() =>
                    openGenKptAndRastersModalForCadObject({ cadObjectNumber: record.num })
                  }
                >
                  Сгенерировать КПТ и растры
                </Menu.Item>
                <Menu.Item
                  key={'gen_kpt'}
                  onClick={() => openGenKptModalForCadObject({ cadObjectNumber: record.num })}
                >
                  Сгенерировать КПТ
                </Menu.Item>
                <Menu.SubMenu key={'gen_rasters'} title={'Сгенерировать растры'}>
                  <Menu.Item
                    key={'gen_rasters_map-box'}
                    onClick={() => genRasters(record.num, 'map_box')}
                  >
                    Сгенерировать растры (Map Box)
                  </Menu.Item>
                  <Menu.Item
                    key={'gen_rasters_yandex'}
                    onClick={() => genRasters(record.num, 'yandex')}
                  >
                    Сгенерировать растры (Yandex)
                  </Menu.Item>
                </Menu.SubMenu>
                <Menu.Item
                  key={'autoRun'}
                  onClick={() =>
                    openAutoRunWizardModalForCadObject({ cadObjectNumber: record.num })
                  }
                >
                  Автоматический прогон
                </Menu.Item>
                <Menu.Item
                  key={'autoSing'}
                  onClick={() =>
                    openAutoSignWizardModalForCadObject({ cadObjectNumber: record.num })
                  }
                >
                  Автоматическое подписание
                </Menu.Item>
              </Menu.SubMenu>
              <Menu.SubMenu key={'cords'} title={'Координаты'}>
                <Menu.Item key={'toCords'}>
                  <Link to={routes.cadObjectDetailCoordinate(record._id)}>
                    Перейти к координатам
                  </Link>
                </Menu.Item>
              </Menu.SubMenu>

              <Menu.Item
                key={'delete'}
                danger
                onClick={() => {
                  Modal.confirm({
                    title: 'Вы действительно хотите безвозвратно удалить кадастровый объект?',
                    content: 'Это действие нельзя будет отменить',
                    onOk: () => deleteCadObject(record._id),
                    okType: 'danger',
                    okText: 'Да, удалить',
                    cancelText: 'Отмена',
                  });
                }}
              >
                Удалить
              </Menu.Item>
            </>
          )}
        </Menu>
      ),
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [
    contract.actusCode,
    isCanEditContract,
    onExcludeCadObject,
    onBackToWorkCadObject,
    genRasters,
    deleteCadObject,
  ]);

  const selectedCadObjects = useStore($selectedCadObjects);
  // const [selectedCadObjects, setSelectedCadObjects] = React.useState<CadObjectZipInfo[]>([]);

  const rowSelection = isCanEditContract
    ? {
        fixed: true,
        selectedRowKeys: selectedCadObjects.map((x) => x._id),
        onChange: (_: any, selectedCadObjects: CadObjectResponse[]) => {
          setSelectedCadObjects(selectedCadObjects);
        },
      }
    : undefined;

  const tableContext = {
    onEdit: (payload) =>
      patchCadObject({
        id: payload.oldRecord._id,
        patch: payload.patch,
      }),
    document: {
      onClickLoad: ({ fileType, oldRecord, name, dataIndex }) =>
        openSingleFileEdit({
          name,
          cadObjectId: oldRecord._id,
          dataIndex: dataIndex,
          fileType: fileType,
        }),
      onLoadAlreadyExist: ({ name, fileType, dataIndex, oldRecord }) =>
        openSelectAlreadyExistFile({
          name,
          fileType,
          dataIndex,
          cadObjectId: oldRecord._id,
        }),
      onDelete: async ({ oldRecord, dataIndex }) => deleteFileInCadObject(oldRecord._id, dataIndex),
    },
    document_collection: {
      onClick: ({ fileType, name, dataIndex, oldRecord }) => {
        //Если это пд коллекция
        if (fileType === 'pd') {
          openPdCollectionModalFromCell({
            dataIndex,
            cadObjectId: oldRecord._id,
            columnName: name,
          });
        } else if (fileType === 'rasters' || fileType === 'kpt') {
          //Если это коллекция парных файлов
          openEditCoupleFileCollectionModal({
            name,
            dataIndex,
            cadObjectId: oldRecord._id,
            fileType,
          });
        } else {
          //Если это коллекция одиночных файлов
          openFileCollectionFileEdit({
            dataIndex,
            name,
            cadObjectId: oldRecord._id,
            fileType,
          });
        }
      },
    },
  } as ServerConfigTableContext;

  // const tableContext = useMemo(() => {
  //   return {
  //     onEdit: (payload) =>
  //       patchCadObject({
  //         id: payload.oldRecord._id,
  //         patch: payload.patch,
  //       }),
  //     document: {
  //       onClickLoad: ({ fileType, oldRecord, name, dataIndex }) =>
  //         openSingleFileEdit({
  //           name,
  //           cadObjectId: oldRecord._id,
  //           dataIndex: dataIndex,
  //           fileType: fileType,
  //         }),
  //       onLoadAlreadyExist: ({ name, fileType, dataIndex, oldRecord }) =>
  //         openSelectAlreadyExistFile({
  //           name,
  //           fileType,
  //           dataIndex,
  //           cadObjectId: oldRecord._id,
  //         }),
  //       onDelete: async ({ oldRecord, dataIndex }) =>
  //         deleteFileInCadObject(oldRecord._id, dataIndex),
  //     },
  //     document_collection: {
  //       onClick: ({ fileType, name, dataIndex, oldRecord }) => {
  //         //Если это пд коллекция
  //         if (fileType === 'pd') {
  //           openPdCollectionModalFromCell({
  //             dataIndex,
  //             cadObjectId: oldRecord._id,
  //             columnName: name,
  //           });
  //         } else if (fileType === 'rasters' || fileType === 'kpt') {
  //           //Если это коллекция парных файлов
  //           openEditCoupleFileCollectionModal({
  //             name,
  //             dataIndex,
  //             cadObjectId: oldRecord._id,
  //             fileType,
  //           });
  //         } else {
  //           //Если это коллекция одиночных файлов
  //           openFileCollectionFileEdit({
  //             dataIndex,
  //             name,
  //             cadObjectId: oldRecord._id,
  //             fileType,
  //           });
  //         }
  //       },
  //     },
  //   } as ServerConfigTableContext;
  // }, [
  //   deleteFileInCadObject,
  //   openEditCoupleFileCollectionModal,
  //   openFileCollectionFileEdit,
  //   openSelectAlreadyExistFile,
  //   openSingleFileEdit,
  //   patchCadObject,
  // ]);

  console.log('✅ cadObjectPage    ', cadObjectPage);

  return (
    <div className='main-table-page-table__wrapper'>
      <ServerConfigTable
        editable={isCanEditContract}
        clientColumns={clientColumns}
        serverConfig={serverConfig}
        totalItems={cadObjectPage?.meta.count || 0}
        dataSource={cadObjectPage?.data}
        tableState={tableState}
        onChangeTableState={setTableState}
        multipleSorting
        rowKey={'_id'}
        rowSelection={rowSelection}
        loading={cadObjectPageIsLoading}
        scroll={scroll}
        bordered
        size={'small'}
        tableContext={tableContext}
      />
      <MainTableSelectedItemsActions />
    </div>
  );
};
const MainTablePageTableView = _MainTablePageTableView;
