import React, { useCallback, useEffect, useState } from 'react';
import { Form, message, Modal } from 'antd';
import { useGetEmployeeByIdQ, usePatchEmployeeM } from '@/hooks/directory/employees';
import { EmployeesPatchRequest } from '@/api/Dictionary/Employees/types';
import { RecordId } from '@/api/common/types';
import { UpdateEmployeeFormType } from '@/components/modals/employee/EditEmployeeModal/types';
import moment from 'moment';
import { ManagementEmployeeFormFields } from '../common/EmployeeFormFields';

export type EditEmployeeModalProps =
  | {
      visible: false;
    }
  | ({ visible: true } & VisibleProps);

type VisibleProps = {
  employeeId: RecordId;
  onClose: () => void;
};

export const EditManagementEmployeeModal: React.FC<EditEmployeeModalProps> = (props) => {
  const { visible } = props;

  if (!visible) {
    return null;
  }
  const { visible: _, ...visibleProps } = props;
  return <VisibleModal {...visibleProps} />;
};

const VisibleModal: React.FC<VisibleProps> = React.memo((props) => {
  const { onClose, employeeId } = props;
  const { data: employee, isLoading } = useGetEmployeeByIdQ(employeeId);

  const patchEmployeeM = usePatchEmployeeM();

  const [form] = Form.useForm<UpdateEmployeeFormType>();
  useEffect(() => {
    form.setFieldsValue({
      ...employee,
      passportDate: employee ? moment(employee.passportDate) : undefined,
    });
  }, [employee, form]);

  const [changes, setChanges] = useState<UpdateEmployeeFormType | null>(null);

  const onUpdateHandler = useCallback(async () => {
    if (changes) {
      try {
        const updateReq = {
          id: employeeId,
          patch: {
            ...changes,
            passportDate: changes.passportDate ? changes.passportDate.toISOString() : undefined,
          },
        } as EmployeesPatchRequest;
        try {
          await patchEmployeeM.mutateAsync(updateReq);
          onClose();
          message.success('Запись изменена');
        } catch (e) {
          message.error('При изменении произошла ошибка');
        }
      } catch (e) {
        message.error('Есть невалидные поля');
      }
    }
  }, [employeeId, patchEmployeeM, onClose, changes]);

  const { submit } = form;

  return (
    <Modal
      visible={true}
      width={1000}
      onCancel={onClose}
      title={
        isLoading
          ? 'Загрузка...'
          : `${employee?.lastName || ''} ${
              employee?.firstName || ''
            }: Редактирование записи о сотруднике`
      }
      okText={'Обновить запись'}
      okButtonProps={{ loading: patchEmployeeM.isLoading, disabled: changes === null }}
      onOk={submit}
    >
      <Form
        form={form}
        layout={'vertical'}
        wrapperCol={{ span: 24 }}
        requiredMark={'optional'}
        onFinish={onUpdateHandler}
        onValuesChange={(changes) => {
          setChanges((prevState) => ({ ...prevState, ...changes }));
        }}
      >
        <ManagementEmployeeFormFields />
      </Form>
    </Modal>
  );
});
