import { Api } from '@/api';
import { AxiosResponse } from 'axios';
import { createApi, createDomain, sample } from 'effector';
import { ExecItem } from '../utils/type';
import { RecordId } from '../../api/common/types';
import { createExecQueue } from '../utils/createExecQueue';

const autoGenFilesDomain = createDomain();

const {
  $execItem,
  $execState,
  $queue,
  $history,
  startExecQueue,
  resetExecQueue,
  newExecItem,
  moveExecItemToHistoryWithError,
  moveExecItemToHistoryWithSuccess,
} = createExecQueue<number>();

const $execContractId = autoGenFilesDomain.createStore<RecordId | null>(null);
const { setExecContractId, clearExecContractId } = createApi($execContractId, {
  setExecContractId: (_, payload: RecordId) => payload,
  clearExecContractId: () => null,
});

const $execFileTypes = autoGenFilesDomain.createStore<('kpt' | 'rasters' | 'mif')[]>([]);
const { setExecFileTypes, resetExecFileTypes } = createApi($execFileTypes, {
  setExecFileTypes: (_, payload: ('kpt' | 'rasters' | 'mif')[]) => payload,
  resetExecFileTypes: () => [],
});

// START

const startAutoGenFiles = autoGenFilesDomain.createEvent<{
  contractId: RecordId;
  cadObjectNumbers: number[];
  fileTypes: ('kpt' | 'rasters' | 'mif')[];
}>();
const _startAutoGenFilesFx = autoGenFilesDomain.createEffect<
  {
    contractId: RecordId;
    cadObjectNumbers: number[];
    fileTypes: ('kpt' | 'rasters' | 'mif')[];
  },
  void
>(({ contractId, cadObjectNumbers, fileTypes }) => {
  setExecContractId(contractId);
  setExecFileTypes(fileTypes);
  startExecQueue(cadObjectNumbers);
});

sample({
  clock: startAutoGenFiles,
  filter: $execState.map((x) => x !== 'inProcess'),
  target: _startAutoGenFilesFx,
});

/// RESET

const resetAutoGenFiles = autoGenFilesDomain.createEvent();
sample({
  clock: resetAutoGenFiles,
  filter: $execState.map((x) => x !== 'inProcess'),
  target: [clearExecContractId, resetExecFileTypes, resetExecQueue],
});

/// EXEC

const autoGenFilesNewExecItemFx = autoGenFilesDomain.createEffect<
  { execItem: ExecItem<number>; contractId: RecordId; fileTypes: ('kpt' | 'rasters' | 'mif')[] },
  Promise<AxiosResponse<any>>
>(({ execItem, contractId, fileTypes }) => {
  return Api.cadastralObjects.genFile({ cadObjectNumber: execItem.item, contractId, fileTypes });
});

sample({
  clock: autoGenFilesNewExecItemFx.done,
  fn: () => undefined,
  target: moveExecItemToHistoryWithSuccess,
});

sample({
  clock: autoGenFilesNewExecItemFx.fail,
  fn: ({ error }) => error.message,
  target: moveExecItemToHistoryWithError,
});

sample({
  clock: newExecItem,
  source: { $execContractId, $execFileTypes },
  fn: ({ $execContractId, $execFileTypes }, execItem) => ({
    contractId: $execContractId as RecordId,
    fileTypes: $execFileTypes,
    execItem,
  }),
  target: autoGenFilesNewExecItemFx,
});

const autoGenFilesService = {
  $execItem,
  $queue,
  $execState,
  startAutoGenFiles,
  resetAutoGenFiles,
  $execContractId,
  $history,
  $execFileTypes,
};

export { autoGenFilesService };
