import { CoordinateSystemRecordResponse } from '@/api/Coordinates/CoordinateSystems/types';
import {
  EditCoordinateSystemModal,
  EditCoordinateSystemModalProps,
} from '@/components/modals/coordinateSystems/EditCoordinateSystemModal';
import {
  controlledSortToServer,
  mapFilterStateToServer,
} from '@/components/ServerConfigTable/utils/tableStateMapping';
import {
  useDeleteCoordinateSystemM,
  useGetCoordinateSystemsPageQ,
} from '@/hooks/coordinates/coordinateSystems';
import { getColumns } from '@/pages/Coordinates/CoordinateSystemsPage/CoordinateSystemsTable/columns';
import {
  ControlledTable,
  MultipleActionModal,
  SelectedItemsActions,
} from '@uspect-team/ant-ui-kit';

import { ControlledTableState } from '@uspect-team/ant-ui-kit/dist/Tables/ControlledTable/types';
import { MultipleActionModalProps } from '@uspect-team/ant-ui-kit/dist/Tables/MultipleActionModal';
import { Menu } from 'antd';
import React, { useCallback, useMemo, useState } from 'react';

type Props = {
  className?: string;
  style?: any;
};
export const CoordinateSystemsTable: React.FC<Props> = React.memo((props) => {
  const { className, style } = props;

  const { mutateAsync: deleteCoordinateSystemM } = useDeleteCoordinateSystemM();

  const [tableState, setTableState] = useState<ControlledTableState>({
    pagination: { pageSize: 50, currentPage: 1 },
  });

  const { data: getCoordinateSystemReq, isLoading: isCoordinateSystemsLoading } =
    useGetCoordinateSystemsPageQ({
      page: tableState.pagination?.currentPage,
      limit: tableState.pagination?.pageSize,
      filter: mapFilterStateToServer(tableState.filter),
      sort: controlledSortToServer(tableState.sort),
    });

  const [selectedRecords, setSelectedRecords] = useState<CoordinateSystemRecordResponse[]>([]);

  const [multipleActionModalState, setMultipleActionModalState] = useState<
    MultipleActionModalProps<CoordinateSystemRecordResponse>
  >({
    visible: false,
  });

  const [editCoordinateSystemModalState, setEditCoordinateSystemModalState] =
    useState<EditCoordinateSystemModalProps>({
      visible: false,
    });

  const onClickDeleteSelectedRecordsList = useCallback(
    (selectedItems: CoordinateSystemRecordResponse[]) => {
      setMultipleActionModalState({
        visible: true,
        title: 'Удаление групп колонок',
        onClose: () => setMultipleActionModalState({ visible: false }),
        getRecordKey: (i) => i._id,
        getName: (i) => i.title,
        items: selectedItems,
        asyncAction: (i) => deleteCoordinateSystemM(i._id),
        mode: 'parallel',
      });
      setSelectedRecords([]);
    },
    [deleteCoordinateSystemM],
  );

  const columns = useMemo(() => {
    return getColumns({
      actionMenu: (record) => (
        <Menu>
          <Menu.Item
            onClick={() =>
              setEditCoordinateSystemModalState({
                visible: true,
                coordinateSystemId: record._id,
                onClose: () => setEditCoordinateSystemModalState({ visible: false }),
              })
            }
          >
            Редактировать
          </Menu.Item>
          <Menu.Item danger onClick={() => deleteCoordinateSystemM(record._id)}>
            Удалить
          </Menu.Item>
        </Menu>
      ),
    });
  }, [deleteCoordinateSystemM]);

  return (
    <>
      <ControlledTable
        className={className}
        style={style}
        tableState={tableState}
        onChangeTableState={setTableState}
        rowKey={'_id'}
        columns={columns}
        multipleSorting={false}
        dataSource={getCoordinateSystemReq?.data}
        totalItems={getCoordinateSystemReq?.meta.count}
        loading={isCoordinateSystemsLoading}
        pagination={{ type: 'server' }}
        rowSelection={{
          selectedRowKeys: selectedRecords.map((x) => x._id),
          onChange: (_, selectedRows) => setSelectedRecords(selectedRows),
        }}
        scroll={{ x: true }}
      />
      <SelectedItemsActions
        selectedItems={selectedRecords}
        actions={[
          {
            key: 'delete',
            title: 'Удалить',
            danger: true,
            onClick: onClickDeleteSelectedRecordsList,
          },
        ]}
        onClearSelect={() => setSelectedRecords([])}
      />
      <MultipleActionModal {...multipleActionModalState} />
      <EditCoordinateSystemModal {...editCoordinateSystemModalState} />
    </>
  );
});
