import React, { useState } from 'react';
import { Col, Divider, Form, Input, Row, Select, Tooltip, Typography } from 'antd';
import { errorMessages } from '@/utils/errorMessages';
import { telValidator } from '@/utils/validators';
import { PhoneNumberInput } from '@/components/inputs/special/PhoneNumberInput';
import { QuestionCircleFilled } from '@ant-design/icons';
import './styles.scss';
import { SelectContracts } from '../SelectContarcts';

const roleOptions = [
  { label: 'Администратор', value: 'admin' },
  { label: 'Пользователь', value: 'user' },
  { label: 'Гость', value: 'guest' },
];

export const ManagementEmployeeFormFields: React.FC = React.memo(() => {
  const [showInfoRole, setShowInfoRole] = useState(false);

  const onClickHandler = () => setShowInfoRole((prev) => !prev);

  const RoleLabel = (
    <>
      <Typography className='management-employee__role-label-text'>
        Выберите роль пользователя:
      </Typography>
      <Tooltip title='Info'>
        <QuestionCircleFilled onClick={onClickHandler} />
      </Tooltip>
    </>
  );

  return (
    <>
      <Row gutter={18}>
        <Col span={8}>
          <Form.Item
            name={'lastName'}
            label={'Фамилия'}
            rules={[{ required: true, message: errorMessages.required }]}
          >
            <Input placeholder={'Введите фамилию'} />
          </Form.Item>
        </Col>
        <Col span={8}>
          <Form.Item
            name={'firstName'}
            label={'Имя'}
            rules={[{ required: true, message: errorMessages.required }]}
          >
            <Input placeholder={'Введите имя'} />
          </Form.Item>
        </Col>
        <Col span={8}>
          <Form.Item
            name={'patronymic'}
            label={'Отчество'}
            rules={[{ required: true, message: errorMessages.required }]}
          >
            <Input placeholder={'Введите отчество'} />
          </Form.Item>
        </Col>
      </Row>
      <Row gutter={18}>
        <Col span={8}>
          <Form.Item
            name={'email'}
            label={'E-mail'}
            rules={[
              { required: true, message: errorMessages.required },
              { type: 'email', message: errorMessages.email },
            ]}
          >
            <Input placeholder={'Введите e-mail'} />
          </Form.Item>
        </Col>
        <Col span={8}>
          <Form.Item
            name={'phone'}
            label={'Номер телефона'}
            rules={[
              { required: true, message: errorMessages.required },
              { validator: telValidator },
            ]}
          >
            <PhoneNumberInput block />
          </Form.Item>
        </Col>
        <Col span={8}>
          <Form.Item
            name={'appointment'}
            label={'Должность'}
            rules={[{ required: true, message: errorMessages.required }]}
          >
            <Input placeholder={'Введите должность'} />
          </Form.Item>
        </Col>
      </Row>
      <Divider orientation='left'>Настройки доступа</Divider>
      <Row gutter={18}>
        <Col span={8}>
          <Form.Item
            name={'role'}
            label={RoleLabel}
            rules={[{ required: true, message: errorMessages.required }]}
          >
            <Select options={roleOptions} />
          </Form.Item>
        </Col>
        <Col span={16}>
          <Form.Item
            noStyle
            dependencies={['role']}
            label={RoleLabel}
            rules={[{ required: true, message: errorMessages.required }]}
          >
            {({ getFieldValue }) => {
              const isAdmin = getFieldValue('role') === 'admin';

              if (isAdmin) {
                return (
                  <Form.Item
                    name={'contractsId'}
                    label={'Выберите контракты, к которым предоставить доступ:'}
                    rules={[{ required: true, message: errorMessages.required }]}
                  >
                    <SelectContracts mode='multiple' />
                  </Form.Item>
                );
              }
            }}
          </Form.Item>
        </Col>
      </Row>
      {showInfoRole && (
        <Row gutter={16}>
          <Col span={8}>
            <div className='management-employee__info-wrapper'>
              <Typography className='management-employee__info-title'>
                Администратор организации
              </Typography>
              <Typography className='management-employee__info-subtitle'>
                имеет полный доступ к вашей организации, может создавать и назначать других
                пользователей.
              </Typography>
              <Typography className='management-employee__info-title'>Пользователь</Typography>
              <Typography className='management-employee__info-subtitle'>
                имеет доступ только к выбранным контрактам на чтение и запись
              </Typography>
              <Typography className='management-employee__info-title'>Гость</Typography>
              <Typography className='management-employee__info-subtitle'>
                имеет доступ только к выбранным контрактам на чтение
              </Typography>
            </div>
          </Col>
        </Row>
      )}
    </>
  );
});
