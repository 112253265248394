import { createEvent, guard } from 'effector';
import { createList } from './createList';

export const createQueue = <Type>(initialValue: Type[]) => {
  const { $list, setList, resetList, push } = createList(initialValue);

  const removeHead = createEvent();
  const _removeHead = createEvent();

  const $head = $list.map((list) => {
    if (list.length > 0) {
      return list[0];
    } else {
      return null;
    }
  });

  const $headIsNotEmpty = $head.map((x) => x !== null);

  guard({ clock: removeHead, filter: $headIsNotEmpty, target: _removeHead });

  $list.on(_removeHead, ([_, ...state]) => state);

  return {
    $queue: $list,
    setQueue: setList,
    resetQueue: resetList,
    $head,
    $headIsNotEmpty,
    removeHead,
    push,
  };
};
