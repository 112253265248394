import React from 'react';
import cn from 'classnames';

import './styles.scss';

export type FilterTypeSelectOptionProps = {
  className?: string;
  style?: React.CSSProperties;
  onClick: () => void;
  icon: React.ReactNode;
  selected: boolean;
  hasFilter: boolean;
};

const _FilterTypeSelectOption: React.FC<FilterTypeSelectOptionProps> = (props) => {
  const { className, style, onClick, icon, selected, hasFilter } = props;

  return (
    <div
      className={cn('filter-type-select__option', className, {
        'filter-type-select__option--selected': selected,
        'filter-type-select__option--hasFilter': hasFilter,
      })}
      style={style}
      onClick={onClick}
    >
      {icon}
    </div>
  );
};
export const FilterTypeSelectOption = React.memo(_FilterTypeSelectOption);
