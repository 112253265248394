import { contractsQueryKeys } from './../../contracts/queryKeys';
import { useMutation, useQuery, useQueryClient } from 'react-query';
import { Api } from '@/api';
import { RecordId } from '@/api/common/types';
import { GetColumnsPageRequest } from '@/api/Dictionary/Columns/types';
import { columnsQueryKeys } from '@/hooks/directory/columns/queryKeys';
import { createUseFetchingStateObserver } from '@/hooks/utils';
import React from 'react';
import { useParams } from 'react-router-dom';
import { useGetContractByIdQ } from '@/hooks/contracts';

export const useGetColumnsPageQ = (data: GetColumnsPageRequest) => {
  return useQuery(
    columnsQueryKeys.page(data),
    () => {
      return Api.dictionary.columns.get(data);
    },
    {
      select: (res) => res.data,
    },
  );
};

export const useColumnPageFetchingState = createUseFetchingStateObserver(columnsQueryKeys.pages());

export const useGetAllColumnsQ = () => {
  const query = { page: 1, limit: 1000 };
  const { id }: { id: string } = useParams();

  const { data: contract } = useGetContractByIdQ(id);
  return useQuery(
    columnsQueryKeys.page({ ...query, type: contract?.type ?? '' }),
    () => {
      return Api.dictionary.columns.get({ ...query, type: contract?.type ?? '' });
    },
    {
      select: (res) => res.data.data,
    },
  );
};

export const useGetAllNotAlwaysShowColumnsQ = () => {
  const allColQ = useGetAllColumnsQ();

  const data = React.useMemo(() => {
    if (allColQ.data === undefined) {
      return undefined;
    }
    return allColQ.data.filter((x) => !x.isAlwaysShow);
  }, [allColQ.data]);

  return { ...allColQ, data };
};

export const useGetColumnByIdQ = (id: RecordId) => {
  return useQuery(
    columnsQueryKeys.detail(id),
    () => {
      return Api.dictionary.columns.getById(id);
    },
    { select: (data) => data.data.data },
  );
};

export const useCreateColumnM = () => {
  const queryClient = useQueryClient();
  return useMutation(columnsQueryKeys.create(), Api.dictionary.columns.create, {
    onSuccess: async (response) => {
      queryClient.invalidateQueries(columnsQueryKeys.pages());
      queryClient.removeQueries(contractsQueryKeys.all);

      queryClient.setQueriesData(columnsQueryKeys.detail(response.data.data._id), response);
    },
  });
};

export const usePatchColumnM = () => {
  const queryClient = useQueryClient();
  return useMutation(columnsQueryKeys.patch(), Api.dictionary.columns.patch, {
    onSuccess: async (response, variables) => {
      queryClient.removeQueries(contractsQueryKeys.all);
      queryClient.invalidateQueries(columnsQueryKeys.pages());

      queryClient.setQueriesData(columnsQueryKeys.detail(variables.id), response);
    },
  });
};

export const useDeleteColumnM = () => {
  const queryClient = useQueryClient();
  return useMutation(columnsQueryKeys.delete(), Api.dictionary.columns.delete, {
    onSuccess: async (_, variables) => {
      queryClient.removeQueries(contractsQueryKeys.all);
      queryClient.invalidateQueries(columnsQueryKeys.pages());

      await queryClient.invalidateQueries(columnsQueryKeys.detail(variables));
    },
  });
};
