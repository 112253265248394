import { Col, Row, Typography } from 'antd';
import React from 'react';
import './styles.scss';

export interface IManagementStatisticLeftCardProps {}

const { Title, Text } = Typography;

// const items: { label: string; key: string }[] = [
//   {
//     key: 'today',
//     label: 'сегодня',
//   },
//   {
//     key: 'week',
//     label: 'неделю',
//   },
//   {
//     key: 'month',
//     label: 'месяц',
//   },
//   {
//     key: 'year',
//     label: 'год',
//   },
// ];

const fields = [
  { key: 'geodesy', title: 'С геодезией' },
  { key: 'cpt', title: 'С геодезией/КПТ/растрами' },
  { key: 'noSend', title: 'С геодезией, еще НЕ отправлены на согласование' },
  { key: 'notCpt', title: 'С геодезией БЕЗ кпт ИЛИ растров' },
  { key: 'toAgreed', title: 'Отправлены на согласование' },
  { key: 'agreed', title: 'Согласованы' },
  { key: 'notAgreed', title: 'Не согласованы' },
  { key: 'pd', title: 'С ПД' },
  { key: 'toCp', title: 'Направлено в КП' },
  { key: 'messageInCp', title: 'Получено уведомление из КП' },
  { key: 'inError', title: 'С ошибками' },
  { key: 'inPackage', title: 'С пакетами' },
  { key: 'closeAct', title: 'Закрыто актами' },
];

const TempData = '100 (+10, 2300 км)';

const _ManagementStatisticLeftCard: React.FC<IManagementStatisticLeftCardProps> = (props) => {
  // const statisticIn = useState('сегодня');

  // const onClickHandler: MenuProps['onClick'] = (e) => {
  //   if (e.keyPath) {
  //     const item = items.find((item) => item?.key === e.keyPath[0]);
  //     if (item) {
  //       statisticIn[1](item.label);
  //     }
  //   }
  // };

  return (
    <Col span={8}>
      <div className='paper'>
        <Title level={3}>
          Статистика за &nbsp;
          {/* <Dropdown
            menu={{
              items,
              selectable: true,
              defaultSelectedKeys: ['today'],
              onClick: onClickHandler,
            }}
          >
            <Link>
              <Space>
                {statisticIn}
                <DownOutlined />
              </Space>
            </Link>
          </Dropdown> */}
        </Title>
        <Row gutter={[10, 10]} style={{ fontSize: '12px' }}>
          {fields.map(({ key, title }) => (
            <>
              <Col span={16}>
                <Text strong>{title}</Text>
              </Col>
              <Col span={8}>{TempData}</Col>
            </>
          ))}
        </Row>
      </div>
    </Col>
  );
};
export const ManagementStatisticLeftCard = React.memo(_ManagementStatisticLeftCard);
