import { sleep } from '@/utils/sleep';
import React from 'react';
import { useLocation } from 'react-router-dom';
import { PasswordRestoreConfirmedPageState } from './types';

export const usePasswordRestoreConfirmedPage = () => {
  const { search } = useLocation();

  const key = new URLSearchParams(search).get('key');

  const [state, setState] = React.useState<PasswordRestoreConfirmedPageState>(
    key === null
      ? { state: 'invalidLink' }
      : {
          state: 'notSubmitted',
          isLoading: false,
        },
  );

  const onSubmitSendHandler = React.useCallback(async (newPassword: string) => {
    setState({
      state: 'notSubmitted',
      isLoading: true,
    });

    await sleep(500);

    setState({
      state: 'submitted',
    });
  }, []);

  return { state, onSubmitSendHandler, isInvalidLink: key === null };
};
