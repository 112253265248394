import { StepContent } from '@/components/StepContent';
import { HistoryItem } from '@/globalStorage/utils/type';
import { formatMilliseconds } from '@/utils/dateUtils';
import React from 'react';
import { CopyCadObjNumbersButton } from '../../common/CopyCadObjNumbersButton';
import { ExecResultProp } from '../../common/ExecResultProp';
import { ExecResultPropContainer } from '../../common/ExecResultPropContainer';
import { HistoryCadObjectExecTable } from '../../common/HistoryCadObjectExecTable';

export type AutoGenFilesModalDoneProps = {
  history: HistoryItem<number>[];
  allObjectsCount: number;
  errorCadObjectNumbers: number[];
  successCadObjectNumbers: number[];
  execTimeMilliseconds: number;
};

const _AutoGenFilesModalDone: React.FC<AutoGenFilesModalDoneProps> = ({
  history,
  execTimeMilliseconds,
  allObjectsCount,
  successCadObjectNumbers,
  errorCadObjectNumbers,
}) => {
  return (
    <StepContent>
      <ExecResultPropContainer>
        <ExecResultProp label='Общее время генерации'>
          {formatMilliseconds(execTimeMilliseconds)}
        </ExecResultProp>
        <ExecResultProp label='Всего объектов'>{allObjectsCount}</ExecResultProp>
        <ExecResultProp label='Успешно сгенерировано для объектов'>
          {successCadObjectNumbers.length}
          <CopyCadObjNumbersButton copyNumbers={successCadObjectNumbers} />
        </ExecResultProp>
        <ExecResultProp label='Сгенерировано для объектов с ошибками'>
          {errorCadObjectNumbers.length}
          <CopyCadObjNumbersButton copyNumbers={errorCadObjectNumbers} />
        </ExecResultProp>
      </ExecResultPropContainer>

      <HistoryCadObjectExecTable history={history} />
    </StepContent>
  );
};
export const AutoGenFilesModalDone = React.memo(_AutoGenFilesModalDone);
