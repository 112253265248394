import { Col, Row, Space, Typography } from 'antd';
import React from 'react';
import { Link } from 'react-router-dom';

import { AddEmployeeIcon } from '@/components/customIcons/AddEmployeeIcon';
import { DocumentIcon } from '@/components/customIcons/DocumentIcon';
import { DashboardButton } from '@/components/DasboardButton';
import './styles.scss';

export interface IManagementHeaderProps {
  onAddEmployee: () => void;
}

const _ManagementHeader: React.FC<IManagementHeaderProps> = (props) => {
  const { onAddEmployee } = props;

  return (
    <div className='management-header'>
      <Row className='management-header__subtitle'>
        <Col span={5}>
          <Space direction='horizontal' size={30}>
            <Typography className='management-header__subtitle-field'>
              ID в системе: <span className='management-header__subtitle-text'>123456</span>
            </Typography>
            <Typography className='management-header__subtitle-field'>
              Тариф: <span className='management-header__subtitle-text'>Профессиональный</span>
            </Typography>
          </Space>
        </Col>
      </Row>
      <Row className='management-header__title'>
        <Col className='management-header__title-name' span={6}>
          OOO
        </Col>
        <Col className='management-header__title-price' span={2}>
          {2500} ₽
        </Col>
        <div className='management-header__title-date'>
          <Typography className='management-header__title-paid'>
            Оплачен до <span className='management-header__title-text'>time</span>
          </Typography>
          <Link className='management-header__title-link' to={'#'}>
            Пополнить баланс
          </Link>
        </div>
        <Col className='management-header__title-buttons' span={14}>
          <Space>
            <DashboardButton icon={<DocumentIcon />}>Сгенерировать документ</DashboardButton>
            <DashboardButton onClick={onAddEmployee} icon={<AddEmployeeIcon />}>
              Добавить сотрудника
            </DashboardButton>
          </Space>
        </Col>
      </Row>
    </div>
  );
};
export const ManagementHeader = React.memo(_ManagementHeader);
