import {
  CreateOrganizationRequest,
  GetOrganizationsPageRequest,
  PatchOrganizationRequest,
} from '@/api/Dictionary/Organizations/types';
import { useMutation, useQuery, useQueryClient } from 'react-query';
import { Api } from '@/api';
import { RecordId } from '@/api/common/types';
import { organizationsQueryKeys } from '@/hooks/directory/organizations/queryKeys';
import { createUseFetchingStateObserver } from '@/hooks/utils';

export const useGetOrganizationsPageQ = (params: GetOrganizationsPageRequest) => {
  return useQuery(
    organizationsQueryKeys.page(params),
    () => {
      return Api.dictionary.organizations.get(params);
    },
    {
      select: (data) => data.data,
    },
  );
};

export const useOrganizationsPageFetchingState = createUseFetchingStateObserver(
  organizationsQueryKeys.pages(),
);

export const useGetAllOrganizationsQ = () => {
  const params = { page: 1, limit: 1000 };
  return useQuery(
    organizationsQueryKeys.page(params),
    () => {
      return Api.dictionary.organizations.get(params);
    },
    {
      select: (data) => data.data.data,
    },
  );
};

export const useGetOrganizationByIdQ = (id: RecordId) => {
  return useQuery(
    organizationsQueryKeys.detail(id),
    () => {
      return Api.dictionary.organizations.getById(id);
    },
    {
      select: (data) => data.data.data,
    },
  );
};

export const useCreateOrganizationM = () => {
  const queryClient = useQueryClient();
  return useMutation(
    organizationsQueryKeys.create(),
    (data: CreateOrganizationRequest) => {
      return Api.dictionary.organizations.create(data);
    },
    {
      onSuccess: async (data) => {
        await queryClient.invalidateQueries(organizationsQueryKeys.pages());
        queryClient.setQueriesData(organizationsQueryKeys.detail(data.data.data._id), data);
      },
    },
  );
};

export const useDeleteOrganizationM = () => {
  const queryClient = useQueryClient();
  return useMutation(
    organizationsQueryKeys.delete(),
    (id: RecordId) => {
      return Api.dictionary.organizations.delete(id);
    },
    {
      onSuccess: async (_, variables) => {
        await Promise.all([
          queryClient.invalidateQueries(organizationsQueryKeys.pages()),
          queryClient.invalidateQueries(organizationsQueryKeys.detail(variables)),
        ]);
      },
    },
  );
};

export const usePatchOrganizationM = () => {
  const queryClient = useQueryClient();
  return useMutation(
    organizationsQueryKeys.patch(),
    (data: PatchOrganizationRequest) => {
      return Api.dictionary.organizations.patch(data);
    },
    {
      onSuccess: async (data) => {
        await queryClient.invalidateQueries(organizationsQueryKeys.pages());
        queryClient.setQueriesData(organizationsQueryKeys.detail(data.data.data._id), data);
      },
    },
  );
};
