import React, { Ref } from 'react';
import { Select, SelectProps } from 'antd';
import { RefSelectProps } from 'antd/lib/select';

export const calculateMethodOptions: { value: string; title: string }[] = [
  {
    value: 'analytical_method',
    title: 'Аналитический метод',
  },
  {
    value: 'cartographic_method',
    title: 'Картографический метод',
  },
  {
    value: 'method_of_satellite_geodetic_measurements',
    title: 'Метод спутниковых геодезических измерений (определений)',
  },
];

type Props = SelectProps<string> & { ref?: Ref<RefSelectProps> | undefined };
export const SelectCalculateMethod: React.FC<Props> = React.memo((props) => {
  const { ...otherProps } = props;

  return (
    <Select placeholder={'Выберите метод'} {...otherProps}>
      {calculateMethodOptions.map((i) => (
        <Select.Option key={i.value} value={i.value}>
          {i.title}
        </Select.Option>
      ))}
    </Select>
  );
});
