import React from 'react';
import cn from 'classnames';
import './styles.scss';
import { Button as AntButton } from 'antd';
import { BaseButtonProps } from 'antd/lib/button/button';

type Props = {
  className?: string;
  style?: any;
  icon?: React.ReactNode;
  onClick?: () => void;
} & BaseButtonProps;
export const DashboardButton: React.FC<Props> = (props) => {
  const { className, style, icon, children, onClick, ...buttonProps } = props;

  return (
    <AntButton
      {...buttonProps}
      onClick={onClick}
      icon={icon && <div className='dashboard-button__icon'>{icon}</div>}
      className={cn('dashboard-button', className)}
      style={style}
      size={'small'}
    >
      {children && <div className='dashboard-button__text'>{children}</div>}
    </AntButton>
  );
};
