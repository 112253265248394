import { RecordId } from '@/api/common/types';
import { combine, createDomain, forward, sample } from 'effector';
import { Api } from './../../api/index';
import { createVarStore } from './../utils/createVarStore';
import { createCoordinatesSelection } from './createCoordinatesSelection';
import { createPolylineList } from './createPolylineList';
import { createRaster } from './createRasters';
import { createTpList } from './createTpList';
import { RasterRecord } from './types';
import { calculateAreaParams, optimizePointToView } from './utils';

const coordinatesDomain = createDomain();

const {
  $polylineList,
  initPolylineList,
  resetPolylineList,
  addNewPolyline,
  deletePolyline,
  editPolylinePoint,
  addPointAfterPoint,
  addPointBeforePoint,
  addPoint,
  deletePoint,
  addNewPolylineWithCords,
} = createPolylineList();

const {
  $store: $coordinateId,
  setStore: setCoordinateId,
  resetStore: resetCoordinateId,
} = createVarStore<RecordId | null>(null);

const { $tpList, setTpList, resetTpList, addTp, deleteTp } = createTpList();

const $allCoords = combine(
  { polylineList: $polylineList, tpList: $tpList },
  ({ polylineList, tpList }) => [
    ...polylineList.flatMap((x) => x.points.map((c) => ({ x: c.mskX, y: c.mskY }))),
    ...tpList.flatMap((x) => x.coords.map((c) => ({ x: c.mskX, y: c.mskY }))),
  ],
);

const { $raster, resetRaster, loadRasterForAllPoints } = createRaster($allCoords);

const {
  $store: $haveChanges,
  setStore: setHaveChanges,
  resetStore: resetHaveChanges,
} = createVarStore(false);

const {
  $store: $viewCoordinateOffset,
  setStore: setViewCoordinateOffset,
  resetStore: resetViewCoordinateOffset,
} = createVarStore<{ x: number; y: number }>({ x: 0, y: 0 });

const {
  $store: $loadingCoordinate,
  setStore: setLoadingCoordinate,
  resetStore: resetLoadingCoordinate,
} = createVarStore<boolean>(false);

const { $store: $scale, setStore: setScale, resetStore: resetScale } = createVarStore<number>(1);
const {
  $store: $centerPoint,
  setStore: setCenterPoint,
  resetStore: resetCenterPoint,
} = createVarStore<{ x: number; y: number }>({ x: 0, y: 0 });

const refreshAreaParamsFx = coordinatesDomain.createEffect(
  (allPoints: { x: number; y: number }[]) => {
    const { centerPoint, scale, offset } = calculateAreaParams(allPoints);

    setViewCoordinateOffset(offset);

    setCenterPoint(centerPoint);
    setScale(scale);
  },
);
const refreshAreaParams = coordinatesDomain.createEvent();
sample({ clock: refreshAreaParams, source: $allCoords, target: refreshAreaParamsFx });

const refreshAreaParamsAndRasters = coordinatesDomain.createEvent();
forward({ from: refreshAreaParamsAndRasters, to: [refreshAreaParams, loadRasterForAllPoints] });

const initNewCoordinateFx = coordinatesDomain.createEffect(() => {
  resetAll();
});

const initEditCoordinateFx = coordinatesDomain.createEffect(async (coordinateId: RecordId) => {
  resetAll();
  setLoadingCoordinate(true);

  setCoordinateId(coordinateId);

  const response = await Api.coordinates.coords.detail(coordinateId);

  const coordRecord = response.data.data;

  initPolylineList(coordRecord.polylineList);
  setTpList(coordRecord.tpList);

  refreshAreaParamsAndRasters();

  resetLoadingCoordinate();
});

const { $coordinatesSelection, selectPolyline, selectPoint, selectTp, resetSelect } =
  createCoordinatesSelection();

const $selectedPolylineId = $coordinatesSelection.map((selection) =>
  selection.type === 'polyline' || selection.type === 'point' ? selection.payload.polylineId : null,
);

const $selectedPointId = $coordinatesSelection.map((selection) =>
  selection.type === 'point' ? selection.payload.pointId : null,
);

const $selectedTpId = $coordinatesSelection.map((selection) =>
  selection.type === 'tp' ? selection.payload.tpId : null,
);

const $viewPolylineList = combine(
  {
    offset: $viewCoordinateOffset,
    polyList: $polylineList,
  },
  ({ offset, polyList: unselectedPoly }) => {
    return unselectedPoly.map((poly) => ({
      ...poly,
      points: poly.points.map((p) => ({
        id: p.id,
        ...optimizePointToView({ x: p.mskX, y: p.mskY }, offset),
      })),
    }));
  },
);

const $selectedViewPolyline = combine(
  { polylineList: $viewPolylineList, selectedPolylineId: $selectedPolylineId },
  ({ polylineList, selectedPolylineId }) => {
    if (!selectedPolylineId) {
      return null;
    }
    return polylineList.find((tp) => tp.id === selectedPolylineId) ?? null;
  },
);

const $unselectedViewPolylineList = combine(
  { polylineList: $viewPolylineList, selectedPolylineId: $selectedPolylineId },
  ({ polylineList, selectedPolylineId }) => {
    if (!selectedPolylineId) {
      return polylineList;
    }
    return polylineList.filter((tp) => tp.id !== selectedPolylineId);
  },
);

const $viewTpList = combine(
  {
    offset: $viewCoordinateOffset,
    tpList: $tpList,
  },
  ({ offset, tpList: unselectedPoly }) => {
    return unselectedPoly.map((poly) => ({
      ...poly,
      coords: poly.coords.map((p) => optimizePointToView({ x: p.mskX, y: p.mskY }, offset)),
    }));
  },
);

const $selectedViewTp = combine(
  { tpList: $viewTpList, selectedTpId: $selectedTpId },
  ({ tpList, selectedTpId }) => {
    if (!selectedTpId) {
      return null;
    }
    return tpList.find((tp) => tp._id === selectedTpId) ?? null;
  },
);

const $unselectedViewTpList = combine(
  { tpList: $viewTpList, selectedTpId: $selectedTpId },
  ({ tpList, selectedTpId }) => {
    if (!selectedTpId) {
      return tpList;
    }
    return tpList.filter((tp) => tp._id !== selectedTpId);
  },
);

const $viewRaster = combine(
  {
    offset: $viewCoordinateOffset,
    raster: $raster,
  },
  ({ offset, raster }) => {
    if (!raster) {
      return null;
    }

    return {
      imgUrl: raster.imgUrl,
      coords: raster.coords.map((c) => optimizePointToView(c, offset)),
    } as RasterRecord;
  },
);

sample({
  clock: [
    editPolylinePoint,
    addPoint,
    addPointAfterPoint,
    addPointBeforePoint,
    deletePoint,
    addNewPolyline,
    deletePolyline,
    addTp,
    deleteTp,
    addNewPolylineWithCords,
  ],
  fn: () => true,
  target: setHaveChanges,
});

///Reset
const resetAll = coordinatesDomain.createEvent();
forward({
  from: resetAll,
  to: [
    resetCenterPoint,
    resetLoadingCoordinate,
    resetPolylineList,
    resetScale,
    resetSelect,
    resetTpList,
    resetViewCoordinateOffset,
    resetRaster,
    resetCoordinateId,
    resetHaveChanges,
  ],
});

const coordinateService = {
  $coordinateId,
  $polylineList,
  $unselectedViewPolylineList,
  $selectedViewPolyline,
  $tpList,
  $scale,
  $coordinatesSelection,
  $selectedPolylineId,
  $selectedPointId,
  setScale,
  $centerPoint,
  $selectedTpId,
  $selectedViewTp,
  $unselectedViewTpList,
  $viewRaster,
  $haveChanges,
  setHaveChanges,
  setCenterPoint,
  $loadingCoordinate,
  initEditCoordinateFx,
  initNewCoordinateFx,
  selectPolyline,
  selectPoint,
  selectTp,
  resetSelect,
  resetCenterPoint,
  editPolylinePoint,
  addPointAfterPoint,
  addPointBeforePoint,
  addNewPolyline,
  deletePolyline,
  addPoint,
  deletePoint,
  addTp,
  deleteTp,
  loadRasterForAllPoints,
  refreshAreaParamsAndRasters,
  addNewPolylineWithCords,
};

export { coordinateService };
