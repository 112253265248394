import React from 'react';
import cn from 'classnames';
import './styles.scss';

export type GuestLayoutDescriptionProps = {
  className?: string;
  style?: React.CSSProperties;
  children?: React.ReactNode;
};

export const GuestLayoutDescription: React.FC<GuestLayoutDescriptionProps> = (props) => {
  const { className, style, children } = props;

  return (
    <div className={cn('guest-layout-description', className)} style={style}>
      {children}
    </div>
  );
};
