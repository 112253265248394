import { createEffect, createEvent, sample, Store } from 'effector';
import { windowHrefId } from '../../utils/windowHrefId';
import { createVarStore } from '../utils/createVarStore';
import { Api } from './../../api/index';
import { RasterRecord } from './types';

export const createRaster = ($allPoints: Store<{ x: number; y: number }[]>) => {
  const {
    $store: $raster,
    setStore: setRasters,
    resetStore: resetRaster,
  } = createVarStore<RasterRecord | null>(null);

  const loadRasterForAllPoints = createEvent();

  const loadRasterForAllPointsFx = createEffect(async (allPoints: { x: number; y: number }[]) => {
    const response = await Api.coordinates.coords.getPreviewRastr({
      coords: allPoints,
      cadObjectId: windowHrefId(4),
    });
    setRasters({ imgUrl: response.data.rastr, coords: response.data.coords });
  });

  sample({
    clock: loadRasterForAllPoints,
    source: $allPoints,
    fn: (allPoints) => allPoints,
    target: loadRasterForAllPointsFx,
  });

  return {
    $raster,
    resetRaster,
    loadRasterForAllPoints,
    loadRasterForAllPointsFx,
  };
};
