import { RecordId } from '@/api/common/types';
import { GetContractColumnsRequest, GetContractsPageRequest } from '@/api/Contracts/types';

export const contractsQueryKeys = {
  all: ['contracts'] as const,
  pages: () => [...contractsQueryKeys.all, 'page'] as const,
  page: (data: GetContractsPageRequest) => [...contractsQueryKeys.pages(), data] as const,

  details: () => [...contractsQueryKeys.all, 'detail'] as const,
  detail: (id: RecordId) => [...contractsQueryKeys.details(), id] as const,
  detailColumns: (id: RecordId) => [...contractsQueryKeys.detail(id), 'columns'] as const,
  detailGroupedColumns: ({ contractId, displayModeId }: GetContractColumnsRequest) =>
    [...contractsQueryKeys.detail(contractId), 'columns', 'grouped', { displayModeId }] as const,
  detailAutocompletableColumns: (id: RecordId) =>
    [...contractsQueryKeys.detail(id), 'columns', 'autocompletable'] as const,

  allNotify: () => [...contractsQueryKeys.all, 'notify'] as const,
  notify: (id: RecordId) => [...contractsQueryKeys.allNotify(), id] as const,

  create: () => [...contractsQueryKeys.all, 'create'] as const,
  patch: () => [...contractsQueryKeys.all, 'patch'] as const,
  delete: () => [...contractsQueryKeys.all, 'delete'] as const,
  loadMonitoring: () => [...contractsQueryKeys.all, 'loadMonitoring'] as const,
};
