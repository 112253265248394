import React from 'react';
import cn from 'classnames';
import './styles.scss';

type Props = {
  className?: string;
  style?: any;
};

const SubHeaderLeft: React.FC<Props> = (props) => {
  const { className, style, children } = props;

  return (
    <div className={cn('sub-header__left', className)} style={style}>
      {children}
    </div>
  );
};

const SubHeaderRight: React.FC<Props> = (props) => {
  const { className, style, children } = props;

  return (
    <div className={cn('sub-header__right', className)} style={style}>
      {children}
    </div>
  );
};

const SubHeader: React.FC<Props> & {
  Left: typeof SubHeaderLeft;
  Right: typeof SubHeaderRight;
} = (props) => {
  const { className, style, children } = props;

  return (
    <div className={cn('sub-header', className)} style={style}>
      {children}
    </div>
  );
};

SubHeader.Left = SubHeaderLeft;
SubHeader.Right = SubHeaderRight;

export { SubHeader };
