import { createDomain } from 'effector';
import { TCompanyStore } from './types';

const DEFAULT_COMPANY_VALUES: Partial<TCompanyStore> = {};

const companyDomain = createDomain();

const setCompany = companyDomain.createEvent<TCompanyStore>();

const removeCompany = companyDomain.createEvent();

const $companyStore = companyDomain
  .createStore<Partial<TCompanyStore>>(DEFAULT_COMPANY_VALUES)
  .on(setCompany, (_, company) => company)
  .on(removeCompany, (_) => {});

export const companyStoreService = {
  $companyStore,
  $setCompany: setCompany,
  $removeCompany: removeCompany,
};
