import { ButtonWithMenu } from '@/components/ButtonWithMenu';
import { DashboardButton } from '@/components/DasboardButton';
import {
  resetTableFilters,
  resetTableFiltersAndSort,
  resetTableSort,
} from '@/pages/MainTablePage/model';
import { ReloadOutlined } from '@ant-design/icons';
import { Button, Col, DatePicker, Form, Menu, Row, Space, Typography } from 'antd';
import cn from 'classnames';
import React, { useState } from 'react';
import { ManagementStatisticContractsTable } from './ManagementStatisticContractsTable';
import { ManagementStatisticEmployeeTable } from './ManagementStatisticEployeeTable';
import './styles.scss';

export interface IManagementStatisticRightCardProps {}

const { Title } = Typography;
const { RangePicker } = DatePicker;

const _ManagementStatisticRightCard: React.FC<IManagementStatisticRightCardProps> = (props) => {
  const [isContractTable, setIsContractTable] = useState(false);

  const [isTableHasFilters, isTableHasFiltersOrSort, isTableHasSort] = [true, true, true];

  return (
    <Col span={16}>
      <div className='paper'>
        <Row align='top' justify={'space-between'}>
          <Col span={16}>
            <Title level={3}>
              Статистика по
              <Button
                type='link'
                className={cn('management-statistic__button', !isContractTable ? 'active' : '')}
                onClick={() => setIsContractTable(false)}
              >
                сотрудникам
              </Button>
              /
              <Button
                type='link'
                className={cn('management-statistic__button', isContractTable ? 'active' : '')}
                onClick={() => setIsContractTable(true)}
              >
                контрактам
              </Button>
            </Title>
          </Col>
          <Col span={8}>
            <Form layout='vertical'>
              <Form.Item label='Выберите период для формирования статистики:'>
                <RangePicker />
              </Form.Item>
            </Form>
          </Col>
        </Row>
        <Space direction='vertical' size={20}>
          <Space>
            <DashboardButton icon={<ReloadOutlined />}>Обновить таблицу</DashboardButton>

            <ButtonWithMenu
              disabled={!isTableHasFiltersOrSort}
              onClick={() => resetTableFiltersAndSort()}
              overlay={
                <Menu>
                  {isTableHasFilters && (
                    <Menu.Item key='1' onClick={() => resetTableFilters()}>
                      Сбросить фильтры
                    </Menu.Item>
                  )}
                  {isTableHasSort && (
                    <Menu.Item key='2' onClick={() => resetTableSort()}>
                      Сбросить сортировку
                    </Menu.Item>
                  )}
                </Menu>
              }
            >
              Сбросить фильтры и сортировку
            </ButtonWithMenu>
          </Space>
          <Title level={3}>История операций</Title>
        </Space>

        {isContractTable ? (
          <ManagementStatisticContractsTable />
        ) : (
          <ManagementStatisticEmployeeTable />
        )}
      </div>
    </Col>
  );
};
export const ManagementStatisticRightCard = React.memo(_ManagementStatisticRightCard);
