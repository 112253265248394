import React, { useCallback } from 'react';
import './styles.scss';
import { ContractFavorite } from './ContractFavorite';
import { useHistory } from 'react-router-dom';
import moment from 'moment';
import { ContractRecordResponse } from '@/api/Contracts/types';
import { routes } from '@/resources/routes';

type ContractItemProps = {
  item: ContractRecordResponse & {
    isFavorite: boolean;
  };
  onChangeIsFavorite: (newVal: boolean) => void;
};

export const ContractItem: React.FC<ContractItemProps> = React.memo(
  ({ item, onChangeIsFavorite }) => {
    const history = useHistory();

    const handleChangeFavorite = useCallback(() => {
      onChangeIsFavorite(!item.isFavorite);
    }, [onChangeIsFavorite, item.isFavorite]);

    const handleCardClick = useCallback(() => {
      history.push(routes.contractDetail(item._id));
    }, [item._id, history]);

    return (
      <div className='contract' onClick={handleCardClick}>
        <div className='contract__top'>
          <div className='contract__title'>{item.title}</div>
          <div className='contract__favorite'>
            <ContractFavorite curValue={item.isFavorite} onChange={handleChangeFavorite} />
          </div>
        </div>

        <div className='contract__info'>
          <div className='contract__info_item'>
            <span className='contract__info_item_name'>Дата создания: </span>
            <span className='contract__info_item_value'>
              {moment(item.createdAt).format('DD.MM.YYYY hh:mm')}
            </span>
          </div>
          <div className='contract__info_item'>
            <span className='contract__info_item_name'>Дата изменения: </span>
            <span className='contract__info_item_value'>
              {moment(item.updatedAt).format('DD.MM.YYYY hh:mm')}
            </span>
          </div>
          <div className='contract__info_item'>
            <span className='contract__info_item_name'>Код в Territory: </span>
            <span className='contract__info_item_value'>{item.actusCode}</span>
          </div>
        </div>
      </div>
    );
  },
);
