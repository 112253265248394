import { AppButton } from '@/components/AppButton';
import { AppInput } from '@/components/inputs/AppInput';
import { routes } from '@/resources/routes';
import { errorMessages } from '@/utils/errorMessages';
import { Form } from 'antd';
import { Rule } from 'antd/lib/form';
import React from 'react';
import { PasswordRestoreForm } from '../types';
import './styles.scss';

const emailRules: Rule[] = [
  { required: true, message: errorMessages.required },
  { type: 'email' as const, message: errorMessages.email },
];

export type PasswordRestorePageFormProps = {
  onSubmit: (formData: PasswordRestoreForm) => void;
  isLoading: boolean;
};

const _PasswordRestorePageForm: React.FC<PasswordRestorePageFormProps> = ({
  onSubmit,
  isLoading,
}) => {
  const [form] = Form.useForm<PasswordRestoreForm>();

  return (
    <Form form={form} onFinish={onSubmit}>
      <Form.Item name={'email'} rules={emailRules}>
        <AppInput className='password-reset__email' placeholder='E-mail*' disabled={isLoading} />
      </Form.Item>
      <div className='password-restore-page-form__buttons'>
        <AppButton size='large' type='primary' htmlType='submit' block loading={isLoading}>
          Отправить письмо с подтверждением
        </AppButton>
        <AppButton size='large' type='primary' ghost block to={routes.login}>
          Вернуться к авторизации
        </AppButton>
      </div>
    </Form>
  );
};
export const PasswordRestorePageForm = React.memo(_PasswordRestorePageForm);
