import { openAutoRunModal } from '@/globalStorage/autoRun/modal';
import { $autoRunWidgetState } from '@/globalStorage/autoRun/widget';
import { LoadingOutlined } from '@ant-design/icons';
import { createComponent } from 'effector-react';
import React from 'react';
import { WidgetContainer } from '../common/WidgetContainer';

export type AutoRunWidgetProps = {
  className?: string;
  style?: React.CSSProperties;
};

export const AutoRunWidget = createComponent($autoRunWidgetState, (props, state) => {
  const { status } = state;

  if (status === 'idle') {
    return (
      <WidgetContainer onClick={openAutoRunModal}>
        <div>Автопрогон объектов (не ведется)</div>
      </WidgetContainer>
    );
  }

  if (status === 'inProcess') {
    const { doneCount, allCount } = state;
    return (
      <WidgetContainer onClick={openAutoRunModal}>
        <div>Автопрогон объектов</div>
        <LoadingOutlined spin />
        <div>
          {doneCount} / {allCount}
        </div>
      </WidgetContainer>
    );
  }
  if (status === 'done') {
    return (
      <WidgetContainer onClick={openAutoRunModal}>
        <div>Автопрогон объектов</div>
        <div>(готово)</div>
      </WidgetContainer>
    );
  }
});
