import React from 'react';
import cn from 'classnames';
import './styles.scss';

type Props = {
  className?: string;
  style?: any;
  children?: React.ReactNode;
};
const _StepContent: React.FC<Props> = (props) => {
  const { className, style, children } = props;

  return (
    <div className={cn('step-content', className)} style={style}>
      {children}
    </div>
  );
};
export const StepContent = React.memo(_StepContent);
