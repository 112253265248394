import { ControlledTableColumns } from '@uspect-team/ant-ui-kit/dist/Tables/ControlledTable/types';
import { TdRecordResponse } from '@/api/Td/types';
import { Button, Popconfirm, Popover, Space, Tag } from 'antd';
import { RecordPopover } from '@/components/modals/utils/RecordPopover';
import { DeleteOutlined, InfoCircleFilled } from '@ant-design/icons/lib';
import { fileSizeToString } from '@/utils/fileSizeToString';
import React from 'react';
import { DateView } from '@uspect-team/ant-ui-kit';
import { ExternalLink } from '@/components/ExternalLink';

export const getPdColumns = (options?: {
  onDelete?: (record: TdRecordResponse) => void;
}): ControlledTableColumns<TdRecordResponse & { public?: boolean }> => {
  const columns: ControlledTableColumns<TdRecordResponse & { public?: boolean }> = [
    {
      key: 'title',
      title: 'Имя',
      dataIndex: 'title',
      width: 300,
      render: (value, record) => (
        <Space>
          <div>{value}</div>
          <Popover content={<RecordPopover record={record} />}>
            <InfoCircleFilled style={{ color: '#66CCFF' }} />
          </Popover>
        </Space>
      ),
    },
    {
      key: 'fileName',
      dataIndex: ['file'],
      title: 'Имя файла',
      width: 200,
      render: (_, record) => (
        <ExternalLink path={record.file.fileUri} ellipsis>
          {record.file.fileName}
        </ExternalLink>
      ),
    },
    {
      key: 'fileSize',
      dataIndex: ['file', 'size'],
      title: 'Размер',
      width: 100,
      render: (value) => fileSizeToString(value),
    },
    {
      key: 'fileUploadOn',
      dataIndex: ['date'],
      title: 'Дата создания',
      width: 100,
      render: (value) => <DateView value={value} />,
    },
    {
      key: 'fileUploadOn',
      dataIndex: ['file', 'createdAt'],
      title: 'Дата загрузки',
      width: 100,
      render: (value) => <DateView value={value} />,
    },

    {
      key: 'fileExt',
      title: 'Расширение',
      align: 'center',
      dataIndex: ['file', 'extension'],
      width: 130,
      render: (value) => <Tag>{value}</Tag>,
    },
  ];
  const onDelete = options?.onDelete;
  if (onDelete) {
    columns.push({
      key: 'delete',
      dataIndex: 'act',
      width: 75,
      fixed: 'right',
      render: (_, record) => {
        if (record.public) {
          return undefined;
        }
        return (
          <div style={{ display: 'flex', justifyContent: 'center' }}>
            <Popconfirm title={'Вы уверены?'} onConfirm={() => onDelete(record)}>
              <Button icon={<DeleteOutlined />} type={'dashed'} danger />
            </Popconfirm>
          </div>
        );
      },
    });
  }

  return columns;
};
