import { Form, message, Modal } from 'antd';
import React, { useCallback } from 'react';
import { CreateColumnGroupFormType } from '@/components/modals/columnGroups/CreateColumnGroupModal/types';
import { useCreateColumnGroupM } from '@/hooks/directory/columnGroups';
import { ColumnGroupsCreateRequest } from '@/api/Dictionary/ColumnGroups/types';
import { ColumnGroupFields } from '@/components/modals/columnGroups/common/ColumnGroupFields';

export type CreateColumnGroupProps =
  | {
      visible: false;
    }
  | ({
      visible: true;
    } & VisibleProps);

type VisibleProps = {
  onClose: () => void;
};

export const CreateColumnGroupModal: React.FC<CreateColumnGroupProps> = React.memo((props) => {
  const { visible } = props;

  if (!visible) {
    return null;
  }
  const { visible: _, ...visibleProps } = props;
  return <VisibleModal {...visibleProps} />;
});

const VisibleModal: React.FC<VisibleProps> = React.memo((props) => {
  const { onClose } = props;
  const [form] = Form.useForm<CreateColumnGroupFormType>();
  const createColumnGroupM = useCreateColumnGroupM();

  const onFinishFormHandler = useCallback(
    async (values: CreateColumnGroupFormType) => {
      try {
        const createReq = values as ColumnGroupsCreateRequest;
        await createColumnGroupM.mutateAsync(createReq);
        onClose();
        message.success('Группа колонок успешно создана');
      } catch (e) {
        message.error('Не удалось создать группу колонок');
      }
    },
    [createColumnGroupM, onClose],
  );
  return (
    <Modal
      visible={true}
      title={'Создание группы колонок'}
      okText={'Создать'}
      onOk={form.submit}
      okButtonProps={{ loading: createColumnGroupM.isLoading }}
      cancelText={'Отмена'}
      onCancel={onClose}
    >
      <Form
        layout={'vertical'}
        form={form}
        onFinish={onFinishFormHandler}
        initialValues={{ contractType: 'all', order: 1 }}
      >
        <ColumnGroupFields />
      </Form>
    </Modal>
  );
});
