import { api } from '@/api/axiosInstance';
import { RecordId } from '@/api/common/types';
import {
  CreateDisplayModeRequest,
  CreateDisplayModeResponse,
  GetDisplayModeByIdResponse,
  GetDisplayModePageRequest,
  GetDisplayModePageResponse,
  PatchDisplayModeRequest,
  PatchDisplayModeResponse,
} from '@/api/Dictionary/DisplayMods/types';

const baseUrl = '/display_mode';
export class DisplayModsApi {
  public getPage(params: GetDisplayModePageRequest) {
    return api.get<GetDisplayModePageResponse>(baseUrl, { params: params });
  }

  public getById = async (id: RecordId) => {
    return await api.get<GetDisplayModeByIdResponse>(`${baseUrl}/${id}`);
  };

  public create(data: CreateDisplayModeRequest) {
    return api.post<CreateDisplayModeResponse>(baseUrl, data);
  }
  public patch(data: PatchDisplayModeRequest) {
    return api.patch<PatchDisplayModeResponse>(`${baseUrl}/${data.id}`, data.patch);
  }
  public delete(id: RecordId) {
    return api.delete<void>(`${baseUrl}/${id}`);
  }
}
