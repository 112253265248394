import React from 'react';
import cn from 'classnames';
import './styles.scss';

type Props = {
  className?: string;
  style?: any;
};
export const Footer: React.FC<Props> = (props) => {
  const { className, style } = props;

  return (
    <footer className={cn('main-layout-footer', className)} style={style}>
      ООО &laquo;Вершина&raquo;. Все права защищены.
    </footer>
  );
};
