import {
  CptCreateRequest,
  CptCreateResponse,
  CptGetByIdResponse,
  GetCptPageRequest,
  CptGetResponse,
  CptPatchRequest,
  CptPatchResponse,
} from './types';
import { api } from '@/api/axiosInstance';
import { RecordId } from '@/api/common/types';

export class CptApi {
  get(params: GetCptPageRequest) {
    return api.get<CptGetResponse>('/cpt', { params });
  }
  getById(id: RecordId) {
    return api.get<CptGetByIdResponse>(`/cpt/${id}`);
  }
  create(data: CptCreateRequest) {
    return api.post<CptCreateResponse>('/cpt', data);
  }
  patch(data: CptPatchRequest) {
    return api.patch<CptPatchResponse>(`/cpt/${data.id}`, data.patch);
  }
  delete(id: RecordId) {
    return api.delete<void>(`/cpt/${id}`);
  }
}
