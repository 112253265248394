import React, { useCallback, useEffect, useState } from 'react';
import { Form, message, Modal } from 'antd';
import { useGetOrganizationByIdQ, usePatchOrganizationM } from '@/hooks/directory/organizations';
import { PatchOrganizationRequest } from '@/api/Dictionary/Organizations/types';
import { RecordId } from '@/api/common/types';
import { UpdateOrganizationFormType } from './types';
import { OrganizationFields } from '@/components/modals/organizations/common/OrganizationFields';
import moment from 'moment';

export type EditOrganizationModalProps =
  | {
      visible: false;
    }
  | ({ visible: true } & VisibleProps);

type VisibleProps = {
  organizationId: RecordId;
  onClose: () => void;
};

export const EditOrganizationModal: React.FC<EditOrganizationModalProps> = (props) => {
  const { visible } = props;

  if (!visible) {
    return null;
  }
  const { visible: _, ...visibleProps } = props;
  return <VisibleModal {...visibleProps} />;
};

const VisibleModal: React.FC<VisibleProps> = React.memo((props) => {
  const { onClose, organizationId } = props;

  const patchOrganizationM = usePatchOrganizationM();
  const { data: organization, isLoading } = useGetOrganizationByIdQ(organizationId);

  const [form] = Form.useForm<UpdateOrganizationFormType>();
  useEffect(() => {
    form.setFieldsValue({
      ...organization,
      regDate: organization?.regDate ? moment(organization.regDate) : undefined,
    });
  }, [organization, form]);
  const [changes, setChanges] = useState<UpdateOrganizationFormType | null>(null);

  const onUpdateHandler = useCallback(async () => {
    if (changes) {
      try {
        const updateReq = {
          id: organizationId,
          patch: {
            ...changes,
            regDate: changes.regDate ? changes.regDate.toISOString() : undefined,
          },
        } as PatchOrganizationRequest;
        try {
          await patchOrganizationM.mutateAsync(updateReq);
          onClose();
          message.success('Запись успешно изменена');
        } catch (e) {
          message.error('При редактировании записи организации произошла ошибка');
        }
      } catch (e) {
        message.error('Есть невалидные поля');
      }
    }
  }, [changes, organizationId, patchOrganizationM, onClose]);

  return (
    <Modal
      visible={true}
      width={1000}
      onCancel={onClose}
      title={isLoading ? 'Загрузка...' : `${organization?.title || ''}: Редактирование записи`}
      okText={'Сохранить изменения'}
      okButtonProps={{ loading: patchOrganizationM.isLoading, disabled: changes === null }}
      onOk={form.submit}
    >
      <Form
        form={form}
        layout={'vertical'}
        wrapperCol={{ span: 24 }}
        requiredMark={'optional'}
        onFinish={onUpdateHandler}
        onValuesChange={(changes) => {
          setChanges((prevState) => ({ ...prevState, ...changes }));
        }}
      >
        <OrganizationFields
          onChangeExoticFormValues={(ch) => setChanges((prevState) => ({ ...prevState, ...ch }))}
        />
      </Form>
    </Modal>
  );
});
