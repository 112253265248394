import { RecordId } from '@/api/common/types';
import { GetColumnsPageRequest } from '@/api/Dictionary/Columns/types';

export const columnsQueryKeys = {
  all: ['columns'] as const,
  pages: () => [...columnsQueryKeys.all, 'page'] as const,
  page: (data: GetColumnsPageRequest & { type?: string }) =>
    [...columnsQueryKeys.pages(), data] as const,

  details: () => [...columnsQueryKeys.all, 'detail'] as const,
  detail: (id: RecordId) => [...columnsQueryKeys.details(), id] as const,

  create: () => [...columnsQueryKeys.all, 'create'] as const,
  patch: () => [...columnsQueryKeys.all, 'patch'] as const,
  delete: () => [...columnsQueryKeys.all, 'delete'] as const,
};
