import React from 'react';

import cn from 'classnames';
import './styles.scss';
import { AutoRunWidget } from './AutoRunWidget';
import { AutoSignWidget } from './AutoSignWidget';
import { AutoGenFilesWidget } from './AutoGenFilesWidget';

export type AppStateWidgetProps = {
  className?: string;
  style?: React.CSSProperties;
};

const _AppStateWidget: React.FC<AppStateWidgetProps> = (props) => {
  const { className, style } = props;
  return (
    <div className={cn('app-state-widget', className)} style={style}>
      <div className='app-state-widget__left-side'>
        <AutoRunWidget />
        <AutoSignWidget />
        <AutoGenFilesWidget />
      </div>
      <div className='app-state-widget__right-side'></div>
    </div>
  );
};
export const AppStateWidget = React.memo(_AppStateWidget);
