import { Api } from '@/api';
import { AxiosResponse } from 'axios';
import { createApi, createDomain, sample } from 'effector';
import { ExecItem } from '../utils/type';
import { RecordId } from '../../api/common/types';
import { createExecQueue } from '../utils/createExecQueue';

const autoRunDomain = createDomain();

const {
  $execItem,
  $execState,
  $queue,
  $history,
  startExecQueue,
  resetExecQueue,
  newExecItem,
  moveExecItemToHistoryWithError,
  moveExecItemToHistoryWithSuccess,
} = createExecQueue<number>();

const $execContractId = autoRunDomain.createStore<RecordId | null>(null);

const { setExecContractId, clearExecContractId } = createApi($execContractId, {
  setExecContractId: (_, payload: RecordId) => payload,
  clearExecContractId: () => null,
});

// START

const startAutoRun = autoRunDomain.createEvent<{
  contractId: RecordId;
  cadObjectNumbers: number[];
}>();
const _startAutoRunFx = autoRunDomain.createEffect<
  {
    contractId: RecordId;
    cadObjectNumbers: number[];
  },
  void
>(({ contractId, cadObjectNumbers }) => {
  setExecContractId(contractId);
  startExecQueue(cadObjectNumbers);
});

sample({
  clock: startAutoRun,
  filter: $execState.map((x) => x !== 'inProcess'),
  target: _startAutoRunFx,
});

/// RESET

const resetAutoRun = autoRunDomain.createEvent();
sample({
  clock: resetAutoRun,
  filter: $execState.map((x) => x !== 'inProcess'),
  target: [clearExecContractId, resetExecQueue],
});

/// EXEC

const autoRunNewExecItemFx = autoRunDomain.createEffect<
  { execItem: ExecItem<number>; contractId: RecordId },
  Promise<AxiosResponse<any>>
>(({ execItem, contractId }) => {
  return Api.cadastralObjects.autoRun({ cadObjectNum: execItem.item, contractId });
});

sample({
  clock: autoRunNewExecItemFx.done,
  fn: () => undefined,
  target: moveExecItemToHistoryWithSuccess,
});

sample({
  clock: autoRunNewExecItemFx.fail,
  fn: ({ error }) => error.message,
  target: moveExecItemToHistoryWithError,
});

sample({
  clock: newExecItem,
  source: $execContractId,
  fn: (contractId, execItem) => ({ contractId: contractId as RecordId, execItem }),
  target: autoRunNewExecItemFx,
});

const autoRunService = {
  $execItem,
  $queue,
  $execState,
  startAutoRun,
  resetAutoRun,
  $execContractId,
  $history,
};

export { autoRunService };
