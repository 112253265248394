import { ColumnRecordResponse } from '@/api/Dictionary/Columns/types';
import { DictCrudProps } from '@/components/DictCrudPage';
import { StringFilterDropdown } from '@/components/filterDropdowns/StringFilterDropdown';
import { StringFilterItem } from '@/components/ServerConfigTable/types';
import { useDeleteColumnM, useGetColumnsPageQ } from '@/hooks/directory/columns';
import { DateView } from '@uspect-team/ant-ui-kit';
import { RenderFilterParams } from '@uspect-team/ant-ui-kit/dist/Tables/ControlledTable/types';
import { Checkbox } from 'antd';

const handleFilter = (params: RenderFilterParams) => {
  const { currentFilterState, setFilterValue, removeFilter } = params;
  const filter: StringFilterItem = currentFilterState;

  return (
    <StringFilterDropdown
      filter={filter}
      onChangeFilter={setFilterValue}
      onResetFilter={removeFilter}
      hasIncludes
      hasEqual
    />
  );
};

export const getColumnPageConfig = (params: {
  onCreateButtonClick: () => void;
  onEditButtonClick: (record: ColumnRecordResponse) => void;
}): DictCrudProps<ColumnRecordResponse> => ({
  getName: (record) => record.name,
  texts: {
    createButtonText: 'Добавить колонку',
    delete: {
      confirmTitle: (record) =>
        `Вы действительно хотите безвозвратно удалить колонку (${record.name})`,
      successMessage: (record) => `Колонка успешно удалена (${record.name})`,
      errorMessage: (record) => `При удалении колонки произошла ошибка (${record.name})`,
    },
  },
  pagination: { type: 'server', getPageQ: useGetColumnsPageQ },
  deleteRecordM: useDeleteColumnM,
  onCreateButtonClick: params.onCreateButtonClick,
  onEditButtonClick: params.onEditButtonClick,
  columns: [
    {
      fixed: 'left',
      key: 'name',
      dataIndex: 'name',
      title: 'Название',
      sortable: true,
      filterDropdown: handleFilter,
    },
    {
      align: 'center',
      key: 'order',
      dataIndex: 'order',
      title: 'Порядок',
      sortable: true,
    },
    {
      align: 'center',
      key: 'dataIndex',
      dataIndex: 'dataIndex',
      title: 'Имя поля',
      sortable: true,
      filterDropdown: handleFilter,
    },
    {
      align: 'center',
      key: 'isEditable',
      dataIndex: 'isEditable',
      title: 'Изменяемое',
      render: (value) => {
        return <Checkbox checked={value} disabled />;
      },
    },
    {
      align: 'center',
      key: 'isSortable',
      dataIndex: 'isSortable',
      title: 'Сортируемое',
      render: (value) => {
        return <Checkbox checked={value} disabled />;
      },
    },
    {
      align: 'center',
      key: 'isFilterable',
      dataIndex: 'isFilterable',
      title: 'Фильтруемое',
      render: (value) => {
        return <Checkbox checked={value} disabled />;
      },
    },
    {
      align: 'center',
      key: 'isRequired',
      dataIndex: 'isRequired',
      title: 'Обязательное',
      render: (value) => {
        return <Checkbox checked={value} disabled />;
      },
    },
    {
      align: 'center',
      key: 'isFixed',
      dataIndex: 'isFixed',
      title: 'Зафиксировано',
      render: (value) => {
        return <Checkbox checked={value} disabled />;
      },
    },
    {
      align: 'center',
      ellipsis: true,
      key: 'groupName',
      dataIndex: 'groupName',
      title: 'Группа',
      render: (_, record) => {
        return record.groupId?.name;
      },
    },
    {
      align: 'center',
      ellipsis: true,
      key: 'contractType',
      dataIndex: 'contractType',
      title: 'Тип контракта',
      render: (value) => {
        return value;
      },
    },

    {
      key: 'createdAt',
      dataIndex: 'createdAt',
      title: 'Дата создания',
      render: (value) => {
        return <DateView value={value} />;
      },
      sortable: true,
    },
    {
      key: 'updatedAt',
      dataIndex: 'updatedAt',
      title: 'Дата изменения',
      render: (value) => {
        return <DateView value={value} />;
      },
      sortable: true,
    },
  ],
});
