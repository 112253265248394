import React, { useCallback, useState } from 'react';
import {
  CreateColumnModal,
  CreateColumnModalProps,
} from '@/components/modals/columns/CreateColumnModal';
import { EditColumnModal, EditColumnModalProps } from '@/components/modals/columns/EditColumnModal';
import { getColumnPageConfig } from '@/pages/Dictionary/ColumnPage/config';
import { ColumnRecordResponse } from '@/api/Dictionary/Columns/types';
import { DictCrudPage } from '@/components/DictCrudPage';

const _ColumnPage: React.FC = () => {
  const [createColumnModalState, setCreateColumnModalState] = useState<CreateColumnModalProps>({
    visible: false,
  });

  const [editColumnModalState, setEditColumnModalState] = useState<EditColumnModalProps>({
    visible: false,
  });

  const onCreateColumnHandler = useCallback(() => {
    setCreateColumnModalState({
      visible: true,
      onClose: () => setCreateColumnModalState({ visible: false }),
    });
  }, []);

  const onEditColumnHandler = useCallback((record: ColumnRecordResponse) => {
    setEditColumnModalState({
      visible: true,
      onClose: () => setEditColumnModalState({ visible: false }),
      columnId: record._id,
    });
  }, []);

  const columnPageConfig = React.useMemo(() => {
    return getColumnPageConfig({
      onCreateButtonClick: onCreateColumnHandler,
      onEditButtonClick: onEditColumnHandler,
    });
  }, [onCreateColumnHandler, onEditColumnHandler]);

  return (
    <>
      <DictCrudPage {...columnPageConfig} />

      <CreateColumnModal {...createColumnModalState} />
      <EditColumnModal {...editColumnModalState} />
    </>
  );
};

export const ColumnPage = React.memo(_ColumnPage);
