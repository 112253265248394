import {
  $currentUserAvatarLetters,
  $fullUserName,
  $userRoleName,
  logoutFx,
} from '@/globalStorage/user';
import { helperLink } from '@/resources/constants';
import { Avatar, Button, Dropdown, Menu, message } from 'antd';
import { useStore } from 'effector-react';
import React, { useState } from 'react';
import './styles.scss';

export const MenuProfile: React.FC = React.memo(() => {
  const [isShow, setIsShow] = useState(false);

  const fullUserName = useStore($fullUserName);
  const currentUserAvatarLetters = useStore($currentUserAvatarLetters);
  const userRoleName = useStore($userRoleName);
  const isLoadingLogout = useStore(logoutFx.pending);

  const onClickLogoutHandler = React.useCallback(async () => {
    try {
      await logoutFx();
    } catch {
      message.error('Не удалось выйти из аккаунта');
    }
  }, []);

  return (
    <div className='header__container'>
      <Dropdown
        overlay={
          <>
            <div className='header__avatar_welcome'>
              <div className='header__avatar_welcomeText'>Вы вошли как:</div>
              <div className='header__avatar_welcomeName'>{fullUserName}</div>
              <div className='header__avatar_welcomeRole'>{userRoleName}</div>
            </div>
            <Menu>
              <Menu.Divider />
              <Menu.Item key={'logout'}>
                <Button block target='_blank' href={helperLink} style={{ marginBottom: '10px' }}>
                  Помощь
                </Button>
                <Button
                  loading={isLoadingLogout}
                  onClick={onClickLogoutHandler}
                  block
                  type={'text'}
                >
                  Выход
                </Button>
              </Menu.Item>
            </Menu>
          </>
        }
        onVisibleChange={setIsShow}
        visible={isShow}
        className='header__avatar'
        overlayClassName='header__avatar_dropdown'
      >
        <div className='header__avatar__container'>
          <Avatar size={38}>{currentUserAvatarLetters ?? '??'}</Avatar>
        </div>
      </Dropdown>
    </div>
  );
});
