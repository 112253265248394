import { $currentUserInfo } from '@/globalStorage/user';
import { Menu } from 'antd';
import { useStoreMap } from 'effector-react';
import React from 'react';
import { useHistory, useLocation } from 'react-router-dom';
import './styles.scss';
import { getNavMenuConfig } from './utils';

export type HeaderNavMenuProps = {
  className?: string;
  style?: React.CSSProperties;
};

const _HeaderNavMenu: React.FC<HeaderNavMenuProps> = (props) => {
  const history = useHistory();

  const { pathname } = useLocation();

  const permissions = useStoreMap($currentUserInfo, (state) => state?.permissions ?? null);

  const navItems = getNavMenuConfig(permissions);

  const onSelectHandler = ({ key }: { key: string }) => {
    history.push(key);
  };

  return (
    <Menu
      className={'header-nav-menu'}
      mode='horizontal'
      defaultSelectedKeys={[pathname]}
      onSelect={onSelectHandler}
    >
      {navItems}
    </Menu>
  );
};
export const HeaderNavMenu = React.memo(_HeaderNavMenu);
