import { DashboardButton } from '@/components/DasboardButton';
import { BackIcon } from '@/components/customIcons/BackIcon';
import { Row, Col, Button, Typography } from 'antd';
import React from 'react';
import { useHistory } from 'react-router-dom';

import './styles.scss';

const { Title } = Typography;

export type TBoundaryHeaderProps = {
  onSave: () => void;
  contractId: string;
  title: string;
};

const _BoundaryHeader = ({ onSave, contractId, title }: TBoundaryHeaderProps) => {
  const history = useHistory();

  return (
    <Row className='boundary-header__layout'>
      <Col className='boundary-header__col'>
        <DashboardButton
          onClick={() => history.push(`/contracts/${contractId}`)}
          icon={<BackIcon />}
        >
          Вернуться к списку
        </DashboardButton>
        <Title level={2}>Параметры объекта “{title}”</Title>
      </Col>
      <Col className='boundary-header__buttons'>
        <Button type='ghost' onClick={() => history.push(`/contracts/${contractId}`)}>
          Отмена
        </Button>
        <Button type='primary' onClick={onSave}>
          Сохранить изменения
        </Button>
      </Col>
    </Row>
  );
};

export const BoundaryHeader = React.memo(_BoundaryHeader);
