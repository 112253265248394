import { GuestLayout } from '@/components/layouts/GuestLayout';
import React from 'react';
import { RegisterFooter } from './components/RegisterFooter';
import { RegisterForm } from './components/RegisterForm';
import './styles.scss';

const _RegisterPage: React.FC = () => {
  return (
    <GuestLayout className='register-page'>
      <div className='register-page__block-content'>
        <RegisterForm className='register-page__form' />
        <RegisterFooter className='register-page__footer' />
      </div>
    </GuestLayout>
  );
};
export const RegisterPage = React.memo(_RegisterPage);
