import cn from 'classnames';
import React from 'react';
import { CompanyIconButton } from './CompanyIconButton';
import { HeaderLogoTitle } from './LogoTitle';
import './styles.scss';

export type HeaderMainMenuProps = {
  className?: string;
  style?: React.CSSProperties;
  additional?: React.ReactNode;
  withoutText?: boolean;
};

const _HeaderMainMenu: React.FC<HeaderMainMenuProps> = (props) => {
  const { className, style, additional, withoutText } = props;

  return (
    <div className={cn(className, 'header-main-menu')} style={style}>
      <div className='header-main-menu__company-zone'>
        <CompanyIconButton />
      </div>
      <div className='header-main-menu__logo-zone'>
        <HeaderLogoTitle withoutText={withoutText} />

        {additional && <div className='header-main-menu__additional-zone'>{additional}</div>}
      </div>
      <div className='header-main-menu__devider' />
    </div>
  );
};
export const HeaderMainMenu = React.memo(_HeaderMainMenu);
