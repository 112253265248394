import {
  ServerChildColumnType,
  ServerParentColumnType,
  ServerConfigTableContext,
  TableRecordType,
} from '../types';
import {
  ControlledGroupType,
  ControlledTableChildType,
} from '@uspect-team/ant-ui-kit/dist/Tables/ControlledTable/types';
import { isParentServerColumn } from './isParentServerColumn';
import { mapChildServerColumn } from '../utils/mapClildServerColumn';

export const mapAnyServerColumn = (
  serverColumn: ServerChildColumnType | ServerParentColumnType,
  context: ServerConfigTableContext,
  editable: boolean,
): ControlledGroupType<TableRecordType> | ControlledTableChildType<TableRecordType> => {
  if (isParentServerColumn(serverColumn)) {
    const parent = serverColumn as ServerParentColumnType;
    return {
      key: parent.key,
      title: parent.name,
      children: parent.children
        .sort((a, b) => a.order - b.order)
        .map((c) => {
          return mapAnyServerColumn(c, context, editable);
        }),
    } as ControlledGroupType<TableRecordType>;
  } else {
    const children = serverColumn as ServerChildColumnType;
    return mapChildServerColumn(children, context, editable);
  }
};
