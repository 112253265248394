import { RecordId } from '@/api/common/types';
import { SelectContract } from '@/components/inputs/SelectContract';
import { autoGenFilesModalService } from '@/globalStorage/autoGenFiles/modal';
import { GenFileType } from '@/globalStorage/autoGenFiles/types';
import { Checkbox, Form, Space } from 'antd';
import { CheckboxChangeEvent } from 'antd/lib/checkbox';
import React from 'react';
import { StepContent } from '../../../StepContent';
import { CadObjectNumbersSelector } from '../../cadObjects/common/CadObjectNumbersSelector';

export type AutoGenFilesModalIdleProps = {
  contractId: RecordId | null;
  cadObjectNumbers: number[];
  fileTypes: GenFileType[];
};

const _AutoGenFilesModalIdle: React.FC<AutoGenFilesModalIdleProps> = (props) => {
  const { contractId, cadObjectNumbers, fileTypes } = props;

  const fileTypeOnChangeHandleCreator = React.useCallback(
    (fileType: 'kpt' | 'rasters' | 'mif') => {
      return ({ target: { checked } }: CheckboxChangeEvent) => {
        const newSet = new Set(fileTypes);

        if (checked) {
          newSet.add(fileType);
        } else {
          newSet.delete(fileType);
        }

        autoGenFilesModalService.setInitialFileTypes(Array.from(newSet));
      };
    },
    [fileTypes],
  );

  return (
    <StepContent>
      <Form layout='vertical'>
        <Form.Item label={'Контракт'}>
          <SelectContract
            placeholder='Выберите контракт'
            value={contractId}
            onChange={autoGenFilesModalService.setInitialContractId}
          />
        </Form.Item>
        <Form.Item label='Выберите какие файлы нужно сгенерировать'>
          <Space align='center' size={'large'}>
            <Checkbox
              checked={fileTypes.includes('rasters')}
              onChange={fileTypeOnChangeHandleCreator('rasters')}
            >
              Растры (jpg/tab)
            </Checkbox>

            <Checkbox
              checked={fileTypes.includes('kpt')}
              onChange={fileTypeOnChangeHandleCreator('kpt')}
            >
              КПТ (xml/mif)
            </Checkbox>
            {/* <Checkbox
              checked={fileTypes.includes('mif')}
              onChange={fileTypeOnChangeHandleCreator('mif')}
              disabled
            >
              Оси (при наличии координат)
            </Checkbox> */}
          </Space>
        </Form.Item>

        <Form.Item label='Заполните номера объектов (или вставьте их из Excel)'>
          <CadObjectNumbersSelector
            initNumbers={cadObjectNumbers}
            onChangeNumbers={autoGenFilesModalService.setInitialCadObjectNumbers}
          />
        </Form.Item>
      </Form>
    </StepContent>
  );
};
export const AutoGenFilesModalIdle = React.memo(_AutoGenFilesModalIdle);
