import React, { useCallback, useState } from 'react';
import { CadObjectNumbersTextarea } from '@/components/inputs/CadObjectNumbersTextarea';
import { Checkbox } from 'antd';
import './styles.scss';

type Props = {
  initNumbers?: number[];
  selectAll?: {
    value: boolean;
    onChange: (value: boolean) => void;
  };
  onChangeNumbers: (numbers: number[]) => void;
};
const _CadObjectNumbersSelector: React.FC<Props> = React.memo((props) => {
  const { initNumbers, selectAll, onChangeNumbers } = props;
  const [selectedNumbers, setSelectedNumbers] = useState<number[]>(initNumbers || []);

  const onChangeNumbersHandler = useCallback(
    (numbers: number[]) => {
      setSelectedNumbers(numbers);
      onChangeNumbers(numbers);
    },
    [onChangeNumbers],
  );

  const allItemsSelected = selectAll?.value === true;

  return (
    <>
      <CadObjectNumbersTextarea
        onChange={onChangeNumbersHandler}
        initValues={initNumbers}
        disabled={allItemsSelected}
      />
      <div className='cad-object-num-selector__selected-items-addition'>
        {allItemsSelected ? (
          'Выбраны все элементы в контракте'
        ) : selectedNumbers.length > 0 ? (
          <div className='cad-object-num-selector__selected-items'>
            Выбрано объектов: {selectedNumbers.length}
          </div>
        ) : undefined}
        {/* {selectedNumbers.length > 0 && (
          <div className='cad-object-num-selector__selected-items'>
            Выбрано объектов: {selectedNumbers.length}
          </div>
        )} */}

        {selectAll && (
          <Checkbox
            className={'cad-object-num-selector__autocompleteAll-cbx'}
            checked={selectAll.value}
            onChange={(e) => selectAll.onChange(e.target.checked)}
          >
            Выбрать все объекты контракта
          </Checkbox>
        )}
      </div>
    </>
  );
});

export const CadObjectNumbersSelector = React.memo(_CadObjectNumbersSelector);
