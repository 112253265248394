import { CadObjectResponse } from '@/api/CadObjects/types';
import { useCadObjectsGenRastersM, useDeleteCadObjectM } from '@/hooks/cadastralObjects';
import { SelectedItemsActions } from '@uspect-team/ant-ui-kit';
import { SelectedItemsActionsAction } from '@uspect-team/ant-ui-kit/dist/Tables/SelectedItemsActions';
import { Modal } from 'antd';
import { useStore } from 'effector-react';
import React from 'react';
import {
  $contractId,
  $isCanEditContract,
  $selectedCadObjects,
  clearSelectedCadObjects,
  openAutoRunWizardModalWithSelectedCadObjects,
  openAutoSignWizardModalWithSelectedCadObjects,
  openFieldFillingWizardModalWithContext,
  openGenKptAndRastersWithSelectedCadObjects,
  openGenKptWithSelectedCadObjects,
  openMultipleActionModal,
} from '../../model';

const _MainTableSelectedItemsActions: React.FC = () => {
  const contractId = useStore($contractId);
  const selectedItems = useStore($selectedCadObjects);
  const isCanEditContract = useStore($isCanEditContract);

  const { mutateAsync: deleteCadObjectM } = useDeleteCadObjectM();
  const { mutateAsync: genRastersM } = useCadObjectsGenRastersM();

  const openMultipleGenRastersModal = React.useCallback(
    (items: CadObjectResponse[]) => {
      if (contractId) {
        openMultipleActionModal({
          title: 'Генерация растров',
          items,
          asyncAction: (i) => genRastersM({ cadObjectNumber: i.num, contractId }),
          mode: 'parallel',
        });
      }
    },
    [contractId, genRastersM],
  );

  const onDeleteSelectedItemsHandler = React.useCallback(
    (items: CadObjectResponse[]) => {
      Modal.confirm({
        title: `Вы действительно хотите безвозвратно удалить кадастровые объекты 
          (${items.length} шт.)?`,
        content: 'Это действие нельзя будет отменить',
        onOk: () => {
          openMultipleActionModal({
            title: 'Удаление кадастровых объектов',
            items: items,
            asyncAction: (i) => deleteCadObjectM(i._id),
            onClose: () => {
              clearSelectedCadObjects();
            },
            mode: 'parallel',
          });
        },
        okType: 'danger',
        okText: 'Да, удалить',
        cancelText: 'Отмена',
      });
    },
    [deleteCadObjectM],
  );

  const actions: SelectedItemsActionsAction[] = React.useMemo(() => {
    if (!isCanEditContract) {
      return [];
    }
    return [
      {
        key: 'gen_kpt_and_rasters',
        title: 'Сгенерировать КПТ и растры',
        onClick: () => openGenKptAndRastersWithSelectedCadObjects(),
      },
      {
        key: 'gen_kpt',
        title: 'Сгенерировать КПТ',
        onClick: () => openGenKptWithSelectedCadObjects(),
      },
      {
        key: 'gen_rasters',
        title: 'Сгенерировать растр',
        onClick: openMultipleGenRastersModal,
      },
      {
        key: 'autoRun',
        title: 'Автоматический прогон',
        onClick: () => openAutoRunWizardModalWithSelectedCadObjects(),
      },
      {
        key: 'autoSign',
        title: 'Автоматическое подписание',
        onClick: () => openAutoSignWizardModalWithSelectedCadObjects(),
      },
      {
        key: 'field_filling_wizard',
        title: 'Вызвать мастер заполнения полей',
        onClick: () => openFieldFillingWizardModalWithContext(),
      },
      {
        key: 'delete',
        danger: true,
        title: 'Удалить',
        onClick: onDeleteSelectedItemsHandler,
      },
    ];
  }, [isCanEditContract, onDeleteSelectedItemsHandler, openMultipleGenRastersModal]);

  return (
    <SelectedItemsActions
      selectedItems={selectedItems}
      actions={actions}
      onClearSelect={clearSelectedCadObjects}
    />
  );
};
export const MainTableSelectedItemsActions = React.memo(_MainTableSelectedItemsActions);
