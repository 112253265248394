import { RecordId } from '@/api/common/types';
import { FileRecordResponse, FileType } from '@/api/Files/types';
import { SelectAlreadyExistFileModalProps } from '@/components/modals/cadObjects/SelectAlreadyExistFileModal';
import { UploadFileModalProps } from '@/components/modals/files/UploadFileModal';
import { createDomain } from 'effector';

const fileDomain = createDomain();

export const openUploadFileModal = fileDomain.createEvent<{
  fileType: FileType;
  contractId?: RecordId;
  onUpload: (file: FileRecordResponse) => void;
}>();
export const closeUploadFileModal = fileDomain.createEvent();

export const openSelectAlreadyExistFileModal = fileDomain.createEvent<{
  fileType: FileType;
  contractId?: RecordId;
  title: string;
  onSelectFile: (file: FileRecordResponse) => void;
}>();
export const closeSelectAlreadyExistFileModal = fileDomain.createEvent();

export const $uploadFileModalState = fileDomain.createStore<UploadFileModalProps>({
  visible: false,
});

export const $selectAlreadyExistFileModalState =
  fileDomain.createStore<SelectAlreadyExistFileModalProps>({ visible: false });

$uploadFileModalState
  .on(openUploadFileModal, (_, payload) => ({
    visible: true,
    fileType: payload.fileType,
    onClose: () => closeUploadFileModal(),
    contractId: payload.contractId,
    onUpload: payload.onUpload,
  }))
  .reset(closeUploadFileModal);

$selectAlreadyExistFileModalState
  .on(openSelectAlreadyExistFileModal, (_, payload) => ({
    visible: true,
    fileType: payload.fileType,
    title: payload.title,
    onClose: () => closeSelectAlreadyExistFileModal(),
    contractId: payload.contractId,
    onSelectFile: payload.onSelectFile,
  }))
  .reset(closeSelectAlreadyExistFileModal);
