import { RecordId } from '@/api/common/types';
import { GetUserPageRequest } from '@/api/Users/types';

export const usersQueryKeys = {
  all: ['users'] as const,
  pages: () => [...usersQueryKeys.all, 'page'] as const,
  page: (data: GetUserPageRequest) => [...usersQueryKeys.pages(), data] as const,

  details: () => [...usersQueryKeys.all, 'detail'] as const,
  detail: (id: RecordId) => [...usersQueryKeys.details(), id] as const,
};
