import cn from 'classnames';
import React from 'react';
import { AddressSuggestions, DaDataAddress, DaDataSuggestion } from 'react-dadata';
import { daDataToken } from '../constants';
import './../dadataInputStyles.scss';

export type AddressInputProps = {
  className?: string;
  value?: DaDataSuggestion<DaDataAddress>;
  onChange?: (value?: DaDataSuggestion<DaDataAddress>) => void;
  inputProps?: React.HTMLProps<HTMLInputElement>;
};

const _AddressInput: React.FC<AddressInputProps> = (props) => {
  const { className, value, onChange, inputProps } = props;
  return (
    <AddressSuggestions
      value={value}
      inputProps={{ className: 'dadata-antd-input__input', ...inputProps }}
      token={daDataToken}
      onChange={onChange}
      containerClassName={cn('dadata-antd-input__container', className)}
      suggestionsClassName='dadata-antd-input__suggestions'
      suggestionClassName='dadata-antd-input__suggestion'
      currentSuggestionClassName='dadata-antd-input__suggestion--current'
      highlightClassName='organization-name-input__suggestion-highlight'
    />
  );
};
export const AddressInput = React.memo(_AddressInput);
