import React from 'react';
import './styles.scss';

export type ListWithTitleProps = {
  title: string;
  children?: React.ReactNode;
};

const _ListWithTitle: React.FC<ListWithTitleProps> = (props) => {
  const { title, children } = props;

  return (
    <div className='list-with-title'>
      <div className='list-with-title__title'>{title}</div>
      <div className='list-with-title__list'>{children}</div>
    </div>
  );
};
export const ListWithTitle = React.memo(_ListWithTitle);
