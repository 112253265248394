import React, { useState } from 'react';

import { EmployeeRecordResponse } from '@/api/Dictionary/Employees/types';
import { ManagementEmployeeTable } from '@/components/ManagementEmployeeTable';
import { EditEmployeeModalProps } from '@/components/modals/employee/EditEmployeeModal';
import {
  CreateEmployeeModalProps,
  CreateManagementEmployeeModal,
} from '@/components/modals/management/employee/CreateEmployeeModal';
import { EditManagementEmployeeModal } from '@/components/modals/management/employee/EditEmployeeModal';
import { getEmployeesPageConfig } from './config';
import './styles.scss';

const _EmployeesPage: React.FC = () => {
  const [createEmployeeModalState, setCreateEmployeeModalState] =
    useState<CreateEmployeeModalProps>({ visible: false });

  const [editEmployeeModalState, setEditEmployeeModalState] = useState<EditEmployeeModalProps>({
    visible: false,
  });

  const onCreateButtonClickHandler = React.useCallback(() => {
    setCreateEmployeeModalState({
      visible: true,
      onClose: () => {
        setCreateEmployeeModalState({ visible: false });
      },
    });
  }, []);

  const onEditButtonClickHandler = React.useCallback((record: EmployeeRecordResponse) => {
    setEditEmployeeModalState({
      visible: true,
      onClose: () => {
        setEditEmployeeModalState({ visible: false });
      },
      employeeId: record._id,
    });
  }, []);

  const employeesPageConfig = React.useMemo(() => {
    return getEmployeesPageConfig({
      onCreateButtonClick: onCreateButtonClickHandler,
      onEditButtonClick: onEditButtonClickHandler,
    });
  }, [onCreateButtonClickHandler, onEditButtonClickHandler]);

  return (
    <div className='management-employee__wrapper'>
      <ManagementEmployeeTable {...employeesPageConfig} />
      <CreateManagementEmployeeModal {...createEmployeeModalState} />
      <EditManagementEmployeeModal {...editEmployeeModalState} />
    </div>
  );
};

export const ManagementEmployees = React.memo(_EmployeesPage);
