import { CadObjectResponse } from '@/api/CadObjects/types';
import { Api } from '@/api';
import { RecordId } from '@/api/common/types';
import { PolylineRecord } from '@/api/Coordinates/Coords/types';
import { coordinateService } from '@/globalStorage/coordinates/newIndex';
import { createDomain, sample } from 'effector';
import { createVarStore } from './../utils/createVarStore';

const cadObjectDomain = createDomain();

const { $store: $editCordCadObject, setStore: setEditCordCadObject } =
  createVarStore<CadObjectResponse | null>(null);

const $editCordCadObjectId = $editCordCadObject.map((x) => x?._id ?? null);
const $editContractId = $editCordCadObject.map((x) => x?.contractId ?? null);

const {
  $store: $initCadObjectCoordinateEditIsLoading,
  setStore: setInitCadObjectCoordinateEditIsLoading,
  resetStore: resetInitCadObjectCoordinateEditIsLoading,
} = createVarStore<boolean>(false);

const initCadObjectCoordinateFx = cadObjectDomain.createEffect(
  async (payload: { cadObjectId: RecordId }) => {
    setInitCadObjectCoordinateEditIsLoading(true);

    const response = await Api.cadastralObjects.getById(payload.cadObjectId);
    const coordinateId = response.data.data.coordinates;
    setEditCordCadObject(response.data.data);

    if (coordinateId) {
      coordinateService.initEditCoordinateFx(coordinateId);
    } else {
      coordinateService.initNewCoordinateFx();
    }
    resetInitCadObjectCoordinateEditIsLoading();
  },
);

const saveCoordinateChangesFx = cadObjectDomain.createEffect(
  async (payload: {
    cadObjectId: RecordId;
    coordinateId: RecordId | null;
    changes: { polylineList: PolylineRecord[]; tpList: RecordId[] };
  }) => {
    const { cadObjectId, coordinateId, changes } = payload;

    if (coordinateId) {
      await Api.coordinates.coords.update({
        coordinateId: coordinateId,
        patch: { cadObjectId, ...changes },
      });
    } else {
      const response = await Api.coordinates.coords.create({ cadObjectId, ...changes });
      const newCoordinateId = response.data.data._id;
      await Api.cadastralObjects.patch({
        id: cadObjectId,
        patch: { coordinates: newCoordinateId },
      });
      coordinateService.initEditCoordinateFx(newCoordinateId);
    }

    coordinateService.setHaveChanges(false);
  },
);
const $saveCoordinateChangesIsLoading = saveCoordinateChangesFx.pending;

const saveCoordinateChanges = cadObjectDomain.createEvent();

sample({
  clock: saveCoordinateChanges,
  source: {
    coordinateId: coordinateService.$coordinateId,
    cadObjectId: $editCordCadObjectId,
    tpList: coordinateService.$tpList.map((x) => x.map((x) => x._id)),
    polylineList: coordinateService.$polylineList.map((x) =>
      x.map(
        (x) =>
          ({
            coords: x.points.map((p) => ({
              mskX: p.mskX,
              mskY: p.mskY,
              wgsX: p.wgsX,
              wgsY: p.wgsY,
            })),
          } as PolylineRecord),
      ),
    ),
  },
  filter: ({ cadObjectId }) => cadObjectId !== null,
  fn: ({ cadObjectId, coordinateId, polylineList, tpList }) => ({
    cadObjectId: cadObjectId as RecordId,
    coordinateId,
    changes: { polylineList, tpList },
  }),
  target: saveCoordinateChangesFx,
});

const editCadObjectCoordinateService = {
  $contractId: $editContractId,
  init: initCadObjectCoordinateFx,
  initIsLoading: $initCadObjectCoordinateEditIsLoading,
  saveCoordinateChanges,
  $saveCoordinateChangesIsLoading,
  $editCordCadObject,
};

export { editCadObjectCoordinateService };
