import { errorMessages } from '@/utils/errorMessages';
import { Checkbox, Col, Form, InputNumber, Row, Select, Slider } from 'antd';
import { Rule } from 'antd/lib/form';
import React from 'react';
import { TabContent } from '../../TabContent';
import {
  availablePaperFormatsOptions,
  availableScalesOptions,
  ozFormatOptions,
} from '../constants';

export type TerritoryConfigTabContentProps = {
  className?: string;
  style?: React.CSSProperties;
};

const requiredRules: Rule[] = [{ required: true, message: errorMessages.required }];
const positiveNumberRules: Rule[] = [{ type: 'number', min: 0, message: errorMessages.positive }];
const requiredPositiveNumberRules: Rule[] = [...requiredRules, ...positiveNumberRules];
const notEmptyArrayRules: Rule[] = [
  { required: true, type: 'array', min: 1, message: errorMessages.notEmptyArray },
];

const _TerritoryConfigTabContent: React.FC<TerritoryConfigTabContentProps> = (props) => {
  return (
    <TabContent>
      <Form.Item name={'ozFormat'} label='Формат построения ОЗ' rules={requiredRules}>
        <Select options={ozFormatOptions} placeholder='Выберите формат' />
      </Form.Item>
      <Row gutter={16}>
        <Col span={12}>
          <Form.Item
            name={'availableFormats'}
            label='Доступные форматы бумаги'
            rules={notEmptyArrayRules}
          >
            <Select mode='multiple' showSearch allowClear options={availablePaperFormatsOptions} />
          </Form.Item>
        </Col>
        <Col span={12}>
          <Form.Item name={'availableScales'} label='Доступные масштабы' rules={notEmptyArrayRules}>
            <Select mode='multiple' showSearch allowClear options={availableScalesOptions} />
          </Form.Item>
        </Col>
      </Row>
      <Row gutter={16}>
        <Col span={12}>
          <Form.Item name={'ozWidth'} label='Ширина ОЗ' rules={requiredPositiveNumberRules}>
            <InputNumber style={{ width: '100%' }} min={0} />
          </Form.Item>
        </Col>
        <Col span={12}>
          <Form.Item dependencies={['type']}>
            {({ getFieldValue }) => {
              const contractType = getFieldValue('type');
              const label =
                (contractType === 'electrical_grid' && 'Ширина ОЗ ТП') ||
                (contractType === 'heating_network' && 'Ширина ОЗ тепловых камер');
              return (
                <>
                  {label && (
                    <Form.Item
                      name={'ozTermalChamberWidth'}
                      label={label}
                      rules={positiveNumberRules}
                    >
                      <InputNumber style={{ width: '100%' }} min={0} />
                    </Form.Item>
                  )}
                </>
              );
            }}
          </Form.Item>
        </Col>
      </Row>
      <Row gutter={16}>
        <Col span={12}>
          <Form.Item
            name={'exportFontWeight'}
            label='Размер шрифта экспорта'
            rules={requiredPositiveNumberRules}
          >
            <InputNumber style={{ width: '100%' }} min={0} />
          </Form.Item>
        </Col>
        <Col span={12}>
          <Form.Item
            name={'exportFontOffset'}
            label='Смещение шрифта экспорта'
            rules={requiredPositiveNumberRules}
          >
            <InputNumber style={{ width: '100%' }} min={0} />
          </Form.Item>
        </Col>
      </Row>
      <Row gutter={16}>
        <Col span={12}>
          <Form.Item label='Увеличение линий'>
            <Row gutter={8} align='middle'>
              <Col span={16}>
                <Form.Item
                  name={'lineScalePercent'}
                  noStyle
                  style={{ flex: 1 }}
                  rules={requiredPositiveNumberRules}
                >
                  <Slider min={0} max={100} tipFormatter={(value) => `${value} %`} />
                </Form.Item>
              </Col>
              <Col span={8}>
                <Form.Item name={'lineScalePercent'} noStyle rules={requiredPositiveNumberRules}>
                  <InputNumber style={{ width: '100%' }} addonAfter='%' />
                </Form.Item>
              </Col>
            </Row>
          </Form.Item>
        </Col>
        <Col span={12}>
          <Form.Item label='Увеличение точек'>
            <Row gutter={8} align='middle'>
              <Col span={16}>
                <Form.Item
                  name={'pointScalePercent'}
                  noStyle
                  style={{ flex: 1 }}
                  rules={requiredPositiveNumberRules}
                >
                  <Slider min={0} max={100} tipFormatter={(value) => `${value} %`} />
                </Form.Item>
              </Col>
              <Col span={8}>
                <Form.Item name={'pointScalePercent'} noStyle rules={requiredPositiveNumberRules}>
                  <InputNumber style={{ width: '100%' }} addonAfter='%' />
                </Form.Item>
              </Col>
            </Row>
          </Form.Item>
        </Col>
      </Row>

      <Row gutter={16}>
        <Col span={12}>
          <Form.Item label='Допустимое отклонение'>
            <Row gutter={8} align='middle'>
              <Col span={16}>
                <Form.Item
                  name={'tolerance'}
                  noStyle
                  style={{ flex: 1 }}
                  rules={requiredPositiveNumberRules}
                >
                  <Slider min={0} max={100} tipFormatter={(value) => `${value} %`} />
                </Form.Item>
              </Col>
              <Col span={8}>
                <Form.Item name={'tolerance'} noStyle rules={requiredPositiveNumberRules}>
                  <InputNumber style={{ width: '100%' }} addonAfter='%' />
                </Form.Item>
              </Col>
            </Row>
          </Form.Item>
        </Col>
      </Row>
      <Row gutter={32}>
        <Col>
          <Form.Item name={'enableScheme'} valuePropName='checked'>
            <Checkbox>Включить схему в пакет</Checkbox>
          </Form.Item>
        </Col>
        <Col>
          <Form.Item name={'enableDescBorders'} valuePropName='checked'>
            <Checkbox>Включить описание границ в пакет</Checkbox>
          </Form.Item>
        </Col>
      </Row>

      <Row gutter={32}>
        <Col>
          <Form.Item name={'enableMSK'} valuePropName='checked'>
            <Checkbox>Включить добавление папки МСК</Checkbox>
          </Form.Item>
        </Col>
      </Row>
    </TabContent>
  );
};
export const TerritoryConfigTabContent = React.memo(_TerritoryConfigTabContent);
