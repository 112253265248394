import { TdRecordResponse } from '@/api/Td/types';
import { openCreatePdModal, openSelectAlreadyExistPdModal } from '@/globalStorage/pd';
import { CloudUploadOutlined, UnorderedListOutlined, UploadOutlined } from '@ant-design/icons/lib';
import { EditableDocument } from '@uspect-team/ant-ui-kit';
import { Button, Dropdown, Menu } from 'antd';
import React, { useCallback } from 'react';

type Props = {
  className?: string;
  style?: any;
  value?: TdRecordResponse;
  onChange?: (value?: TdRecordResponse) => void;
  block?: boolean;
};
export const PdDocumentField: React.FC<Props> = React.memo((props) => {
  const { className, style, value, onChange, block } = props;

  const onCreatePdClick = useCallback(() => {
    openCreatePdModal({
      title: 'Создание нового ПД',
      onCreate: (newPd) => {
        if (onChange) {
          onChange(newPd);
        }
      },
    });
  }, [onChange]);

  const onSelectAlreadyExistPdClick = useCallback(() => {
    openSelectAlreadyExistPdModal({
      title: 'Выбор существующего ПД',
      onSelectPd: (pd) => {
        if (onChange) {
          onChange(pd);
        }
      },
    });
  }, [onChange]);

  return (
    <>
      <EditableDocument
        className={className}
        style={style}
        block={block}
        document={
          value
            ? {
                name: value.title,
                uploadOn: new Date(value.createdAt),
                size: value.file.size,
                link: value.file.fileUri,
              }
            : undefined
        }
        onDelete={() => {
          if (onChange) {
            onChange(undefined);
          }
        }}
        actions={
          <Dropdown
            overlay={
              <Menu>
                <Menu.Item key={'load'} icon={<CloudUploadOutlined />} onClick={onCreatePdClick}>
                  Загрузить файл
                </Menu.Item>
                <Menu.Item
                  key={'exist'}
                  icon={<UnorderedListOutlined />}
                  onClick={onSelectAlreadyExistPdClick}
                >
                  Выбрать из существующих
                </Menu.Item>
              </Menu>
            }
          >
            <Button icon={<UploadOutlined />} />
          </Dropdown>
        }
      />
    </>
  );
});
