import { CheckOutlined, CloseOutlined } from '@ant-design/icons';
import { FilterTypeOption } from '../common/FilterTypeSelect';
import { DocumentFilterType } from './types';
import React from 'react';

export const existOption: FilterTypeOption<DocumentFilterType> = {
  value: 'exist',
  icon: <CheckOutlined />,
  label: 'Заполнено',
};
export const notExistOption: FilterTypeOption<DocumentFilterType> = {
  value: 'notExist',
  icon: <CloseOutlined />,
  label: 'Не заполнено',
};
