import { TpRecordResponse } from '@/api/Coordinates/Tp/types';
import { Button } from 'antd';
import React from 'react';
import './styles.scss';
import { SelectTp } from './SelectTp';
import { coordinateService } from '@/globalStorage/coordinates/newIndex';

export type AddTpFormProps = {
  className?: string;
  style?: React.CSSProperties;
};

const _AddTpForm: React.FC<AddTpFormProps> = (props) => {
  const [selectedTp, setSelectedTp] = React.useState<TpRecordResponse | undefined>(undefined);

  const submitBtnClickHandler = React.useCallback(() => {
    if (selectedTp) {
      coordinateService.addTp(selectedTp);
      setSelectedTp(undefined);
    }
  }, [selectedTp]);
  return (
    <div className='add-tp-form'>
      <SelectTp
        value={selectedTp?._id}
        onChangeTp={setSelectedTp}
        className='add-tp-form__select'
        placeholder='Выберите ТП'
      />
      <Button
        className='add-tp-form__submit'
        disabled={!selectedTp}
        onClick={submitBtnClickHandler}
      >
        Добавить ТП
      </Button>
    </div>
  );
};
export const AddTpForm = React.memo(_AddTpForm);
