import { QueryKey, useIsFetching, useQueryClient } from 'react-query';
import { useCallback } from 'react';

export const createUseFetchingStateObserver = (queryKey: QueryKey) => {
  return () => {
    const queryClient = useQueryClient();
    const isFetching = useIsFetching(queryKey) > 0;
    const refresh = useCallback(() => {
      return queryClient.refetchQueries(queryKey);
    }, [queryClient]);

    return { isFetching, refresh };
  };
};
