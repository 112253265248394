import React from 'react';
import { Col, Form, Input, InputNumber, Row } from 'antd';
import { errorMessages } from '@/utils/errorMessages';
import { ColumnsMultipleSelect } from '@/components/modals/displayMods/common/DisplayModeFields/ColumnsMultipleSelect';

export const DisplayModeFields: React.FC = React.memo(() => {
  return (
    <>
      <Row gutter={18}>
        <Col span={18}>
          <Form.Item
            name={'name'}
            label={'Наименование'}
            rules={[{ required: true, message: errorMessages.required }]}
          >
            <Input style={{ width: '100%' }} />
          </Form.Item>
        </Col>
        <Col span={6}>
          <Form.Item
            name={'sort'}
            label={'Порядок'}
            rules={[
              { required: true, message: errorMessages.required },
              { type: 'integer', message: errorMessages.integer },
            ]}
          >
            <InputNumber style={{ width: '100%' }} />
          </Form.Item>
        </Col>
      </Row>

      <Form.Item
        name={'listColumns'}
        label={'Колонки'}
        rules={[{ required: true, message: errorMessages.required }]}
      >
        <ColumnsMultipleSelect />
      </Form.Item>
    </>
  );
});
