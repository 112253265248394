import { coordinateService } from '@/globalStorage/coordinates/newIndex';
import { useStore } from 'effector-react';
import React from 'react';
import { AddTpForm } from './AddTpForm';
import './styles.scss';
import { TpItem } from './TpItem/intex';

export type TpListProps = {
  className?: string;
  style?: React.CSSProperties;
};

const _TpList: React.FC<TpListProps> = (props) => {
  const tpList = useStore(coordinateService.$tpList);

  return (
    <div className='tp-list'>
      <div className='tp-list__header'>Список ТП</div>
      <div className='tp-list__items-container'>
        {tpList.map((tp) => (
          <TpItem key={tp._id} tp={tp} />
        ))}
      </div>
      <AddTpForm />
    </div>
  );
};
export const TpList = React.memo(_TpList);
