import React from 'react';
import cn from 'classnames';
import './styles.scss';

type Props = {
  className?: string;
  style?: any;
  path: string | undefined;
  ellipsis?: boolean;
};
export const ExternalLink: React.FC<Props> = (props) => {
  const { className, style, path, children, ellipsis } = props;

  return (
    <div
      className={cn('external-link-ceil', className, { 'external-link-ceil--ellipsis': ellipsis })}
      style={style}
    >
      <a href={path} target={'_blank'} rel={'noreferrer'}>
        {children}
      </a>
    </div>
  );
};
