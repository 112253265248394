import React from 'react';
import cn from 'classnames';
import './styles.scss';

type Props = {
  className?: string;
  style?: any;
  addition?: React.ReactNode;
  htmlFor?: string;
};
export const AntdLabelMimic: React.FC<Props> = React.memo((props) => {
  const { className, style, children, addition, htmlFor } = props;

  return (
    <div className={cn('antd-label-mimic', className)} style={style}>
      <label htmlFor={htmlFor}>{children}</label>
      {addition}
    </div>
  );
});
