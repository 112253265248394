import React, { useMemo, useState } from 'react';
import { Button, message } from 'antd';

import { PlusOutlined } from '@ant-design/icons';
import './styles.scss';
import { useFavoriteContractIdsQ, useGetContractsAllQ } from '../../hooks/contracts';
import { ContractList } from './components/ContractList';
import { MainLayout } from '@/components/layouts/MainLayout';
import { CreateContractModal } from '@/components/modals/contracts/CreateContractModal';
import { $currentUserName } from '@/globalStorage/user';
import { useStore } from 'effector-react';

export const ContractsPage = () => {
  const { data, isLoading } = useGetContractsAllQ();
  const [createContractModalIsVisible, setCreateContractModalIsVisible] = useState(false);

  const currentUserName = useStore($currentUserName);

  const { data: favorites, setIsFavorite } = useFavoriteContractIdsQ();

  const favoriteList = useMemo(() => {
    return data?.filter((el) => favorites.includes(el._id)) || [];
  }, [data, favorites]);
  const otherList = useMemo(() => {
    return data?.filter((el) => !favorites.includes(el._id)) || [];
  }, [data, favorites]);

  const onChangeIsFavorite = (id: string, newVal: boolean) => {
    setIsFavorite(id, newVal);

    message.success(newVal ? 'Контракт добавлен в избранное' : 'Контракт убран из избранного');
  };

  return (
    <MainLayout>
      <div className='wrap'>
        <div className='page__header'>
          <div className='page__header_left'>
            <div className='page__header_title'>Контракты</div>
            <div className='page__header_subtitle'>{currentUserName}, здравствуйте!</div>
          </div>

          <div className='page__header_right'>
            <Button
              type='primary'
              size='large'
              icon={<PlusOutlined />}
              onClick={() => setCreateContractModalIsVisible(true)}
            >
              Добавить контракт
            </Button>
          </div>
        </div>

        <div className='contracts'>
          {!!favoriteList.length && (
            <div className='contracts__favorite'>
              <div className='contracts__title'>Избранные контракты:</div>
              <ContractList
                data={favoriteList}
                favorites={favorites}
                onChangeIsFavorite={onChangeIsFavorite}
                isLoading={isLoading}
              />
            </div>
          )}

          <div className='contracts__other'>
            {!!otherList.length && <div className='contracts__title'>Остальные контракты:</div>}
            <ContractList
              data={otherList}
              favorites={favorites}
              onChangeIsFavorite={onChangeIsFavorite}
              isLoading={isLoading}
            />
          </div>
        </div>
      </div>
      <CreateContractModal
        visible={createContractModalIsVisible}
        onCancel={() => setCreateContractModalIsVisible(false)}
      />
    </MainLayout>
  );
};
