import { Form, Tabs } from 'antd';
import React from 'react';
import { TEditContractForm } from '@/types';
import { MailingListTabContent } from './MailingListsTabContent';
import { MainDataTabContent } from './MainDataTabContent';
import { SharedPdTabContent } from './SharedPdTabContent';
import './styles.scss';
import { TabWithValid } from './TabWithValid';
import { TechnokadDataTabContent } from './TechnokadDataTabContent';
import { TerritoryConfigTabContent } from './TerritoryConfigTabContent';
import { ZeroQuarterTabContent } from '@/components/modals/contracts/common/ContractFields/ZeroQuarterTabContent';
import { FeatureToggle } from '@/config';

const firstTabFieldNames = [
  'title',
  'actusCode',
  'region',
  'type',
  'isArchiveContract',
  'monitoring',
  'coordinateSystems',
];
const secondTabFieldNames = [
  'customerCompany',
  'agents',
  'engineer',
  'attorney',
  'calculationMethod',
  'calculationAccuracy',
  'restrictions',
];

const territoryConfigFiledNames = [
  'ozFormat',
  'availableFormats',
  'availableScales',
  'ozWidth',
  'ozTermalChamberWidth',
  'exportFontWeight',
  'exportFontOffset',
  'lineScalePercent',
  'pointScalePercent',
  'tolerance',
  'enableMSK',
];

type ContractFieldsProps = {
  onChangeForm: (formPatch: TEditContractForm) => void;
};
export const ContractFields: React.FC<ContractFieldsProps> = React.memo(({ onChangeForm }) => {
  return (
    <>
      <Tabs type='card'>
        <Tabs.TabPane
          key='mainData'
          forceRender
          tab={
            <Form.Item noStyle shouldUpdate>
              {({ getFieldsError }) => {
                const errors = getFieldsError(firstTabFieldNames);
                const haveError = errors.some((x) => x.errors.length > 0);
                return <TabWithValid haveError={haveError}>Основные данные</TabWithValid>;
              }}
            </Form.Item>
          }
        >
          <MainDataTabContent />
        </Tabs.TabPane>
        );
        <Tabs.TabPane
          key='technokadData'
          forceRender
          tab={
            <Form.Item noStyle shouldUpdate>
              {({ getFieldsError }) => {
                const errors = getFieldsError(secondTabFieldNames);
                const haveError = errors.some((x) => x.errors.length > 0);
                return <TabWithValid haveError={haveError}>Данные технокад</TabWithValid>;
              }}
            </Form.Item>
          }
        >
          <TechnokadDataTabContent />
        </Tabs.TabPane>
        <Tabs.TabPane
          key={'territoryConfig'}
          forceRender
          tab={
            <Form.Item noStyle shouldUpdate>
              {({ getFieldsError }) => {
                const errors = getFieldsError(territoryConfigFiledNames);
                const haveError = errors.some((x) => x.errors.length > 0);
                return <TabWithValid haveError={haveError}>Конфигурация Territory</TabWithValid>;
              }}
            </Form.Item>
          }
        >
          <TerritoryConfigTabContent />
        </Tabs.TabPane>
        <Tabs.TabPane key={'sharedPd'} forceRender tab={'Общие ПД'}>
          <SharedPdTabContent onChangeForm={onChangeForm} />
        </Tabs.TabPane>
        {FeatureToggle.isZeroQuarterEnabled() && (
          <Tabs.TabPane key={'zeroQuarter'} forceRender tab={'Нулевые кварталы'}>
            <ZeroQuarterTabContent onChangeForm={onChangeForm} />
          </Tabs.TabPane>
        )}
        <Tabs.TabPane key={'mailing-lists'} forceRender tab={'Рассылки'}>
          <MailingListTabContent />
        </Tabs.TabPane>
      </Tabs>
    </>
  );
});
