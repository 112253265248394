import { GetUserPageRequest } from './../../api/Users/types';
import { RecordId } from '@/api/common/types';
import { useQuery, useMutation, useQueryClient } from 'react-query';
import { Api } from '@/api';
import { usersQueryKeys } from './queryKeys';

export const useGetUserPageQ = (data: GetUserPageRequest) => {
  return useQuery(
    usersQueryKeys.page(data),
    () => {
      return Api.users.getPage(data);
    },
    {
      select: (response) => response.data,
    },
  );
};

export const useGetUserDetailQ = (id: RecordId) => {
  return useQuery(
    usersQueryKeys.detail(id),
    () => {
      return Api.users.detail(id);
    },
    {
      select: (response) => response.data.data,
    },
  );
};

export const useCreateUserM = () => {
  const queryClient = useQueryClient();

  return useMutation(Api.users.create, {
    onSuccess: (resp) => {
      queryClient.invalidateQueries(usersQueryKeys.pages());
      queryClient.setQueryData(usersQueryKeys.detail(resp.data.data._id), resp);
    },
  });
};

export const useUpdateUserM = () => {
  const queryClient = useQueryClient();

  return useMutation(Api.users.update, {
    onSuccess: (resp) => {
      queryClient.invalidateQueries(usersQueryKeys.pages());
      queryClient.setQueryData(usersQueryKeys.detail(resp.data.data._id), resp);
    },
  });
};

export const useDeleteUserM = () => {
  const queryClient = useQueryClient();

  return useMutation(Api.users.delete, {
    onSuccess: () => {
      queryClient.invalidateQueries(usersQueryKeys.pages());
    },
  });
};
