import { PdDocumentField } from '@/components/inputs/PdDocumentField';
import { Row, Col, Form, DatePicker, Input, Typography } from 'antd';
import React from 'react';

import './styles.scss';

const { Title } = Typography;

const _BoundaryDocs = () => {
  return (
    <div className='boundary-docs__layout boundary-cadaster__form-layout'>
      <Col>
        <Title level={5}>Загрузите документы, использованные при подготовке межевого плана:</Title>
        <Title level={3}>Кадастровый план территории</Title>
        <Row gutter={32}>
          <Col>
            <Form.Item label='Дата:' name={'kptDate'}>
              <DatePicker />
            </Form.Item>
          </Col>
          <Col>
            <Form.Item label='Номер:' name={'kptNumber'}>
              <Input />
            </Form.Item>
          </Col>
          <Col>
            <Form.Item label='Исполнительный орган:' name={'kptIspOrg'}>
              <Input />
            </Form.Item>
          </Col>
        </Row>
      </Col>
      <Col className='boundary-docs__file'>
        <Form.Item label='Выписка из ЕГРН:' name={'dischargeEgrnPd'}>
          <PdDocumentField />
        </Form.Item>
      </Col>
    </div>
  );
};

export const BoundaryDocs = React.memo(_BoundaryDocs);
