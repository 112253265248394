import React from 'react';
import cn from 'classnames';
import './styles.scss';

export type ContentWithFooterContainerProps = {
  className?: string;
  style?: React.CSSProperties;
  children?: React.ReactNode;
};

export const ContentWithFooterContainer: React.FC<ContentWithFooterContainerProps> = (props) => {
  const { className, style, children } = props;

  return (
    <div className={cn(className, 'guest-layout__content-with-footer-container')} style={style}>
      {children}
    </div>
  );
};
