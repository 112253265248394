import { StringFilterDropdown } from '@/components/filterDropdowns/StringFilterDropdown';
import { StringFilterItem } from '@/components/ServerConfigTable/types';
import { RenderFilterParams } from '@uspect-team/ant-ui-kit/dist/Tables/ControlledTable/types';

export const handleFilter = (params: RenderFilterParams) => {
  const { currentFilterState, setFilterValue, removeFilter } = params;
  const filter: StringFilterItem = currentFilterState;

  return (
    <StringFilterDropdown
      filter={filter}
      onChangeFilter={setFilterValue}
      onResetFilter={removeFilter}
      hasIncludes
      hasEqual
    />
  );
};
