import { Space } from 'antd';
import React from 'react';
import { NavLink } from 'react-router-dom';
import './styles.scss';

export interface IManagementMenuProps {}
const menuItems = [
  { title: 'Статистика организации', link: '/management-organization/statistic' },
  { title: 'Данные организации, реквизиты', link: '/management-organization/data-organization' },
  { title: 'Документы и настройки', link: '/management-organization/settings' },
  { title: 'Сотрудники (10)', link: '/management-organization/employees' },
  { title: 'Финансы', link: '/management-organization/financial' },
];

const _ManagementMenu: React.FC<IManagementMenuProps> = (props) => {
  return (
    <Space className='management-menu' size={30}>
      {menuItems.map((item) => (
        <NavLink className='management-menu__item ' to={item.link} key={item.link}>
          {item.title}
        </NavLink>
      ))}
    </Space>
  );
};
export const ManagementMenu = React.memo(_ManagementMenu);
