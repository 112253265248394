import { MainLayout } from '@/components/layouts/MainLayout';
import { CreateEmployeeModalProps } from '@/components/modals/employee/CreateEmployeeModal';
import { CreateManagementEmployeeModal } from '@/components/modals/management/employee/CreateEmployeeModal';
import { Button, Form } from 'antd';
import React, { useState } from 'react';
import { useLocation } from 'react-router-dom';
import { ManagementDataFields } from './ManagementData';
import { ManagementFinance } from './ManagementFinance';
import { ManagementHeader } from './ManagementHeader';
import { ManagementMenu } from './ManagementMenu';
import { ManagementStatistic } from './ManagementStatistic';
import { ManagementEmployees } from './ManagmentEmployee';
import './styles.scss';
import { IManagementOrganizationChange } from './types';

const _ManagementOrganization: React.FC = () => {
  const [form] = Form.useForm<IManagementOrganizationChange>();

  const [createEmployeeModalState, setCreateEmployeeModalState] =
    useState<CreateEmployeeModalProps>({ visible: false });

  const onCreateButtonClickHandler = React.useCallback(() => {
    setCreateEmployeeModalState({
      visible: true,
      onClose: () => {
        setCreateEmployeeModalState({ visible: false });
      },
    });
  }, []);

  const { pathname } = useLocation();

  const currentPath = pathname.split('/').slice(-1)[0];

  return (
    <MainLayout
      hideFooter
      customFooter={
        <footer className='custom-management__footer '>
          <Button type='primary' className='save-button' onClick={() => form.submit()}>
            Сохранить изменения
          </Button>
        </footer>
      }
    >
      <div className='management-wrapper'>
        <ManagementHeader onAddEmployee={onCreateButtonClickHandler} />
        <ManagementMenu />
        {currentPath === 'data-organization' && (
          <Form
            form={form}
            layout={'vertical'}
            onFinish={(values) => console.log('values    ', values)}
          >
            <ManagementDataFields />
          </Form>
        )}
        {currentPath === 'employees' && <ManagementEmployees />}
        {currentPath === 'financial' && <ManagementFinance />}
        {currentPath === 'statistic' && <ManagementStatistic />}
      </div>
      <CreateManagementEmployeeModal {...createEmployeeModalState} />
    </MainLayout>
  );
};
export const ManagementOrganization = React.memo(_ManagementOrganization);
