import { Input } from 'antd';
import React from 'react';

import { StringFilterItem } from '@/components/ServerConfigTable/types';
import { FilterDropdownActions } from '../common/FilterDropdownActions';
import { FilterDropdownContentBase } from '../common/FilterDropdownContentBase';
import { FilterDropdownFormContainer } from '../common/FilterDropdownFormContainer';
import { FilterTypeOption, FilterTypeSelect } from '../common/FilterTypeSelect';
import { equalOption, existOption, includesOption, notExistOption } from './consts';
import { StringFilterType, StringFilterValue } from './types';

export type StringFilterDropdownProps = {
  className?: string;
  style?: React.CSSProperties;

  filter: StringFilterValue;
  onChangeFilter: (value: StringFilterItem) => void;
  onResetFilter: () => void;
  hasIncludes?: boolean;
  hasEqual?: boolean;
  hasExist?: boolean;
};

const _StringFilterDropdown = (props: StringFilterDropdownProps) => {
  const {
    className,
    style,
    filter,
    onChangeFilter,
    onResetFilter,
    hasIncludes,
    hasEqual,
    hasExist,
  } = props;

  React.useEffect(() => {
    if (filter) {
      setUpdatedFilterState(filter);
    }
  }, [filter]);

  const [updatedFilterState, setUpdatedFilterState] = React.useState<StringFilterItem | null>(null);

  const filterTypeOptions = React.useMemo(() => {
    const options: FilterTypeOption<StringFilterType>[] = [];
    if (hasEqual) {
      options.push(equalOption);
    }
    if (hasIncludes) {
      options.push(includesOption);
    }
    if (hasExist) {
      options.push(existOption, notExistOption);
    }
    return options;
  }, [hasEqual, hasExist, hasIncludes]);

  const [selectedFilterType, _setSelectedFilterType] = React.useState<StringFilterType>('equal');
  const changeFilterType = React.useCallback((filterType: StringFilterType) => {
    _setSelectedFilterType(filterType);
    if (filterType === 'exist' || filterType === 'notExist') {
      setUpdatedFilterState({
        filterAs: 'string',
        filterType,
      });
    } else {
      setUpdatedFilterState(null);
    }
  }, []);

  const form = React.useMemo(() => {
    switch (selectedFilterType) {
      case 'exist':
      case 'notExist':
        return null;
      case 'includes': {
        const inputValue =
          updatedFilterState?.filterType === 'includes' ? updatedFilterState.payload : '';
        return (
          <FilterDropdownFormContainer>
            <Input
              value={inputValue}
              onChange={(e) =>
                setUpdatedFilterState({
                  filterAs: 'string',
                  filterType: 'includes',
                  payload: e.target.value,
                })
              }
            />
          </FilterDropdownFormContainer>
        );
      }

      case 'equal': {
        const inputValue =
          updatedFilterState?.filterType === 'equal' ? updatedFilterState.payload : '';
        return (
          <FilterDropdownFormContainer>
            <Input
              value={inputValue}
              onChange={(e) =>
                setUpdatedFilterState({
                  filterAs: 'string',
                  filterType: 'equal',
                  payload: e.target.value,
                })
              }
            />
          </FilterDropdownFormContainer>
        );
      }
    }
  }, [selectedFilterType, updatedFilterState]);

  return (
    <FilterDropdownContentBase className={className} style={style}>
      <FilterTypeSelect
        onChangeSelectedFilterType={changeFilterType}
        selectedFilterType={selectedFilterType}
        filteredType={filter?.filterType ?? null}
        options={filterTypeOptions}
      />
      {form}
      <FilterDropdownActions
        canChangeFilter={updatedFilterState !== null}
        hasFilters={filter !== null && filter !== undefined}
        onSubmitChanges={() => {
          if (updatedFilterState === null) {
            onResetFilter();
          } else {
            onChangeFilter(updatedFilterState);
          }
        }}
        onResetFilter={onResetFilter}
      />
    </FilterDropdownContentBase>
  );
};

export const StringFilterDropdown = React.memo(_StringFilterDropdown);
