import cn from 'classnames';
import React from 'react';
import './styles.scss';

export type GuestLayoutContentContainerProps = {
  className?: string;
  style?: React.CSSProperties;
  children?: React.ReactNode;
};

export const GuestLayoutContentContainer: React.FC<GuestLayoutContentContainerProps> = (props) => {
  const { className, style, children } = props;

  return (
    <div className={cn(className, 'guest-layout__content-center')} style={style}>
      <div className='guest-layout__content-wrapper'>{children}</div>
    </div>
  );
};
