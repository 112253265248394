import cn from 'classnames';
import React from 'react';
import { Link } from 'react-router-dom';
import './styles.scss';

type Props = {
  className?: string;
  style?: any;
};
export const CompaniesPageFooter: React.FC<Props> = React.memo((props) => {
  const { className, style } = props;

  return (
    <div className={cn('companies-page-footer', className)} style={style}>
      <Link className={'companies-page-footer__link'} to={'#'}>
        Техническая поддержка
      </Link>
    </div>
  );
});
