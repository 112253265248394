import React from 'react';
import './styles.scss';
import cn from 'classnames';

export type WidgetContainerProps = {
  className?: string;
  style?: React.CSSProperties;
  onClick?: () => void;
};

export const WidgetContainer: React.FC<WidgetContainerProps> = ({
  children,
  className,
  style,
  onClick,
}) => {
  const isClickable = onClick !== undefined;
  return (
    <div
      className={cn('widget-container', className, { 'widget-container--clickable': isClickable })}
      style={style}
      onClick={onClick}
    >
      {children}
    </div>
  );
};
