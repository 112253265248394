import { RecordId } from '@/api/common/types';
import { TdRecordResponse } from '@/api/Td/types';
import { SelectAlreadyExistPdModalProps } from '@/components/modals/cadObjects/SelectAlreadyExistPdModal';
import { CreatePdModalProps } from '@/components/modals/CreatePdModal';
import { createDomain } from 'effector';

const pdDomain = createDomain('pd');

export const openCreatePdModal = pdDomain.createEvent<{
  title: string;
  contractId?: RecordId;
  onCreate: (newPd: TdRecordResponse) => void;
}>();
export const closeCreatePdModal = pdDomain.createEvent();

export const openSelectAlreadyExistPdModal = pdDomain.createEvent<{
  title: string;
  contractId?: RecordId;
  onSelectPd: (pd: TdRecordResponse) => void;
}>();
export const closeSelectAlreadyExistPdModal = pdDomain.createEvent();

export const $createPdModalState = pdDomain.createStore<CreatePdModalProps>({ visible: false });
export const $selectAlreadyExistPdModalState = pdDomain.createStore<SelectAlreadyExistPdModalProps>(
  { visible: false },
);

$createPdModalState
  .on(openCreatePdModal, (_, payload) => ({
    visible: true,
    onClose: () => closeCreatePdModal(),
    title: payload.title,
    contractId: payload.contractId,
    onCreate: payload.onCreate,
  }))
  .reset(closeCreatePdModal);

$selectAlreadyExistPdModalState
  .on(openSelectAlreadyExistPdModal, (_, payload) => ({
    visible: true,
    onClose: () => closeSelectAlreadyExistPdModal(),
    onSelectPd: payload.onSelectPd,
    title: payload.title,
    contractId: payload.contractId,
  }))
  .reset(closeSelectAlreadyExistPdModal);
