import { RecordId } from '@/api/common/types';
import { EmployeesGetPageRequest } from '@/api/Dictionary/Employees/types';

export const employeesQueryKeys = {
  all: ['employees'] as const,
  pages: () => [...employeesQueryKeys.all, 'page'] as const,
  page: (data: EmployeesGetPageRequest) => [...employeesQueryKeys.pages(), data] as const,

  details: () => [...employeesQueryKeys.all, 'detail'] as const,
  detail: (id: RecordId) => [...employeesQueryKeys.details(), id] as const,

  create: () => [...employeesQueryKeys.all, 'create'] as const,
  patch: () => [...employeesQueryKeys.all, 'patch'] as const,
  delete: () => [...employeesQueryKeys.all, 'delete'] as const,
};
