import React from 'react';
import { UserPermissions } from '@/api/Users/types';
import { routes } from '@/resources/routes';
import { Menu } from 'antd';
import SubMenu from 'antd/lib/menu/SubMenu';

export const getNavMenuConfig = (permissions: UserPermissions | null): React.ReactNode => {
  const isNotGuest = permissions !== null && permissions.role !== 'guest';
  const isSuperAdmin = permissions !== null && permissions.role === 'superadmin';

  return (
    <>
      <Menu.Item key={routes.contracts}>Контракты</Menu.Item>
      {isNotGuest && (
        <>
          <SubMenu key='2' title='Координаты'>
            <Menu.Item key={routes.coordinates.tp}>ТП</Menu.Item>
            <Menu.Item key={routes.coordinates.coordinateSystems}>Системы координат</Menu.Item>
          </SubMenu>
          <SubMenu key='5' title='Справочники'>
            <Menu.Item key={routes.dictionary.cpt}>КПТ</Menu.Item>
            <Menu.Item key={routes.dictionary.employees}>Сотрудники</Menu.Item>
            <Menu.Item key={routes.dictionary.organizations}>Организации</Menu.Item>
            <Menu.Item key={routes.dictionary.columnGroups}>Группы колонок</Menu.Item>
            <Menu.Item key={routes.dictionary.columns}>Колонки</Menu.Item>
            <Menu.Item key={routes.dictionary.displayMods}>Режимы отображения</Menu.Item>
          </SubMenu>
        </>
      )}
      {isSuperAdmin && (
        <SubMenu key='6' title='Администрирование'>
          <Menu.Item key={routes.administration.users()}>Пользователи</Menu.Item>
        </SubMenu>
      )}
    </>
  );
};
