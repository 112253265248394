import React, { Ref, useMemo, useState } from 'react';
import { Button, Select, SelectProps, Space } from 'antd';
import { RefSelectProps } from 'antd/lib/select';
import '@/components/modals/contracts/common/SelectCustomerCompany/styles.scss';
import { useGetAllOrganizationsQ } from '@/hooks/directory/organizations';
import { formatDateToString } from '@uspect-team/ant-ui-kit';

type Props = SelectProps<string> & { ref?: Ref<RefSelectProps> | undefined };
export const SelectCustomerCompany: React.FC<Props> = React.memo((props) => {
  const { data: allOrganizations, isLoading } = useGetAllOrganizationsQ();
  const [inputSearchValue, setInputSearchValue] = useState('');
  const { ...otherProps } = props;

  const selectedDeclarant = useMemo(() => {
    return allOrganizations?.find((x) => x._id === otherProps.value);
  }, [allOrganizations, otherProps.value]);

  return (
    <div className={'select-declarant'}>
      <Select
        showSearch
        optionFilterProp={'children'}
        filterOption={(input, option) => {
          const childrenText = option?.children as string | null | undefined;
          return childrenText?.toLowerCase().includes(input.toLowerCase()) || false;
        }}
        onSearch={setInputSearchValue}
        notFoundContent={<Button block>{`Создать "${inputSearchValue}"`}</Button>}
        loading={isLoading}
        placeholder={'Выберите заявителя'}
        {...otherProps}
      >
        {allOrganizations?.map((i) => (
          <Select.Option key={i._id} value={i._id}>
            {i.title}
          </Select.Option>
        ))}
      </Select>
      {selectedDeclarant && (
        <Space style={{ padding: '8px 0' }}>
          <div>
            <span className={'select-declarant__prop-name'}>ИНН: </span>
            <span className={'select-declarant__prop-value'}>{selectedDeclarant.inn}</span>
          </div>
          <div>
            <span className={'select-declarant__prop-name'}>ОГРН: </span>
            <span className={'select-declarant__prop-value'}>{selectedDeclarant.ogrn}</span>
          </div>
          <div>
            <span className={'select-declarant__prop-name'}>Дата регистрации: </span>
            <span className={'select-declarant__prop-value'}>
              {formatDateToString(new Date(selectedDeclarant.createdAt))}
            </span>
          </div>
        </Space>
      )}
    </div>
  );
});
