import { ForwardButton } from '@/components/ForwardButton';
import { AppInput } from '@/components/inputs/AppInput';
import { registerStateService } from '@/globalStorage/register';
import { RegisterCompanyInfo } from '@/globalStorage/register/types';
import { errorMessages } from '@/utils/errorMessages';
import { Form } from 'antd';
import { Rule } from 'antd/lib/form';
import React from 'react';
import { WorkerCountSelect } from './components/WorkerCountSelect';
import { RegisterCompanyInfoForm } from './types';
import './styles.scss';

const requiredRules: Rule[] = [{ required: true, message: errorMessages.required }];
const websiteUrlRules: Rule[] = [...requiredRules, { type: 'url', message: errorMessages.url }];

const _RegisterCompanyInfoStep: React.FC = () => {
  const [form] = Form.useForm();

  const onSubmitForm = (values: RegisterCompanyInfoForm) => {
    registerStateService.finishThirdStep(values as RegisterCompanyInfo);
  };

  return (
    <Form form={form} onFinish={onSubmitForm} initialValues={{ workerCount: null }}>
      <Form.Item name={'websiteUrl'} rules={websiteUrlRules}>
        <AppInput className='register-step-three__email' placeholder='Веб-сайт вашей компании*' />
      </Form.Item>
      <Form.Item name={'workerCount'} rules={requiredRules}>
        <WorkerCountSelect
          className='register-step-three__select'
          placeholder='Сколько человек будет работать с системой?*'
        />
      </Form.Item>
      <ForwardButton type='primary' size='large' block htmlType='submit'>
        Зарегистрироваться в системе
      </ForwardButton>
    </Form>
  );
};
export const RegisterCompanyInfoStep = React.memo(_RegisterCompanyInfoStep);
