import React from 'react';
import cn from 'classnames';
import './styles.scss';

export type AppSpinnerProps = {
  className?: string;
  style?: React.CSSProperties;
  theme?: 'light' | 'dark';
  size?: 'small' | 'medium' | 'large' | 'gigant';
};

const _AppSpinner: React.FC<AppSpinnerProps> = (props) => {
  const { className, style, theme = 'light', size = 'medium' } = props;
  return (
    <div
      className={cn('app-spinner', className, {
        'app-spinner--dark': theme === 'dark',
        'app-spinner--small': size === 'small',
        'app-spinner--medium': size === 'medium',
        'app-spinner--large': size === 'large',
        'app-spinner--gigant': size === 'gigant',
      })}
      style={style}
    />
  );
};
export const AppSpinner = React.memo(_AppSpinner);
