import { combine } from 'effector';
import { autoGenFilesService } from './autoGenFiles';
import { AutoGenFilesWidgetState } from './types';

export const $autoGenFilesWidgetState = combine(
  {
    autoRunState: autoGenFilesService.$execState,
    autoRunHistory: autoGenFilesService.$history,
    autoRunQueue: autoGenFilesService.$queue,
    autoRunExecItem: autoGenFilesService.$execItem,
  },
  ({ autoRunState, autoRunHistory, autoRunQueue, autoRunExecItem }) => {
    if (autoRunState === 'idle') {
      return {
        status: 'idle',
      } as AutoGenFilesWidgetState;
    } else if (autoRunState === 'inProcess') {
      return {
        status: 'inProcess',
        doneCount: autoRunHistory.length,
        allCount: autoRunHistory.length + autoRunQueue.length + (autoRunExecItem ? 1 : 0),
      } as AutoGenFilesWidgetState;
    } else {
      return {
        status: 'done',
      } as AutoGenFilesWidgetState;
    }
  },
);
