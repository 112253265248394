import { CadObjectResponse } from '@/api/CadObjects/types';
import { RecordId } from '@/api/common/types';
import { BatchFileDownloadWizardModalProps } from '@/components/modals/cadObjects/BatchFileDownloadWizardModal';
import { CreateCadObjectModalProps } from '@/components/modals/cadObjects/CreateCadObjectModal';
import { EditPdCollectionInCadModalProps } from '@/components/modals/cadObjects/EditPdCollectionInCadModal/index';
import { FieldFillingWizardModalProps } from '@/components/modals/cadObjects/FieldFillingGenTechPlanModal';
import { ImportCadObjectsFromXlsxModalProps } from '@/components/modals/cadObjects/ImportCadObjectsFromXlsxModal';
import { ImportOsModalProps } from '@/components/modals/cadObjects/ImportOsModal';
import { ImportRastrModalProps } from '@/components/modals/cadObjects/ImportRastrModal';
import { MonitoringGenModalProps } from '@/components/modals/cadObjects/MonitoringGenWizardModal';
import { EditContractColumnsModalProps } from '@/components/modals/contracts/EditContractColumnsModal';
import { EditContractModalProps } from '@/components/modals/contracts/EditContractModal';
import { ServerConfigTableState } from '@/components/ServerConfigTable/types';
import { autoGenFilesModalService } from '@/globalStorage/autoGenFiles/modal';
import { openAutoRunWithInit } from '@/globalStorage/autoRun/modal';
import { autoSignModalService } from '@/globalStorage/autoSign/modal';
import { $currentUserInfo } from '@/globalStorage/user';
import { MultipleActionModalProps } from '@uspect-team/ant-ui-kit/dist/Tables/MultipleActionModal';
import { combine, createDomain, forward, restore, sample } from 'effector';
import { CadObjectStatus } from './../../../api/CadObjects/types';
import { createVarStore } from './../../../globalStorage/utils/createVarStore';
import { CadObjectZipInfo } from './types';

const domain = createDomain('mainTablePageDomain');

export const { $store: $contractId, setStore: setContractId } = createVarStore<RecordId | null>(
  null,
);

export const $isCanEditContract = combine(
  { contractId: $contractId, userInfo: $currentUserInfo },
  ({ contractId, userInfo }) => {
    if (!userInfo || !contractId) {
      return false;
    }
    if (userInfo.permissions.role === 'guest') {
      return false;
    }
    if (userInfo.permissions.role === 'superadmin') {
      return true;
    }
    if (
      userInfo.permissions.role === 'user' &&
      userInfo.permissions.contracts.includes(contractId)
    ) {
      return true;
    }

    return false;
  },
);

/// SELECTED ITEMS

export const setSelectedCadObjects = domain.createEvent<CadObjectResponse[]>();
export const clearSelectedCadObjects = domain.createEvent();

export const $selectedCadObjects = domain.createStore<CadObjectZipInfo[]>([]);
export const $selectedCadObjectsNumbers = $selectedCadObjects.map((selectedCadObjects) =>
  selectedCadObjects.map((x) => x.num),
);

$selectedCadObjects
  .on(setSelectedCadObjects, (_, payload) =>
    payload.map((x) => ({ _id: x._id, num: x.num, name: x.name })),
  )
  .reset(clearSelectedCadObjects);

/// ========================================================

export const setTableState = domain.createEvent<ServerConfigTableState>('setTableState');
export const resetTableState = domain.createEvent('resetTableState');
export const resetTableFilters = domain.createEvent();
export const resetTableSort = domain.createEvent();
export const resetTablePagination = domain.createEvent();
export const resetTableFiltersAndSort = domain.createEvent();
forward({ from: resetTableFiltersAndSort, to: [resetTableFilters, resetTableSort] });

export const setTableFilterStatus = domain.createEvent<CadObjectStatus>();

export const $tableState = domain
  .createStore<ServerConfigTableState>({
    page: 1,
    limit: 10,
  })
  .on(setTableState, (_, payload) => payload)
  .on(setTableFilterStatus, (state, payload) => ({
    ...state,
    filter: { status: { filterAs: 'options', filterType: 'in', payload: [payload] } },
  }))
  .on(resetTableFilters, ({ filter: _, ...stateWithoutFilters }) => stateWithoutFilters)
  .on(resetTableSort, ({ sort: _, ...stateWithoutSort }) => stateWithoutSort)
  .on(resetTablePagination, () => {
    return { page: 1, limit: 10 };
  })
  .reset(resetTableState);

export const $isTableHasFilters = $tableState.map(
  (state) => !!state.filter && Object.keys(state.filter).length > 0,
);
export const $isTableHasSort = $tableState.map(
  (state) => !!state.sort && Object.keys(state.sort).length > 0,
);
export const $isTableHasFiltersOrSort = combine(
  $isTableHasFilters,
  $isTableHasSort,
  (a, b) => a || b,
);

export const setSelectedDisplayModeId = domain.createEvent<RecordId | null>();

export const $selectedDisplayModeId = restore(setSelectedDisplayModeId, null);

const closedModalState = {
  visible: false as const,
};

// CREATE CAD OBJECT MODAL

export const openCreateCadObjectModalWithContext = domain.createEvent();
export const openCreateCadObjectModal = domain.createEvent<{ contractId: RecordId }>();
export const closeCreateCadObjectModal = domain.createEvent();

export const $createCadObjectModalState = domain
  .createStore<CreateCadObjectModalProps>(closedModalState)
  .on(openCreateCadObjectModal, (_, { contractId }) => ({
    visible: true,
    contractId,
    onClose: () => closeCreateCadObjectModal(),
  }))
  .reset(closeCreateCadObjectModal);

sample({
  clock: openCreateCadObjectModalWithContext,
  source: $contractId,
  filter: (contractId) => contractId !== null,
  fn: (contractId) => ({ contractId: contractId as RecordId }),
  target: openCreateCadObjectModal,
});

// ============================================

export const openEditContractModalWithContext = domain.createEvent();
export const openEditContractModal = domain.createEvent<{ contractId: RecordId }>();
export const closeEditContractModal = domain.createEvent();

export const $editContractModalState = domain
  .createStore<EditContractModalProps>(closedModalState)
  .on(openEditContractModal, (_, { contractId }) => ({
    visible: true,
    contractId,
    onCancel: () => closeEditContractModal(),
  }))
  .reset(closeEditContractModal);

sample({
  clock: openEditContractModalWithContext,
  source: $contractId,
  filter: (contractId) => contractId !== null,
  fn: (contractId) => ({ contractId: contractId as RecordId }),
  target: openEditContractModal,
});

// ================================================================

export const openEditContractColumnsModalWithContext = domain.createEvent();
export const openEditContractColumnsModal = domain.createEvent<{ contractId: RecordId }>();
export const closeEditContractColumnsModal = domain.createEvent();

export const $editContractColumnsModalState = domain
  .createStore<EditContractColumnsModalProps>(closedModalState)
  .on(openEditContractColumnsModal, (_, { contractId }) => ({
    visible: true,
    contractId,
    onClose: () => closeEditContractColumnsModal(),
  }))
  .reset(closeEditContractColumnsModal);

sample({
  clock: openEditContractColumnsModalWithContext,
  source: $contractId,
  filter: (contractId) => contractId !== null,
  fn: (contractId) => ({ contractId: contractId as RecordId }),
  target: openEditContractColumnsModal,
});

// ===========================================

export const openImportCadObjectsFromXlsxModalWithContext = domain.createEvent();
export const openImportCadObjectsFromXlsxModal = domain.createEvent<{ contractId: RecordId }>();
export const closeImportCadObjectsFromXlsxModal = domain.createEvent();

export const $importCadObjectsFromXlsxModalState = domain
  .createStore<ImportCadObjectsFromXlsxModalProps>(closedModalState)
  .on(openImportCadObjectsFromXlsxModal, (_, { contractId }) => ({
    visible: true,
    contractId,
    onClose: () => closeImportCadObjectsFromXlsxModal(),
  }))
  .reset(closeImportCadObjectsFromXlsxModal);

sample({
  clock: openImportCadObjectsFromXlsxModalWithContext,
  source: $contractId,
  filter: (contractId) => contractId !== null,
  fn: (contractId) => ({ contractId: contractId as RecordId }),
  target: openImportCadObjectsFromXlsxModal,
});

/// ================================================

export const openImportOsModalWithContext = domain.createEvent();
export const openImportOsModal = domain.createEvent<{ contractId: RecordId }>();
export const closeImportOsModal = domain.createEvent();

export const $importOsModalState = domain
  .createStore<ImportOsModalProps>(closedModalState)
  .on(openImportOsModal, (_, { contractId }) => ({
    visible: true,
    contractId,
    onClose: () => closeImportOsModal(),
  }))
  .reset(closeImportOsModal);

sample({
  clock: openImportOsModalWithContext,
  source: $contractId,
  filter: (contractId) => contractId !== null,
  fn: (contractId) => ({ contractId: contractId as RecordId }),
  target: openImportOsModal,
});

// ==================================

export const openImportRastrModalWithContext = domain.createEvent();
export const openImportRastrModal = domain.createEvent<{ contractId: RecordId }>();
export const closeImportRastrModal = domain.createEvent();

export const $importRastrModalState = domain
  .createStore<ImportRastrModalProps>(closedModalState)
  .on(openImportRastrModal, (_, { contractId }) => ({
    visible: true,
    contractId,
    onClose: () => closeImportRastrModal(),
  }))
  .reset(closeImportRastrModal);

sample({
  clock: openImportRastrModalWithContext,
  source: $contractId,
  filter: (contractId) => contractId !== null,
  fn: (contractId) => ({ contractId: contractId as RecordId }),
  target: openImportRastrModal,
});

/// FIELD FILLING WIZARD MODAL

export const openFieldFillingWizardModalWithContext = domain.createEvent();

export const openFieldFillingWizardModal = domain.createEvent<{
  contractId: RecordId;
  initialCadObjectNumbers?: number[];
}>();
export const closeFieldFillingWizardModal = domain.createEvent();

export const $fieldFillingWizardModalState =
  domain.createStore<FieldFillingWizardModalProps>(closedModalState);

$fieldFillingWizardModalState
  .on(openFieldFillingWizardModal, (_, { contractId, initialCadObjectNumbers }) => ({
    visible: true,
    contractId,
    initialCadObjectNumbers,
    onClose: () => closeFieldFillingWizardModal(),
  }))
  .reset(closeFieldFillingWizardModal);

sample({
  clock: openFieldFillingWizardModalWithContext,
  source: { $contractId, $selectedCadObjects },
  filter: ({ $contractId }) => $contractId !== null,
  fn: ({ $contractId, $selectedCadObjects }) => ({
    contractId: $contractId as RecordId,
    initialCadObjectNumbers: $selectedCadObjects.map((x) => x.num),
  }),
  target: openFieldFillingWizardModal,
});

///==============================================

/// AUTO RUN MODAL

export const openAutoRunWizardModalForCadObject = domain.createEvent<{ cadObjectNumber: number }>();
export const openAutoRunWizardModalWithSelectedCadObjects = domain.createEvent();

// export const openAutoRunWizardModal = domain.createEvent<{
//   contractId: RecordId;
//   initialCadObjectNumbers?: number[];
// }>();
// export const closeAutoRunWizardModal = domain.createEvent();

// export const $autoRunWizardModalState = domain
//   .createStore<AutoRunWizardModalProps>(closedModalState)
//   .on(openAutoRunWizardModal, (_, { contractId, initialCadObjectNumbers }) => ({
//     visible: true,
//     contractId,
//     initialCadObjectNumbers,
//     onClose: () => closeAutoRunWizardModal(),
//   }))
//   .reset(closeAutoRunWizardModal);

sample({
  clock: openAutoRunWizardModalWithSelectedCadObjects,
  source: { $contractId, $selectedCadObjects },
  filter: ({ $contractId }) => $contractId !== null,
  fn: ({ $contractId, $selectedCadObjects }) => ({
    initialContractId: $contractId as RecordId,
    initialCadObjectNumbers: $selectedCadObjects.map((x) => x.num),
  }),
  target: openAutoRunWithInit,
});

sample({
  clock: openAutoRunWizardModalForCadObject,
  source: $contractId,
  filter: (contractId) => contractId !== null,
  fn: (contractId, { cadObjectNumber }) => ({
    initialContractId: contractId as RecordId,
    initialCadObjectNumbers: [cadObjectNumber],
  }),
  target: openAutoRunWithInit,
});

/// ========================

/// AUTO SIGN MODAL

export const openAutoSignWizardModalForCadObject = domain.createEvent<{
  cadObjectNumber: number;
}>();
export const openAutoSignWizardModalWithSelectedCadObjects = domain.createEvent();

sample({
  clock: openAutoSignWizardModalWithSelectedCadObjects,
  source: { $contractId, $selectedCadObjects },
  filter: ({ $contractId }) => $contractId !== null,
  fn: ({ $contractId, $selectedCadObjects }) => ({
    initialContractId: $contractId as RecordId,
    initialCadObjectNumbers: $selectedCadObjects.map((x) => x.num),
  }),
  target: autoSignModalService.openAutoSignModalWithInit,
});

sample({
  clock: openAutoSignWizardModalForCadObject,
  source: $contractId,
  filter: ($contractId) => $contractId !== null,
  fn: (contractId, { cadObjectNumber }) => ({
    initialContractId: contractId as RecordId,
    initialCadObjectNumbers: [cadObjectNumber],
  }),
  target: autoSignModalService.openAutoSignModalWithInit,
});

/// ========================================================

export const openBatchFileDownloadWizardModal = domain.createEvent<{
  initialCadObjectNumbers?: number[];
  contractId?: string;
}>();
export const closeBatchFileDownloadWizardModal = domain.createEvent();

export const $batchFileDownloadWizardModalState = domain
  .createStore<BatchFileDownloadWizardModalProps>(closedModalState)
  .on(openBatchFileDownloadWizardModal, (_, { initialCadObjectNumbers, contractId }) => ({
    visible: true,
    initialCadObjectNumbers,
    contractId,
    onClose: () => closeBatchFileDownloadWizardModal(),
  }))
  .reset(closeBatchFileDownloadWizardModal);

// GEN FILES MODAL

export const openGenKptAndRastersModalForCadObject = domain.createEvent<{
  cadObjectNumber: number;
}>();

export const openGenKptModalForCadObject = domain.createEvent<{ cadObjectNumber: number }>();

export const openGenKptAndRastersWithSelectedCadObjects = domain.createEvent();

export const openGenKptWithSelectedCadObjects = domain.createEvent();

export const openGenFileWizardWithSelectedCadObjects = domain.createEvent();

export const closeGenFilesWizardModal = domain.createEvent();

export const openGenFileWizardModalFromCell = domain.createEvent<{
  initialCadObjectNumbers?: number[];
  initialFileTypes?: ('kpt' | 'rasters' | 'mif')[];
}>();

sample({
  source: $contractId,
  clock: openGenFileWizardModalFromCell,
  fn: (contractId, payload) => ({
    initialContractId: contractId as RecordId,
    initialCadObjectNumbers: payload.initialCadObjectNumbers ?? [],
    initialFileTypes: payload.initialFileTypes ?? ['kpt', 'rasters'],
  }),
  filter: (s) => s !== null,
  target: autoGenFilesModalService.openAutoGenFilesModalWithInit,
});

sample({
  clock: openGenFileWizardWithSelectedCadObjects,
  source: { $contractId, $selectedCadObjects },
  filter: ({ $contractId }) => $contractId !== null,
  fn: ({ $contractId, $selectedCadObjects }) => ({
    initialContractId: $contractId as RecordId,
    initialCadObjectNumbers: $selectedCadObjects.map((x) => x.num),
    initialFileTypes: ['kpt' as const, 'rasters' as const],
  }),
  target: autoGenFilesModalService.openAutoGenFilesModalWithInit,
});

sample({
  clock: openGenKptAndRastersWithSelectedCadObjects,
  source: { contractId: $contractId, selectedCadObjectsNumbers: $selectedCadObjectsNumbers },
  filter: ({ contractId }) => contractId !== null,

  fn: ({ contractId, selectedCadObjectsNumbers }) => ({
    initialContractId: contractId as RecordId,
    initialCadObjectNumbers: selectedCadObjectsNumbers,
    initialFileTypes: ['kpt' as const, 'rasters' as const],
  }),
  target: autoGenFilesModalService.openAutoGenFilesModalWithInit,
});

sample({
  clock: openGenKptWithSelectedCadObjects,
  source: { contractId: $contractId, selectedCadObjectsNumbers: $selectedCadObjectsNumbers },
  filter: ({ contractId }) => contractId !== null,

  fn: ({ contractId, selectedCadObjectsNumbers }) => ({
    initialContractId: contractId as RecordId,
    initialCadObjectNumbers: selectedCadObjectsNumbers,
    initialFileTypes: ['kpt' as const],
  }),
  target: autoGenFilesModalService.openAutoGenFilesModalWithInit,
});

sample({
  clock: openGenKptAndRastersModalForCadObject,
  source: { $contractId },
  filter: ({ $contractId }) => $contractId !== null,
  fn: ({ $contractId }, { cadObjectNumber }) => ({
    initialContractId: $contractId as RecordId,
    initialCadObjectNumbers: [cadObjectNumber],
    initialFileTypes: ['kpt' as const, 'rasters' as const],
  }),
  target: autoGenFilesModalService.openAutoGenFilesModalWithInit,
});

sample({
  clock: openGenKptModalForCadObject,
  source: { $contractId },
  filter: ({ $contractId }) => $contractId !== null,
  fn: ({ $contractId }, { cadObjectNumber }) => ({
    initialContractId: $contractId as RecordId,
    initialCadObjectNumbers: [cadObjectNumber],
    initialFileTypes: ['kpt' as const],
  }),
  target: autoGenFilesModalService.openAutoGenFilesModalWithInit,
});

/// =======================

/// EDIT PD COLLECTION MODAL

export const openPdCollectionModalFromCell = domain.createEvent<{
  cadObjectId: RecordId;
  dataIndex: string;
  columnName: string;
}>();

export const openPdCollectionModal = domain.createEvent<{
  contractId: RecordId;
  cadObjectId: RecordId;
  title: string;
  dataIndex: string;
}>();
export const closePdCollectionModal = domain.createEvent();

export const $editPdCollectionModalState =
  domain.createStore<EditPdCollectionInCadModalProps>(closedModalState);

$editPdCollectionModalState
  .on(openPdCollectionModal, (_, payload) => ({
    visible: true,
    ...payload,
    onClose: () => closePdCollectionModal(),
  }))
  .reset(closePdCollectionModal);

sample({
  clock: openPdCollectionModalFromCell,
  source: { contractId: $contractId },
  filter: ({ contractId }) => contractId !== null,
  fn: ({ contractId }, { cadObjectId, dataIndex, columnName }) => ({
    contractId: contractId as RecordId,
    cadObjectId,
    dataIndex,
    title: `${columnName}: Редактирование ПД`,
  }),
  target: openPdCollectionModal,
});

/// ================================

/// MULTIPLE ACTION MODAL

export const openMultipleActionModal = domain.createEvent<{
  title: string;
  items: CadObjectZipInfo[];
  mode: 'ordered' | 'parallel';
  asyncAction: (record: CadObjectZipInfo) => Promise<any>;
  onClose?: () => void;
}>();
export const closeMultipleActionModalState = domain.createEvent();

export const $multipleActionModalState =
  domain.createStore<MultipleActionModalProps<CadObjectZipInfo>>(closedModalState);

$multipleActionModalState
  .on(openMultipleActionModal, (_, payload) => ({
    visible: true,
    getName: (record) => `${record.name} (Номер договора: ${record.num})`,
    getRecordKey: (record) => record._id,
    ...payload,
    onClose: () => {
      closeMultipleActionModalState();
      payload.onClose && payload.onClose();
    },
  }))
  .reset(closeMultipleActionModalState);

///GEN MONITORING WIZARD MODAL

export const openMonitoringGenWizardModalWithContext = domain.createEvent();
export const openMonitoringGenWizardModal = domain.createEvent<{
  contractId: RecordId;
  initialCadObjectNumbers?: number[];
}>();

export const closeMonitoringGenWizardModal = domain.createEvent();

export const $monitoringGenWizardModalState =
  domain.createStore<MonitoringGenModalProps>(closedModalState);

$monitoringGenWizardModalState
  .on(openMonitoringGenWizardModal, (_, payload) => ({
    visible: true,
    contractId: payload.contractId,
    initialCadObjectNumbers: payload.initialCadObjectNumbers,

    onClose: () => closeMonitoringGenWizardModal(),
  }))
  .reset(closeMonitoringGenWizardModal);

sample({
  clock: openMonitoringGenWizardModalWithContext,
  source: { $contractId, $selectedCadObjectsNumbers },
  filter: ({ $contractId }) => $contractId !== null,
  fn: ({ $contractId, $selectedCadObjectsNumbers }) => ({
    contractId: $contractId as RecordId,
    initialCadObjectNumbers: $selectedCadObjectsNumbers,
  }),
  target: openMonitoringGenWizardModal,
});

//GEN TECH PLAN

export const openGenTechPlanModalWithContext = domain.createEvent();
export const openGenTechPlanWizardModal = domain.createEvent<{
  contractId: RecordId;
  initialCadObjectNumbers?: number[];
}>();

export const closeGenTechPlanWizardModal = domain.createEvent();

export const $genTechPlanModalState = domain.createStore<MonitoringGenModalProps>(closedModalState);

$genTechPlanModalState
  .on(openGenTechPlanWizardModal, (_, payload) => ({
    visible: true,
    contractId: payload.contractId,
    initialCadObjectNumbers: payload.initialCadObjectNumbers,

    onClose: () => closeGenTechPlanWizardModal(),
  }))
  .reset(closeGenTechPlanWizardModal);

sample({
  clock: openGenTechPlanModalWithContext,
  source: { $contractId, $selectedCadObjectsNumbers },
  filter: ({ $contractId }) => $contractId !== null,
  fn: ({ $contractId, $selectedCadObjectsNumbers }) => ({
    contractId: $contractId as RecordId,
    initialCadObjectNumbers: $selectedCadObjectsNumbers,
  }),
  target: openGenTechPlanWizardModal,
});
