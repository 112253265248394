import React from 'react';
import cn from 'classnames';
import './styles.scss';
import { HintBlockType } from './types';
import { HINT_BLOCK_TYPE_ICON_DICT } from './contants';

export type HintBlockProps = {
  className?: string;
  style?: React.CSSProperties;
  type: HintBlockType;
  title: React.ReactNode;
  description: React.ReactNode;
  theme?: 'light' | 'dark';
};

const _HintBlock: React.FC<HintBlockProps> = (props) => {
  const { className, style, type, title, description, theme = 'light' } = props;

  return (
    <div
      className={cn(className, 'hint-block', { 'hint-block--dark': theme === 'dark' })}
      style={style}
    >
      {HINT_BLOCK_TYPE_ICON_DICT[type]}
      <div className='hint-block__title'>{title}</div>
      <div className='hint-block__description'>{description}</div>
    </div>
  );
};
export const HintBlock = React.memo(_HintBlock);
