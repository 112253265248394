import React, { useEffect } from 'react';
import { StepContent } from '@/components/StepContent';
import './styles.scss';
import { Button } from 'antd';
import { useCadObjectArchivesM } from '@/hooks/cadastralObjects';

export type TBatchFileDownloadResultStepProps = {
  nums: number[];
  contractId: string;
  types: string[];
  setDisabled: React.Dispatch<React.SetStateAction<boolean>>;
};

const _BatchFileDownloadResultStep: React.FC<TBatchFileDownloadResultStepProps> = (props) => {
  const { nums, contractId, types, setDisabled } = props;
  const { data, mutate, isLoading } = useCadObjectArchivesM();

  useEffect(() => {
    if (nums && contractId && types) {
      mutate({ nums, contractId, keys: types });
    }
  }, [contractId, mutate, nums, types]);

  useEffect(() => {
    if (isLoading) {
      setDisabled(true);
    } else {
      setDisabled(false);
    }
  }, [isLoading, setDisabled]);

  return (
    <StepContent className={'batch-file-download-result-step'}>
      <div className='batch-file-download-result-step__info'>
        <div className={'batch-file-download-result-step__main'}>
          {`Формирование архива ${isLoading ? 'почти' : ''} завершено.`}
        </div>
        <div className='batch-file-download-result-step__desc'>
          Вы можете скачать сформированный пакет перейдя по ссылке
        </div>
      </div>
      <Button loading={isLoading} href={data?.data.fileUri}>
        Скачать
      </Button>
    </StepContent>
  );
};
export const BatchFileDownloadResultStep = React.memo(_BatchFileDownloadResultStep);
