import React from 'react';
import cn from 'classnames';

import './styles.scss';
import { Button } from 'antd';

export type FilterDropdownActionsProps = {
  className?: string;
  style?: React.CSSProperties;
  hasFilters: boolean;
  canChangeFilter: boolean;
  onSubmitChanges: () => void;
  onResetFilter: () => void;
};

const _FilterDropdownActions: React.FC<FilterDropdownActionsProps> = (props) => {
  const { className, style, hasFilters, canChangeFilter, onSubmitChanges, onResetFilter } = props;
  return (
    <div className={cn('filter-dropdown-actions', className)} style={style}>
      <Button
        className='filter-dropdown-actions_set-filter-btn'
        type='primary'
        disabled={!canChangeFilter}
        onClick={onSubmitChanges}
      >
        Фильтровать
      </Button>
      <Button
        className='filter-dropdown-actions_reset-filter'
        disabled={!hasFilters}
        onClick={onResetFilter}
      >
        Очистить фильтр
      </Button>
    </div>
  );
};
export const FilterDropdownActions = React.memo(_FilterDropdownActions);
