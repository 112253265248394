import React from 'react';
import cn from 'classnames';
import './styles.scss';

type Props = {
  className?: string;
  style?: any;
};
export const TabContent: React.FC<Props> = React.memo((props) => {
  const { className, style, children } = props;

  return (
    <div className={cn('tab-content', className)} style={style}>
      {children}
    </div>
  );
});
