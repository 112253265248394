import { AddressFilterDropdown } from '@/components/filterDropdowns/AddressFilterDropdown';
import { AddressFilterValue } from '@/components/filterDropdowns/AddressFilterDropdown/types';
import { errorMessages } from '@/utils/errorMessages';
import { EditableAddress } from '@uspect-team/ant-ui-kit';
import { ControlledTableChildType } from '@uspect-team/ant-ui-kit/dist/Tables/ControlledTable/types';
import React from 'react';
import * as yup from 'yup';
import {
  AddressServerChildColumnAddition,
  BaseServerChildColumnType,
  ServerConfigTableContext,
  TableRecordType,
} from '../../types';
import { dataIndexToStringKey } from '../dataIndexToStringKey';

export const getAddressColumnAddition = (
  strSerCol: BaseServerChildColumnType & AddressServerChildColumnAddition,
  context: ServerConfigTableContext,
  editable: boolean,
): Partial<ControlledTableChildType<TableRecordType>> => {
  let validationSchema = yup.object().nullable().default(null);
  if (strSerCol.isRequired) {
    validationSchema = validationSchema.required(errorMessages.required);
  }

  const onEdit = context?.onEdit;

  const { dataIndex, isFilterable, width } = strSerCol;
  const dataIndexKey = dataIndexToStringKey(dataIndex);

  return {
    align: 'left',
    width: width ?? 200,
    filterDropdown: isFilterable
      ? ({ currentFilterState, setFilterValue, removeFilter }) => {
          const filter = currentFilterState as AddressFilterValue;
          return (
            <AddressFilterDropdown
              filter={filter}
              onChangeFilter={setFilterValue}
              onResetFilter={removeFilter}
              hasEqual
              hasExist
              hasIncludes
            />
          );
        }
      : undefined,

    render: (value, record) => {
      if (record.status === 'excluded') {
        return value?.value;
      }

      if (!editable) {
        return value?.value;
      }

      return (
        <EditableAddress
          block
          value={value}
          validationSchema={validationSchema}
          onChange={
            onEdit
              ? (value) => onEdit({ patch: { [dataIndexKey]: value }, oldRecord: record })
              : undefined
          }
          noDropdown
          className={'server-config-table__address-cell'}
        />
      );
    },
  };
};
