import { Form, message, Modal } from 'antd';
import React, { useCallback } from 'react';
import { CreateDisplayModeRequest } from '@/api/Dictionary/DisplayMods/types';
import { useCreateDisplayModeM } from '@/hooks/directory/displayMods';
import { DisplayModeFields } from '@/components/modals/displayMods/common/DisplayModeFields';

export type CreateDisplayModeModalProps =
  | {
      visible: false;
    }
  | ({
      visible: true;
    } & VisibleProps);

type VisibleProps = {
  onClose: () => void;
};

const _CreateDisplayModeModal: React.FC<CreateDisplayModeModalProps> = (props) => {
  const { visible } = props;

  if (!visible) {
    return null;
  }
  const { visible: _, ...visibleProps } = props;
  return <VisibleModal {...visibleProps} />;
};
export const CreateDisplayModeModal = React.memo(_CreateDisplayModeModal);

const VisibleModal: React.FC<VisibleProps> = React.memo((props) => {
  const { onClose } = props;
  const [form] = Form.useForm<CreateDisplayModeRequest>();
  const { mutateAsync: createDisplayModeM, isLoading: isLoadingCreateDisplayMode } =
    useCreateDisplayModeM();

  const onFinishFormHandler = useCallback(
    async (values: CreateDisplayModeRequest) => {
      try {
        await createDisplayModeM(values);
        onClose();
        message.success('Режим отображения успешно создан');
      } catch (e) {
        message.error('Не удалось создать режим отображения');
      }
    },
    [createDisplayModeM, onClose],
  );
  return (
    <Modal
      visible={true}
      title={'Создание режима отображения'}
      okText={'Создать'}
      onOk={form.submit}
      okButtonProps={{ loading: isLoadingCreateDisplayMode }}
      cancelText={'Отмена'}
      onCancel={onClose}
    >
      <Form
        layout={'vertical'}
        form={form}
        onFinish={onFinishFormHandler}
        initialValues={{ listColumns: [] }}
      >
        <DisplayModeFields />
      </Form>
    </Modal>
  );
});
