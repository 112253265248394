import React, { useState } from 'react';
import {
  CreateOrganizationModal,
  CreateOrganizationModalProps,
} from '@/components/modals/organizations/CreateOrganizationModal';
import {
  EditOrganizationModal,
  EditOrganizationModalProps,
} from '@/components/modals/organizations/EditOrganizationModal';
import { getOrganizationsPageConfig } from './config';
import { OrganizationRecordResponse } from '@/api/Dictionary/Organizations/types';
import { DictCrudPage } from '@/components/DictCrudPage';

const _OrganizationsPage: React.FC = () => {
  const [createOrganizationModalState, setCreateOrganizationModalState] =
    useState<CreateOrganizationModalProps>({ visible: false });

  const [editOrganizationModalState, setEditOrganizationModalState] =
    useState<EditOrganizationModalProps>({ visible: false });

  const onCreateButtonClickHandler = React.useCallback(() => {
    setCreateOrganizationModalState({
      visible: true,
      onClose: () => setCreateOrganizationModalState({ visible: false }),
    });
  }, []);

  const onEditButtonClickHandler = React.useCallback((record: OrganizationRecordResponse) => {
    setEditOrganizationModalState({
      visible: true,
      onClose: () => setEditOrganizationModalState({ visible: false }),
      organizationId: record._id,
    });
  }, []);

  const organizationPageConfig = React.useMemo(() => {
    return getOrganizationsPageConfig({
      onCreateButtonClick: onCreateButtonClickHandler,
      onEditButtonClick: onEditButtonClickHandler,
    });
  }, [onCreateButtonClickHandler, onEditButtonClickHandler]);

  return (
    <>
      <DictCrudPage {...organizationPageConfig} />
      <CreateOrganizationModal {...createOrganizationModalState} />
      <EditOrganizationModal {...editOrganizationModalState} />
    </>
  );
};

export const OrganizationsPage = React.memo(_OrganizationsPage);
