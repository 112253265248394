import { Form, message, Modal } from 'antd';
import React, { useCallback, useEffect, useState } from 'react';
import { RecordId } from '@/api/common/types';
import { PatchDisplayModeRequest } from '@/api/Dictionary/DisplayMods/types';
import { useGetDisplayModeByIdQ, usePatchDisplayModeM } from '@/hooks/directory/displayMods';
import { DisplayModeFields } from '@/components/modals/displayMods/common/DisplayModeFields';

export type EditDisplayModeModalProps =
  | {
      visible: false;
    }
  | ({
      visible: true;
    } & VisibleProps);

type VisibleProps = {
  displayModeId: RecordId;
  onClose: () => void;
};

export const EditDisplayModeModal: React.FC<EditDisplayModeModalProps> = React.memo((props) => {
  const { visible } = props;

  if (!visible) {
    return null;
  }
  const { visible: _, ...visibleProps } = props;
  return <VisibleModal {...visibleProps} />;
});

const VisibleModal: React.FC<VisibleProps> = React.memo((props) => {
  const { onClose, displayModeId } = props;
  const [form] = Form.useForm<PatchDisplayModeRequest['patch']>();
  const { mutateAsync: patchDisplayModeM, isLoading: isLoadingPatchDisplayMode } =
    usePatchDisplayModeM();
  const { data: currentDisplayMode, isLoading } = useGetDisplayModeByIdQ(displayModeId);
  useEffect(() => {
    if (currentDisplayMode) {
      form.setFieldsValue({
        ...currentDisplayMode,
        listColumns: currentDisplayMode.listColumns.map((col) => col._id),
      });
    }
  }, [currentDisplayMode, form]);
  const [changes, setChanges] = useState<PatchDisplayModeRequest['patch'] | null>(null);

  const onFinishFormHandler = useCallback(async () => {
    if (changes) {
      try {
        const createReq = { id: displayModeId, patch: changes } as PatchDisplayModeRequest;
        await patchDisplayModeM(createReq);
        onClose();
        message.success('Режим отображения успешно изменен');
      } catch (e) {
        message.error('Не удалось изменить режим отображения');
      }
    }
  }, [patchDisplayModeM, onClose, displayModeId, changes]);
  return (
    <Modal
      visible={true}
      title={
        isLoading
          ? 'Загрузка...'
          : `${currentDisplayMode?.name || ''}: Изменение режима отображения`
      }
      okText={'Сохранить изменения'}
      onOk={form.submit}
      okButtonProps={{ loading: isLoadingPatchDisplayMode }}
      cancelText={'Отмена'}
      onCancel={onClose}
    >
      <Form
        layout={'vertical'}
        form={form}
        onFinish={onFinishFormHandler}
        onValuesChange={(changes) => {
          setChanges((prevState) => ({ ...prevState, ...changes }));
        }}
      >
        <DisplayModeFields />
      </Form>
    </Modal>
  );
});
