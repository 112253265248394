import { GuestLayout } from '@/components/layouts/GuestLayout';
import React from 'react';
import { LoginForm } from './components/LoginForm';

export const LoginPage: React.FC = React.memo(() => {
  return (
    <GuestLayout className='login-page'>
      <GuestLayout.ContentWithFooterContainer>
        <GuestLayout.ContentContainer>
          <GuestLayout.ContentHeader>
            <GuestLayout.Title>Введите данные для входа</GuestLayout.Title>
          </GuestLayout.ContentHeader>

          <LoginForm />
        </GuestLayout.ContentContainer>
        <GuestLayout.DefaultFooter />
      </GuestLayout.ContentWithFooterContainer>
    </GuestLayout>
  );
});
