import { RecordId } from '@/api/common/types';
import { combine, createDomain, sample, createApi } from 'effector';
import { autoGenFilesService } from './autoGenFiles';
import { AutoGenFilesModalState, GenFileType } from './types';

const autoGenFilesModalDomain = createDomain();

const startGenFiles = autoGenFilesModalDomain.createEvent();

///IS OPEN MODAL

const $isOpenAutoSignModal = autoGenFilesModalDomain.createStore(false);

const { openAutoGenFilesModal, closeAutoGenFilesModal } = createApi($isOpenAutoSignModal, {
  openAutoGenFilesModal: () => true,
  closeAutoGenFilesModal: () => false,
});

/// INIT NUMBERS

const $initialCadObjectNumbers = autoGenFilesModalDomain.createStore<number[]>([]);
const { setInitialCadObjectNumbers, resetInitialCadObjectNumbers } = createApi(
  $initialCadObjectNumbers,
  {
    setInitialCadObjectNumbers: (_, payload: number[]) => payload,
    resetInitialCadObjectNumbers: () => [],
  },
);

///

// INIT CONTRACT ID

const $initialContractId = autoGenFilesModalDomain.createStore<RecordId | null>(null);
const { setInitialContractId, resetInitialContractId } = createApi($initialContractId, {
  setInitialContractId: (_, payload: RecordId) => payload,
  resetInitialContractId: () => null,
});

/// INIT AUTOGEN FILETYPES
const $initialFileTypes = autoGenFilesModalDomain.createStore<GenFileType[]>(['kpt', 'rasters']);
const { setInitialFileTypes, resetInitialFileTypes } = createApi($initialFileTypes, {
  setInitialFileTypes: (_, payload: GenFileType[]) => payload,
  resetInitialFileTypes: () => [],
});

const $autoGenFilesModalState = combine(
  {
    isOpen: $isOpenAutoSignModal,
    initialCadObjectNumbers: $initialCadObjectNumbers,
    initialContractId: $initialContractId,
    initialFileTypes: $initialFileTypes,
    autoRunState: autoGenFilesService.$execState,
    autoRunContractId: autoGenFilesService.$execContractId,
    autoRunExecItem: autoGenFilesService.$execItem,
    autoRunHistory: autoGenFilesService.$history,
    autoRunQueue: autoGenFilesService.$queue,
  },
  ({
    isOpen,
    initialCadObjectNumbers,
    initialContractId,
    initialFileTypes,
    autoRunState,
    autoRunContractId,
    autoRunExecItem,
    autoRunHistory,
    autoRunQueue,
  }) => {
    if (!isOpen) {
      return { visible: false } as AutoGenFilesModalState;
    } else {
      if (autoRunState === 'idle') {
        return {
          visible: true,
          state: 'idle',
          contractId: initialContractId,
          fileTypes: initialFileTypes,
          cadObjectNumbers: initialCadObjectNumbers,
          isCanStartGenFiles:
            initialContractId !== null &&
            initialCadObjectNumbers.length > 0 &&
            initialFileTypes.length > 0,
        } as AutoGenFilesModalState;
      } else if (autoRunState === 'inProcess') {
        return {
          visible: true,
          state: 'inProcess',
          contractId: autoRunContractId,
          fileTypes: initialFileTypes,
          execItem: autoRunExecItem,
          history: autoRunHistory,
          queue: autoRunQueue,
        } as AutoGenFilesModalState;
      } else if (autoRunState === 'done') {
        return {
          visible: true,
          state: 'done',
          contractId: initialContractId,
          fileTypes: initialFileTypes,
          errorCadObjectNumbers: autoRunHistory
            .filter((x) => x.status === 'error')
            .map((x) => x.item),
          successCadObjectNumbers: autoRunHistory
            .filter((x) => x.status === 'success')
            .map((x) => x.item),
          history: autoRunHistory,
          allObjectsCount: autoRunHistory.length,
          execTimeMilliseconds: autoRunHistory.reduce(
            (acc, history) => acc + history.execTimeMilliseconds,
            0,
          ),
        } as AutoGenFilesModalState;
      } else {
        return {
          visible: true,
          state: 'idle',
          contractId: initialContractId,
          fileTypes: initialFileTypes,
          cadObjectNumbers: initialCadObjectNumbers,
        } as AutoGenFilesModalState;
      }
    }
  },
);

sample({
  clock: startGenFiles,
  source: { $initialContractId, $initialCadObjectNumbers, $initialFileTypes },
  filter: $autoGenFilesModalState.map(
    (x) => x.visible === true && x.state === 'idle' && x.isCanStartGenFiles,
  ),
  fn: ({ $initialContractId, $initialCadObjectNumbers, $initialFileTypes }) => ({
    cadObjectNumbers: $initialCadObjectNumbers,
    contractId: $initialContractId as RecordId,
    fileTypes: $initialFileTypes,
  }),
  target: autoGenFilesService.startAutoGenFiles,
});

/// RESET AUTO RUN MODAL

const resetAutoSignModal = autoGenFilesModalDomain.createEvent();

sample({
  clock: resetAutoSignModal,
  target: [resetInitialCadObjectNumbers, resetInitialContractId, resetInitialFileTypes],
});

const resetGenFilesModalWithInit = autoGenFilesModalDomain.createEvent<{
  initialCadObjectNumbers: number[];
  initialContractId: RecordId;
  initialFileTypes: GenFileType[];
}>();

sample({
  clock: resetGenFilesModalWithInit,
  fn: ({ initialCadObjectNumbers }) => initialCadObjectNumbers,
  target: setInitialCadObjectNumbers,
});

sample({
  clock: resetGenFilesModalWithInit,
  fn: ({ initialContractId }) => initialContractId,
  target: setInitialContractId,
});

sample({
  clock: resetGenFilesModalWithInit,
  fn: ({ initialFileTypes }) => initialFileTypes,
  target: setInitialFileTypes,
});

/// ===================================

const backToFileTypesInit = autoGenFilesModalDomain.createEvent();

sample({
  clock: backToFileTypesInit,
  target: [resetAutoSignModal, autoGenFilesService.resetAutoGenFiles],
});

////

const openAutoGenFilesModalWithInit = autoGenFilesModalDomain.createEvent<{
  initialCadObjectNumbers: number[];
  initialContractId: RecordId;
  initialFileTypes: GenFileType[];
}>();

sample({
  clock: openAutoGenFilesModalWithInit,
  filter: autoGenFilesService.$execState.map((x) => x === 'idle'),
  target: [resetGenFilesModalWithInit, openAutoGenFilesModal],
});

sample({
  clock: openAutoGenFilesModalWithInit,
  filter: autoGenFilesService.$execState.map((x) => x === 'done' || x === 'inProcess'),
  target: openAutoGenFilesModal,
});

///

const autoGenFilesModalService = {
  $autoGenFilesModalState,
  openAutoGenFilesModal,
  openAutoGenFilesModalWithInit,
  closeAutoGenFilesModal,
  setInitialCadObjectNumbers,
  setInitialContractId,
  setInitialFileTypes,
  startGenFiles,
  backToFileTypesInit,
};

export { autoGenFilesModalService };
