import React from 'react';
import { TpRecordResponse } from '@/api/Coordinates/Tp/types';
import { DotsMenu } from '@/components/DotsMenu';
import { DateView } from '@uspect-team/ant-ui-kit';
import { ControlledTableColumns } from '@uspect-team/ant-ui-kit/dist/Tables/ControlledTable/types';
import { ReactElement } from 'react';
import { ContractFilterDropdown } from './ContractFilterDropdown';

const defaultColumns: ControlledTableColumns<TpRecordResponse> = [
  {
    key: 'title',
    dataIndex: 'title',
    title: 'Название',
  },
  {
    key: 'contractTitle',
    dataIndex: ['contract', 'title'],
    title: 'Контракт',
    filterKey: 'contract',
    filterDropdown: ({ currentFilterState, setFilterValue, removeFilter }) => {
      return (
        <ContractFilterDropdown
          filter={currentFilterState}
          onChangeFilter={setFilterValue}
          onResetFilter={removeFilter}
        />
      );
    },
  },
  {
    key: 'coordsLength',
    dataIndex: ['coords', 'length'],
    title: 'Кол-во координат',
  },
  {
    key: 'createdAt',
    dataIndex: 'createdAt',
    title: 'Дата создания',
    render: (value) => {
      return <DateView value={value} />;
    },
  },
  {
    key: 'updatedAt',
    dataIndex: 'updatedAt',
    title: 'Дата изменения',
    render: (value) => {
      return <DateView value={value} />;
    },
  },
];

export const getColumns = (options?: {
  actionMenu?: (record: TpRecordResponse) => ReactElement;
}): ControlledTableColumns<TpRecordResponse> => {
  const res: ControlledTableColumns<TpRecordResponse> = [...defaultColumns];

  const actionMenu = options?.actionMenu;
  if (actionMenu) {
    res.push({
      fixed: 'right',
      key: 'actions',
      dataIndex: 'actions',
      align: 'center',
      render: (_, record) => {
        return <DotsMenu menu={actionMenu(record)} />;
      },
    });
  }

  return res;
};
