import React, { ReactElement } from 'react';
import { Button, ButtonProps, Dropdown } from 'antd';
import { EllipsisOutlined } from '@ant-design/icons/lib';

type Props = {
  className?: string;
  style?: any;
  menu: ReactElement;
} & ButtonProps;

export const DotsMenu: React.FC<Props> = React.memo((props) => {
  const { className, style, menu, ...buttonProps } = props;

  return (
    <Dropdown overlay={menu}>
      <Button className={className} style={style} icon={<EllipsisOutlined />} {...buttonProps} />
    </Dropdown>
  );
});
