import React, { useState } from 'react';
import {
  CreateEmployeeModal,
  CreateEmployeeModalProps,
} from '@/components/modals/employee/CreateEmployeeModal';
import {
  EditEmployeeModal,
  EditEmployeeModalProps,
} from '@/components/modals/employee/EditEmployeeModal';
import { DictCrudPage } from '@/components/DictCrudPage';
import { getEmployeesPageConfig } from './config';
import { EmployeeRecordResponse } from '@/api/Dictionary/Employees/types';

const _EmployeesPage: React.FC = () => {
  const [createEmployeeModalState, setCreateEmployeeModalState] =
    useState<CreateEmployeeModalProps>({ visible: false });

  const [editEmployeeModalState, setEditEmployeeModalState] = useState<EditEmployeeModalProps>({
    visible: false,
  });

  const onCreateButtonClickHandler = React.useCallback(() => {
    setCreateEmployeeModalState({
      visible: true,
      onClose: () => {
        setCreateEmployeeModalState({ visible: false });
      },
    });
  }, []);

  const onEditButtonClickHandler = React.useCallback((record: EmployeeRecordResponse) => {
    setEditEmployeeModalState({
      visible: true,
      onClose: () => {
        setEditEmployeeModalState({ visible: false });
      },
      employeeId: record._id,
    });
  }, []);

  const employeesPageConfig = React.useMemo(() => {
    return getEmployeesPageConfig({
      onCreateButtonClick: onCreateButtonClickHandler,
      onEditButtonClick: onEditButtonClickHandler,
    });
  }, [onCreateButtonClickHandler, onEditButtonClickHandler]);

  return (
    <>
      <DictCrudPage {...employeesPageConfig} />
      <CreateEmployeeModal {...createEmployeeModalState} />
      <EditEmployeeModal {...editEmployeeModalState} />
    </>
  );
};

export const EmployeesPage = React.memo(_EmployeesPage);
