import { StepContent } from '@/components/StepContent';
import { HistoryItem } from '@/globalStorage/utils/type';
import { formatMilliseconds } from '@/utils/dateUtils';
import React from 'react';
import { CopyCadObjNumbersButton } from '../../common/CopyCadObjNumbersButton';
import { ExecResultProp } from '../../common/ExecResultProp';
import { ExecResultPropContainer } from '../../common/ExecResultPropContainer';
import { HistoryCadObjectExecTable } from '../../common/HistoryCadObjectExecTable';

export type AutoSignModalDoneProps = {
  history: HistoryItem<number>[];
  allObjectsCount: number;
  errorCadObjectNumbers: number[];
  successCadObjectNumbers: number[];
  execTimeMilliseconds: number;
};

const _AutoSignModalDone: React.FC<AutoSignModalDoneProps> = ({
  history,
  execTimeMilliseconds,
  allObjectsCount,
  successCadObjectNumbers,
  errorCadObjectNumbers,
}) => {
  return (
    <StepContent>
      <ExecResultPropContainer>
        <ExecResultProp label='Общее время подписания'>
          {formatMilliseconds(execTimeMilliseconds)}
        </ExecResultProp>
        <ExecResultProp label='Всего объектов на подписании'>{allObjectsCount}</ExecResultProp>
        <ExecResultProp label='Успешно подписано объектов'>
          {successCadObjectNumbers.length}
          <CopyCadObjNumbersButton copyNumbers={successCadObjectNumbers} />
        </ExecResultProp>
        <ExecResultProp label='С ошибками подписано объектов'>
          {errorCadObjectNumbers.length}
          <CopyCadObjNumbersButton copyNumbers={errorCadObjectNumbers} />
        </ExecResultProp>
      </ExecResultPropContainer>

      <HistoryCadObjectExecTable history={history} />
    </StepContent>
  );
};
export const AutoSignModalDone = React.memo(_AutoSignModalDone);
