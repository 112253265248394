import React from 'react';
import cn from 'classnames';
import './styles.scss';

type Props = {
  className?: string;
  style?: any;
};

const Table: React.FC<Props> = React.memo((props) => {
  const { className, style, children } = props;

  return (
    <div className={cn('edit-file-collection-base__table', className)} style={style}>
      {children}
    </div>
  );
});

const NewFile: React.FC<Props> = React.memo((props) => {
  const { className, style, children } = props;

  return (
    <div className={cn('edit-file-collection-base__new-file', className)} style={style}>
      {children}
    </div>
  );
});

const Upload: React.FC<Props> = React.memo((props) => {
  const { className, style, children } = props;

  return (
    <div className={cn('edit-file-collection-base__upload', className)} style={style}>
      {children}
    </div>
  );
});

const EditFileCollectionBase: React.FC<Props> & {
  Upload: typeof Upload;
  Table: typeof Table;
  NewFile: typeof NewFile;
} = (props) => {
  const { className, style, children } = props;

  return (
    <div className={cn('edit-file-collection-base', className)} style={style}>
      {children}
    </div>
  );
};

EditFileCollectionBase.Upload = Upload;
EditFileCollectionBase.Table = Table;
EditFileCollectionBase.NewFile = NewFile;

export { EditFileCollectionBase };
