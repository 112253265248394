import {
  BaseServerChildColumnType,
  DocumentServerChildColumnAddition,
  ServerConfigTableContext,
  TableRecordType,
} from '../../types';
import React from 'react';
import { ControlledTableChildType } from '@uspect-team/ant-ui-kit/dist/Tables/ControlledTable/types';
import { EditableDocumentWithUploadAlreadyExist } from '../../../EditableDocumentWithUploadAlreadyExist';
import { dataIndexToStringKey } from '@/components/ServerConfigTable/utils/dataIndexToStringKey';
import { FileRecordResponse, FileType } from '@/api/Files/types';
import { DocumentFilterValue } from '@/components/filterDropdowns/DocumentFilterDropdown/types';
import { DocumentFilterDropdown } from '@/components/filterDropdowns/DocumentFilterDropdown';

export const getDocumentColumnAddition = (
  strSerCol: BaseServerChildColumnType & DocumentServerChildColumnAddition,
  context: ServerConfigTableContext,
  editable: boolean,
): Partial<ControlledTableChildType<TableRecordType>> => {
  const { dataIndex, name, width } = strSerCol;
  const onClickLoad = context.document?.onClickLoad;
  const onDelete = context.document?.onDelete;
  const onLoadAlreadyExist = context.document?.onLoadAlreadyExist;

  const fileType = dataIndexToStringKey(dataIndex) as FileType;

  return {
    width: width ?? 220,
    filterDropdown: ({ currentFilterState: _currentFilterState, removeFilter, setFilterValue }) => {
      const filter = _currentFilterState as DocumentFilterValue;
      return (
        <DocumentFilterDropdown
          filter={filter}
          onChangeFilter={setFilterValue}
          onResetFilter={removeFilter}
        />
      );
    },
    render: (value: FileRecordResponse | undefined, record) => {
      const dataIndexStr = dataIndexToStringKey(dataIndex);
      return (
        <EditableDocumentWithUploadAlreadyExist
          editable={editable}
          document={
            value
              ? {
                  name: value.fileName,
                  link: value.fileUri,
                  size: value.size,
                  uploadOn: new Date(value.createdAt),
                }
              : undefined
          }
          onLoadClick={
            onClickLoad && record.status !== 'excluded'
              ? () => {
                  onClickLoad({
                    dataIndex: dataIndexStr,
                    oldRecord: record,
                    value,
                    fileType,
                    name,
                  });
                }
              : undefined
          }
          onLoadAlreadyExistClick={
            onLoadAlreadyExist && record.status !== 'excluded'
              ? () =>
                  onLoadAlreadyExist({
                    dataIndex: dataIndexStr,
                    oldRecord: record,
                    value,
                    name,
                    fileType,
                  })
              : undefined
          }
          onDelete={
            onDelete && value && record.status !== 'excluded'
              ? () => onDelete({ dataIndex: dataIndexStr, oldRecord: record, value, name })
              : undefined
          }
        />
      );
    },
  };
};
