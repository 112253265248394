import { EmployeeRecordResponse } from '@/api/Dictionary/Employees/types';
import { DictCrudProps } from '@/components/DictCrudPage';
import { useDeleteEmployeeM, useGetEmployeesPageQ } from '@/hooks/directory/employees';
import { handleFilter } from '@/utils/filterUtils';
import { DateView } from '@uspect-team/ant-ui-kit';

const getRecordName = (record: EmployeeRecordResponse) => {
  return `${record.lastName}\u00A0${record.firstName}`;
};

export const getEmployeesPageConfig = (params: {
  onCreateButtonClick: () => void;
  onEditButtonClick: (record: EmployeeRecordResponse) => void;
}): DictCrudProps<EmployeeRecordResponse> => ({
  getName: getRecordName,
  texts: {
    createButtonText: 'Добавить запись сотрудника',
    delete: {
      confirmTitle: (record) =>
        `Вы действительно хотите безвозвратно удалить запись сотрудника (${getRecordName(record)})`,
      successMessage: (record) => `Запись сотрудника успешно удалена (${getRecordName(record)})`,
      errorMessage: (record) =>
        `При удалении записи сотружника произошла ошибка (${getRecordName(record)})`,
    },
  },
  pagination: { type: 'server', getPageQ: useGetEmployeesPageQ },
  deleteRecordM: useDeleteEmployeeM,
  onCreateButtonClick: params.onCreateButtonClick,
  onEditButtonClick: params.onEditButtonClick,
  columns: [
    {
      key: 'firstName',
      dataIndex: 'firstName',
      title: 'Имя',
      sortable: true,
      filterDropdown: handleFilter,
    },
    {
      key: 'lastName',
      dataIndex: 'lastName',
      title: 'Фамилия',
      sortable: true,
      filterDropdown: handleFilter,
    },
    {
      key: 'patronymic',
      dataIndex: 'patronymic',
      title: 'Отчество',
      sortable: true,
      filterDropdown: handleFilter,
    },
    {
      key: 'email',
      dataIndex: 'email',
      title: 'Email',
      sortable: true,
      filterDropdown: handleFilter,
    },
    {
      key: 'phone',
      dataIndex: 'phone',
      title: 'Телефон',
      onCell: () => ({ style: { whiteSpace: 'nowrap' } }),
      sortable: true,
      filterDropdown: handleFilter,
    },
    {
      key: 'inn',
      dataIndex: 'inn',
      title: 'ИНН',
      sortable: true,
      filterDropdown: handleFilter,
    },
    {
      key: 'appointment',
      dataIndex: 'appointment',
      title: 'Должность',
      sortable: true,
      filterDropdown: handleFilter,
    },
    {
      key: 'createdAt',
      dataIndex: 'createdAt',
      title: 'Дата создания',
      render: (value) => <DateView value={value} withTime />,
      sortable: true,
    },
    {
      key: 'updatedAt',
      dataIndex: 'updatedAt',
      title: 'Дата изменения',
      render: (value) => <DateView value={value} withTime />,
      sortable: true,
    },
  ],
});
