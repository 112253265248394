import { RecordId } from '@/api/common/types';
import { GetCadObjectsPageRequest } from '@/api/CadObjects/types';

export const cadObjQueryKeys = {
  all: ['cad-objects'] as const,
  pages: () => [...cadObjQueryKeys.all, 'page'] as const,
  page: (data: GetCadObjectsPageRequest) => [...cadObjQueryKeys.pages(), data] as const,

  details: () => [...cadObjQueryKeys.all, 'detail'] as const,
  detail: (id: RecordId) => [...cadObjQueryKeys.details(), id] as const,
  detailFiles: (id: RecordId, dataIndex: string) =>
    [...cadObjQueryKeys.detail(id), dataIndex] as const,

  detailColumns: (id: RecordId) => [...cadObjQueryKeys.detail(id), 'columns'] as const,
  detailGroupedColumns: (id: RecordId) =>
    [...cadObjQueryKeys.detail(id), 'columns', 'grouped'] as const,
  detailAutocompletableColumns: (id: RecordId) =>
    [...cadObjQueryKeys.detail(id), 'columns', 'autocompletable'] as const,

  create: () => [...cadObjQueryKeys.all, 'create'] as const,
  patch: () => [...cadObjQueryKeys.all, 'patch'] as const,
  delete: () => [...cadObjQueryKeys.all, 'delete'] as const,
  deleteFile: () => [...cadObjQueryKeys.all, 'delete-file'] as const,
  deleteFileInCollection: () => [...cadObjQueryKeys.all, 'delete-file-in-collection'] as const,
  importFromExcel: () => [...cadObjQueryKeys.all, 'import-from-excel'] as const,
  importOs: () => [...cadObjQueryKeys.all, 'import-os'] as const,
  importRastr: () => [...cadObjQueryKeys.all, 'import-rastr'] as const,
  genFiles: () => [...cadObjQueryKeys.all, 'gen-files'] as const,
  autocomplete: () => [...cadObjQueryKeys.all, 'autocomplete'] as const,
  autoRun: () => [...cadObjQueryKeys.all, 'auto-run'] as const,
  autoSign: () => [...cadObjQueryKeys.all, 'auto-sign'] as const,
  archives: () => [...cadObjQueryKeys.all, 'archives'] as const,
};
