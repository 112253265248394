import React, { useCallback } from 'react';
import { Col, Form, Input, InputNumber, message, Modal, Row } from 'antd';
import { useCreateCadObjectM } from '@/hooks/cadastralObjects';
import { CadObjectCreateRequest } from '@/api/CadObjects/types';
import { errorMessages } from '@/utils/errorMessages';
import { AddressSearchSelect } from '@uspect-team/ant-ui-kit';
import { CreateCadObjectFormType } from '@/components/modals/cadObjects/CreateCadObjectModal/types';
import './styles.scss';

export type CreateCadObjectModalProps =
  | {
      visible: false;
    }
  | ({ visible: true } & VisibleProps);

type VisibleProps = {
  contractId: string;
  onClose: () => void;
};

export const CreateCadObjectModal: React.FC<CreateCadObjectModalProps> = (props) => {
  const { visible } = props;

  if (!visible) {
    return null;
  }
  const { visible: _, ...visibleProps } = props;
  return <VisibleModal {...visibleProps} />;
};

const VisibleModal: React.FC<VisibleProps> = React.memo((props) => {
  const { onClose, contractId } = props;

  const createCadObjM = useCreateCadObjectM();

  const [form] = Form.useForm<CreateCadObjectFormType>();

  const onFinishForm = useCallback(
    async (values: CreateCadObjectFormType) => {
      try {
        const createReqObj = { ...values, contractId } as CadObjectCreateRequest;
        try {
          await createCadObjM.mutateAsync(createReqObj);
          onClose();
        } catch (e) {
          message.error('При создании объекта произошла ошибка');
        }
      } catch (e) {
        message.error('Есть невалидные поля');
      }
    },
    [createCadObjM, onClose, contractId],
  );

  const { submit } = form;

  return (
    <Modal
      className={'create-cad-object-modal'}
      visible={true}
      onCancel={onClose}
      title={'Создание кадастрового объекта'}
      okText={'Создать'}
      okButtonProps={{ loading: createCadObjM.isLoading }}
      onOk={submit}
      cancelText={'Отмена'}
    >
      <p className={'create-cad-object-modal__desc'}>
        Для создания объекта заполните обязательные поля, остальные поля вы сможете добавить уже в
        таблице
      </p>
      <Form
        className={'create-cad-object-modal__form'}
        form={form}
        layout={'vertical'}
        wrapperCol={{ span: 24 }}
        requiredMark={'optional'}
        onFinish={onFinishForm}
      >
        <Form.Item
          name={'name'}
          label={'Наименование объекта'}
          rules={[{ required: true, message: errorMessages.required }]}
        >
          <Input />
        </Form.Item>
        <Form.Item
          name={'address'}
          label={'Адрес объекта'}
          rules={[{ required: true, message: errorMessages.required }]}
        >
          <AddressSearchSelect block />
        </Form.Item>
        <Row gutter={16}>
          <Col span={8}>
            <Form.Item
              name={'inventNumber'}
              label={'Инвертарный номер'}
              rules={[{ required: true, message: errorMessages.required }]}
            >
              <Input />
            </Form.Item>
          </Col>
          <Col span={8}>
            <Form.Item
              name={'num'}
              label={'Номер по договору'}
              rules={[{ required: true, message: errorMessages.required }]}
            >
              <Input />
            </Form.Item>
          </Col>
          <Col span={8}>
            <Form.Item
              name={'dogLength'}
              label={'Протяженность'}
              rules={[
                { type: 'number', message: 'Доступны только числа' },
                { required: true, message: errorMessages.required },
                // { min: 0, message: errorMessages.positive },
              ]}
            >
              <InputNumber min={0} />
            </Form.Item>
          </Col>
        </Row>
      </Form>
    </Modal>
  );
});
