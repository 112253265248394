import React from 'react';
import { autoSignModalService } from '@/globalStorage/autoSign/modal';
import { $autoSignWidgetState } from '@/globalStorage/autoSign/widget';
import { LoadingOutlined } from '@ant-design/icons';
import { createComponent } from 'effector-react';
import { WidgetContainer } from '../common/WidgetContainer';

export const AutoSignWidget = createComponent($autoSignWidgetState, (props, state) => {
  const { status } = state;

  if (status === 'idle') {
    return (
      <WidgetContainer onClick={autoSignModalService.openAutoSignModal}>
        <div>Автоподписание объектов (не ведется)</div>
      </WidgetContainer>
    );
  }

  if (status === 'inProcess') {
    const { doneCount, allCount } = state;
    return (
      <WidgetContainer onClick={autoSignModalService.openAutoSignModal}>
        <div>Автоподписание объектов</div>
        <LoadingOutlined spin />
        <div>
          {doneCount} / {allCount}
        </div>
      </WidgetContainer>
    );
  }
  if (status === 'done') {
    return (
      <WidgetContainer onClick={autoSignModalService.openAutoSignModal}>
        <div>Автоподписание объектов</div>
        <div>(готово)</div>
      </WidgetContainer>
    );
  }
});
