import { handleFilter } from '@/utils/filterUtils';
import { ControlledTable } from '@uspect-team/ant-ui-kit';
import { ControlledTableState } from '@uspect-team/ant-ui-kit/dist/Tables/ControlledTable/types';
import React, { useMemo, useState } from 'react';

export interface ManagementStatisticContractsTableProps {}

const _ManagementStatisticContractsTable: React.FC<ManagementStatisticContractsTableProps> = (
  props,
) => {
  const tableState = useState<ControlledTableState>({
    pagination: { currentPage: 1, pageSize: 10 },
  });
  const columns = useMemo(
    () => [
      {
        title: 'Дата',
        dataIndex: 'date',
        key: 'date',
        sortable: true,
        filterDropdown: handleFilter,
      },
      {
        title: 'Контракт',
        dataIndex: 'contract',
        key: 'contract',
        sortable: true,
        filterDropdown: handleFilter,
      },
      {
        title: 'Добавлено объектов',
        dataIndex: 'addObject',
        key: 'addObject',
        sortable: true,
        filterDropdown: handleFilter,
      },
      {
        title: 'Сген-но осей',
        dataIndex: 'genAxis',
        key: 'genAxis',
        sortable: true,
        filterDropdown: handleFilter,
      },
      {
        title: 'Сген-но КПТ/растров',
        dataIndex: 'genCpt/rastr',
        key: 'genCpt/rastr',
        sortable: true,
        filterDropdown: handleFilter,
      },
      {
        title: 'Сген-но схем',
        dataIndex: 'genScheme',
        key: 'genScheme',
        sortable: true,
        filterDropdown: handleFilter,
      },
      {
        title: 'Загружено ПД',
        dataIndex: 'downloadPd',
        key: 'downloadPd',
        sortable: true,
        filterDropdown: handleFilter,
      },
      {
        title: 'Сгенерировано пакетов',
        dataIndex: 'genPackage',
        key: 'genPackage',
        sortable: true,
        filterDropdown: handleFilter,
      },
      {
        title: 'Сгенерировано тех.планов',
        dataIndex: 'genPlan',
        key: 'genPlan',
        sortable: true,
        filterDropdown: handleFilter,
      },
      {
        title: 'Отправлено на согл-е',
        dataIndex: 'sendToAgreed',
        key: 'sendToAgreed',
        sortable: true,
        filterDropdown: handleFilter,
      },
      {
        title: 'Согласовано',
        dataIndex: 'agreed',
        key: 'agreed',
        sortable: true,
        filterDropdown: handleFilter,
      },
      {
        title: 'Не согласовано',
        dataIndex: 'notAgreed',
        key: 'notAgreed',
        sortable: true,
        filterDropdown: handleFilter,
      },
      {
        title: 'Направлено в КП',
        dataIndex: 'moveToCp',
        key: 'moveToCp',
        sortable: true,
        filterDropdown: handleFilter,
      },
      {
        title: 'Получено увед-е из КП',
        dataIndex: 'messageCp',
        key: 'messageCp',
        sortable: true,
        filterDropdown: handleFilter,
      },
    ],
    [],
  );

  const columnsKeys = columns.map((item) => item.key);

  const TempDataItem = columnsKeys.reduce((acc, key, index) => {
    acc[key] = 'test';
    return acc;
  }, {} as any);

  const TempData = new Array(5).fill(TempDataItem);

  return (
    <ControlledTable
      columns={columns}
      dataSource={TempData}
      tableState={tableState[0]}
      pagination={{
        type: 'none',
      }}
      rowKey={'_id'}
      scroll={{ x: true }}
    />
  );
};
export const ManagementStatisticContractsTable = React.memo(_ManagementStatisticContractsTable);
