import { DictCrudProps } from '@/components/DictCrudPage';
import { DateView } from '@uspect-team/ant-ui-kit';
import React from 'react';
import { ColumnGroupRecordResponse } from '@/api/Dictionary/ColumnGroups/types';
import { useDeleteColumnGroupM, useGetColumnGroupPageQ } from '@/hooks/directory/columnGroups';

export const getColumnGroupPageConfig = (params: {
  onCreateButtonClick: () => void;
  onEditButtonClick: (record: ColumnGroupRecordResponse) => void;
}): DictCrudProps<ColumnGroupRecordResponse> => ({
  getName: (record) => record.name,
  texts: {
    createButtonText: 'Добавить группу колонок',
    delete: {
      confirmTitle: (record) =>
        `Вы действительно хотите безвозвратно удалить группу колонок (${record.name})`,
      successMessage: (record) => `Группа колонок успешно удалена (${record.name})`,
      errorMessage: (record) => `При удалении группы колонок произошла ошибка (${record.name})`,
    },
  },
  pagination: { type: 'server', getPageQ: useGetColumnGroupPageQ },
  deleteRecordM: useDeleteColumnGroupM,
  onCreateButtonClick: params.onCreateButtonClick,
  onEditButtonClick: params.onEditButtonClick,
  columns: [
    {
      key: 'name',
      dataIndex: 'name',
      title: 'Название группы',
    },
    {
      align: 'center',
      key: 'order',
      dataIndex: 'order',
      title: 'Порядок',
    },
    {
      key: 'createdAt',
      dataIndex: 'createdAt',
      title: 'Дата создания',
      render: (value) => {
        return <DateView value={value} />;
      },
    },
    {
      key: 'updatedAt',
      dataIndex: 'updatedAt',
      title: 'Дата изменения',
      render: (value) => {
        return <DateView value={value} />;
      },
    },
  ],
});
