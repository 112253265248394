import React, { useCallback, useEffect, useState } from 'react';
import { Form, message, Modal } from 'antd';
import { CoordinateSystemFields } from '@/components/modals/coordinateSystems/common/CoordinateSystemFields';
import { RecordId } from '@/api/common/types';
import {
  useGetCoordinateSystemByIdQ,
  usePatchCoordinateSystemM,
} from '@/hooks/coordinates/coordinateSystems';
import { EditCoordinateSystemFormType } from '@/components/modals/coordinateSystems/EditCoordinateSystemModal/types';
import { PatchCoordinateSystemRequest } from '@/api/Coordinates/CoordinateSystems/types';

export type EditCoordinateSystemModalProps =
  | {
      visible: false;
    }
  | ({ visible: true } & VisibleProps);
type VisibleProps = {
  coordinateSystemId: RecordId;
  onClose: () => void;
};

export const EditCoordinateSystemModal: React.FC<EditCoordinateSystemModalProps> = React.memo(
  (props) => {
    const { visible } = props;

    if (!visible) {
      return null;
    }

    const { visible: _, ...visibleProps } = props;
    return <VisibleModal {...visibleProps} />;
  },
);

export const VisibleModal: React.FC<VisibleProps> = React.memo((props) => {
  const { onClose, coordinateSystemId } = props;
  const patchCoordinateSystemM = usePatchCoordinateSystemM();
  const { data: currentCoordinateSystem, isLoading } =
    useGetCoordinateSystemByIdQ(coordinateSystemId);
  const [form] = Form.useForm<EditCoordinateSystemFormType>();
  useEffect(() => {
    if (currentCoordinateSystem) {
      form.setFieldsValue(currentCoordinateSystem);
    }
  }, [currentCoordinateSystem, form]);
  const [changes, setChanges] = useState<EditCoordinateSystemFormType | null>(null);

  const onFinishFormHandler = useCallback(async () => {
    if (changes) {
      try {
        const reqData = { id: coordinateSystemId, patch: changes } as PatchCoordinateSystemRequest;
        await patchCoordinateSystemM.mutateAsync(reqData);
        onClose();
        message.success('Система координат изменена');
      } catch {
        message.error('При изменении системы координат произошла ошибка');
      }
    }
  }, [patchCoordinateSystemM, onClose, changes, coordinateSystemId]);

  const onValuesChangeHandler = useCallback((newChanges: any) => {
    setChanges((prevState) => ({ ...prevState, ...newChanges }));
  }, []);

  return (
    <Modal
      visible={true}
      onCancel={onClose}
      title={
        isLoading
          ? 'Загрузка...'
          : `${currentCoordinateSystem?.title || ''}: Редактирование записи системы координат`
      }
      okText={'Сохранить изменения'}
      onOk={form.submit}
      okButtonProps={{ disabled: changes === null, loading: patchCoordinateSystemM.isLoading }}
    >
      <Form
        form={form}
        layout={'vertical'}
        requiredMark={'optional'}
        onValuesChange={onValuesChangeHandler}
        onFinish={onFinishFormHandler}
      >
        <CoordinateSystemFields />
      </Form>
    </Modal>
  );
});
