import { AppButton } from '@/components/AppButton';
import { routes } from '@/resources/routes';
import { errorMessages } from '@/utils/errorMessages';
import { Form, Input } from 'antd';
import { Rule } from 'antd/lib/form';
import React from 'react';
import { PasswordRestoreConfirmedForm } from '../types';
import './styles.scss';

const requiredRules: Rule[] = [{ required: true, message: errorMessages.required }];

const repeatPasswordRules: Rule[] = [
  ...requiredRules,
  ({ getFieldValue }) => ({
    validator: (_, value?: string) => {
      if (!value || getFieldValue('newPassword') === value) {
        return Promise.resolve();
      }
      return Promise.reject(new Error('Пароли должны совпадать'));
    },
  }),
];

export type PasswordRestoreConfirmedPageFormProps = {
  onSubmit: (newPassword: string) => void;
  isLoading: boolean;
};

const _PasswordRestoreConfirmedPageForm: React.FC<PasswordRestoreConfirmedPageFormProps> = ({
  onSubmit,
  isLoading,
}) => {
  const [form] = Form.useForm<PasswordRestoreConfirmedForm>();

  const onFinishHandler = (formData: PasswordRestoreConfirmedForm) => {
    if (formData.newPassword) {
      onSubmit(formData.newPassword);
    }
  };

  return (
    <Form form={form} onFinish={onFinishHandler}>
      <Form.Item name={'newPassword'} rules={requiredRules}>
        <Input.Password placeholder='Ваш новый пароль*' autoComplete='restore-password' />
      </Form.Item>
      <Form.Item
        required
        name={'repeatPassword'}
        dependencies={['newPassword']}
        rules={repeatPasswordRules}
      >
        <Input.Password placeholder='Повторите пароль еще раз*' autoComplete='restore-password' />
      </Form.Item>

      <div className='password-restore-confirmed-page-form__buttons'>
        <AppButton size='large' type='primary' htmlType='submit' block loading={isLoading}>
          Сменить пароль
        </AppButton>
        <AppButton size='large' type='primary' ghost block to={routes.login}>
          Вернуться к авторизации
        </AppButton>
      </div>
    </Form>
  );
};
export const PasswordRestoreConfirmedPageForm = React.memo(_PasswordRestoreConfirmedPageForm);
