import { RecordId } from '@/api/common/types';
import { PhoneNumberInput } from '@/components/inputs/special/PhoneNumberInput';
import { errorMessages } from '@/utils/errorMessages';
import { telValidator } from '@/utils/validators';
import { Button, Col, Form, Input, Row } from 'antd';
import React from 'react';
import { EditUserPasswordModal, EditUserPasswordModalProps } from '../../EditUserPasswordModal';
import { PermissionSettingsInput } from './PermissionSettingsInput';

export const UserFields: React.FC<{ editProps?: { userId: RecordId } }> = React.memo(
  ({ editProps }) => {
    const [editPasswordModalState, setEditPasswordModalState] =
      React.useState<EditUserPasswordModalProps>({
        visible: false,
      });

    const onClickEditPassword = () => {
      if (editProps) {
        setEditPasswordModalState({
          visible: true,
          userId: editProps.userId,
          onClose: () => setEditPasswordModalState({ visible: false }),
        });
      }
    };

    return (
      <>
        <Row gutter={16}>
          <Col span={8}>
            <Form.Item
              name={'name'}
              label={'Имя'}
              rules={[{ required: true, message: errorMessages.required }]}
            >
              <Input />
            </Form.Item>
          </Col>
          <Col span={8}>
            <Form.Item
              name={'secondName'}
              label={'Фамилия'}
              rules={[{ required: true, message: errorMessages.required }]}
            >
              <Input />
            </Form.Item>
          </Col>
          <Col span={8}>
            <Form.Item
              name={'patronymic'}
              label={'Отчество'}
              rules={[{ required: true, message: errorMessages.required }]}
            >
              <Input />
            </Form.Item>
          </Col>
        </Row>
        <Row gutter={16} align='bottom'>
          <Col span={8}>
            <Form.Item
              name={'email'}
              label={'E-mail'}
              rules={[{ required: true, message: errorMessages.required }]}
            >
              <Input style={{ width: '100%' }} />
            </Form.Item>
          </Col>

          <Col span={8}>
            <Form.Item
              name={'phone'}
              label={'Номер телефона'}
              rules={[
                { required: true, message: errorMessages.required },
                { validator: telValidator },
              ]}
            >
              <PhoneNumberInput block />
            </Form.Item>
          </Col>

          {editProps ? (
            <Col span={8}>
              <Form.Item label=' '>
                <Button block onClick={onClickEditPassword}>
                  Изменить пароль
                </Button>
              </Form.Item>
            </Col>
          ) : (
            <Col span={8}>
              <Form.Item
                name={'password'}
                label={'Пароль'}
                rules={[{ required: true, message: errorMessages.required }]}
              >
                <Input style={{ width: '100%' }} type='password' />
              </Form.Item>
            </Col>
          )}
        </Row>
        <Form.Item
          name={'permissions'}
          rules={[{ required: true, message: errorMessages.required }]}
        >
          <PermissionSettingsInput />
        </Form.Item>

        {editProps && <EditUserPasswordModal {...editPasswordModalState} />}
      </>
    );
  },
);
