import { RecordId } from '@/api/common/types';

const contractFavoritesLcKey = 'actus__favorites_contracts';

export class ContractsLocalStorageRepo {
  private static _getFavoritesContractIdsFromLC() {
    const str = localStorage.getItem(contractFavoritesLcKey);
    if (str) {
      return JSON.parse(str) as string[];
    }

    return [];
  }

  private static _saveFavoritesContractIdsFromLC(a: string[]) {
    localStorage.setItem(contractFavoritesLcKey, JSON.stringify(a));
  }

  public getFavoriteIds() {
    return ContractsLocalStorageRepo._getFavoritesContractIdsFromLC();
  }

  public setIsFavorite(id: string, value: boolean) {
    const set = new Set(ContractsLocalStorageRepo._getFavoritesContractIdsFromLC());
    if (value) {
      set.add(id);
    } else {
      set.delete(id);
    }
    const result = Array.from(set);

    ContractsLocalStorageRepo._saveFavoritesContractIdsFromLC(result);

    return result;
  }

  public deleteContractInfo(id: RecordId) {
    const set = new Set(ContractsLocalStorageRepo._getFavoritesContractIdsFromLC());
    set.delete(id);

    const result = Array.from(set);

    ContractsLocalStorageRepo._saveFavoritesContractIdsFromLC(result);
  }
}
