import React, { useMemo } from 'react';
import { Select, SelectProps } from 'antd';
import { useGetAllColumnGroupsQ } from '@/hooks/directory/columnGroups';

type Props = SelectProps<string>;
export const ColumnGroupSelect: React.FC<Props> = React.memo((props) => {
  const { data: columnGroups = [] } = useGetAllColumnGroupsQ();
  const options = useMemo(() => {
    return [...columnGroups.map((x) => ({ value: x._id, title: x.name }))];
  }, [columnGroups]);
  return (
    <Select
      showSearch
      optionFilterProp='children'
      filterOption={(input, option) => {
        const childrenText = option?.children as string | null | undefined;
        return childrenText?.toLowerCase().includes(input.toLowerCase()) || false;
      }}
      allowClear
      placeholder={'Без группы'}
      {...props}
    >
      {options.map((o) => (
        <Select.Option key={o.value} value={o.value}>
          {o.title}
        </Select.Option>
      ))}
    </Select>
  );
});
