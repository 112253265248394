import { RecordId } from '@/api/common/types';
import { useGetContractNotifyQ } from '@/hooks/contracts';
import { EyeOutlined } from '@ant-design/icons';
import { Badge, Button, Dropdown } from 'antd';
import cn from 'classnames';
import React from 'react';
import { NotifyOverlay } from './NotifyOverlay';

export type NotifyButtonProps = {
  className?: string;
  style?: React.CSSProperties;

  contractId: RecordId;
};

const _NotifyButton: React.FC<NotifyButtonProps> = (props) => {
  const { contractId } = props;

  const { data: notifyInfo } = useGetContractNotifyQ(contractId);

  const [isVisbleDropdown, setIsVisibleDropdown] = React.useState<boolean>(false);

  const notifyItemsCount = React.useMemo(() => {
    if (!notifyInfo) {
      return undefined;
    }
    const { genKptRasters, genPackageZip, genScheme, signaturePackageZip } = notifyInfo;

    return (
      genKptRasters.length + genPackageZip.length + genScheme.length + signaturePackageZip.length
    );
  }, [notifyInfo]);

  const closeOverlay = React.useCallback(() => {
    setIsVisibleDropdown(false);
  }, []);

  return (
    <Dropdown
      trigger={['click']}
      overlay={<NotifyOverlay contractId={contractId} close={closeOverlay} />}
      placement='topRight'
      visible={isVisbleDropdown}
      onVisibleChange={setIsVisibleDropdown}
    >
      <div className='statistic-bar__notify-btn-container'>
        <Badge count={notifyItemsCount} overflowCount={1000}>
          <Button
            type='text'
            className={cn('statistic-bar__btn', {
              'statistic-bar__btn--notify': notifyItemsCount && notifyItemsCount > 0,
            })}
            icon={<EyeOutlined />}
            shape='circle'
          />
        </Badge>
      </div>
    </Dropdown>
  );
};
export const NotifyButton = React.memo(_NotifyButton);
