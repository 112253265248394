import React, { useState } from 'react';
import { CreateCptModal, CreateCptModalProps } from '@/components/modals/cpts/CreateCptModal';
import { EditCptModal, EditCptModalProps } from '@/components/modals/cpts/EditCptModal';
import { DictCrudPage } from '@/components/DictCrudPage';
import { getCptPageConfig } from '@/pages/Dictionary/CptPage/config';
import { CptRecordResponse } from '@/api/Dictionary/Cpt/types';

const _CptPage: React.FC = () => {
  const [createCptModalState, setCreateCptModalState] = useState<CreateCptModalProps>({
    visible: false,
  });

  const [editCptModalState, setEditCptModalState] = useState<EditCptModalProps>({ visible: false });

  const onCreateButtonClickHandler = React.useCallback(() => {
    setCreateCptModalState({
      visible: true,
      onClose: () => setCreateCptModalState({ visible: false }),
    });
  }, []);

  const onEditButtonClickHandler = React.useCallback((record: CptRecordResponse) => {
    setEditCptModalState({
      visible: true,
      onClose: () => setEditCptModalState({ visible: false }),
      cptId: record._id,
    });
  }, []);

  const cptPageConfig = React.useMemo(() => {
    return getCptPageConfig({
      onCreateButtonClick: onCreateButtonClickHandler,
      onEditButtonClick: onEditButtonClickHandler,
    });
  }, [onCreateButtonClickHandler, onEditButtonClickHandler]);

  return (
    <>
      <DictCrudPage {...cptPageConfig} />

      <CreateCptModal {...createCptModalState} />
      <EditCptModal {...editCptModalState} />
    </>
  );
};

export const CptPage = React.memo(_CptPage);
