import { WorkerCount } from './../../../../../../../../globalStorage/register/types';

export const WORKERS_COUNT_SELECT_OPTIONS: { value: WorkerCount; label: React.ReactNode }[] = [
  {
    value: '1-5',
    label: '1-5 человек',
  },
  {
    value: '6-20',
    label: '6-20 человек',
  },
  {
    value: '21-50',
    label: '21-50 человек',
  },
  {
    value: '51-150',
    label: '51-150 человек',
  },
  {
    value: '151-300',
    label: '151-300 человек',
  },
  {
    value: '301-1000',
    label: '301-1000 человек',
  },
  {
    value: '1001-5000',
    label: '1001-5000 человек',
  },
  {
    value: '5000+',
    label: '5000+ человек',
  },
];
