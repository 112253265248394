import { RecordId } from '@/api/common/types';
import { coordinateService } from '@/globalStorage/coordinates/newIndex';
import { ViewPolylineRecord } from '@/globalStorage/coordinates/types';
import { GroupShape, PointShape, PolylineShape } from '@uspect-team/ant-ui-kit';
import { useStore } from 'effector-react';
import React from 'react';

type PolylineViewProps = {
  polyline: ViewPolylineRecord;
};

const _PolylineView: React.FC<PolylineViewProps> = (props) => {
  const { polyline } = props;

  const selectedPolylineId = useStore(coordinateService.$selectedPolylineId);

  const selectedPointId = useStore(coordinateService.$selectedPointId);

  const onLineClickHandler = React.useCallback(() => {
    coordinateService.selectPolyline({ polylineId: polyline.id });
  }, [polyline]);

  const onPointClickHandler = React.useCallback(
    (pointId: RecordId) => {
      coordinateService.selectPoint({ polylineId: polyline.id, pointId: pointId });
    },
    [polyline.id],
  );

  const selectedPolyline = selectedPolylineId === polyline.id;

  return (
    <GroupShape>
      <PolylineShape
        points={polyline.points}
        strokeWidth={selectedPolyline ? 4 : 2}
        stroke={selectedPolyline ? '#a7a7a7' : '#d4d4d4'}
        fill={'transparent'}
        onClick={onLineClickHandler}
      />

      {polyline.points.map((point) => {
        const selectedPoint = selectedPointId === point.id;
        return (
          <PointShape
            key={point.id}
            point={point}
            radius={selectedPoint ? 8 : 4}
            fill={selectedPoint ? '#7084f3' : 'white'}
            stroke={selectedPoint ? '#2c44d7' : '#5771ea'}
            strokeWidth={1}
            onClick={() => onPointClickHandler(point.id)}
          />
        );
      })}
    </GroupShape>
  );
};

export const PolylineView = React.memo(_PolylineView);
