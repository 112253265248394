import { DateString, RecordId } from '@/api/common/types';
import { companyStoreService } from '@/globalStorage/company';
import { routes } from '@/resources/routes';
import { formatDateToString } from '@uspect-team/ant-ui-kit';
import cn from 'classnames';
import React from 'react';
import { useHistory } from 'react-router-dom';
import './styles.scss';

export type CompanyListItemProps = {
  className?: string;
  style?: React.CSSProperties;
  id: RecordId;
  name: string;
  tariffName: string;
  inn: string;
  ogrn: string;
  paidUpTo: DateString;
  isOverdue: boolean;
};

const _CompanyListItem: React.FC<CompanyListItemProps> = (props) => {
  const history = useHistory();

  const { className, style, id, name, tariffName, inn, ogrn, paidUpTo, isOverdue } = props;

  const setCompany = companyStoreService.$setCompany;

  const onClickItemHandler = () => {
    setCompany({ id, name, tariff: tariffName, inn, ogrn, paidUpTo, isOverdue });

    history.push(routes.contracts);
  };

  return (
    <div
      className={cn(className, 'company-list-item', { 'company-list-item--overdue': isOverdue })}
      style={style}
      onClick={onClickItemHandler}
    >
      <div className='company-list-item__title'>{name}</div>
      <div className='company-list-item__id'>{`id: ${id}`}</div>
      <div className='company-list-item__description'>
        <div className='company-list-item__description-section'>
          <div className='company-list-item__description-prop'>
            <div className='company-list-item__description-prop-name'>ИНН / ОРГН:</div>
            <div className='company-list-item__description-prop-value'>{`${inn} / ${ogrn}`}</div>
          </div>
        </div>
        <div className='company-list-item__description-section'>
          <div className='company-list-item__description-section'>
            <div className='company-list-item__description-prop'>
              <div className='company-list-item__description-prop-name'>Тариф:</div>
              <div className='company-list-item__description-prop-value'>{tariffName}</div>
            </div>
            <div className='company-list-item__description-prop'>
              <div className='company-list-item__description-prop-name'>Оплачено до:</div>
              <div className='company-list-item__description-prop-value'>
                {formatDateToString(paidUpTo)}
              </div>
            </div>
          </div>
        </div>

        {isOverdue && (
          <div className='company-list-item__description-message'>
            Доступ закончился, войдите в организацию, чтобы продлить подписку
          </div>
        )}
      </div>
    </div>
  );
};
export const CompanyListItem = React.memo(_CompanyListItem);
