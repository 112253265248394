import { CheckOutlined, CloseOutlined } from '@ant-design/icons';
import { FilterTypeOption } from '../common/FilterTypeSelect';
import { OptionFilterType } from './types';
import React from 'react';

export const inOption: FilterTypeOption<OptionFilterType> = {
  value: 'in',
  icon: '+',
  label: 'Один из',
};
export const notInOption: FilterTypeOption<OptionFilterType> = {
  value: 'notIn',
  icon: '-',
  label: 'Не один из',
};
export const existOption: FilterTypeOption<OptionFilterType> = {
  value: 'exist',
  icon: <CheckOutlined />,
  label: 'Заполнено',
};
export const notExistOption: FilterTypeOption<OptionFilterType> = {
  value: 'notExist',
  icon: <CloseOutlined />,
  label: 'Не заполнено',
};
