import { AppSpinner } from '@/components/AppSpinner';
import { HintBlock } from '@/components/HintBlock';
import { registerStateService } from '@/globalStorage/register';
import { useStoreMap } from 'effector-react';
import React from 'react';
import './styles.scss';

const _RegisterInProgressStep: React.FC = () => {
  const firstName = useStoreMap(registerStateService.$registerState, (state) => {
    if (state.step === 4) {
      return state.data.personalInfo.firstName;
    }
    return null;
  });
  return (
    <div className='register-in-progress-step'>
      <div className='register-in-progress-step__title'>
        {firstName !== null
          ? `${firstName}, мы создаем ваше рабочее пространство`
          : 'Мы создаем ваше рабочее пространство'}
      </div>

      <div className='register-in-progress-step__content'>
        <AppSpinner
          className='register-in-progress-step__content-loader'
          theme='dark'
          size='gigant'
        />
        <div className='register-in-progress-step__content-title'>
          Пожалуйста, подождите и не закрывайте эту вкладку браузера
        </div>
        <div className='register-in-progress-step__content-description'>
          Обычно это занимает не больше 1 минуты
        </div>
      </div>

      <HintBlock
        className='register-in-progress-step__hint'
        type='info'
        title={'Полезная информация про сервис: подсказки, советы и т. д.'}
        description={
          'Тут будут полезные советы, пока человек ждет когда создается аккаунт его компании.'
        }
        theme='dark'
      />
    </div>
  );
};
export const RegisterInProgressStep = React.memo(_RegisterInProgressStep);
