import React, { useCallback } from 'react';
import { Form, message, Modal } from 'antd';
import { CoordinateSystemFields } from '@/components/modals/coordinateSystems/common/CoordinateSystemFields';
import { CreateCoordinateSystemFormType } from './types';
import { useCreateCoordinateSystemM } from '@/hooks/coordinates/coordinateSystems';
import { CreateCoordinateSystemRequest } from '@/api/Coordinates/CoordinateSystems/types';

export type CreateCoordinateSystemModalProps =
  | {
      visible: false;
    }
  | ({ visible: true } & VisibleProps);
type VisibleProps = {
  onClose: () => void;
};

export const CreateCoordinateSystemModal: React.FC<CreateCoordinateSystemModalProps> = React.memo(
  (props) => {
    const { visible } = props;

    if (!visible) {
      return null;
    }

    const { visible: _, ...visibleProps } = props;
    return <VisibleModal {...visibleProps} />;
  },
);

export const VisibleModal: React.FC<VisibleProps> = React.memo((props) => {
  const { onClose } = props;
  const [form] = Form.useForm<CreateCoordinateSystemFormType>();
  const createCoordinateSystemM = useCreateCoordinateSystemM();

  const onFinishFormHandler = useCallback(
    async (fields: CreateCoordinateSystemFormType) => {
      try {
        const reqData = fields as CreateCoordinateSystemRequest;
        await createCoordinateSystemM.mutateAsync(reqData);
        onClose();
        message.success('Система координат создана');
      } catch {
        message.error('При создании системы координат произошла ошибка');
      }
    },
    [createCoordinateSystemM, onClose],
  );

  return (
    <Modal
      visible={true}
      onCancel={onClose}
      title={'Создание новой системы координат'}
      okText={'Создать'}
      onOk={form.submit}
      okButtonProps={{ loading: createCoordinateSystemM.isLoading }}
    >
      <Form
        form={form}
        onFinish={onFinishFormHandler}
        layout={'vertical'}
        requiredMark={'optional'}
        initialValues={{ isEnabled: true }}
      >
        <CoordinateSystemFields />
      </Form>
    </Modal>
  );
});
