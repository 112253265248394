import { Row } from 'antd';
import React from 'react';
import { ManagementStatisticLeftCard } from './ManagementStatisticLeftCard';
import { ManagementStatisticRightCard } from './ManagementStatisticRightCard';

const _ManagementStatistic: React.FC = () => {
  return (
    <div className='management-item__wrapper'>
      <Row gutter={40}>
        <ManagementStatisticLeftCard />
        <ManagementStatisticRightCard />
      </Row>
    </div>
  );
};
export const ManagementStatistic = React.memo(_ManagementStatistic);
