import { useMutation, useQuery, useQueryClient } from 'react-query';
import { Api } from '@/api';
import { useCallback, useState } from 'react';
import { LocalStorageRepo } from '@/utils/localStorage';
import { RecordId } from '@/api/common/types';
import { GetContractColumnsRequest, GetContractsPageRequest } from '@/api/Contracts/types';
import { contractsQueryKeys } from '@/hooks/contracts/queryKeys';

export const useGetContractsPageQ = (query: GetContractsPageRequest) => {
  return useQuery(contractsQueryKeys.page(query), () => Api.contracts.get(query), {
    select: (data) => data.data.data,
  });
};

export const useGetContractsAllQ = () => {
  const query: GetContractsPageRequest = { page: 1, limit: 1000 };
  return useQuery(contractsQueryKeys.page(query), () => Api.contracts.get(query), {
    select: (data) => data.data.data,
  });
};

export const useGetContractByIdQ = (id: RecordId) => {
  return useQuery(contractsQueryKeys.detail(id), () => Api.contracts.getById(id), {
    select: (data) => data.data.data,
  });
};

export const useGetContractColumnsByIdQ = (id: RecordId) => {
  return useQuery(
    contractsQueryKeys.detailColumns(id),
    async () => {
      return await Api.contracts.getById(id);
    },
    {
      select: (data) => {
        return data.data.data.fields;
      },
    },
  );
};

export const useGetContractAutocompletableColumnsByIdQ = (id: RecordId) => {
  return useQuery(
    contractsQueryKeys.detailAutocompletableColumns(id),
    () => {
      return Api.contracts.getById(id);
    },
    {
      select: (data) =>
        data.data.data.fields.filter(
          (x) =>
            (x.type === 'address' ||
              x.type === 'date' ||
              x.type === 'decimal' ||
              x.type === 'integer' ||
              x.type === 'string') &&
            x.dataIndex !== 'N_dog' &&
            x.dataIndex !== 'name',
        ),
    },
  );
};

export const useGetGroupedContactColumnsQ = (request: GetContractColumnsRequest) => {
  return useQuery(
    contractsQueryKeys.detailGroupedColumns(request),
    () => Api.contracts.getContractColumns(request),
    {
      select: (data) => data.data.data,
      keepPreviousData: true,
    },
  );
};

export const useFavoriteContractIdsQ = () => {
  const [data, setData] = useState<string[]>(LocalStorageRepo.contracts.getFavoriteIds());

  const setIsFavorite = useCallback((id: string, isFavorite: boolean) => {
    const newData = LocalStorageRepo.contracts.setIsFavorite(id, isFavorite);
    setData(newData);
  }, []);

  return {
    data,
    setIsFavorite,
  };
};

export const useGetContractNotifyQ = (contractId: RecordId) => {
  return useQuery(
    contractsQueryKeys.notify(contractId),
    () => Api.contracts.getNotify(contractId),
    {
      select: (data) => data.data.data,
      keepPreviousData: true,
    },
  );
};

export const useCreateContactM = () => {
  const queryClient = useQueryClient();
  return useMutation(contractsQueryKeys.create(), Api.contracts.create, {
    onSuccess: async () => {
      await queryClient.invalidateQueries(contractsQueryKeys.all);
    },
  });
};

export const usePatchContactM = () => {
  const queryClient = useQueryClient();
  return useMutation(contractsQueryKeys.patch(), Api.contracts.patch, {
    onSuccess: async () => {
      await queryClient.invalidateQueries(contractsQueryKeys.all);
    },
  });
};

export const useDeleteContactM = () => {
  const queryClient = useQueryClient();
  return useMutation(contractsQueryKeys.delete(), Api.contracts.delete, {
    onSuccess: async () => {
      await queryClient.invalidateQueries(contractsQueryKeys.all);
    },
  });
};

export const useEditFieldListContractM = () => {
  const queryClient = useQueryClient();
  return useMutation(
    contractsQueryKeys.patch(),
    (data: { id: RecordId; fieldList: RecordId[] }) => {
      const { id, fieldList } = data;
      return Api.contracts.patch({ id, patch: { fields: fieldList } });
    },
    {
      onSuccess: async () => {
        await queryClient.invalidateQueries(contractsQueryKeys.all);
      },
    },
  );
};

export const useLoadContractMonitoringM = () => {
  return useMutation(contractsQueryKeys.loadMonitoring(), Api.contracts.loadMonitoring);
};

export const useGetContractPublicPdList = (contractId: RecordId) => {
  const { data, isLoading } = useGetContractByIdQ(contractId);
  return {
    data: data?.publicPD,
    isLoading,
  };
};
