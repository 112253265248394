import { InfoOutlined } from '@ant-design/icons';
import { Popover, PopoverProps } from 'antd';
import React from 'react';
import './styles.scss';

export type InfoPopupProps = PopoverProps;

const _InfoPopup: React.FC<InfoPopupProps> = (props) => {
  return (
    <Popover {...props}>
      <InfoOutlined className='info-popup' />
    </Popover>
  );
};
export const InfoPopup = React.memo(_InfoPopup);
