import { $isNotGuest } from '@/globalStorage/user';
import cn from 'classnames';
import { useStore } from 'effector-react';
import React from 'react';
import { AppStateWidget } from './components/AppStateWidget';
import { Footer } from './components/Footer';
import { Header } from './components/Header';
import './styles.scss';

type Props = {
  className?: string;
  style?: any;
  hideLogoName?: boolean;
  leftAddition?: React.ReactNode;
  hideFooter?: boolean;
  singlePage?: boolean;
  customFooter?: React.ReactNode;
};
export const MainLayout: React.FC<Props> = (props) => {
  const {
    className,
    style,
    children,
    hideLogoName,
    customFooter,
    leftAddition,
    hideFooter,
    singlePage,
  } = props;

  const isNotGuest = useStore($isNotGuest);

  return (
    <div
      className={cn('main-layout', className, { 'main-layout--single-page': singlePage })}
      style={style}
    >
      <Header
        className={'main-layout__header'}
        mainMenuProps={{
          additional: leftAddition,
          withoutText: hideLogoName,
        }}
      />
      <div className='main-layout__content'>{children}</div>
      {customFooter}

      {!hideFooter && <Footer className={'main-layout__footer'} />}
      {isNotGuest && <AppStateWidget className='main-layout__app-state' />}
    </div>
  );
};
