import React from 'react';
import cn from 'classnames';
import './styles.scss';
import { LogoIcon } from '@/components/customIcons/LogoIcon';
import { Link } from 'react-router-dom';
import { routes } from '@/resources/routes';

export type HeaderLogoTitleProps = {
  className?: string;
  style?: React.CSSProperties;
  withoutText?: boolean;
};

const _HeaderLogoTitle: React.FC<HeaderLogoTitleProps> = (props) => {
  const { className, style, withoutText } = props;

  return (
    <Link
      to={routes.contracts}
      className={cn(
        'header-logo-title',
        { 'header-logo-title--without-text': withoutText },
        className,
      )}
      style={style}
    >
      <LogoIcon className='header-logo-title__logo-icon' />
      <div className='header-logo-title__logo-text'>
        Actusland <sup className='header-logo-title__logo-text-beta'>β</sup>
      </div>
    </Link>
  );
};
export const HeaderLogoTitle = React.memo(_HeaderLogoTitle);
