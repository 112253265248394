import { GetTdPageRequest } from '@/api/Td/types';
import { RecordId } from '@/api/common/types';

export const tdsQueryKeys = {
  all: ['tds'] as const,
  pages: () => [...tdsQueryKeys.all, 'page'] as const,
  page: (data: GetTdPageRequest) => [...tdsQueryKeys.pages(), data] as const,
  details: () => [...tdsQueryKeys.all, 'detail'] as const,
  detail: (id: RecordId) => [...tdsQueryKeys.details(), id] as const,

  create: () => [...tdsQueryKeys.all, 'create'] as const,
};
