import React from 'react';
import { DaDataParty, DaDataSuggestion } from 'react-dadata';
import { OrganizationInput, OrganizationInputProps } from '../OrganizationInput';

export type OrganizationNameInputProps = {
  value?: string;
  onChange?: (value: string, suggestion?: DaDataSuggestion<DaDataParty>) => void;
  placeholder?: string;
} & Omit<OrganizationInputProps, 'value' | 'onChange'>;

const _OrganizationNameInput: React.FC<OrganizationNameInputProps> = ({
  value,
  onChange,
  placeholder,
  inputProps,
  ...orgProps
}) => {
  const onChangeInputHandler: React.FormEventHandler<HTMLInputElement> = (e) => {
    onChange && onChange(e.currentTarget.value);
  };

  const onChangeHandler = (suggestion?: DaDataSuggestion<DaDataParty>) => {
    if (suggestion) {
      onChange && onChange(suggestion.value, suggestion);
    } else {
      onChange && onChange('');
    }
  };

  return (
    <OrganizationInput
      inputProps={{ ...inputProps, value, onChange: onChangeInputHandler, placeholder }}
      {...orgProps}
      onChange={onChangeHandler}
    />
  );
};
export const OrganizationNameInput = React.memo(_OrganizationNameInput);
