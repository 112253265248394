import { DocumentFilterItem } from '@/components/ServerConfigTable/types';
import React from 'react';
import { FilterDropdownActions } from '../common/FilterDropdownActions';
import { FilterDropdownContentBase } from '../common/FilterDropdownContentBase';
import { FilterTypeOption, FilterTypeSelect } from '../common/FilterTypeSelect';
import { existOption, notExistOption } from './consts';
import { DocumentFilterType, DocumentFilterValue } from './types';

export type DocumentFilterDropdownProps = {
  className?: string;
  style?: React.CSSProperties;

  filter: DocumentFilterValue;
  onChangeFilter: (value: DocumentFilterItem) => void;
  onResetFilter: () => void;
};

const _DocumentFilterDropdown = (props: DocumentFilterDropdownProps) => {
  const { className, style, filter, onChangeFilter, onResetFilter } = props;

  React.useEffect(() => {
    setUpdatedFilterState({ filterAs: 'document', filterType: 'exist' });
  }, [filter]);

  const [updatedFilterState, setUpdatedFilterState] = React.useState<DocumentFilterItem | null>(
    null,
  );

  const filterTypeOptions = React.useMemo(() => {
    const options: FilterTypeOption<DocumentFilterType>[] = [existOption, notExistOption];
    return options;
  }, []);

  const [selectedFilterType, _setSelectedFilterType] = React.useState<DocumentFilterType>('exist');
  const changeFilterType = React.useCallback((filterType: DocumentFilterType) => {
    _setSelectedFilterType(filterType);
    if (filterType === 'exist' || filterType === 'notExist') {
      setUpdatedFilterState({
        filterAs: 'document',
        filterType,
      });
    } else {
      setUpdatedFilterState(null);
    }
  }, []);

  return (
    <FilterDropdownContentBase className={className} style={style}>
      <FilterTypeSelect
        onChangeSelectedFilterType={changeFilterType}
        selectedFilterType={selectedFilterType}
        filteredType={filter?.filterType ?? null}
        options={filterTypeOptions}
      />
      <FilterDropdownActions
        canChangeFilter={updatedFilterState !== null}
        hasFilters={filter !== null && filter !== undefined}
        onSubmitChanges={() => {
          if (updatedFilterState === null) {
            onResetFilter();
          } else {
            onChangeFilter(updatedFilterState);
          }
        }}
        onResetFilter={onResetFilter}
      />
    </FilterDropdownContentBase>
  );
};
export const DocumentFilterDropdown = React.memo(_DocumentFilterDropdown);
