import { RecordId } from '@/api/common/types';
import { useUpdateUserM } from '@/hooks/users';
import { Form, Input, message, Modal } from 'antd';
import React, { useCallback } from 'react';

export type EditUserPasswordModalProps =
  | {
      visible: false;
    }
  | ({ visible: true } & VisibleProps);

type VisibleProps = {
  userId: RecordId;
  onClose: () => void;
};
export const EditUserPasswordModal: React.FC<EditUserPasswordModalProps> = React.memo((props) => {
  const { visible } = props;

  if (!visible) {
    return null;
  }
  const { visible: _, ...visibleProps } = props;
  return <VisibleModal {...visibleProps} />;
});

export const VisibleModal: React.FC<VisibleProps> = React.memo((props) => {
  const { onClose, userId } = props;

  const { mutateAsync: updateUserM, isLoading: isLoadingUpdate } = useUpdateUserM();

  const [form] = Form.useForm();

  const onFormFinishHandler = useCallback(
    async ({ password }) => {
      try {
        await updateUserM({ id: userId, patch: { password } });
        message.success('Пароль успешно изменен');
      } catch {
        message.error('Произошла ошибка при изменении пароля');
      }
    },
    [updateUserM, userId],
  );

  return (
    <Modal
      visible={true}
      width={500}
      title={'Изменение пароля'}
      okText={'Сохранить изменения'}
      onOk={form.submit}
      okButtonProps={{ loading: isLoadingUpdate }}
      cancelText={'Отмена'}
      onCancel={onClose}
    >
      <Form form={form} onFinish={onFormFinishHandler} layout={'vertical'}>
        <Form.Item label='Новый пароль' name={'password'} required>
          <Input style={{ width: '100%' }} placeholder='Введите новый пароль' type={'password'} />
        </Form.Item>
      </Form>
    </Modal>
  );
});
