import { api } from '@/api/axiosInstance';
import { RecordId } from '@/api/common/types';
import {
  EmployeesCreateRequest,
  EmployeesCreateResponse,
  EmployeesGetByIdResponse,
  EmployeesGetPageRequest,
  EmployeesGetResponse,
  EmployeesPatchRequest,
  EmployeesPatchResponse,
} from './types';

export class EmployeesApi {
  get(params: EmployeesGetPageRequest) {
    return api.get<EmployeesGetResponse>('/employees', { params });
  }

  getById(id: RecordId) {
    return api.get<EmployeesGetByIdResponse>(`/employees/${id}`);
  }

  create(data: EmployeesCreateRequest) {
    return api.post<EmployeesCreateResponse>('/employees', data);
  }

  delete(id: RecordId) {
    return api.delete<void>(`/employees/${id}`);
  }

  patch(data: EmployeesPatchRequest) {
    return api.patch<EmployeesPatchResponse>(`/employees/${data.id}`, data.patch);
  }
}
