import { editCadObjectCoordinateService } from '@/globalStorage/cadObjects';
import { coordinateService } from '@/globalStorage/coordinates/newIndex';
import { PolylineView } from '@/pages/CoordsPage/CordsManager/CordsManagerCanvas/PolylineView';
import { ReloadOutlined, UploadOutlined } from '@ant-design/icons';
import { SvgCanvasSmart } from '@uspect-team/ant-ui-kit';
import { Button } from 'antd';
import cn from 'classnames';
import { useList, useStore } from 'effector-react';
import React from 'react';
import { RasterView } from './RasterView';
import './style.scss';
import { TpView } from './TpView';

type CordsManagerCanvasProps = {
  className?: string;
};

const _CordsManagerCanvas: React.FC<CordsManagerCanvasProps> = (props) => {
  const { className } = props;

  const tpList = useList(coordinateService.$tpList, { fn: (tp) => <TpView tp={tp} /> });

  const unselectedPolylineList = useList(coordinateService.$unselectedViewPolylineList, {
    getKey: (x) => x.id,
    fn: (polyline) => <PolylineView polyline={polyline} />,
  });

  const selectedPolyline = useStore(coordinateService.$selectedViewPolyline);

  const raster = useStore(coordinateService.$viewRaster);

  const scale = useStore(coordinateService.$scale);

  const centerPoint = useStore(coordinateService.$centerPoint);
  const saveCoordinateChangesIsLoading = useStore(
    editCadObjectCoordinateService.$saveCoordinateChangesIsLoading,
  );

  const haveChanges = useStore(coordinateService.$haveChanges);

  return (
    <div className={cn('cords-manager-canvas', className)}>
      <div className='cords-manager-canvas__actions-panel'>
        <Button
          icon={<UploadOutlined />}
          size='small'
          title={'Отправить изменения'}
          onClick={() => editCadObjectCoordinateService.saveCoordinateChanges()}
          disabled={saveCoordinateChangesIsLoading || !haveChanges}
        />
        <Button
          icon={<ReloadOutlined />}
          size='small'
          title={'Обновить карту'}
          onClick={() => coordinateService.refreshAreaParamsAndRasters()}
        />
      </div>
      <SvgCanvasSmart
        className='cords-manager-canvas__area'
        scale={scale}
        onChangeScale={coordinateService.setScale}
        centerPoint={centerPoint}
        onChangeCenterPoint={coordinateService.setCenterPoint}
        scaleMultiplier={1.5}
        width={700}
        height={500}
      >
        {raster && <RasterView {...raster} />}

        {tpList}

        {unselectedPolylineList}

        {selectedPolyline && <PolylineView polyline={selectedPolyline} />}
      </SvgCanvasSmart>
    </div>
  );
};

export const CordsManagerCanvas = React.memo(_CordsManagerCanvas);
