import { Space } from 'antd';
import React from 'react';
import { NavLink } from 'react-router-dom';
import type { TBoundaryMenuProps } from './types';

const menuItems = [
  { title: 'Сведения о работах и участках', link: '/info' },
  { title: 'Документы', link: '/docs' },
];

const _BoundaryMenu = ({ id }: TBoundaryMenuProps) => {
  return (
    <Space className='management-menu' size={30}>
      {menuItems.map((item) => (
        <NavLink
          className='management-menu__item '
          to={'/boundary/' + id + item.link}
          key={item.link}
        >
          {item.title}
        </NavLink>
      ))}
    </Space>
  );
};

export const BoundaryMenu = React.memo(_BoundaryMenu);
