import { removeAuthInfo } from './../globalStorage/user/index';
import axios from 'axios';
import { message } from 'antd';
import { $accessToken, refreshAuthFx } from '@/globalStorage/user';

export const ApiUrl = `${process.env.REACT_APP_API_URL}/api`;

const blank_api = axios.create({
  baseURL: ApiUrl,
});

const api = axios.create({
  baseURL: ApiUrl,
  withCredentials: true,
});

api.interceptors.request.use((config) => {
  const newConfig = { ...config };
  const accessToken = $accessToken.getState();
  if (accessToken) {
    newConfig.headers['x-access-token'] = accessToken;
  }
  return newConfig;
});

api.interceptors.response.use(
  (config) => config,
  async (error) => {
    if (error.response?.status === 401) {
      try {
        await refreshAuthFx();

        return api.request(error.config); //Запуск запроса повторно
      } catch (e) {
        removeAuthInfo();
        message.error('Вы не авторизованы. Доступ запрещен');
      }
    }

    if (error.response?.data?.error?.message) {
      error.message = error.response.data.error.message;
    } else {
      error.message = `Неизвестная ошибка (${error.message})`;
    }

    return Promise.reject(error);
  },
);

export { api, blank_api };
