import React, { Ref } from 'react';
import { Select, SelectProps } from 'antd';
import { RefSelectProps } from 'antd/lib/select';
import { useGetAllOrganizationsQ } from '@/hooks/directory/organizations';

type Props = SelectProps<string> & { ref?: Ref<RefSelectProps> | undefined };
export const SelectOrganization: React.FC<Props> = React.memo((props) => {
  const { data: allOrganizations, isLoading } = useGetAllOrganizationsQ();
  const { ...otherProps } = props;

  return (
    <Select
      showSearch
      optionFilterProp={'children'}
      filterOption={(input, option) => {
        const childrenText = option?.children as string | null | undefined;
        return childrenText?.toLowerCase().includes(input.toLowerCase()) || false;
      }}
      loading={isLoading}
      placeholder={'Выберите организацию'}
      {...otherProps}
    >
      {allOrganizations?.map((i) => (
        <Select.Option key={i._id} value={i._id}>
          {i.title}
        </Select.Option>
      ))}
    </Select>
  );
});
