import React from 'react';
import { Button, Form, InputNumber } from 'antd';
import { MinusCircleOutlined, PlusOutlined } from '@ant-design/icons';
import { FormListFieldData, FormListOperation } from 'antd/lib/form/FormList';
import './styles.scss';
import { TpRecordResponse } from '@/api/Coordinates/Tp/types';

type FormListRenderCreatorType = (
  isAutoGenWgs: boolean,
) => (
  fields: FormListFieldData[],
  operation: FormListOperation,
  meta: { errors: React.ReactNode[]; warnings: React.ReactNode[] },
) => React.ReactNode;

const PlusOutlinedIcon = <PlusOutlined />;
const FormListRenderCreator: FormListRenderCreatorType =
  (isAutoGenWgs) =>
  (fields, { add, remove }, { errors }) => {
    return (
      <>
        {fields.map(({ key, name }) => {
          return (
            <CoordItem
              key={key}
              name={name}
              fieldKey={name}
              remove={remove}
              isAutoGenWgs={isAutoGenWgs}
            />
          );
        })}
        <tr>
          <td colSpan={5} className={'coords-table__error-td'}>
            {errors}
          </td>
        </tr>
        <tr>
          <td colSpan={5}>
            <Form.Item>
              <Button type='dashed' onClick={() => add({})} block icon={PlusOutlinedIcon}>
                Добавить точку
              </Button>
            </Form.Item>
          </td>
        </tr>
      </>
    );
  };
type CoordItemProps = {
  name: number;
  fieldKey: number;
  isAutoGenWgs: boolean;
  remove: (index: number | number[]) => void;
} & Pick<FormListFieldData, never>;
const _CoordItem: React.FC<CoordItemProps> = (props) => {
  const { name, fieldKey, remove, isAutoGenWgs, ...restField } = props;

  const isNotRequired = React.useMemo(() => {
    return fieldKey > 3;
  }, [fieldKey]);

  const onRemove = React.useCallback(() => {
    return remove(fieldKey);
  }, [remove, fieldKey]);
  return (
    <tr>
      <td>{`${fieldKey + 1}:`}</td>
      <td className={'coords-table__td'}>
        <Form.Item {...restField} name={[name, 'mskX']} fieldKey={[fieldKey, 'mskX']}>
          <InputNumber className={'coords-table__input'} />
        </Form.Item>
      </td>
      <td className={'coords-table__td'}>
        <Form.Item {...restField} name={[name, 'mskY']} fieldKey={[fieldKey, 'mskY']}>
          <InputNumber className={'coords-table__input'} />
        </Form.Item>
      </td>
      <td className={'coords-table__td'}>
        <Form.Item {...restField} name={[name, 'wgsX']} fieldKey={[fieldKey, 'wgsX']}>
          <InputNumber className={'coords-table__input'} disabled={isAutoGenWgs} />
        </Form.Item>
      </td>
      <td className={'coords-table__td'}>
        <Form.Item {...restField} name={[name, 'wgsY']} fieldKey={[fieldKey, 'wgsY']}>
          <InputNumber className={'coords-table__input'} disabled={isAutoGenWgs} />
        </Form.Item>
      </td>
      {isNotRequired && (
        <td className={'coords-table__td'}>
          <MinusCircleOutlined onClick={onRemove} />
        </td>
      )}
    </tr>
  );
};
export const CoordItem = React.memo(_CoordItem);

type CoordsTableProps = {
  isAutoGenWgs: boolean;
};
const _CoordsTable: React.FC<CoordsTableProps> = ({ isAutoGenWgs }) => {
  const FormListRender = React.useMemo(() => {
    return FormListRenderCreator(isAutoGenWgs);
  }, [isAutoGenWgs]);
  return (
    <table className={'coords-table'}>
      <thead>
        <tr>
          <th />
          <th>МСК X</th>
          <th>МСК Y</th>
          <th>WGS X</th>
          <th>WGS Y</th>
          <th />
        </tr>
      </thead>
      <tbody>
        <Form.List
          name='coords'
          initialValue={[{}, {}, {}, {}]}
          rules={[
            {
              validator: (_, value: TpRecordResponse['coords']) => {
                let isError = value.some((x) => x.mskX === undefined || x.mskY === undefined);
                if (!isAutoGenWgs) {
                  isError = value.some((x) => x.wgsX === undefined || x.wgsY === undefined);
                }
                if (isError) {
                  return Promise.reject(
                    'Все поля как минимум для четырех точек должны быть заполнены',
                  );
                }
                return Promise.resolve();
              },
            },
          ]}
        >
          {FormListRender}
        </Form.List>
      </tbody>
    </table>
  );
};
export const CoordsTable = React.memo(_CoordsTable);
