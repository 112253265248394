import { RecordId } from '@/api/common/types';
import { GetColumnsPageRequest } from '@/api/Dictionary/Columns/types';

export const displayModsQueryKeys = {
  all: ['display-mods'] as const,
  pages: () => [...displayModsQueryKeys.all, 'page'] as const,
  page: (data: GetColumnsPageRequest) => [...displayModsQueryKeys.pages(), data] as const,

  details: () => [...displayModsQueryKeys.all, 'detail'] as const,
  detail: (id: RecordId) => [...displayModsQueryKeys.details(), id] as const,

  create: () => [...displayModsQueryKeys.all, 'create'] as const,
  patch: () => [...displayModsQueryKeys.all, 'patch'] as const,
  delete: () => [...displayModsQueryKeys.all, 'delete'] as const,
};
