import { ContractsApi } from './Contracts';
import { CadastralObjectApi } from '@/api/CadObjects';
import { FilesApi } from './Files';
import { DictionaryApi } from './Dictionary';
import { UsersApi } from './Users';
import { TdApi } from './Td';
import { CoordinatesApi } from '@/api/Coordinates';
import { StatisticsApi } from '@/api/Statistics';
import { CompaniesApi } from './Companies';

export class Api {
  static contracts = new ContractsApi();
  static cadastralObjects = new CadastralObjectApi();
  static files = new FilesApi();
  static dictionary = new DictionaryApi();
  static users = new UsersApi();
  static td = new TdApi();
  static coordinates = new CoordinatesApi();
  static statistics = new StatisticsApi();
  static companies = new CompaniesApi();
}
