import { RecordId } from '@/api/common/types';
import { FileRecordResponse } from '@/api/Files/types';
import { AntdLabelMimic } from '@/components/formHelpers/AntdLabelMimic';
import { CadObjectNumbersSelector } from '@/components/modals/cadObjects/common/CadObjectNumbersSelector';
import { StepContent } from '@/components/StepContent';
import { Button, Modal, Space, Steps } from 'antd';
import React from 'react';
import { MonitoringGenResultStep } from './MonitoringGenResultStep';
import { MonitoringGenStatusStep } from './MonitoringGenStatusStep';

export type MonitoringGenModalProps =
  | {
      visible: false;
    }
  | ({ visible: true } & VisibleProps);
type VisibleProps = {
  contractId: RecordId;
  initialCadObjectNumbers?: number[];
  onClose: () => void;
};

const _MonitoringGenModal: React.FC<MonitoringGenModalProps> = (props) => {
  const { visible } = props;

  if (!visible) {
    return null;
  }

  const { visible: _, ...visibleProps } = props;
  return <VisibleModal {...visibleProps} />;
};
export const MonitoringGenModal = React.memo(_MonitoringGenModal);

const _VisibleModal: React.FC<VisibleProps> = (props) => {
  const { onClose, initialCadObjectNumbers, contractId } = props;

  const [currentStep, setCurrentStep] = React.useState(0);
  const onClickNextStepHandler = React.useCallback(() => {
    return setCurrentStep((prevState) => prevState + 1);
  }, []);
  const [secondStepCompleteInfo, setSecondStepCompleteInfo] =
    React.useState<FileRecordResponse | null>(null);

  const [cadObjectNumbers, setCadObjectNumbers] = React.useState<number[]>([]);
  const [genForAll, setGenForAll] = React.useState<boolean>(
    initialCadObjectNumbers === undefined || initialCadObjectNumbers.length === 0 ? true : false,
  );

  const onCompleteSecondStep = React.useCallback((secondStepInfo: FileRecordResponse | null) => {
    setSecondStepCompleteInfo(secondStepInfo);
    setCurrentStep(2);
  }, []);

  const footer = React.useMemo(() => {
    switch (currentStep) {
      case 0:
        return (
          <Space>
            <Button onClick={onClose}>Отмена</Button>
            <Button
              type={'primary'}
              onClick={onClickNextStepHandler}
              disabled={!genForAll && cadObjectNumbers.length === 0}
            >
              Следующий шаг
            </Button>
          </Space>
        );
      case 1:
        return (
          <Space>
            <Button onClick={onClose}>Отмена</Button>
            <Button
              type={'primary'}
              onClick={onClickNextStepHandler}
              disabled={secondStepCompleteInfo === null}
            >
              К итогам
            </Button>
          </Space>
        );
      case 2:
        return (
          <Button type={'primary'} onClick={onClose}>
            Завершить
          </Button>
        );
    }
  }, [
    currentStep,
    onClickNextStepHandler,
    genForAll,
    cadObjectNumbers,
    onClose,
    secondStepCompleteInfo,
  ]);

  return (
    <Modal
      width={700}
      visible={true}
      onCancel={onClose}
      title={'Мастер генерации мониторинга'}
      footer={footer}
    >
      <Steps size='small' current={currentStep}>
        <Steps.Step title='Выбор объектов' />
        <Steps.Step title='Статус генерации' />
        <Steps.Step title='Результат' />
      </Steps>
      {currentStep === 0 && (
        <StepContent>
          <AntdLabelMimic>Заполните номера объектов (или вставьте их из Excel)</AntdLabelMimic>
          <CadObjectNumbersSelector
            initNumbers={initialCadObjectNumbers}
            selectAll={{ value: genForAll, onChange: setGenForAll }}
            onChangeNumbers={setCadObjectNumbers}
          />
        </StepContent>
      )}
      {currentStep === 1 && (
        <MonitoringGenStatusStep
          genForAll={genForAll}
          contractId={contractId}
          cadObjectNumbers={cadObjectNumbers}
          onCompleteStep={onCompleteSecondStep}
        />
      )}
      {currentStep === 2 && secondStepCompleteInfo !== null && (
        <MonitoringGenResultStep secondStepCompleteInfo={secondStepCompleteInfo} />
      )}
    </Modal>
  );
};
const VisibleModal = React.memo(_VisibleModal);
