import React from 'react';
import { UserInfoRecordResponse } from '@/api/Users/types';
import { DictCrudProps } from '@/components/DictCrudPage';
import { useDeleteUserM, useGetUserPageQ } from '@/hooks/users';
import { DateView } from '@uspect-team/ant-ui-kit';
import { roleToView } from './utils';

const getName = (record: UserInfoRecordResponse) => {
  return `${record.name} ${record.secondName}`;
};

export const getUsersPageConfig = (params: {
  onCreateButtonClick: () => void;
  onEditButtonClick: (record: UserInfoRecordResponse) => void;
}): DictCrudProps<UserInfoRecordResponse> => ({
  getName: (record) => record.name,
  texts: {
    createButtonText: 'Создать нового пользователя',
    delete: {
      confirmTitle: (record) =>
        `Вы действительно хотите безвозвратно удалить пользователя (${getName(record)})`,
      successMessage: (record) => `Пользователь успешно удален (${getName(record)})`,
      errorMessage: (record) => `При удалении группы колонок произошла ошибка (${getName(record)})`,
    },
  },
  pagination: { type: 'server', getPageQ: useGetUserPageQ },
  deleteRecordM: useDeleteUserM,
  onCreateButtonClick: params.onCreateButtonClick,
  onEditButtonClick: params.onEditButtonClick,
  columns: [
    {
      key: 'name',
      dataIndex: 'name',
      title: 'Имя',
    },
    {
      key: 'secondName',
      dataIndex: 'secondName',
      title: 'Фамилия',
    },
    {
      key: 'patronymic',
      dataIndex: 'patronymic',
      title: 'Отчество',
    },
    {
      key: 'email',
      dataIndex: 'email',
      title: 'Email',
    },
    {
      key: 'phone',
      dataIndex: 'phone',
      title: 'Телефон',
    },
    {
      key: 'role',
      dataIndex: 'role',
      title: 'Роль',
      render: (_, record) => roleToView(record.permissions.role),
    },
    {
      key: 'createdAt',
      dataIndex: 'createdAt',
      title: 'Дата создания',
      render: (value) => {
        return <DateView value={value} />;
      },
    },
    {
      key: 'updatedAt',
      dataIndex: 'updatedAt',
      title: 'Дата изменения',
      render: (value) => {
        return <DateView value={value} />;
      },
    },
  ],
});
