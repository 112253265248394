import React, { Ref } from 'react';
import { Select, SelectProps } from 'antd';
import { RefSelectProps } from 'antd/lib/select';
import { useGetAllEmployeesQ } from '@/hooks/directory/employees';

type Props = SelectProps<string> & { ref?: Ref<RefSelectProps> | undefined };
export const SelectAgents: React.FC<Props> = React.memo((props) => {
  const { ...otherProps } = props;

  const { data: allEmployees, isLoading } = useGetAllEmployeesQ();

  return (
    <Select
      mode={'multiple'}
      allowClear
      showSearch
      optionFilterProp={'children'}
      filterOption={(input, option) => {
        const childrenText = option?.children as string | null | undefined;
        return childrenText?.toLowerCase().includes(input.toLowerCase()) || false;
      }}
      loading={isLoading}
      placeholder={'Выберите представителей'}
      {...otherProps}
    >
      {allEmployees?.map((i) => (
        <Select.Option key={i._id} value={i._id}>
          {`${i.lastName ? i.lastName + ' ' : ''}${i.firstName ? i.firstName + ' ' : ''}${
            i.patronymic ? i.patronymic + ' ' : ''
          }`}
        </Select.Option>
      ))}
    </Select>
  );
});
