import { PdDocumentField } from '@/components/inputs/PdDocumentField';
import { errorMessages } from '@/utils/errorMessages';
import { Col, DatePicker, Form, Input, InputNumber, Row } from 'antd';
import React from 'react';
import { SelectAgents } from '../../SelectAgents';
import { SelectCalculateMethod } from '../../SelectCalculateMethod';
import { SelectCustomerCompany } from '../../SelectCustomerCompany';
import { SelectEngineer } from '../../SelectEngineer';
import { TabContent } from '../../TabContent';

const _TechnokadDataTabContent: React.FC = () => {
  return (
    <TabContent>
      <Form.Item
        name={'customerCompany'}
        label={'Заявители'}
        rules={[{ required: true, message: errorMessages.required }]}
      >
        <SelectCustomerCompany />
      </Form.Item>

      <Form.Item
        name={'agents'}
        label={'Представители'}
        rules={[{ required: true, message: errorMessages.required }]}
      >
        <SelectAgents />
      </Form.Item>

      <Form.Item
        name={'engineer'}
        label={'Кадастровый инженер'}
        rules={[{ required: true, message: errorMessages.required }]}
      >
        <SelectEngineer />
      </Form.Item>

      <Row gutter={16}>
        <Col span={12}>
          <Form.Item
            name={'calculationMethod'}
            label={'Метод вычисления координат'}
            rules={[{ required: true, message: errorMessages.required }]}
          >
            <SelectCalculateMethod />
          </Form.Item>
        </Col>
        <Col span={12}>
          <Form.Item
            name={'calculationAccuracy'}
            label={'Погрешность вычисления координат'}
            rules={[{ required: true, message: errorMessages.required }]}
          >
            <InputNumber min={0} style={{ width: '100%' }} />
          </Form.Item>
        </Col>
      </Row>
      <Row gutter={16}>
        <Col span={12}>
          <Form.Item
            name={'attorney'}
            label={'Доверенность'}
            // rules={[{ required: true, message: errorMessages.required }]}
          >
            <PdDocumentField />
          </Form.Item>
        </Col>
        <Col span={12}>
          <Form.Item
            name={'order'}
            label={'Приказ'}
            // rules={[{ required: true, message: errorMessages.required }]}
          >
            <PdDocumentField />
          </Form.Item>
        </Col>
      </Row>
      <Row gutter={16}>
        <Col span={12}>
          <Form.Item name='agreementName' label='Договор номер'>
            <Input style={{ width: '100%' }} />
          </Form.Item>
        </Col>
        <Col span={12}>
          <Form.Item name='agreementDate' label='Договор дата'>
            <DatePicker placeholder='Договор дата' />
          </Form.Item>
        </Col>
      </Row>
      <Form.Item
        name={'restrictions'}
        label={'Ограничения устанавливаемой ОЗ согласно нормативному акту'}
        // rules={[{ required: true, message: errorMessages.required }]}
      >
        <Input.TextArea rows={3} />
      </Form.Item>
    </TabContent>
  );
};
export const TechnokadDataTabContent = React.memo(_TechnokadDataTabContent);
