import React, { useCallback, useState } from 'react';
import { message, Modal, notification, Space } from 'antd';
import { RcFile } from 'antd/lib/upload';
import { FileLoader } from '@uspect-team/ant-ui-kit';
import { FileCard } from '@/components/FileCard';
import { AntdLabelMimic } from '@/components/formHelpers/AntdLabelMimic';
import { ExternalLink } from '@/components/ExternalLink';
import { useCadObjectsImportFromExcelM } from '@/hooks/cadastralObjects';
import { RecordId } from '@/api/common/types';

export type ImportCadObjectsFromXlsxModalProps =
  | {
      visible: false;
    }
  | ({ visible: true } & VisibleProps);
type VisibleProps = {
  contractId: RecordId;
  onClose: () => void;
};

export const ImportCadObjectsFromXlsxModal: React.FC<ImportCadObjectsFromXlsxModalProps> =
  React.memo((props) => {
    const { visible } = props;

    if (!visible) {
      return null;
    }

    const { visible: _, ...visibleProps } = props;
    return <VisibleModal {...visibleProps} />;
  });

export const VisibleModal: React.FC<VisibleProps> = React.memo((props) => {
  const { onClose, contractId } = props;
  const [xlsxFile, setXlsxFile] = useState<RcFile | null>(null);
  const { mutateAsync: importFromExcelM, isLoading: isLoadingImportFromExcel } =
    useCadObjectsImportFromExcelM();

  const onImport = useCallback(async () => {
    if (!xlsxFile) {
      message.error('Пожалуйста, загрузите excel-файл');
      return;
    }
    try {
      await importFromExcelM({ contractId, file: xlsxFile });
      message.success('Кадастровые объекты успешно импортированны из excel-файла');
      onClose();
    } catch (e) {
      notification.open({
        type: 'error',
        message: 'Произошла ошибка при импроте',
        description: 'Пожалуйста, проверте, правильно ли заполненен excel-файл',
      });
    }
  }, [xlsxFile, contractId, importFromExcelM, onClose]);

  return (
    <Modal
      visible={true}
      onCancel={onClose}
      title={'Импорт объектов контракта'}
      onOk={onImport}
      okText={'Импортировать'}
      okButtonProps={{ disabled: xlsxFile === null, loading: isLoadingImportFromExcel }}
    >
      <Space direction={'vertical'}>
        <AntdLabelMimic addition={<ExternalLink path={'#'}>Пример Excel-файла</ExternalLink>}>
          Загрузите Excel-файл с данными
        </AntdLabelMimic>

        {xlsxFile && (
          <FileCard
            title={'Загружаемый файл'}
            file={{ name: xlsxFile.name, size: xlsxFile.size, uploadAt: 'now' }}
            onDelete={() => setXlsxFile(null)}
          />
        )}

        <FileLoader allowedFiles={[['xlsx']]} onUpload={setXlsxFile} />
      </Space>
    </Modal>
  );
});
