import { MaskedStringInput } from '@uspect-team/ant-ui-kit';
import React from 'react';
import { MaskedStringInputProps } from '@uspect-team/ant-ui-kit/dist/Inputs/MaskedStringInput';

const mask: MaskedStringInputProps['mask'] = [
  /\d/,
  /\d/,
  /\d/,
  '-',
  /\d/,
  /\d/,
  /\d/,
  '-',
  /\d/,
  /\d/,
  /\d/,
  ' ',
  /\d/,
  /\d/,
];

type SnilsInputProps = Omit<MaskedStringInputProps, 'mask'>;

const _SnilsInput: React.FC<SnilsInputProps> = (props) => {
  return <MaskedStringInput mask={mask} placeholder={'123-456-789 10'} {...props} />;
};

export const SnilsInput = React.memo(_SnilsInput);
