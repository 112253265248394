import React, { useState } from 'react';
import { Button, Modal, Space, Steps } from 'antd';
import { StepContent } from '@/components/StepContent';
import { AntdLabelMimic } from '@/components/formHelpers/AntdLabelMimic';
import './styles.scss';
import { CadObjectNumbersSelector } from '../common/CadObjectNumbersSelector';
import { BatchFileDownloadSelectFileTypeStep } from './BatchFileDownloadSelectFileTypeStep';
import { BatchFileDownloadResultStep } from './BatchFileDownloadResultStep';

export type BatchFileDownloadWizardModalProps =
  | {
      visible: false;
    }
  | ({ visible: true } & VisibleProps);
type VisibleProps = {
  initialCadObjectNumbers?: number[];
  contractId?: string;
  onClose: () => void;
};

const _BatchFileDownloadWizardModal: React.FC<BatchFileDownloadWizardModalProps> = (props) => {
  const { visible } = props;

  if (!visible) {
    return null;
  }

  const { visible: _, ...visibleProps } = props;
  return <VisibleModal {...visibleProps} />;
};
export const BatchFileDownloadWizardModal = React.memo(_BatchFileDownloadWizardModal);

const _VisibleModal: React.FC<VisibleProps> = (props) => {
  const { onClose, initialCadObjectNumbers, contractId } = props;

  const [currentStep, setCurrentStep] = React.useState(0);
  const [disabled, setDisabled] = React.useState(false);
  const onClickNextStepHandler = React.useCallback(() => {
    return setCurrentStep((prevState) => prevState + 1);
  }, []);
  const [fileType, setFileType] = useState<string[]>([]);

  const [cadObjectNumbers, setCadObjectNumbers] = React.useState<number[]>([]);
  // const [autocompleteAll, setAutocompleteAll] = React.useState<boolean>(false);

  const footer = React.useMemo(() => {
    switch (currentStep) {
      case 0:
        return (
          <Space>
            <Button onClick={onClose}>Отмена</Button>
            <Button
              type={'primary'}
              onClick={onClickNextStepHandler}
              disabled={cadObjectNumbers.length === 0}
            >
              Следующий шаг
            </Button>
          </Space>
        );
      case 1:
        return (
          <Space>
            <Button onClick={onClose}>Отмена</Button>
            <Button type={'primary'} onClick={onClickNextStepHandler}>
              Начать загрузку
            </Button>
          </Space>
        );
      case 2:
        return (
          <Space>
            <Button onClick={onClose}>Отмена</Button>
            <Button disabled={disabled} type={'primary'} onClick={onClose}>
              Завершить
            </Button>
          </Space>
        );
    }
  }, [currentStep, onClose, onClickNextStepHandler, cadObjectNumbers.length, disabled]);

  return (
    <Modal
      width={1000}
      visible={true}
      onCancel={onClose}
      title={'Пакетное скачивание файлов'}
      footer={footer}
    >
      <Steps size='small' current={currentStep}>
        <Steps.Step title='Выбор объектов' />
        <Steps.Step title='Выбор типа файлов' />
        <Steps.Step title='Результат' />
      </Steps>
      {currentStep === 0 && (
        <StepContent>
          <AntdLabelMimic>Заполните номера объектов (или вставьте их из Excel)</AntdLabelMimic>
          <CadObjectNumbersSelector
            initNumbers={initialCadObjectNumbers}
            onChangeNumbers={setCadObjectNumbers}
          />
        </StepContent>
      )}
      {currentStep === 1 && (
        <BatchFileDownloadSelectFileTypeStep fileType={fileType} onChangeFileType={setFileType} />
      )}
      {currentStep === 2 && (
        <BatchFileDownloadResultStep
          nums={initialCadObjectNumbers || []}
          contractId={contractId || ''}
          types={fileType}
          setDisabled={setDisabled}
        />
      )}
    </Modal>
  );
};
const VisibleModal = React.memo(_VisibleModal);
