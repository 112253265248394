import { createEvent, createStore, Store } from 'effector';

export const createListIsNotEmpty = <Type>(listStore: Store<Type[]>) => {
  return listStore.map((x) => x.length > 0);
};

export const createList = <Type>(initValue: Type[]) => {
  const $list = createStore(initValue);

  const setList = createEvent<Type[]>();
  const resetList = createEvent();
  const push = createEvent<Type>();

  $list
    .on(setList, (_, payload) => payload)
    .on(push, (state, payload) => [...state, payload])
    .reset(resetList);

  return {
    $list,
    setList,
    resetList,
    push,
  };
};
