import React from 'react';

export type RasterViewProps = {
  coords: { x: number; y: number }[];
  imgUrl: string;
};

const _RasterView: React.FC<RasterViewProps> = ({ coords, imgUrl }) => {
  const minX = Math.min(...coords.map((c) => c.x));
  const maxX = Math.max(...coords.map((c) => c.x));
  const minY = Math.min(...coords.map((c) => c.y));
  const maxY = Math.max(...coords.map((c) => c.y));

  const width = maxX - minX;
  const height = maxY - minY;

  const topLeftSwgX = minX;
  const topLeftSwgY = -maxY;

  return (
    <image
      x={topLeftSwgX}
      y={topLeftSwgY}
      width={width}
      height={height}
      href={imgUrl}
      opacity={0.5}
    />
  );
};
export const RasterView = React.memo(_RasterView);
