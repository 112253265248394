import { RecordId } from '@/api/common/types';
import { createApi, createStore } from 'effector';
import { CoordinatesSelection } from './types';
export const createCoordinatesSelection = () => {
  const $coordinatesSelection = createStore<CoordinatesSelection>({
    type: 'noSelect',
  });

  const { selectPolyline, selectPoint, selectTp, resetSelect } = createApi($coordinatesSelection, {
    selectPolyline: (_, payload: { polylineId: RecordId }) => ({
      type: 'polyline',
      payload: {
        polylineId: payload.polylineId,
      },
    }),
    selectPoint: (_, payload: { polylineId: RecordId; pointId: RecordId }) => ({
      type: 'point',
      payload: {
        polylineId: payload.polylineId,
        pointId: payload.pointId,
      },
    }),
    selectTp: (_, payload: { tpId: RecordId }) => ({
      type: 'tp',
      payload: {
        tpId: payload.tpId,
      },
    }),
    resetSelect: () => ({ type: 'noSelect' }),
  });

  return {
    $coordinatesSelection,
    selectPolyline,
    selectPoint,
    selectTp,
    resetSelect,
  };
};
