import { RecordId } from '@/api/common/types';
import { EllipsisOutlined } from '@ant-design/icons';
import { Button, Dropdown } from 'antd';
import React from 'react';
import { DetailStatisticOverlay } from './DetailStatisticOverlay';

export type DetailStatisticButtonProps = {
  className?: string;
  style?: React.CSSProperties;
  contractId: RecordId;
};

const _DetailStatisticButton: React.FC<DetailStatisticButtonProps> = (props) => {
  const { contractId } = props;
  const [isVisibleOverlay, setIsVisibleOverlay] = React.useState<boolean>(false);

  return (
    <Dropdown
      trigger={['click']}
      overlay={<DetailStatisticOverlay contractId={contractId} />}
      visible={isVisibleOverlay}
      onVisibleChange={setIsVisibleOverlay}
    >
      <Button className='statistic-bar__btn statistic-bar_detail-btn' icon={<EllipsisOutlined />} />
    </Dropdown>
  );
};
export const DetailStatisticButton = React.memo(_DetailStatisticButton);
