import {
  BaseServerChildColumnType,
  ExternalLinkServerChildColumnAddition,
  TableRecordType,
} from '../../types';
import React from 'react';
import { ControlledTableChildType } from '@uspect-team/ant-ui-kit/dist/Tables/ControlledTable/types';
import { ExternalLink } from '../../../ExternalLink';
import { EmptyValue } from '@uspect-team/ant-ui-kit';

export const getExternalLinkColumnAddition = (
  strSerCol: BaseServerChildColumnType & ExternalLinkServerChildColumnAddition,
): Partial<ControlledTableChildType<TableRecordType>> => {
  const { width } = strSerCol;
  return {
    align: 'center',
    width: width ?? 200,
    render: (value) => {
      const link: string | undefined = value?.fileUri;
      const label: string | undefined = value?.fileName || link;
      if (!link) {
        return <EmptyValue />;
      }
      return (
        <ExternalLink path={link} ellipsis>
          {label}
        </ExternalLink>
      );
    },
  };
};
