import moment from 'moment';

export function getDayRange(
  date: Date | string,
  utc = true,
): { beforeDate: Date; afterDate: Date } {
  return {
    beforeDate: getBeforeDate(date, utc),
    afterDate: getAfterDate(date, utc),
  };
}

export function getBeforeDate(date: Date | string, utc = true): Date {
  return moment(date)
    .set('hour', 0)
    .set('minute', 0)
    .set('second', 0)
    .set('millisecond', 0)
    .utc(utc)
    .toDate();
}

export function getAfterDate(date: Date | string, utc = true): Date {
  return moment(date)
    .set('hour', 23)
    .set('minute', 59)
    .set('second', 59)
    .set('millisecond', 999)
    .utc(utc)
    .toDate();
}

export const formatMilliseconds = (millis: number) => {
  const duration = moment.duration(millis, 'milliseconds');
  const hours = duration.hours();
  const minutes = duration.minutes();
  const seconds = duration.seconds();

  return `${hours > 0 ? `${hours} ч. ` : ''}${minutes > 0 ? `${minutes} м. ` : ''}${
    seconds > 0 ? `${seconds} c.` : ''
  }`;
};
