import React from 'react';
import cn from 'classnames';
import { fileSizeToString } from '@/utils/fileSizeToString';
import './styles.scss';
import { Button, Tooltip } from 'antd';
import { DeleteOutlined } from '@ant-design/icons';
import { formatDateToString } from '@uspect-team/ant-ui-kit';

type File = {
  name: string;
  size: number;
  link?: string;
  uploadAt?: Date | 'now';
};

type Props = {
  className?: string;
  style?: any;
  file: File;
  title?: string;
  onDelete?: () => void;
};
export const FileCard: React.FC<Props> = (props) => {
  const { className, style, title, file, onDelete } = props;

  return (
    <div
      className={cn('file-card', className, { 'file-card--with-no-title': title === undefined })}
      style={style}
    >
      {title && <div className='file-card__title'>{title}</div>}
      {file.link ? (
        <Tooltip title={file.name}>
          <a className={'file-card__name'} href={file.link} target={'_blank'} rel={'noreferrer'}>
            {file.name}
          </a>
        </Tooltip>
      ) : (
        <Tooltip title={file.name}>
          <div className={'file-card__name'}>{file.name}</div>
        </Tooltip>
      )}

      <div className='file-card__size'>{`(${fileSizeToString(file.size)})`}</div>
      {file.uploadAt && (
        <div className='file-card__upload-on'>
          {file.uploadAt === 'now'
            ? 'Загружен только что'
            : `Загружен ${formatDateToString(file.uploadAt)}`}
        </div>
      )}
      {!!onDelete && (
        <div className={'file-card__delete'}>
          <Button type={'text'} danger icon={<DeleteOutlined />} onClick={onDelete} />
        </div>
      )}
    </div>
  );
};
