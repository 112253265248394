import React, { useCallback, useState } from 'react';
import {
  CreateColumnGroupModal,
  CreateColumnGroupProps,
} from '@/components/modals/columnGroups/CreateColumnGroupModal';
import {
  EditColumnGroupModal,
  EditColumnGroupModalProps,
} from '@/components/modals/columnGroups/EditColumnGroupModal';
import { DictCrudPage } from '@/components/DictCrudPage';
import { getColumnGroupPageConfig } from './config';
import { ColumnGroupRecordResponse } from '@/api/Dictionary/ColumnGroups/types';

const _ColumnGroupPage: React.FC = () => {
  const [createColumnGroupState, setCreateColumnGroupState] = useState<CreateColumnGroupProps>({
    visible: false,
  });

  const [editColumnGroupModalState, setEditColumnGroupModalState] =
    useState<EditColumnGroupModalProps>({
      visible: false,
    });

  const onCreateButtonClickHandler = useCallback(() => {
    setCreateColumnGroupState({
      visible: true,
      onClose: () => setCreateColumnGroupState({ visible: false }),
    });
  }, []);

  const onEditButtonClickHandler = useCallback((record: ColumnGroupRecordResponse) => {
    setEditColumnGroupModalState({
      visible: true,

      onClose: () => setEditColumnGroupModalState({ visible: false }),
      columnId: record._id,
    });
  }, []);

  const columnGroupPageConfig = React.useMemo(() => {
    return getColumnGroupPageConfig({
      onCreateButtonClick: onCreateButtonClickHandler,
      onEditButtonClick: onEditButtonClickHandler,
    });
  }, [onCreateButtonClickHandler, onEditButtonClickHandler]);

  return (
    <>
      <DictCrudPage {...columnGroupPageConfig} />

      <CreateColumnGroupModal {...createColumnGroupState} />
      <EditColumnGroupModal {...editColumnGroupModalState} />
    </>
  );
};

export const ColumnGroupPage = React.memo(_ColumnGroupPage);
