import { Input, Modal, Table } from 'antd';
import React, { useEffect } from 'react';
import './styles.scss';

const validLineRegex = /^(\s*[0-9]*[.,]?[0-9]+\s*\t?){2}$/;

export type CoordsImportModalProps =
  | {
      visible: false;
    }
  | ({ visible: true } & VisibleProps);
type VisibleProps = {
  onClose: () => void;
  onSuccess: (coords: { x: number; y: number }[]) => void;
};

const _CoordsImportModal: React.FC<CoordsImportModalProps> = (props) => {
  const { visible } = props;

  if (!visible) {
    return null;
  }

  const { visible: _, ...visibleProps } = props;
  return <VisibleModal {...visibleProps} />;
};
export const CoordsImportModal = React.memo(_CoordsImportModal);

const _VisibleModal: React.FC<VisibleProps> = (props) => {
  const { onClose, onSuccess } = props;
  const [text, setText] = React.useState<string>('');
  const [validError, setValidError] = React.useState<string | null>(null);
  const onChangeTextAreaHandler: React.ChangeEventHandler<HTMLTextAreaElement> = React.useCallback(
    (e) => {
      setText(e.target.value);
    },
    [],
  );
  const [coords, setCoords] = React.useState<{ x: number; y: number }[] | null>(null);
  const onKeyDownTabHandler: React.KeyboardEventHandler<HTMLTextAreaElement> = React.useCallback(
    (e) => {
      if (e.key === 'Tab') {
        e.preventDefault();
        setText((prevState) => prevState + '\t');
      }
    },
    [],
  );

  useEffect(() => {
    setValidError(null);
    setCoords(null);

    const lines = text
      .trim()
      .split('\n')
      .filter((x) => x !== '');
    const newCoords: { x: number; y: number }[] = [];
    lines.forEach((line, index) => {
      if (!validLineRegex.test(line)) {
        setValidError(`В строке ${index + 1} ошибка`);
        return;
      }
      const lineCoords = line
        .split(/[\t|\s]/)
        .map((x) => Number.parseFloat(x.trim().replace(',', '.')));

      newCoords.push({
        x: lineCoords[0],
        y: lineCoords[1],
      });
    });
    setCoords(newCoords);
  }, [text]);

  const okButtonProps = React.useMemo(() => {
    return { disabled: validError !== null || coords === null };
  }, [validError, coords]);
  const onOkHandler = React.useCallback(() => {
    if (coords) {
      onSuccess(coords);
      onClose();
    }
  }, [onSuccess, coords, onClose]);

  return (
    <Modal
      visible={true}
      onCancel={onClose}
      title={'Импорт координат'}
      okButtonProps={okButtonProps}
      onOk={onOkHandler}
    >
      <div className={'coords-import-modal'}>
        {coords && coords.length > 0 && (
          <Table dataSource={coords} pagination={false} size={'small'}>
            <Table.Column title={'X'} dataIndex={'x'} />
            <Table.Column title={'Y'} dataIndex={'y'} />
          </Table>
        )}

        <Input.TextArea
          className={'coords-import-modal__text-area'}
          onKeyDown={onKeyDownTabHandler}
          value={text}
          onChange={onChangeTextAreaHandler}
          rows={5}
          placeholder={'Скопируйте сюда ячейки из excel'}
        />

        {validError && <div className={'coords-import-modal__error-message'}>{validError}</div>}
      </div>
    </Modal>
  );
};
const VisibleModal = React.memo(_VisibleModal);
