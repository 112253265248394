export const categoryPlane = [
  { value: '003001000000', label: 'Земли сельскохозяйственного назначения' },
  { value: '003002000000', label: 'Земли населенных пунктов' },
  {
    value: '003003000000',
    label:
      // eslint-disable-next-line max-len
      'Земли промышленности, энергетики, транспорта, связи, радиовещания, телевидения, информатики, земли для обеспечения космической деятельности, земли обороны, безопасности и земли иного специального назначения',
  },
  { value: '003004000000', label: 'Земли особо охраняемых территорий и объектов' },
  { value: '003005000000', label: 'Земли лесного фонда' },
  { value: '003006000000', label: 'Земли водного фонда' },
  { value: '003007000000', label: 'Земли запаса' },
  { value: '003008000000', label: 'Категория не установлена' },
];

export const typeUse = [
  { value: '214001000000', label: 'Сельскохозяйственное использование' },
  { value: '214001001000', label: 'Растениеводство' },
  { value: '214001001001', label: 'Выращивание зерновых и иных сельскохозяйственных культур' },
  { value: '214001001002', label: 'Овощеводство' },
  { value: '214001001003', label: 'Выращивание тонизирующих, лекарственных, цветочных культур' },
  { value: '214001001004', label: 'Садоводство' },
  { value: '214001001005', label: 'Выращивание льна и конопли' },
  { value: '214001002000', label: 'Животноводство' },
  { value: '214001002001', label: 'Скотоводство' },
  { value: '214001002002', label: 'Звероводство' },
  { value: '214001002003', label: 'Птицеводство' },
  { value: '214001002004', label: 'Свиноводство' },
  { value: '214001003000', label: 'Пчеловодство' },
  { value: '214001004000', label: 'Рыбоводство' },
  { value: '214001005000', label: 'Научное обеспечение сельского хозяйства' },
  { value: '214001006000', label: 'Хранение и переработка сельскохозяйственной продукции' },
  { value: '214001007000', label: 'Ведение личного подсобного хозяйства на полевых участках' },
  { value: '214001008000', label: 'Питомники' },
  { value: '214001009000', label: 'Обеспечение сельскохозяйственного производства' },
  { value: '214002000000', label: 'Жилая застройка' },
  {
    value: '214002001000',
    label:
      'Малоэтажная жилая застройка (индивидуальное жилищное строительство; размещение дачных домов и садовых домов)',
  },
  { value: '214002002000', label: 'Приусадебный участок личного подсобного хозяйства' },
  { value: '214002003000', label: 'Блокированная жилая застройка' },
  { value: '214002004000', label: 'Передвижное жилье' },
  { value: '214002005000', label: 'Среднеэтажная жилая застройка' },
  { value: '214002006000', label: 'Многоэтажная жилая застройка (высотная застройка)' },
  { value: '214002007000', label: 'Обслуживание жилой застройки' },
  {
    value: '214003000000',
    label: 'Общественное использование объектов капитального строительства',
  },
  { value: '214003001000', label: 'Коммунальное обслуживание' },
  { value: '214003002000', label: 'Социальное обслуживание' },
  { value: '214003003000', label: 'Бытовое обслуживание' },
  { value: '214003004000', label: 'Здравоохранение' },
  { value: '214003005000', label: 'Образование и просвещение' },
  { value: '214003006000', label: 'Культурное развитие' },
  { value: '214003007000', label: 'Религиозное использование' },
  { value: '214003008000', label: 'Общественное управление' },
  { value: '214003009000', label: 'Обеспечение научной деятельности' },
  { value: '214003010000', label: 'Ветеринарное обслуживание' },
  { value: '214004000000', label: 'Предпринимательство' },
  { value: '214004001000', label: 'Деловое управление' },
  { value: '214004002000', label: 'Торговые центры (Торгово-развлекательные центры)' },
  { value: '214004003000', label: 'Рынки' },
  { value: '214004004000', label: 'Магазины' },
  { value: '214004005000', label: 'Банковская и страховая деятельность' },
  { value: '214004006000', label: 'Общественное питание' },
  { value: '214004007000', label: 'Гостиничное обслуживание' },
  { value: '214004008000', label: 'Развлечения' },
  { value: '214004009000', label: 'Обслуживание автотранспорта' },
  { value: '214005000000', label: 'Отдых (рекреация)' },
  { value: '214005001000', label: 'Спорт' },
  { value: '214005002000', label: 'Природно-познавательный туризм' },
  { value: '214005003000', label: 'Охота и рыбалка' },
  { value: '214005004000', label: 'Причалы для маломерных судов' },
  { value: '214005005000', label: 'Поля для гольфа или конных прогулок' },
  { value: '214006000000', label: 'Производственная деятельность' },
  { value: '214006001000', label: 'Недропользование' },
  { value: '214006002000', label: 'Тяжелая промышленность' },
  { value: '214006003000', label: 'Легкая промышленность' },
  { value: '214006004000', label: 'Пищевая промышленность' },
  { value: '214006005000', label: 'Нефтехимическая промышленность' },
  { value: '214006006000', label: 'Строительная промышленность' },
  { value: '214006007000', label: 'Энергетика' },
  { value: '214006008000', label: 'Связь' },
  { value: '214006009000', label: 'Склады' },
  { value: '214007000000', label: 'Обеспечение космической деятельности' },
  { value: '214008000000', label: 'Транспорт' },
  { value: '214008001000', label: 'Железнодорожный транспорт' },
  { value: '214008002000', label: 'Автомобильный транспорт' },
  { value: '214008003000', label: 'Водный транспорт' },
  { value: '214008004000', label: 'Воздушный транспорт' },
  { value: '214008005000', label: 'Трубопроводный транспорт' },
  { value: '214009000000', label: 'Обеспечение обороны и безопасности' },
  { value: '214010000000', label: 'Обеспечение вооруженных сил' },
  { value: '214011000000', label: 'Охрана Государственной границы Российской Федерации' },
  { value: '214012000000', label: 'Обеспечение внутреннего правопорядка' },
  { value: '214013000000', label: 'Обеспечение деятельности по исполнению наказаний' },
  { value: '214014000000', label: 'Деятельность по особой охране и изучению природы' },
  { value: '214015000000', label: 'Охрана природных территорий' },
  { value: '214016000000', label: 'Курортная деятельность' },
  { value: '214017000000', label: 'Историческая' },
  { value: '214018000000', label: 'Лесная' },
  { value: '214018001000', label: 'Заготовка древесины' },
  { value: '214018002000', label: 'Лесные плантации' },
  { value: '214018003000', label: 'Заготовка лесных ресурсов' },
  { value: '214018004000', label: 'Резервные леса' },
  { value: '214019000000', label: 'Водные объекты' },
  { value: '214020000000', label: 'Общее пользование водными объектами' },
  { value: '214021000000', label: 'Специальное пользование водными объектами' },
  { value: '214022000000', label: 'Гидротехнические сооружения' },
  { value: '214023000000', label: 'Общее пользование территории' },
  { value: '214024000000', label: 'Ритуальная деятельность' },
  { value: '214025000000', label: 'Специальная' },
  { value: '214026000000', label: 'Запас' },
  { value: '214099000000', label: 'Сведения отсутствуют' },
];
