import { Col, Menu, message, Modal, Row, Typography } from 'antd';
import React, { useCallback, useMemo, useState } from 'react';

import { PaginationRequest, PaginationResponse, RecordId } from '@/api/common/types';
import {
  ControlledTable,
  MultipleActionModal,
  SelectedItemsActions,
} from '@uspect-team/ant-ui-kit';
import {
  ControlledTableColumns,
  ControlledTableState,
} from '@uspect-team/ant-ui-kit/dist/Tables/ControlledTable/types';
import { AxiosResponse } from 'axios';
import { UseMutationResult, UseQueryResult } from 'react-query';
import { AdditionalAnyRecordAction, getCrudColumns } from './utils/getCrudColumns';

import {
  resetTableFilters,
  resetTableFiltersAndSort,
  resetTableSort,
} from '@/pages/MainTablePage/model';
import { PlusCircleOutlined, ReloadOutlined } from '@ant-design/icons';
import { MultipleActionModalProps } from '@uspect-team/ant-ui-kit/dist/Tables/MultipleActionModal';
import { SelectedItemsActionsAction } from '@uspect-team/ant-ui-kit/dist/Tables/SelectedItemsActions';
import { TableRowSelection } from 'antd/lib/table/interface';
import { ButtonWithMenu } from '../ButtonWithMenu';
import { DashboardButton } from '../DasboardButton';
import {
  controlledSortToServer,
  mapFilterStateToServer,
} from '../ServerConfigTable/utils/tableStateMapping';

interface IRecord {
  _id: RecordId;
}

export type DictCrudProps<RecordType extends IRecord> = {
  className?: string;
  style?: any;
  texts?: {
    createButtonText?: string;
    delete?: {
      confirmTitle?: (record: RecordType) => string;
      confirmDesc?: (record: RecordType) => string;

      multipleDeleteModalTitle?: string;
      multipleDeleteModalTableTexts?: {
        nameColumnTitle?: React.ReactNode;
        statusColumnTitle?: React.ReactNode;
        timeColumnTitle?: React.ReactNode;
      };

      successMessage?: (record: RecordType) => string;
      errorMessage?: (record: RecordType) => string;
    };
  };
  onCreateButtonClick: () => void;
  onEditButtonClick: (record: RecordType) => void;

  getName: (record: RecordType) => string;

  pagination:
    | { type: 'none'; getDataQ: () => UseQueryResult<RecordType[]> }
    | {
        type: 'server';
        pageSizeOptions?: number[];
        getPageQ: (
          data: PaginationRequest & {
            filter?: Record<string, any>;
            sort?: Record<string, 'asc' | 'desc'>;
          },
        ) => UseQueryResult<PaginationResponse<RecordType>>;
      };

  deleteRecordM: () => UseMutationResult<AxiosResponse, unknown, RecordId>;

  columns: ControlledTableColumns<RecordType>;
  additionalRecordActions?: AdditionalAnyRecordAction<RecordType>[];
  getAdditionalSelectedActions?: (selectedItems: RecordType[]) => SelectedItemsActionsAction[];
};

const scroll = { x: true as const };

function _SimpleTable<RecordType extends IRecord>(props: DictCrudProps<RecordType>) {
  const {
    onEditButtonClick,
    onCreateButtonClick,
    pagination,
    deleteRecordM,
    columns,
    texts,
    getName,
    additionalRecordActions,
    getAdditionalSelectedActions,
  } = props;

  const { mutateAsync: deleteRecordMutateAsync } = deleteRecordM();

  const [tableState, setTableState] = useState<ControlledTableState>({
    pagination: { currentPage: 1, pageSize: 3 },
  });
  const [multipleActionModalState, setMultipleActionModalState] = useState<
    MultipleActionModalProps<RecordType>
  >({
    visible: false,
  });
  const [selectedItems, setSelectedItems] = React.useState<RecordType[]>([]);

  const loadingHookQ = pagination.type === 'server' ? pagination.getPageQ : pagination.getDataQ;

  const {
    data: loadingData,
    isFetching: isLoadingData,
    refetch,
  } = loadingHookQ({
    page: tableState.pagination?.currentPage,
    limit: tableState.pagination?.pageSize,
    filter: mapFilterStateToServer(tableState.filter),
    sort: controlledSortToServer(tableState.sort),
  });

  const onDeleteClickHandler = useCallback(
    async (record: RecordType) => {
      Modal.confirm({
        title:
          texts?.delete?.confirmTitle?.(record) ||
          'Вы действительно хотите безвозвратно удалить объект',
        content: texts?.delete?.confirmDesc?.(record) || 'Это действие нельзя будет отменить',
        okType: 'danger',
        okText: 'Да, удалить',
        cancelText: 'Отмена',
        onOk: async () => {
          try {
            await deleteRecordMutateAsync(record._id);
            message.success(texts?.delete?.successMessage?.(record) || 'Объект успешно удален');
          } catch (e) {
            message.success(
              texts?.delete?.errorMessage?.(record) || 'При удалении объекта произошла ошибка',
            );
          }
        },
      });
    },
    [deleteRecordMutateAsync, texts?.delete],
  );

  const onDeactivate = useCallback((record: RecordType) => {}, []);

  const columnsWithAdditions = useMemo(() => {
    return getCrudColumns({
      recordColumns: columns,
      onDeleteClick: onDeleteClickHandler,
      onEditClick: onEditButtonClick,
      onDeactivate: onDeactivate,

      additionalRecordActions,
    });
  }, [additionalRecordActions, columns, onDeactivate, onDeleteClickHandler, onEditButtonClick]);

  const dataSource = React.useMemo(() => {
    return pagination.type === 'server'
      ? (loadingData as PaginationResponse<RecordType> | undefined)?.data.map((item) => ({
          ...item,
          isEnabled: !false,
          role: 'Администратор',
        }))
      : (loadingData as RecordType[] | undefined);
  }, [loadingData, pagination.type]);

  const totalItems = React.useMemo(() => {
    return pagination.type === 'server'
      ? (loadingData as PaginationResponse<RecordType> | undefined)?.meta.count
      : (loadingData as RecordType[] | undefined)?.length;
  }, [loadingData, pagination.type]);

  const selectedItemsKeys = React.useMemo(() => {
    return selectedItems.map((x) => x._id);
  }, [selectedItems]);

  const rowSelection: TableRowSelection<RecordType> = React.useMemo(() => {
    return {
      selectedRowKeys: selectedItemsKeys,
      fixed: 'left',
      type: 'checkbox',
      onChange: (_, newSelectedItems) => setSelectedItems(newSelectedItems),
    };
  }, [selectedItemsKeys]);

  const onClearSelectHandler = React.useCallback(() => {
    return setSelectedItems([]);
  }, []);

  const onRefreshClickHandler = useCallback(() => {
    return refetch();
  }, [refetch]);

  const onClickDeleteSelectedRecordsHandler = React.useCallback(
    (selectedItems: RecordType[]) => {
      Modal.confirm({
        okText: 'Да, удалить',
        cancelText: 'Отмена',
        title: `Вы действительно хотите безвозвратно удалить записи (${selectedItems.length}\u00A0шт.)?`,
        content: 'Это действие нельзя будет отменить',
        type: 'warning',

        onOk: () => {
          setMultipleActionModalState({
            visible: true,
            onClose: () => setMultipleActionModalState({ visible: false }),
            onComplete: () => setSelectedItems([]),
            title: texts?.delete?.multipleDeleteModalTitle || 'Удаление',
            texts: texts?.delete?.multipleDeleteModalTableTexts,
            size: 'small',
            scroll: { y: 500 },
            mode: 'parallel',
            getRecordKey: (record) => record._id,
            getName,
            items: selectedItems,
            asyncAction: (record) => deleteRecordMutateAsync(record._id),
          });
        },
      });
    },
    [
      deleteRecordMutateAsync,
      getName,
      texts?.delete?.multipleDeleteModalTableTexts,
      texts?.delete?.multipleDeleteModalTitle,
    ],
  );

  const getSelectedItemsActions = React.useCallback(
    (selectedItems: RecordType[]): SelectedItemsActionsAction[] => {
      const additionalSelectedActions = getAdditionalSelectedActions
        ? getAdditionalSelectedActions(selectedItems)
        : [];

      return [
        ...additionalSelectedActions,
        {
          key: 'delete',
          title: 'Удалить',
          danger: true,
          onClick: () => onClickDeleteSelectedRecordsHandler(selectedItems),
        },
      ];
    },
    [getAdditionalSelectedActions, onClickDeleteSelectedRecordsHandler],
  );

  const [isTableHasFilters, isTableHasFiltersOrSort, isTableHasSort] = [true, true, true];

  return (
    <div>
      <Row gutter={15}>
        <Col>
          <Typography className='management-employee__main-title'>Сотрудники</Typography>
        </Col>
        <Col>
          <DashboardButton
            className='management-employee__button'
            icon={<PlusCircleOutlined />}
            onClick={onCreateButtonClick}
          >
            Добавить сотрудника
          </DashboardButton>
        </Col>
        <Col>
          <DashboardButton icon={<ReloadOutlined />} onClick={onRefreshClickHandler}>
            Обновить таблицу
          </DashboardButton>
        </Col>
        <Col>
          <ButtonWithMenu
            disabled={!isTableHasFiltersOrSort}
            onClick={() => resetTableFiltersAndSort()}
            overlay={
              <Menu>
                {isTableHasFilters && (
                  <Menu.Item key='1' onClick={() => resetTableFilters()}>
                    Сбросить фильтры
                  </Menu.Item>
                )}
                {isTableHasSort && (
                  <Menu.Item key='2' onClick={() => resetTableSort()}>
                    Сбросить сортировку
                  </Menu.Item>
                )}
              </Menu>
            }
          >
            Сбросить фильтры и сортировку
          </ButtonWithMenu>
        </Col>
      </Row>
      <ControlledTable
        columns={columnsWithAdditions}
        dataSource={dataSource}
        multipleSorting={false}
        tableState={tableState}
        onChangeTableState={setTableState}
        pagination={pagination}
        loading={isLoadingData}
        scroll={scroll}
        totalItems={totalItems}
        rowSelection={rowSelection}
        rowKey={'_id'}
      />
      <SelectedItemsActions
        selectedItems={selectedItems}
        actions={getSelectedItemsActions}
        onClearSelect={onClearSelectHandler}
      />

      <MultipleActionModal {...multipleActionModalState} />
    </div>
  );
}

export const ManagementEmployeeTable = _SimpleTable;
