import React, { useMemo, useState } from 'react';
import { CoupleFileResponse, CoupleFileType } from '@/api/Files/types';
import { Checkbox, Modal } from 'antd';
import { SelectAlreadyExistBase } from '@/components/modals/common/SelectAlreadyExistBase';
import { ControlledTable } from '@uspect-team/ant-ui-kit';
import { ControlledTableState } from '@uspect-team/ant-ui-kit/dist/Tables/ControlledTable/types';
import { getCoupleColumns } from '@/components/modals/utils/getCoupleColumns';
import { getCouplePseudoId } from '@/components/modals/utils/getCouplePseudoId';
import { unpackCoupleFileType } from '@/utils/fileTypeUtils';
import { useGetCoupleFilesPageQ } from '@/hooks/files';
import { RecordId } from '@/api/common/types';

export type SelectAlreadyExistCoupleFilesModalProps =
  | {
      visible: false;
    }
  | ({ visible: true } & VisibleProps);

type VisibleProps = {
  title: string;
  contractId?: RecordId;
  onClose: () => void;
  fileType: CoupleFileType;
  onSelectCouple: (couple: CoupleFileResponse) => void;
};
export const SelectAlreadyExistCoupleFilesModal: React.FC<SelectAlreadyExistCoupleFilesModalProps> =
  React.memo((props) => {
    const { visible } = props;

    if (!visible) {
      return null;
    }

    const { visible: _, ...visibleProps } = props;
    return <VisibleModal {...visibleProps} />;
  });

export const VisibleModal: React.FC<VisibleProps> = React.memo((props) => {
  const { title, onClose, fileType, onSelectCouple, contractId } = props;

  const coupleFileTypes = useMemo(() => {
    return unpackCoupleFileType(fileType);
  }, [fileType]);

  const [tableState, setTableState] = useState<ControlledTableState>({
    pagination: {
      pageSize: 5,
      currentPage: 1,
    },
  });
  const [isCurrentContract, setIsCurrentContract] = React.useState<boolean>(true);

  const { data: couplePaginationData, isLoading } = useGetCoupleFilesPageQ({
    fileType,
    page: tableState.pagination?.currentPage,
    limit: tableState.pagination && tableState.pagination?.pageSize * 2,
    filter:
      contractId && isCurrentContract
        ? {
            contractId,
          }
        : undefined,
  });

  const deletableFileColumns = useMemo(() => {
    return getCoupleColumns(coupleFileTypes);
  }, [coupleFileTypes]);
  const [selectedCouple, setSelectedCouple] = useState<CoupleFileResponse | null>(null);

  return (
    <Modal
      visible={true}
      width={1000}
      title={title}
      okText={'Выбрать пару'}
      okButtonProps={{ disabled: selectedCouple === null }}
      onOk={() => {
        if (selectedCouple) {
          onSelectCouple(selectedCouple);
          onClose();
        }
      }}
      onCancel={onClose}
      cancelText={'Отмена'}
    >
      <SelectAlreadyExistBase>
        {contractId && (
          <SelectAlreadyExistBase.Filters>
            <Checkbox
              checked={isCurrentContract}
              onChange={(e) => setIsCurrentContract(e.target.checked)}
            >
              Файлы этого контракта
            </Checkbox>
          </SelectAlreadyExistBase.Filters>
        )}
        <SelectAlreadyExistBase.Table>
          <ControlledTable
            rowKey={getCouplePseudoId}
            tableState={tableState}
            onChangeTableState={setTableState}
            columns={deletableFileColumns}
            dataSource={couplePaginationData?.data}
            pagination={{ type: 'client', pageSizeOptions: [5] }}
            totalItems={couplePaginationData && couplePaginationData?.meta.count / 2}
            loading={isLoading}
            rowSelection={{
              selectedRowKeys: selectedCouple ? [getCouplePseudoId(selectedCouple)] : [],
              type: 'radio',
              onChange: (_, selectedRows) => {
                if (selectedRows[0]) {
                  setSelectedCouple(selectedRows[0]);
                } else {
                  setSelectedCouple(null);
                }
              },
            }}
            scroll={{ x: true }}
          />
        </SelectAlreadyExistBase.Table>
      </SelectAlreadyExistBase>
    </Modal>
  );
});
