import React, { useCallback, useState } from 'react';
import { message, Modal, notification } from 'antd';
import { RcFile } from 'antd/lib/upload';
import { FileLoader } from '@uspect-team/ant-ui-kit';
import { FileCard } from '@/components/FileCard';
import { AntdLabelMimic } from '@/components/formHelpers/AntdLabelMimic';
import { ExternalLink } from '@/components/ExternalLink';
import { useCadObjectsImportOsM } from '@/hooks/cadastralObjects';
import { RecordId } from '@/api/common/types';
import example_svg from '@/assets/images/os_sit_import_example.svg';
import { ImportBase } from '@/components/modals/cadObjects/common/ImportBase';

export type ImportOsModalProps =
  | {
      visible: false;
    }
  | ({ visible: true } & VisibleProps);
type VisibleProps = {
  contractId: RecordId;
  onClose: () => void;
};

export const ImportOsModal: React.FC<ImportOsModalProps> = React.memo((props) => {
  const { visible } = props;

  if (!visible) {
    return null;
  }

  const { visible: _, ...visibleProps } = props;
  return <VisibleModal {...visibleProps} />;
});

export const VisibleModal: React.FC<VisibleProps> = React.memo((props) => {
  const { onClose, contractId } = props;
  const [osZipFile, setOsZipFile] = useState<RcFile | null>(null);
  const { mutateAsync: importOsM, isLoading: isLoadingImportOs } = useCadObjectsImportOsM();

  const onImport = useCallback(async () => {
    if (!osZipFile) {
      message.error('Пожалуйста, загрузите zip-файл');
      return;
    }
    try {
      await importOsM({ contractId, file: osZipFile });
      onClose();
      message.success('Os/sit успешно импортированны из zip-файла');
    } catch (e) {
      notification.open({
        type: 'error',
        message: 'Произошла ошибка при импроте',
        description: 'Пожалуйста, проверте, правильно ли заполненен zip-файл',
      });
    }
  }, [osZipFile, contractId, importOsM, onClose]);

  return (
    <Modal
      visible={true}
      width={600}
      onCancel={onClose}
      title={'Множественный импорт os/sit файлов (геодезии)'}
      onOk={onImport}
      okText={'Импортировать'}
      okButtonProps={{ disabled: osZipFile === null, loading: isLoadingImportOs }}
    >
      <ImportBase>
        <ImportBase.Description>
          <ImportBase.Description.Example img={example_svg} />
          <ImportBase.Description.Title>
            Какой архив нужен для импорта?
          </ImportBase.Description.Title>
          <ImportBase.Description.List>
            <ImportBase.Description.List.Item>
              Название каждой папки должно соответствовать номеру объекта по договору в контракте
            </ImportBase.Description.List.Item>
            <ImportBase.Description.List.Item>
              В каждой папке должны находиться файлы:
              <br />
              os.mif – обязательный файл; <br />
              sit.mif – если присутствует у объекта;
              <br />
            </ImportBase.Description.List.Item>
            <ImportBase.Description.List.Item>
              Архив не должен содержать других файлов
            </ImportBase.Description.List.Item>
            <ImportBase.Description.List.Item>
              Архив не должен быть повреждён или зашифрован
            </ImportBase.Description.List.Item>
          </ImportBase.Description.List>
        </ImportBase.Description>
      </ImportBase>

      <AntdLabelMimic addition={<ExternalLink path={'#'}>Пример ZIP-архива</ExternalLink>}>
        Загрузите архив со структурой, описанной выше
      </AntdLabelMimic>

      {osZipFile && (
        <FileCard
          title={'Загружаемый файл'}
          file={{ name: osZipFile.name, size: osZipFile.size, uploadAt: 'now' }}
          onDelete={() => setOsZipFile(null)}
        />
      )}

      <FileLoader allowedFiles={[['zip']]} onUpload={setOsZipFile} />
    </Modal>
  );
});
