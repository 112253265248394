import React, { useCallback, useMemo, useState } from 'react';
import {
  ControlledTable,
  MultipleActionModal,
  SelectedItemsActions,
} from '@uspect-team/ant-ui-kit';
import { ControlledTableState } from '@uspect-team/ant-ui-kit/dist/Tables/ControlledTable/types';
import { getColumns } from './columns';
import { MultipleActionModalProps } from '@uspect-team/ant-ui-kit/dist/Tables/MultipleActionModal';
import { Menu } from 'antd';
import { useDeleteTpM, useGetTpsPageQ } from '@/hooks/coordinates/tp';
import { TpRecordResponse } from '@/api/Coordinates/Tp/types';
import { EditTpModal, EditTpModalProps } from '@/components/modals/tps/EditTpModal';
import { mapFilterStateToServer } from '@/components/ServerConfigTable/utils/tableStateMapping';

type Props = {
  className?: string;
  style?: any;
};
const _TpTable: React.FC<Props> = (props) => {
  const { className, style } = props;

  const { mutateAsync: deleteTpM } = useDeleteTpM();

  const [tableState, setTableState] = useState<ControlledTableState>({
    pagination: { pageSize: 50, currentPage: 1 },
  });

  const { data: getTpsReq, isLoading: isTpsLoading } = useGetTpsPageQ({
    page: tableState.pagination?.currentPage,
    limit: tableState.pagination?.pageSize,
    filter: mapFilterStateToServer(tableState.filter),
  });

  const [selectedRecords, setSelectedRecords] = useState<TpRecordResponse[]>([]);

  const [multipleActionModalState, setMultipleActionModalState] = useState<
    MultipleActionModalProps<TpRecordResponse>
  >({
    visible: false,
  });

  const [editTpModalState, setEditTpModalState] = useState<EditTpModalProps>({
    visible: false,
  });

  const onClickDeleteSelectedRecordsList = useCallback(
    (selectedItems) => {
      setMultipleActionModalState({
        visible: true,
        title: 'Удаление ТП',
        onClose: () => setMultipleActionModalState({ visible: false }),
        getName: (i) => i.title,
        getRecordKey: (i) => i._id,
        items: selectedItems,
        asyncAction: (i) => deleteTpM(i._id),
        mode: 'parallel',
      });
      setSelectedRecords([]);
    },
    [deleteTpM],
  );

  const columns = useMemo(() => {
    return getColumns({
      actionMenu: (record) => (
        <Menu>
          <Menu.Item
            onClick={() =>
              setEditTpModalState({
                visible: true,
                tpId: record._id,
                onClose: () => setEditTpModalState({ visible: false }),
              })
            }
          >
            Редактировать
          </Menu.Item>
          <Menu.Item danger onClick={() => deleteTpM(record._id)}>
            Удалить
          </Menu.Item>
        </Menu>
      ),
    });
  }, [deleteTpM]);

  return (
    <>
      <ControlledTable
        className={className}
        style={style}
        tableState={tableState}
        onChangeTableState={setTableState}
        rowKey={(x) => x._id}
        columns={columns}
        dataSource={getTpsReq?.data}
        totalItems={getTpsReq?.meta.count}
        loading={isTpsLoading}
        pagination={{ type: 'server' }}
        rowSelection={{
          selectedRowKeys: selectedRecords.map((x) => x._id),
          onChange: (_, selectedRows) => setSelectedRecords(selectedRows),
        }}
        scroll={{ x: true }}
      />
      <SelectedItemsActions
        selectedItems={selectedRecords}
        actions={[
          {
            key: 'delete',
            title: 'Удалить',
            danger: true,
            onClick: onClickDeleteSelectedRecordsList,
          },
        ]}
        onClearSelect={() => setSelectedRecords([])}
      />
      <MultipleActionModal {...multipleActionModalState} />
      <EditTpModal {...editTpModalState} />
    </>
  );
};
export const TpTable = React.memo(_TpTable);
