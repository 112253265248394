import { DateFilterItem } from '@/components/ServerConfigTable/types';
import { Col, DatePicker, Row } from 'antd';
import moment from 'moment';
import React from 'react';
import { FilterDropdownActions } from '../common/FilterDropdownActions';
import { FilterDropdownContentBase } from '../common/FilterDropdownContentBase';
import { FilterDropdownFormContainer } from '../common/FilterDropdownFormContainer';
import { FilterTypeOption, FilterTypeSelect } from '../common/FilterTypeSelect';
import { betweenOption, equalOption, existOption, notExistOption } from './consts';
import { DateFilterType, DateFilterValue } from './types';

export type DateFilterDropdownProps = {
  className?: string;
  style?: React.CSSProperties;
  filter: DateFilterValue;
  onChangeFilter: (value: DateFilterItem) => void;
  onResetFilter: () => void;
  hasBetween?: boolean;
  hasEqual?: boolean;
  hasExist?: boolean;
};

const getActualPropValue = <ReturnValue,>(params: {
  currentFilter: DateFilterValue;
  changes: DateFilterItem | null;
  getProp: (filter: DateFilterValue | null) => ReturnValue;
  defaultValue: ReturnValue;
}) => {
  const { currentFilter, changes, getProp, defaultValue } = params;
  return getProp(changes) ?? getProp(currentFilter) ?? defaultValue;
};

const _DateFilterDropdown = (props: DateFilterDropdownProps) => {
  const {
    className,
    style,
    filter,
    onChangeFilter,
    onResetFilter,
    hasBetween,
    hasEqual,
    hasExist,
  } = props;

  React.useEffect(() => {
    setUpdatedFilterState(null);
  }, [filter]);

  const [updatedFilterState, setUpdatedFilterState] = React.useState<DateFilterItem | null>(null);

  const filterTypeOptions = React.useMemo(() => {
    const options: FilterTypeOption<DateFilterType>[] = [];
    if (hasEqual) {
      options.push(equalOption);
    }
    if (hasBetween) {
      options.push(betweenOption);
    }

    if (hasExist) {
      options.push(existOption, notExistOption);
    }
    return options;
  }, [hasEqual, hasExist, hasBetween]);

  const [selectedFilterType, _setSelectedFilterType] = React.useState<DateFilterType>('equal');
  const changeFilterType = React.useCallback((filterType: DateFilterType) => {
    _setSelectedFilterType(filterType);
    if (filterType === 'exist' || filterType === 'notExist') {
      setUpdatedFilterState({
        filterAs: 'date',
        filterType,
      });
    } else {
      setUpdatedFilterState(null);
    }
  }, []);

  const form = React.useMemo(() => {
    switch (selectedFilterType) {
      case 'exist':
      case 'notExist':
        return null;
      case 'equal': {
        const inputValue = getActualPropValue({
          currentFilter: filter,
          changes: updatedFilterState,
          getProp: (filter) => {
            return filter?.filterType === 'equal' ? moment(filter.payload) : undefined;
          },
          defaultValue: undefined,
        });
        return (
          <FilterDropdownFormContainer>
            <DatePicker
              format={'DD.MM.YYYY'}
              style={{ width: '100%' }}
              value={inputValue}
              onChange={(value) => {
                if (value) {
                  setUpdatedFilterState({
                    filterAs: 'date',
                    filterType: 'equal',
                    payload: value.toDate(),
                  });
                } else {
                  setUpdatedFilterState(null);
                }
              }}
            />
          </FilterDropdownFormContainer>
        );
      }

      case 'between': {
        const valueMin = getActualPropValue({
          currentFilter: filter,
          changes: updatedFilterState,
          getProp: (filter) => {
            return filter?.filterType === 'between' ? filter.payload.min : undefined;
          },
          defaultValue: undefined,
        });

        const valueMax = getActualPropValue({
          currentFilter: filter,
          changes: updatedFilterState,
          getProp: (filter) => {
            return filter?.filterType === 'between' ? filter.payload.max : undefined;
          },
          defaultValue: undefined,
        });
        return (
          <FilterDropdownFormContainer>
            <Row gutter={8}>
              <Col span={12}>
                <DatePicker
                  format={'DD.MM.YYYY'}
                  style={{ width: '100%' }}
                  value={valueMin ? moment(valueMin) : null}
                  onChange={(value) => {
                    if (value) {
                      setUpdatedFilterState({
                        filterAs: 'date',
                        filterType: 'between',
                        payload: {
                          min: value.toDate(),
                          max:
                            updatedFilterState?.filterType === 'between'
                              ? updatedFilterState.payload.max
                              : undefined,
                        },
                      });
                    } else {
                      setUpdatedFilterState({
                        filterAs: 'date',
                        filterType: 'between',
                        payload: {
                          min: undefined,
                          max:
                            updatedFilterState?.filterType === 'between'
                              ? updatedFilterState.payload.max
                              : undefined,
                        },
                      });
                    }
                  }}
                  placeholder='Начало'
                />
              </Col>
              <Col span={12}>
                <DatePicker
                  format={'DD.MM.YYYY'}
                  style={{ width: '100%' }}
                  value={valueMax ? moment(valueMax) : null}
                  onChange={(value) => {
                    if (value) {
                      setUpdatedFilterState({
                        filterAs: 'date',
                        filterType: 'between',
                        payload: {
                          max: value.toDate(),
                          min:
                            updatedFilterState?.filterType === 'between'
                              ? updatedFilterState.payload.min
                              : undefined,
                        },
                      });
                    } else {
                      setUpdatedFilterState({
                        filterAs: 'date',
                        filterType: 'between',
                        payload: {
                          max: undefined,
                          min:
                            updatedFilterState?.filterType === 'between'
                              ? updatedFilterState.payload.max
                              : undefined,
                        },
                      });
                    }
                  }}
                  placeholder='Конец'
                />
              </Col>
            </Row>
          </FilterDropdownFormContainer>
        );
      }
    }
  }, [filter, selectedFilterType, updatedFilterState]);

  return (
    <FilterDropdownContentBase className={className} style={style}>
      <FilterTypeSelect
        onChangeSelectedFilterType={changeFilterType}
        selectedFilterType={selectedFilterType}
        filteredType={filter?.filterType ?? null}
        options={filterTypeOptions}
      />
      {form}
      <FilterDropdownActions
        canChangeFilter={updatedFilterState !== null}
        hasFilters={filter !== null && filter !== undefined}
        onSubmitChanges={() => {
          if (updatedFilterState === null) {
            onResetFilter();
          } else {
            onChangeFilter(updatedFilterState);
          }
        }}
        onResetFilter={onResetFilter}
      />
    </FilterDropdownContentBase>
  );
};
export const DateFilterDropdown = React.memo(_DateFilterDropdown);
