import { CompanyRecord } from '@/api/Companies/types';
import cn from 'classnames';
import React from 'react';
import { CompanyListItem } from './CompanyListItem';
import { CompanyListItemSkeleton } from './CompanyListItemSkeleton';
import './styles.scss';

export type CompanyListProps = {
  className?: string;
  style?: React.CSSProperties;
  items: CompanyRecord[];
  isLoading?: boolean;
};

const _CompanyList: React.FC<CompanyListProps> = (props) => {
  const { className, style, items, isLoading } = props;

  if (isLoading) {
    return (
      <div className={cn(className, 'company-list')} style={style}>
        {Array.from({ length: 3 }).map((_, index) => {
          return <CompanyListItemSkeleton key={index} />;
        })}
      </div>
    );
  }

  return (
    <div className={cn(className, 'company-list')} style={style}>
      {items.map((company) => {
        const isOverdue = new Date(company.paidUpTo).getTime() < Date.now();
        return (
          <CompanyListItem
            key={company.id}
            {...company}
            tariffName={company.tariff.name}
            isOverdue={isOverdue}
          />
        );
      })}
    </div>
  );
};
export const CompanyList = React.memo(_CompanyList);
