import { ControlledTableColumns } from '@uspect-team/ant-ui-kit/dist/Tables/ControlledTable/types';
import { ServerColumnsConfig, ServerConfigTableContext } from '../types';
import { mapAnyServerColumn } from './mapAnyServerColumn';

function mapServerColumns(
  serverColumns: ServerColumnsConfig,
  context: ServerConfigTableContext,
  editable: boolean,
) {
  return serverColumns
    .sort((a, b) => a.order - b.order)
    .map((x) => mapAnyServerColumn(x, context, editable));
}

export function getControlledTableColumns(
  clientColumns: ControlledTableColumns<any>,
  serverColumns: ServerColumnsConfig,
  context: ServerConfigTableContext,
  editable: boolean,
): ControlledTableColumns<any> {
  return [...clientColumns, ...mapServerColumns(serverColumns, context, editable)];
}
