import { OrganizationRecordResponse } from '@/api/Dictionary/Organizations/types';
import { DictCrudProps } from '@/components/DictCrudPage';
import { useDeleteOrganizationM, useGetOrganizationsPageQ } from '@/hooks/directory/organizations';
import { handleFilter } from '@/utils/filterUtils';
import { DateView } from '@uspect-team/ant-ui-kit';

export const getOrganizationsPageConfig = (params: {
  onCreateButtonClick: () => void;
  onEditButtonClick: (record: OrganizationRecordResponse) => void;
}): DictCrudProps<OrganizationRecordResponse> => ({
  getName: (record) => record.title,
  texts: {
    createButtonText: 'Добавить организацию',
    delete: {
      confirmTitle: (record) =>
        `Вы действительно хотите безвозвратно удалить организацию (${record.title})`,
      successMessage: (record) => `Организация успешно удалена (${record.title})`,
      errorMessage: (record) => `При удалении организации произошла ошибка (${record.title})`,
    },
  },
  pagination: { type: 'server', getPageQ: useGetOrganizationsPageQ },
  deleteRecordM: useDeleteOrganizationM,
  onCreateButtonClick: params.onCreateButtonClick,
  onEditButtonClick: params.onEditButtonClick,
  columns: [
    {
      key: 'title',
      dataIndex: 'title',
      title: 'Название',
      sortable: true,
      filterDropdown: handleFilter,
    },
    {
      key: 'inn',
      dataIndex: 'inn',
      title: 'ИНН',
      sortable: true,
      filterDropdown: handleFilter,
    },
    {
      key: 'ogrn',
      dataIndex: 'ogrn',
      title: 'ОГРН',
      sortable: true,
      filterDropdown: handleFilter,
    },
    {
      key: 'regDate',
      dataIndex: 'regDate',
      title: 'Дата регистрации организации',
      render: (value) => <DateView value={value} />,
      sortable: true,
    },
    {
      key: 'createdAt',
      dataIndex: 'createdAt',
      title: 'Дата создания',
      render: (value) => <DateView value={value} withTime />,
      sortable: true,
    },
    {
      key: 'updatedAt',
      dataIndex: 'updatedAt',
      title: 'Дата изменения',
      render: (value) => <DateView value={value} withTime />,
      sortable: true,
    },
  ],
});
