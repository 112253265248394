import React from 'react';
import cn from 'classnames';
import './styles.scss';
import { HeaderMainMenu, HeaderMainMenuProps } from './HeaderMainMenu';
import { HeaderNavMenu } from './HeaderNavMenu';
import { HeaderAdditional } from './HeaderAdditional';

export type HeaderProps = {
  className?: string;
  style?: React.CSSProperties;
  mainMenuProps?: HeaderMainMenuProps;
};

const _Header: React.FC<HeaderProps> = (props) => {
  const { className, style, mainMenuProps } = props;

  return (
    <div className={cn(className, 'main-layout-header')} style={style}>
      <div className='main-layout-header__main-zone'>
        <HeaderMainMenu {...mainMenuProps} />
      </div>
      <div className='main-layout-header__devider-zone'>
        <div className='main-layout-header__devider' />
      </div>
      <div className='main-layout-header__nav-zone'>
        <HeaderNavMenu />
      </div>
      <div className='main-layout-header__additional-zone'>
        <HeaderAdditional />
      </div>
    </div>
  );
};
export const Header = React.memo(_Header);
