import { useMutation, useQuery, useQueryClient } from 'react-query';
import { Api } from '@/api';
import { GetTdPageRequest } from '@/api/Td/types';
import { tdsQueryKeys } from '@/hooks/td/queryKeys';

export const useGetTdPageQ = (data: GetTdPageRequest) => {
  return useQuery(tdsQueryKeys.page(data), () => {
    return Api.td.get(data);
  });
};
export const useGetTdAllQ = () => {
  const data = { limit: 1000, page: 1 };
  return useQuery(
    tdsQueryKeys.page(data),
    () => {
      return Api.td.get(data);
    },
    { select: (data) => data.data.data },
  );
};

export const useGetTdByIdQ = (id: string) => {
  return useQuery(tdsQueryKeys.detail(id), () => {
    return Api.td.getById(id);
  });
};

export const useCreateTdM = () => {
  const queryClient = useQueryClient();
  return useMutation(tdsQueryKeys.create(), Api.td.create, {
    onSuccess: async () => {
      await queryClient.invalidateQueries(tdsQueryKeys.pages());
    },
  });
};
