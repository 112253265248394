import { api } from '@/api/axiosInstance';
import { RecordId } from '@/api/common/types';
import {
  CreateCoordinateSystemRequest,
  CreateCoordinateSystemResponse,
  GetCoordinateSystemByIdResponse,
  GetCoordinateSystemsPageRequest,
  GetCoordinateSystemsPageResponse,
  PatchCoordinateSystemRequest,
  PatchCoordinateSystemResponse,
} from '@/api/Coordinates/CoordinateSystems/types';

export class CoordinatesSystemApi {
  get(params: GetCoordinateSystemsPageRequest) {
    return api.get<GetCoordinateSystemsPageResponse>('/coords_systems', { params });
  }
  getById(id: RecordId) {
    return api.get<GetCoordinateSystemByIdResponse>(`/coords_systems/${id}`);
  }
  create(data: CreateCoordinateSystemRequest) {
    return api.post<CreateCoordinateSystemResponse>('/coords_systems', data);
  }
  patch(data: PatchCoordinateSystemRequest) {
    return api.patch<PatchCoordinateSystemResponse>(`/coords_systems/${data.id}`, data.patch);
  }
  delete(id: RecordId) {
    return api.delete<void>(`/coords_systems/${id}`);
  }
}
