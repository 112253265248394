import { UserInfoRecordResponse } from '@/api/Users/types';
import { DictCrudPage } from '@/components/DictCrudPage';
import { CreateUserModal, CreateUserModalProps } from '@/components/modals/users/CreateUserModal';
import { EditUserModal, EditUserModalProps } from '@/components/modals/users/EditUserModal';
import React from 'react';
import { getUsersPageConfig } from './config';

const _UsersPage: React.FC = (props) => {
  const [createUserModalState, setCreateUserModalState] = React.useState<CreateUserModalProps>({
    visible: false,
  });

  const [editUserModalState, setEditUserModalState] = React.useState<EditUserModalProps>({
    visible: false,
  });

  const onCreateButtonClickHandler = React.useCallback(() => {
    setCreateUserModalState({
      visible: true,
      onClose: () => setCreateUserModalState({ visible: false }),
    });
  }, []);

  const onEditButtonClickHandler = React.useCallback((record: UserInfoRecordResponse) => {
    setEditUserModalState({
      visible: true,

      onClose: () => setEditUserModalState({ visible: false }),
      userId: record._id,
    });
  }, []);

  const columnGroupPageConfig = React.useMemo(() => {
    return getUsersPageConfig({
      onCreateButtonClick: onCreateButtonClickHandler,
      onEditButtonClick: onEditButtonClickHandler,
    });
  }, [onCreateButtonClickHandler, onEditButtonClickHandler]);

  return (
    <>
      <DictCrudPage {...columnGroupPageConfig} />

      <CreateUserModal {...createUserModalState} />
      <EditUserModal {...editUserModalState} />
    </>
  );
};
export const UsersPage = React.memo(_UsersPage);
