import { CadObjectResponse } from '@/api/CadObjects/types';
import { FileRecordResponse, FileType } from '@/api/Files/types';
import { FileCard } from '@/components/FileCard';
import { openSelectAlreadyExistFileModal } from '@/globalStorage/file';
import {
  useCadObjectFileQ,
  useDeleteFileInCadObjectM,
  usePatchCadObjectM,
} from '@/hooks/cadastralObjects';
import { useUploadFileM } from '@/hooks/files';
import { getAllowedExtByFileType } from '@/utils/fileUtils';
import { FileLoader } from '@uspect-team/ant-ui-kit';
import { Button, message, Modal } from 'antd';
import { RcFile } from 'antd/lib/upload';
import { AxiosError } from 'axios';
import React, { useCallback, useMemo, useState } from 'react';
import './styles.scss';

export type EditSingleFileInCadModalProps =
  | {
      visible: false;
    }
  | ({
      visible: true;
    } & VisibleProps);

type VisibleProps = {
  title?: string;
  contractId: string;
  fileType: FileType;
  onClose?: () => void;
  onChangeFile?: (newFile: FileRecordResponse) => void;
  cadObjectId: string;
  dataIndex: string;
};

export const EditSingleFileInCadModal: React.FC<EditSingleFileInCadModalProps> = React.memo(
  (props) => {
    const { visible } = props;

    if (!visible) {
      return null;
    }
    const { visible: _, ...visibleProps } = props;

    return <VisibleModal {...visibleProps} />;
  },
);

const VisibleModal: React.FC<VisibleProps> = React.memo((props) => {
  const { title, fileType, onClose, dataIndex, onChangeFile, cadObjectId, contractId } = props;

  const [uploadFile, setUploadFile] = useState<RcFile | null>();
  const [uploadAlreadyLoaded, setUploadAlreadyLoaded] = useState<FileRecordResponse | null>();

  const uploadFileM = useUploadFileM();
  const patchCadObjectM = usePatchCadObjectM();
  const deleteFileM = useDeleteFileInCadObjectM();
  const { data: currentFile } = useCadObjectFileQ({
    id: cadObjectId,
    dataIndex: dataIndex as keyof CadObjectResponse,
  });

  const allowedExt = useMemo(() => {
    return getAllowedExtByFileType(fileType);
  }, [fileType]);

  const handleUpload = useCallback(async () => {
    try {
      if (uploadFile) {
        const uploadRes = await uploadFileM.mutateAsync({
          type: fileType,
          files: [uploadFile],
          contractId,
        });
        const newFile = uploadRes.data.data[0];

        await patchCadObjectM.mutateAsync({
          id: cadObjectId,
          patch: { [dataIndex]: newFile._id },
        });

        if (onChangeFile) {
          onChangeFile(newFile);
        }
        if (onClose) {
          onClose();
        }
      } else if (uploadAlreadyLoaded) {
        await patchCadObjectM.mutateAsync({
          id: cadObjectId,
          patch: { [dataIndex]: uploadAlreadyLoaded._id },
        });

        if (onChangeFile) {
          onChangeFile(uploadAlreadyLoaded);
        }
        if (onClose) {
          onClose();
        }
      }
    } catch (e) {
      if ((e as AxiosError).response?.status === 409) {
        message.error(
          'Файл с таким именем уже загружен. Выберите его или переименуйте и загрузите этот файл',
        );
      } else {
        message.error('Не удалось загрузить файл');
      }
    }
  }, [
    contractId,
    uploadFile,
    uploadAlreadyLoaded,
    onClose,
    uploadFileM,
    patchCadObjectM,
    cadObjectId,
    dataIndex,
    fileType,
    onChangeFile,
  ]);

  const onSelectAlreadyExistFileHandler = useCallback((file: FileRecordResponse) => {
    setUploadAlreadyLoaded(file);
    setUploadFile(null);
  }, []);

  const onUploadFileHandler = useCallback((file: RcFile) => {
    setUploadFile(file);
    setUploadAlreadyLoaded(null);
  }, []);

  const openAlreadyLoadedModal = useCallback(() => {
    openSelectAlreadyExistFileModal({
      contractId,
      fileType,
      title: 'Выбор из уже загруженных файлов',
      onSelectFile: onSelectAlreadyExistFileHandler,
    });
  }, [contractId, fileType, onSelectAlreadyExistFileHandler]);

  return (
    <Modal
      visible={true}
      title={title || `Редактирование файла`}
      okText={'Сохранить документ'}
      onOk={handleUpload}
      okButtonProps={{
        disabled: !uploadFile && !uploadAlreadyLoaded,
        loading: uploadFileM.isLoading || patchCadObjectM.isLoading,
      }}
      onCancel={onClose}
      cancelText={'Отмена'}
      wrapClassName={'load-file-modal'}
    >
      <div className='load-file-modal__upload-list'>
        {currentFile && (
          <FileCard
            title={'Текущий файл'}
            file={{
              name: currentFile.fileName,
              size: currentFile.size,
              uploadAt: new Date(currentFile.createdAt),
              link: currentFile.fileUri,
            }}
            onDelete={async () => {
              await deleteFileM.mutateAsync({
                id: cadObjectId,
                dataIndex: dataIndex as keyof CadObjectResponse,
              });
            }}
          />
        )}

        {currentFile && (uploadFile || uploadAlreadyLoaded) && (
          <div className='load-file-modal__upload-list-change-on'>Будет заменен на</div>
        )}

        {uploadFile && (
          <FileCard
            title={'Новый файл'}
            file={{
              name: uploadFile.name,
              size: uploadFile.size,
              uploadAt: 'now',
            }}
            onDelete={() => setUploadFile(undefined)}
          />
        )}
        {uploadAlreadyLoaded && (
          <FileCard
            title={'Уже существующий'}
            file={{
              name: uploadAlreadyLoaded.fileName,
              size: uploadAlreadyLoaded.size,
              uploadAt: new Date(uploadAlreadyLoaded.createdAt),
              link: uploadAlreadyLoaded.fileUri,
            }}
            onDelete={() => setUploadAlreadyLoaded(null)}
          />
        )}
      </div>
      <FileLoader
        className={'load-file-modal--upload'}
        allowedFiles={[allowedExt]}
        onUpload={onUploadFileHandler}
      />
      <Button block type={'link'} onClick={openAlreadyLoadedModal}>
        Или выберите среди уже загруженных
      </Button>
    </Modal>
  );
});
