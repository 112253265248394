import React from 'react';
import { Select, SelectProps } from 'antd';
import { ContractType } from '@/api/Contracts/types';

const columnTypes: { value: ContractType | 'all'; title: string }[] = [
  {
    value: 'all',
    title: 'Все',
  },
  {
    value: 'electrical_grid',
    title: 'Электросети',
  },
  {
    value: 'heating_network',
    title: 'Теплосети',
  },
  {
    value: 'gas_pipeline',
    title: 'Газопроводы',
  },
];

type Props = SelectProps<string>;
export const ColumnContractTypeSelect: React.FC<Props> = React.memo((props) => {
  return (
    <Select style={{ width: '100%' }} {...props}>
      {columnTypes.map((c) => (
        <Select.Option key={c.value} value={c.value}>
          {c.title}
        </Select.Option>
      ))}
    </Select>
  );
});
