import React, { useEffect } from 'react';
import { Input, Modal, Table } from 'antd';
import { CoordItem } from '@/api/Coordinates/Tp/types';
import './styles.scss';

//const validRegex = /^(((\s*[0-9]*[.,]?[0-9]+\s*)\t?){2,4}\n?){4,}$/;
const validLineRegex = /^(\s*[0-9]*[.,]?[0-9]+\s*\t?){2,4}$/;

export type CoordsImportModalProps =
  | {
      visible: false;
    }
  | ({ visible: true } & VisibleProps);
type VisibleProps = {
  onClose: () => void;
  onSuccess: (coords: CoordItem[]) => void;
};

const _CoordsImportModal: React.FC<CoordsImportModalProps> = (props) => {
  const { visible } = props;

  if (!visible) {
    return null;
  }

  const { visible: _, ...visibleProps } = props;
  return <VisibleModal {...visibleProps} />;
};
export const CoordsImportModal = React.memo(_CoordsImportModal);

const _VisibleModal: React.FC<VisibleProps> = (props) => {
  const { onClose, onSuccess } = props;
  const [text, setText] = React.useState<string>('');
  const [validError, setValidError] = React.useState<string | null>(null);
  const onChangeTextAreaHandler: React.ChangeEventHandler<HTMLTextAreaElement> = React.useCallback(
    (e) => {
      setText(e.target.value);
    },
    [],
  );
  const [coords, setCoords] = React.useState<CoordItem[] | null>(null);
  const onKeyDownTabHandler: React.KeyboardEventHandler<HTMLTextAreaElement> = React.useCallback(
    (e) => {
      if (e.key === 'Tab') {
        e.preventDefault();
        setText((prevState) => prevState + '\t');
      }
    },
    [],
  );

  useEffect(() => {
    setValidError(null);
    setCoords(null);

    const lines = text
      .trim()
      .split('\n')
      .filter((x) => x !== '');
    if (lines.length < 4) {
      setValidError('Координат должно быть 4 или больше');
      return;
    }
    const newCoords: CoordItem[] = [];
    lines.forEach((line, index) => {
      if (!validLineRegex.test(line)) {
        setValidError(`В строке ${index + 1} ошибка`);
        return;
      }
      const lineCoords = line.split('\t').map((x) => Number.parseFloat(x.trim().replace(',', '.')));

      newCoords.push({
        mskX: lineCoords[0],
        mskY: lineCoords[1],
        wgsX: lineCoords[2],
        wgsY: lineCoords[3],
      });
    });
    setCoords(newCoords);
  }, [text]);

  const okButtonProps = React.useMemo(() => {
    return { disabled: validError !== null || coords === null };
  }, [validError, coords]);
  const onOkHandler = React.useCallback(() => {
    if (coords) {
      onSuccess(coords);
      onClose();
    }
  }, [onSuccess, coords, onClose]);

  return (
    <Modal
      visible={true}
      onCancel={onClose}
      title={'Импорт координат'}
      okButtonProps={okButtonProps}
      onOk={onOkHandler}
    >
      <div className={'coords-import-modal'}>
        {coords && (
          <Table dataSource={coords} pagination={false} size={'small'}>
            <Table.Column title={'mskX'} dataIndex={'mskX'} />
            <Table.Column title={'mskY'} dataIndex={'mskY'} />
            <Table.Column title={'wgsX'} dataIndex={'wgsX'} />
            <Table.Column title={'wgsY'} dataIndex={'wgsY'} />
          </Table>
        )}

        <Input.TextArea
          className={'coords-import-modal__text-area'}
          onKeyDown={onKeyDownTabHandler}
          value={text}
          onChange={onChangeTextAreaHandler}
          rows={5}
          placeholder={'Скопируйте сюда ячейки из excel'}
        />

        {validError && <div className={'coords-import-modal__error-message'}>{validError}</div>}
      </div>
    </Modal>
  );
};
const VisibleModal = React.memo(_VisibleModal);
