import { Col } from 'antd';

function getRandomInt(min: number, max: number) {
  min = Math.ceil(min);
  max = Math.floor(max);
  return Math.floor(Math.random() * (max - min + 1)) + min;
}

export const createFinanceTable = (obj?: any, isNow?: boolean) => {
  const activeRandom = getRandomInt(0, 50);
  const usersRandom = getRandomInt(0, 10);
  const tokenRandom = getRandomInt(0, 100);
  const spaceRandom = getRandomInt(0, 100);

  const activeAvailable = 50 - activeRandom;
  const usersAvailable = 10 - usersRandom;
  const tokenAvailable = 100 - tokenRandom;
  const spaceAvailable = 100 - spaceRandom;

  if (isNow) {
    return (
      <>
        <Col span={5} className='management-finance__left-card-data'>
          {activeRandom}
          <span className='management-finance__left-card-span'>
            {activeAvailable ? `еще ${activeAvailable} доступно` : 'лимит исчерпан'}
          </span>
        </Col>
        <Col span={5} className='management-finance__left-card-data'>
          {usersRandom}
          <span className='management-finance__left-card-span'>
            {usersAvailable ? `еще ${usersAvailable} доступно` : 'лимит исчерпан'}
          </span>
        </Col>
        <Col span={5} className='management-finance__left-card-data'>
          {tokenRandom}
          <span className='management-finance__left-card-span'>
            {tokenAvailable ? `еще ${tokenAvailable} доступно` : 'лимит исчерпан'}
          </span>
        </Col>
        <Col span={5} className='management-finance__left-card-data'>
          {spaceRandom}{' '}
          <span className='management-finance__left-card-span'>
            {spaceAvailable ? `еще ${spaceAvailable} Гб. доступно` : 'лимит исчерпан'}
          </span>
        </Col>
      </>
    );
  }

  return (
    <>
      <Col className='management-finance__left-card-data' span={5}>
        50
      </Col>
      <Col span={5} className='management-finance__left-card-data'>
        10
      </Col>
      <Col span={5} className='management-finance__left-card-data'>
        100 <span className='management-finance__left-card-span'>в мес.</span>
      </Col>
      <Col span={5} className='management-finance__left-card-data'>
        100<span className='management-finance__left-card-span'>Гб. в мес.</span>
      </Col>
    </>
  );
};
