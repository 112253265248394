import React from 'react';
import './styles.scss';

export type ExecResultPropProps = {
  label: string;
  children: React.ReactNode;
};

const _ExecResultProp: React.FC<ExecResultPropProps> = ({ label, children }) => {
  return (
    <div className='exec-result-prop'>
      <div className='exec-result-prop__label'>{label}:</div>
      <div className='exec-result-prop__value'>{children}</div>
    </div>
  );
};
export const ExecResultProp = React.memo(_ExecResultProp);
