import { api } from '@/api/axiosInstance';
import { RecordId } from '@/api/common/types';
import {
  CreateCoordinateRequest,
  CreateCoordinateResponse,
  GetCoordinateDetailResponse,
  GetPreviewRastrRequest,
  GetPreviewRastrResponse,
  UpdateCoordinateRequest,
  UpdateCoordinateResponse,
} from './types';

const rootUrl = '/coordinates';
export class CoordsApi {
  detail(id: RecordId & { _id?: string }) {
    return api.get<GetCoordinateDetailResponse>(`${rootUrl}/${id._id}`);
  }

  create(data: CreateCoordinateRequest) {
    return api.post<CreateCoordinateResponse>(`${rootUrl}`, data);
  }

  update(data: UpdateCoordinateRequest) {
    return api.patch<UpdateCoordinateResponse>(`${rootUrl}/${data.coordinateId}`, data.patch);
  }

  getPreviewRastr(data: GetPreviewRastrRequest) {
    return api.post<GetPreviewRastrResponse>('/cad_objects/preview_rastr', data);
  }
}
