import React, { useCallback } from 'react';
import { StarFilled, StarOutlined } from '@ant-design/icons/lib';

type ContractFavoriteProps = {
  curValue: boolean;
  onChange: () => void;
};

export const ContractFavorite: React.FC<ContractFavoriteProps> = React.memo(
  ({ curValue, onChange }) => {
    const onClickHandler = useCallback(
      (e: React.MouseEvent<HTMLSpanElement>) => {
        e.stopPropagation();
        onChange();
      },
      [onChange],
    );

    return curValue ? (
      <StarFilled
        onClick={onClickHandler}
        className='contract__favorite_star contract__favorite_star-filled'
      />
    ) : (
      <StarOutlined
        onClick={onClickHandler}
        className='contract__favorite_star contract__favorite_star-outline'
      />
    );
  },
);
