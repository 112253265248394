import { ForwardButton } from '@/components/ForwardButton';
import { AppInput } from '@/components/inputs/AppInput';
import { PhoneNumberInput } from '@/components/inputs/special/PhoneNumberInput';
import { registerStateService } from '@/globalStorage/register';
import { errorMessages } from '@/utils/errorMessages';
import { requiredTelValidator } from '@/utils/validators';
import { Form, Input } from 'antd';
import { Rule } from 'antd/lib/form';
import React from 'react';
import { RegisterPersonalInfoForm } from './types';
import './styles.scss';

const requiredRules: Rule[] = [{ required: true, message: errorMessages.required }];
const emailRules: Rule[] = [
  ...requiredRules,
  { type: 'email' as const, message: 'Некорректный email' },
];
const phoneRules: Rule[] = [{ validator: requiredTelValidator }];
const repeatPasswordRules: Rule[] = [
  ...requiredRules,
  ({ getFieldValue }) => ({
    validator: (_, value?: string) => {
      if (!value || getFieldValue('password') === value) {
        return Promise.resolve();
      }
      return Promise.reject(new Error('Пароли должны совпадать'));
    },
  }),
];

const _RegisterPersonalInfoStep: React.FC = () => {
  const [form] = Form.useForm<RegisterPersonalInfoForm>();

  const onSubmitForm = (values: RegisterPersonalInfoForm) => {
    registerStateService.finishFirstStep(values);
  };

  return (
    <Form form={form} onFinish={onSubmitForm} autoComplete={'off'}>
      <Form.Item name={'secondName'} rules={requiredRules}>
        <AppInput placeholder='Фамилия*' className='register-step-one' />
      </Form.Item>
      <Form.Item name={'firstName'} rules={requiredRules}>
        <AppInput placeholder='Имя*' className='register-step-one' />
      </Form.Item>
      <Form.Item name={'patronymic'} rules={requiredRules}>
        <AppInput placeholder='Отчество*' className='register-step-one' />
      </Form.Item>
      <Form.Item required name={'email'} rules={emailRules}>
        <AppInput placeholder='E-mail*' className='register-step-one' />
      </Form.Item>
      <Form.Item name={'phone'} rules={phoneRules} className='register-step-one__phone'>
        <PhoneNumberInput
          className='register-step-one__phone'
          placeholder='Номер телефона*'
          block
        />
      </Form.Item>
      <Form.Item name={'password'} rules={requiredRules}>
        <Input.Password
          className='register-step-one__password'
          placeholder='Пароль*'
          autoComplete='new-password'
        />
      </Form.Item>
      <Form.Item
        required
        name={'repeatPassword'}
        dependencies={['password']}
        rules={repeatPasswordRules}
      >
        <Input.Password className='register-step-one__password' placeholder='Повторите пароль*' />
      </Form.Item>

      <ForwardButton htmlType='submit' block size='large' type='primary'>
        Далее
      </ForwardButton>
    </Form>
  );
};
export const RegisterPersonalInfoStep = React.memo(_RegisterPersonalInfoStep);
