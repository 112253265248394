import './user';
import './pd';
import './file';
import { createDomain } from 'effector';
import { initAuthInfoFx } from './user';

const domain = createDomain('initDomain');

export const initAppFx = domain.createEffect(async () => {
  await Promise.all([initAuthInfoFx()]);
});

export const $isInit = domain.createStore(false).on(initAppFx.finally, () => true);
