import { emailListValidator } from '@/utils/validators';
import { Form, Select } from 'antd';
import React from 'react';
import { TabContent } from '../../TabContent';

export type MailingListTabContentProps = {
  className?: string;
  style?: React.CSSProperties;
};

const _MailingListTabContent: React.FC<MailingListTabContentProps> = (props) => {
  return (
    <TabContent>
      <div style={{ marginBottom: 16 }}>
        Укажите E-mail адреса, на которые будет приходить рассылка со статусом по контракту.
        Рассылка высылается ежедневно в 18:00 по МСК.
      </div>
      <Form.Item
        label='E-mail адреса для рассылки статистики'
        name='emails'
        rules={[
          {
            validator: emailListValidator,
          },
        ]}
      >
        <Select mode='tags' style={{ width: '100%' }} tokenSeparators={[',']} />
      </Form.Item>
    </TabContent>
  );
};
export const MailingListTabContent = React.memo(_MailingListTabContent);
