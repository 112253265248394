/* eslint-disable max-len */
import React from 'react';

const _BackSvg: React.FC<React.SVGProps<SVGSVGElement>> = (props) => {
  return (
    <svg
      width='6'
      height='11'
      viewBox='0 0 6 11'
      fill='none'
      xmlns='http://www.w3.org/2000/svg'
      {...props}
    >
      <path
        d='M5 10C4.93442 10.0008 4.86941 9.98787 4.80913 9.96203C4.74885 9.9362 4.69465 9.89804 4.65 9.85L0.15 5.35C-0.05 5.15 -0.05 4.84 0.15 4.64L4.65 0.15C4.85 -0.05 5.16 -0.05 5.36 0.15C5.56 0.35 5.56 0.66 5.36 0.86L1.21 5L5.36 9.15C5.56 9.35 5.56 9.66 5.36 9.86C5.26 9.96 5.13 10.01 5.01 10.01L5 10Z'
        fill='currentColor'
      />
    </svg>
  );
};
export const BackSvg = React.memo(_BackSvg);
