import { DocumentCollectionFilterItem } from '@/components/ServerConfigTable/types';
import React from 'react';
import { FilterDropdownActions } from '../common/FilterDropdownActions';
import { FilterDropdownContentBase } from '../common/FilterDropdownContentBase';
import { FilterTypeOption, FilterTypeSelect } from '../common/FilterTypeSelect';
import { existOption, notExistOption } from './consts';
import { DocumentCollectionFilterType, DocumentCollectionFilterValue } from './types';

export type DocumentCollectionFilterDropdownProps = {
  className?: string;
  style?: React.CSSProperties;

  filter: DocumentCollectionFilterValue;
  onChangeFilter: (value: DocumentCollectionFilterItem) => void;
  onResetFilter: () => void;
};

const _DocumentCollectionFilterDropdown = (props: DocumentCollectionFilterDropdownProps) => {
  const { className, style, filter, onChangeFilter, onResetFilter } = props;

  React.useEffect(() => {
    setUpdatedFilterState({
      filterAs: 'document-collection',
      filterType: 'exist',
    });
  }, [filter]);

  const [updatedFilterState, setUpdatedFilterState] =
    React.useState<DocumentCollectionFilterItem | null>({
      filterAs: 'document-collection',
      filterType: 'exist',
    });

  const filterTypeOptions = React.useMemo(() => {
    const options: FilterTypeOption<DocumentCollectionFilterType>[] = [existOption, notExistOption];
    return options;
  }, []);

  const [selectedFilterType, _setSelectedFilterType] =
    React.useState<DocumentCollectionFilterType>('exist');
  const changeFilterType = React.useCallback((filterType: DocumentCollectionFilterType) => {
    _setSelectedFilterType(filterType);
    if (filterType === 'exist' || filterType === 'notExist') {
      setUpdatedFilterState({
        filterAs: 'document-collection',
        filterType,
      });
    } else {
      setUpdatedFilterState(null);
    }
  }, []);

  return (
    <FilterDropdownContentBase className={className} style={style}>
      <FilterTypeSelect
        onChangeSelectedFilterType={changeFilterType}
        selectedFilterType={selectedFilterType}
        filteredType={filter?.filterType ?? null}
        options={filterTypeOptions}
      />
      <FilterDropdownActions
        canChangeFilter={updatedFilterState !== null}
        hasFilters={filter !== null && filter !== undefined}
        onSubmitChanges={() => {
          if (updatedFilterState === null) {
            onResetFilter();
          } else {
            onChangeFilter(updatedFilterState);
          }
        }}
        onResetFilter={onResetFilter}
      />
    </FilterDropdownContentBase>
  );
};
export const DocumentCollectionFilterDropdown = React.memo(_DocumentCollectionFilterDropdown);
