import React from 'react';
import cn from 'classnames';
import './styles.scss';
import { Skeleton } from 'antd';

type Props = {
  className?: string;
  style?: any;
};
export const ContractSkeleton: React.FC<Props> = (props) => {
  const { className, style } = props;

  return (
    <div className={cn('contract-skeleton', className)} style={style}>
      <Skeleton active title paragraph={{ rows: 3 }} />
    </div>
  );
};
