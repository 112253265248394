import React, { useCallback, useEffect, useMemo, useState } from 'react';
import { Col, message, Modal, Row, Transfer } from 'antd';
import { RecordId } from '@/api/common/types';
import { useGetAllColumnsQ } from '@/hooks/directory/columns';
import { useEditFieldListContractM, useGetContractColumnsByIdQ } from '@/hooks/contracts';

export type EditContractColumnsModalProps =
  | {
      visible: false;
    }
  | ({ visible: true } & VisibleProps);
type VisibleProps = {
  onClose: () => void;
  contractId: RecordId;
};
export const EditContractColumnsModal: React.FC<EditContractColumnsModalProps> = React.memo(
  (props) => {
    const { visible } = props;
    if (!visible) {
      return null;
    }
    const { visible: _, ...visibleProps } = props;
    return <VisibleModal {...visibleProps} />;
  },
);

function areSetsEqual<T>(a: Set<T>, b: Set<T>) {
  return a.size === b.size && Array.from(a).every((value) => b.has(value));
}

const VisibleModal: React.FC<VisibleProps> = React.memo((props) => {
  const { onClose, contractId } = props;
  const { data: allColumns, isLoading: isAllColumnsLoading } = useGetAllColumnsQ();
  // const allColumnsDisabledMapped = React.useMemo(() => {
  //   return allColumns?.map((x) => ({ ...x, disabled: x.isSystem }));
  // }, [allColumns]);
  const { data: currentColumns, isLoading: isCurrentColumnsIsLoading } =
    useGetContractColumnsByIdQ(contractId);

  const [targetColumnsKeys, setTargetColumnsKeys] = useState<RecordId[]>(
    currentColumns?.map((x) => x._id) || [],
  );
  useEffect(() => {
    setTargetColumnsKeys(currentColumns?.map((x) => x._id) || []);
  }, [currentColumns]);

  const isDirty = useMemo(() => {
    return !areSetsEqual(
      new Set(currentColumns?.map((x) => x._id) || []),
      new Set(targetColumnsKeys),
    );
  }, [currentColumns, targetColumnsKeys]);

  const editFieldListContractM = useEditFieldListContractM();

  const onOkHandler = useCallback(async () => {
    try {
      await editFieldListContractM.mutateAsync({ id: contractId, fieldList: targetColumnsKeys });
      onClose();
      message.success('Колонки в контракте успешно изменены');
    } catch (e) {
      message.error('Возникла ошибка при редактировании колонок');
    }
  }, [editFieldListContractM, targetColumnsKeys, contractId, onClose]);

  return (
    <Modal
      width={1000}
      visible={true}
      title={'Редактирование колонок в контракте'}
      okText={'Сохранить изменения'}
      onOk={onOkHandler}
      okButtonProps={{ disabled: !isDirty, loading: editFieldListContractM.isLoading }}
      cancelText={'Отмена'}
      onCancel={onClose}
    >
      <Row justify={'center'}>
        <Col>
          <Transfer
            titles={['Доступные', ['Выбранные']]}
            rowKey={(x) => x._id}
            dataSource={allColumns}
            targetKeys={targetColumnsKeys}
            disabled={isAllColumnsLoading || isCurrentColumnsIsLoading}
            onChange={setTargetColumnsKeys}
            render={(x) => x.name}
            showSearch
            listStyle={{ width: '250px', height: '500px' }}
            locale={{
              notFoundContent: 'Пусто',
              itemUnit: 'Всего',
              itemsUnit: 'Всего',
              searchPlaceholder: 'Введите имя',
              selectAll: 'Выбрать все',
              selectInvert: 'Инвертировать выбор',
            }}
          />
        </Col>
      </Row>
    </Modal>
  );
});
