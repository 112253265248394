import { CadObjectResponse } from '@/api/CadObjects/types';
import { RecordId } from '@/api/common/types';
import { TdRecordResponse } from '@/api/Td/types';
import { getPdColumns } from '@/components/modals/utils/pdColumns';
import { openCreatePdModal, openSelectAlreadyExistPdModal } from '@/globalStorage/pd';
import {
  useCadObjectTdsQ,
  useDeleteTdInCadObjectCollectionM,
  usePatchCadObjectM,
} from '@/hooks/cadastralObjects';
import { useGetContractPublicPdList } from '@/hooks/contracts';
import { ControlledTable } from '@uspect-team/ant-ui-kit';
import {
  ControlledTableColumns,
  ControlledTableState,
} from '@uspect-team/ant-ui-kit/dist/Tables/ControlledTable/types';
import { Button, message, Modal } from 'antd';
import React, { useCallback, useMemo, useState } from 'react';
import './styles.scss';

export type EditPdCollectionInCadModalProps =
  | {
      visible: false;
    }
  | ({ visible: true } & VisibleProps);

type VisibleProps = {
  title: string;
  contractId: RecordId;
  onClose?: () => void;
  cadObjectId: string;
  dataIndex: string;
};
export const EditPdCollectionInCadModal: React.FC<EditPdCollectionInCadModalProps> = React.memo(
  (props) => {
    const { visible } = props;

    if (!visible) {
      return null;
    }
    const { visible: _, ...visibleProps } = props;

    return <VisibleModal {...visibleProps} />;
  },
);

const VisibleModal: React.FC<VisibleProps> = React.memo((props) => {
  const { title, onClose, cadObjectId, dataIndex, contractId } = props;
  const patchCadObjectM = usePatchCadObjectM();
  const deletePdM = useDeleteTdInCadObjectCollectionM();

  const { data: publicPd, isLoading: isLoadingPublicPd } = useGetContractPublicPdList(contractId);

  const { data: currentPds, isLoading } = useCadObjectTdsQ({
    id: cadObjectId,
    dataIndex: dataIndex as keyof CadObjectResponse,
  });

  const [tableState, setTableState] = useState<ControlledTableState>({
    pagination: { pageSize: 10, currentPage: 1 },
  });

  const columns: ControlledTableColumns<TdRecordResponse & { public?: boolean }> = useMemo(() => {
    return getPdColumns({
      onDelete: async (record) => {
        try {
          await deletePdM.mutateAsync({
            id: cadObjectId,
            dataIndex: dataIndex as keyof CadObjectResponse,
            deletePdId: record._id,
          });
        } catch (e) {
          message.error('Произошла ошибка при удалении ПД');
        }
      },
    });
  }, [cadObjectId, dataIndex, deletePdM]);

  const addNewPdInCollection = useCallback(
    async (pd: TdRecordResponse) => {
      try {
        const currentPdIds = currentPds?.map((x) => x._id);

        await patchCadObjectM.mutateAsync({
          id: cadObjectId,
          patch: {
            [dataIndex]: [...(currentPdIds || []), pd._id],
          },
        });
      } catch (e) {
        message.error('Не удалось загрузить ПД');
      }
    },
    [cadObjectId, currentPds, dataIndex, patchCadObjectM],
  );

  const onClickCreateNewPdHandler = useCallback(() => {
    openCreatePdModal({
      contractId,
      onCreate: addNewPdInCollection,
      title: 'Создание нового ПД',
    });
  }, [addNewPdInCollection, contractId]);

  const onClickSelectAlreadyExistPdHandler = useCallback(() => {
    openSelectAlreadyExistPdModal({
      contractId,
      title: 'Выбор существующего ПД',
      onSelectPd: addNewPdInCollection,
    });
  }, [contractId, addNewPdInCollection]);

  const dataUnion: (TdRecordResponse & { public?: boolean })[] = [
    ...(publicPd ?? []).map((x) => ({
      ...x,
      public: true,
    })),
    ...(currentPds ?? []),
  ];

  return (
    <Modal visible={true} title={title} width={1000} onCancel={onClose} footer={null}>
      <div className='edit-pd-collection-in-cad-modal'>
        <div className='edit-pd-collection-in-cad-modal__actions'>
          <Button onClick={onClickCreateNewPdHandler}>Создать новый ПД</Button>
          <div>ИЛИ</div>
          <Button onClick={onClickSelectAlreadyExistPdHandler}>Добавить из уже загруженных</Button>
        </div>

        <ControlledTable
          rowKey={'_id'}
          tableState={tableState}
          onChangeTableState={setTableState}
          columns={columns}
          pagination={{ type: 'client', pageSizeOptions: [10] }}
          dataSource={dataUnion}
          loading={isLoading || isLoadingPublicPd}
          rowClassName={(record) =>
            record.public ? 'edit-pd-collection-in-cad-modal__disabled-row' : ''
          }
          tableLayout='fixed'
          scroll={{ x: '100%' }}
        />
      </div>
      {/* <Space style={{ marginBottom: '16px' }}></Space>
      <details style={{ marginBottom: '16px' }}>
        <summary>Общие ПД для этого контракта</summary>
        <Table
          dataSource={publicPd}
          loading={isLoadingPublicPd}
          pagination={false}
          columns={getPdColumns()}
        />
      </details> */}
    </Modal>
  );
});
