import { Dropdown } from 'antd';
import { DropdownButtonProps } from 'antd/lib/dropdown';
import React from 'react';
import cn from 'classnames';

import './styles.scss';

export type ButtonWithMenuProps = {
  // className?: string;
  // style?: React.CSSProperties;
  // children: React.ReactNode;
  // overlay: ReactElement<any, string | JSXElementConstructor<any>>;
  // onMainButtonClick?: () => void;
} & DropdownButtonProps;

const _ButtonWithMenu: React.FC<ButtonWithMenuProps> = (props) => {
  const { children, className, ...dropdownButtonProps } = props;
  return (
    <Dropdown.Button
      size='small'
      className={cn(className, 'button-with-menu')}
      overlayClassName={'button-with-menu__overlay'}
      trigger={['click']}
      {...dropdownButtonProps}
    >
      {children}
    </Dropdown.Button>
  );
};
export const ButtonWithMenu = React.memo(_ButtonWithMenu);
