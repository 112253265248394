import { useMutation, useQuery, useQueryClient } from 'react-query';
import { Api } from '@/api';
import { RecordId } from '@/api/common/types';
import { createUseFetchingStateObserver } from '@/hooks/utils';
import { displayModsQueryKeys } from './queryKeys';
import { GetDisplayModePageRequest } from '@/api/Dictionary/DisplayMods/types';

export const useGetDisplayModsPageQ = (data: GetDisplayModePageRequest) => {
  return useQuery(
    displayModsQueryKeys.page(data),
    () => {
      return Api.dictionary.displayMods.getPage(data);
    },
    {
      select: (res) => res.data,
    },
  );
};

export const useDisplayModePageFetchingState = createUseFetchingStateObserver(
  displayModsQueryKeys.pages(),
);

export const useGetAllDisplayModsQ = () => {
  const query = { page: 1, limit: 1000 };
  return useQuery(
    displayModsQueryKeys.page(query),
    () => {
      return Api.dictionary.displayMods.getPage(query);
    },
    {
      select: (res) => res.data.data.sort((a, b) => a.sort - b.sort),
    },
  );
};

export const useGetDisplayModeByIdQ = (id: RecordId) => {
  return useQuery(
    displayModsQueryKeys.detail(id),
    () => {
      return Api.dictionary.displayMods.getById(id);
    },
    { select: (data) => data.data.data },
  );
};

export const useCreateDisplayModeM = () => {
  const queryClient = useQueryClient();
  return useMutation(displayModsQueryKeys.create(), Api.dictionary.displayMods.create, {
    onSuccess: async (response) => {
      await queryClient.invalidateQueries(displayModsQueryKeys.pages());
      queryClient.setQueriesData(displayModsQueryKeys.detail(response.data.data._id), response);
    },
  });
};

export const usePatchDisplayModeM = () => {
  const queryClient = useQueryClient();
  return useMutation(displayModsQueryKeys.patch(), Api.dictionary.displayMods.patch, {
    onSuccess: async (response, variables) => {
      await queryClient.invalidateQueries(displayModsQueryKeys.pages());
      queryClient.setQueriesData(displayModsQueryKeys.detail(variables.id), response);
    },
  });
};

export const useDeleteDisplayModeM = () => {
  const queryClient = useQueryClient();
  return useMutation(displayModsQueryKeys.delete(), Api.dictionary.displayMods.delete, {
    onSuccess: async (_, variables) => {
      await queryClient.invalidateQueries(displayModsQueryKeys.pages());
      await queryClient.invalidateQueries(displayModsQueryKeys.detail(variables));
    },
  });
};
