import { MainLayout } from '@/components/layouts/MainLayout';
import React, { useCallback, useEffect, useState } from 'react';
import { BoundaryHeader } from './components/BoundaryHeader';
import { BoundaryMenu } from './components/BoundaryMenu';
import { RecordId } from '@/api/common/types';
import { useLocation, useParams } from 'react-router-dom';
import { BoundaryInfo } from './components/BoundaryInfo';
import { BoundaryDocs } from './components/BoundaryDocs';
import { useGetBoundaryByIdQ, usePatchBoundaryM } from '@/hooks/boundary';
import { Form, message } from 'antd';
import { categoryPlane, typeUse } from './constants';
import moment from 'moment';
import { convertToFormCadastral, getCadastralPlots, findLabels, findValues } from './utils';

import './styles.scss';

const _BoundaryEditPage = () => {
  const params = useParams<{ id: RecordId }>();

  const id = params.id;

  const [changes, setChanges] = useState<any | null>(null);

  const { data: currentBoundary } = useGetBoundaryByIdQ(id);

  const patchBoundaryM = usePatchBoundaryM();

  const [formValues, setFormValues] = useState({});

  const [form] = Form.useForm<any>();

  useEffect(() => {
    if (currentBoundary) {
      form.setFieldsValue({
        ...currentBoundary,
        kptDate: currentBoundary.kptDate ? moment(currentBoundary.kptDate) : undefined,
        cadastralNumber: currentBoundary.cadastralNumber,
        square: currentBoundary.square,
        reason: currentBoundary.reason,
        conclusion: currentBoundary.conclusion,
        ...convertToFormCadastral(
          currentBoundary.cadastralPlots.map((el: any) => {
            return {
              ...el,
              kindUses: findValues(typeUse, el.kindUses),
            };
          }),
        ),
      });
    }
  }, [currentBoundary, form]);

  const onFinish = useCallback(async () => {
    if (changes) {
      try {
        const { isArchiveContract: _isArchiveContract, ...withoutTrash } = changes;

        const req = {
          id,
          patch: {
            cadastralNumber: withoutTrash.cadastralNumber,
            square: withoutTrash.square,
            reason: withoutTrash.reason,
            conclusion: withoutTrash.conclusion,
            ...(getCadastralPlots(formValues).length && {
              cadastralPlots: getCadastralPlots(formValues).map((el: any) => {
                return {
                  ...el,
                  landCategoryId: el.landCategoryId,
                  ...(el.landCategoryId && {
                    landCategory: findLabels(categoryPlane, [el.landCategoryId])[0],
                  }),
                  ...(el.kindUses.length && {
                    kindUses: findLabels(typeUse, el.kindUses), // findValues(typeUse, el.kindUses)
                  }),
                };
              }),
            }),
            kptNumber: withoutTrash.kptNumber,
            kptDate: withoutTrash.kptDate,
            dischargeEgrnPd: withoutTrash.dischargeEgrnPd?._id,
            kptIspOrg: withoutTrash.kptIspOrg,
          },
        } as any;
        await patchBoundaryM.mutateAsync(req);
        message.success(`Контракт "${currentBoundary?.title || ''}" изменен`);
      } catch (e) {
        message.error('При изменения контракта произошла ошибка');
      }
    }
  }, [changes, id, formValues, patchBoundaryM, currentBoundary?.title]);

  const { pathname } = useLocation();

  const currentPath = pathname.split('/').slice(-1)[0];

  return (
    <MainLayout hideFooter>
      <BoundaryHeader
        onSave={form.submit}
        title={currentBoundary?.name || ''}
        contractId={currentBoundary?.contractId || ''}
      />
      <BoundaryMenu id={id} />
      <Form
        form={form}
        onFinish={onFinish}
        onValuesChange={(changes, values) => {
          for (const key in changes) {
            if (changes[key] === undefined) {
              changes[key] = null;
            }
          }
          setFormValues(values);
          setChanges((prevState: any) => ({ ...prevState, ...changes }));
        }}
        layout={'vertical'}
      >
        <div className='boundary__form'>
          {currentPath === 'info' && <BoundaryInfo data={currentBoundary} />}
          {currentPath === 'docs' && <BoundaryDocs />}
        </div>
      </Form>
    </MainLayout>
  );
};

const BoundaryEditPage = React.memo(_BoundaryEditPage);

export default BoundaryEditPage;
