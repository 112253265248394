import React from 'react';
import { Button, Dropdown, Menu, Popconfirm, Space, Tooltip } from 'antd';
import {
  CloudUploadOutlined,
  DeliveredProcedureOutlined,
  UnorderedListOutlined,
  UploadOutlined,
} from '@ant-design/icons/lib';
import './styles.scss';
import { EditableDocument } from '@uspect-team/ant-ui-kit';
import { EditableDocumentProps } from '@uspect-team/ant-ui-kit/dist/Editable/EditableDocument';

type Props = EditableDocumentProps & {
  onLoadClick?: () => void;
  onLoadAlreadyExist?: () => void;
  onGenMif?: () => void;
  onDelete?: () => void;
};
export const MifCell: React.FC<Props> = React.memo((props) => {
  const { onLoadClick, onLoadAlreadyExist, onGenMif, ...editableDocumentProps } = props;

  return (
    <EditableDocument
      {...editableDocumentProps}
      actions={
        <Space>
          <Dropdown
            overlay={
              <Menu>
                <Menu.Item key={'load'} icon={<CloudUploadOutlined />} onClick={onLoadClick}>
                  Загрузить файл
                </Menu.Item>
                <Menu.Item
                  key={'exist'}
                  icon={<UnorderedListOutlined />}
                  onClick={onLoadAlreadyExist}
                >
                  Выбрать из существующих
                </Menu.Item>
              </Menu>
            }
          >
            <Button icon={<UploadOutlined />} />
          </Dropdown>
          {onGenMif && (
            <Tooltip title={'Сгенерировать MIF из XML'} placement={'bottom'}>
              <Popconfirm
                title={'Вы уверены, что хотите сгененрировать MIF из XML?'}
                okText={'Да'}
                cancelText={'Нет'}
                onConfirm={onGenMif}
                placement={'top'}
              >
                <Button icon={<DeliveredProcedureOutlined />} />
              </Popconfirm>
            </Tooltip>
          )}
        </Space>
      }
    />
  );
  // return (
  //   <div className={cn('xml-cell', className)} style={style}>
  //     <div className={'xml-cell__actions'}>
  //       <Dropdown
  //         overlay={
  //           <Menu>
  //             <Menu.Item key={'load'} icon={<CloudUploadOutlined />} onClick={onLoadClick}>
  //               Загрузить файл
  //             </Menu.Item>
  //             <Menu.Item
  //               key={'exist'}
  //               icon={<UnorderedListOutlined />}
  //               onClick={onLoadAlreadyExist}>
  //               Выбрать из существующих
  //             </Menu.Item>
  //           </Menu>
  //         }>
  //         <Button icon={<UploadOutlined />} />
  //       </Dropdown>
  //       {onGenMif && (
  //         <Tooltip title={'Сгенерировать MIF из XML'} placement={'bottom'}>
  //           <Popconfirm
  //             title={'Вы уверены, что хотите сгененрировать MIF из XML?'}
  //             okText={'Да'}
  //             cancelText={'Нет'}
  //             onConfirm={onGenMif}
  //             placement={'top'}>
  //             <Button icon={<DeliveredProcedureOutlined />} />
  //           </Popconfirm>
  //         </Tooltip>
  //       )}
  //     </div>
  //
  //     {document && (
  //       <div className={'xml-cell__document'}>
  //         <a
  //           href={'/error'}
  //           target={'_blank'}
  //           rel='noreferrer'
  //           className={'xml-cell__document-name'}>
  //           {document.fileName}
  //         </a>
  //         <div className='xml-cell__document-size'>({fileSizeToString(document.size)})</div>
  //         <Popconfirm
  //           title={'Вы уверены?'}
  //           okText={'Да'}
  //           cancelText={'Отмена'}
  //           onConfirm={onDelete}>
  //           <Button
  //             className='xml-cell__document-delete-button'
  //             icon={<DeleteOutlined />}
  //             type={'text'}
  //             size={'small'}
  //           />
  //         </Popconfirm>
  //       </div>
  //     )}
  //   </div>
  // );
});
