import { AppstoreFilled } from '@ant-design/icons';
import React from 'react';
import { HeaderIconButton } from '../../components/HeaderIconButton';
import { CompanyIconOverlay } from './CompanyIconOverlay';

const _CompanyIconButton: React.FC = () => {
  return (
    <HeaderIconButton
      Icon={AppstoreFilled}
      overlay={<CompanyIconOverlay />}
      className='company-icon-button'
      size='large'
    />
  );
};
export const CompanyIconButton = React.memo(_CompanyIconButton);
