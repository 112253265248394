import React from 'react';
import './styles.scss';

export type ExecInProcessContainerProps = {
  children?: React.ReactNode;
};

const ExecInProcessContainerQueue: React.FC = ({ children }) => {
  return <div className='exec-in-process-container__queue'>{children}</div>;
};

const ExecInProcessContainerExec: React.FC = ({ children }) => {
  return <div className='exec-in-process-container__exec'>{children}</div>;
};

const ExecInProcessContainerHistory: React.FC = ({ children }) => {
  return <div className='exec-in-process-container__history'>{children}</div>;
};

const _ExecInProcessContainer: React.FC<ExecInProcessContainerProps> & {
  Queue: typeof ExecInProcessContainerQueue;
  ExecItem: typeof ExecInProcessContainerExec;
  History: typeof ExecInProcessContainerHistory;
} = ({ children }) => {
  return <div className='exec-in-process-container'>{children}</div>;
};

_ExecInProcessContainer.Queue = ExecInProcessContainerQueue;
_ExecInProcessContainer.ExecItem = ExecInProcessContainerExec;
_ExecInProcessContainer.History = ExecInProcessContainerHistory;

export const ExecInProcessContainer = _ExecInProcessContainer;
