import { RecordId } from '@/api/common/types';
import { TpRecordResponse } from '@/api/Coordinates/Tp/types';
import { coordinateService } from '@/globalStorage/coordinates/newIndex';
import { useGetTpsAllQ } from '@/hooks/coordinates/tp';
import { Select, SelectProps } from 'antd';
import { useStoreMap } from 'effector-react';
import React, { useCallback } from 'react';

type Props = {
  value?: RecordId | null;
  onChange?: (value?: RecordId) => void;
  onChangeTp?: (value?: TpRecordResponse) => void;
} & Omit<SelectProps<string>, 'value' | 'onChange'>;
const _SelectTp: React.FC<Props> = (props) => {
  const { value, onChange, onChangeTp: onChangeContract, ...selectProps } = props;

  const existTpListId = useStoreMap(coordinateService.$tpList, (tpList) =>
    tpList.map((x) => x._id),
  );

  const { data: allTdList, isLoading, isSuccess } = useGetTpsAllQ();
  const onChangeHandler = useCallback(
    (value: RecordId) => {
      onChange && onChange(value);
      onChangeContract && onChangeContract(allTdList?.find((x) => x._id === value));
    },
    [onChange, onChangeContract, allTdList],
  );

  const notExistTps = allTdList?.filter((td) => !existTpListId.includes(td._id));

  return (
    <Select
      loading={isLoading}
      value={isSuccess ? value : undefined}
      onChange={onChangeHandler}
      optionFilterProp='children'
      filterOption={(input, option) => {
        const childrenText = option?.children as string | null | undefined;
        return childrenText?.toLowerCase().includes(input.toLowerCase()) || false;
      }}
      {...selectProps}
    >
      {notExistTps?.map((x) => (
        <Select.Option key={x._id} value={x._id}>
          {x.title}
        </Select.Option>
      ))}
    </Select>
  );
};
export const SelectTp = React.memo(_SelectTp);
