import { MainLayout } from '@/components/layouts/MainLayout';
import { SelectSearch } from '@/components/SelectSearch';
import { useGetContractsAllQ } from '@/hooks/contracts';
import { StatisticBar } from '@/pages/MainTablePage/StatisticBar';
import { routes } from '@/resources/routes';
import { Select } from 'antd';
import { useEvent } from 'effector-react';
import React, { useCallback, useEffect } from 'react';
import { useHistory, useParams } from 'react-router-dom';
import { MainTablePageModals } from './MainTablePageModals';
import { MainTablePageSubHeader } from './MainTablePageSubHeader';
import { MainTablePageTable } from './MainTablePageTable';
import { setContractId } from './model';
import './styles.scss';

const _MainTablePage = () => {
  const history = useHistory();

  const { data: contractList, isLoading: contractListIsLoading } = useGetContractsAllQ();
  const setGlobalContractId = useEvent(setContractId);

  const { id: contractId } = useParams<{ id: string }>();
  const onChangeContract = useCallback(
    (id: string) => {
      history.push(routes.contractDetail(id));
    },
    [history],
  );
  useEffect(() => {
    setGlobalContractId(contractId);
  }, [contractId, setGlobalContractId]);

  return (
    <MainLayout
      className={'main-table-page'}
      hideFooter
      hideLogoName
      leftAddition={
        <SelectSearch
          value={!contractListIsLoading && contractId}
          className={'main-table-page__search-select'}
          variant={'dark'}
          onChange={onChangeContract}
          loading={contractListIsLoading}
        >
          {contractList?.map((contract) => (
            <Select.Option key={contract._id} value={contract._id}>
              {contract.title}
            </Select.Option>
          ))}
        </SelectSearch>
      }
    >
      <MainTablePageSubHeader />

      <div className='main-table-page__content'>
        <MainTablePageTable />
        <StatisticBar contractId={contractId} className={'main-table-page__statistic-bar'} />
      </div>

      <MainTablePageModals />
    </MainLayout>
  );
};
const MainTablePage = React.memo(_MainTablePage);
export default MainTablePage;
