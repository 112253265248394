import React from 'react';
import cn from 'classnames';
import './styles.scss';
import { Link, LinkProps } from 'react-router-dom';

export type FooterLinkProps = LinkProps;

export const GuestLayoutFooterLink: React.FC<FooterLinkProps> = (props) => {
  const { className, children, ...linkProps } = props;

  return (
    <Link className={cn(className, 'guest-layout-footer-link')} {...linkProps}>
      {children}
    </Link>
  );
};
