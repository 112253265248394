import React from 'react';
import { DaDataParty, DaDataSuggestion, PartySuggestions } from 'react-dadata';
import cn from 'classnames';
import { daDataToken } from '../constants';
import './../dadataInputStyles.scss';

export type OrganizationInputProps = {
  className?: string;
  value?: DaDataSuggestion<DaDataParty>;
  onChange?: (value?: DaDataSuggestion<DaDataParty>) => void;
  inputProps?: React.HTMLProps<HTMLInputElement>;
};

const _OrganizationInput: React.FC<OrganizationInputProps> = (props) => {
  const { className, value, onChange, inputProps } = props;
  return (
    <PartySuggestions
      value={value}
      inputProps={{ className: 'dadata-antd-input__input', ...inputProps }}
      token={daDataToken}
      onChange={onChange}
      containerClassName={cn('dadata-antd-input__container', className)}
      suggestionsClassName='dadata-antd-input__suggestions'
      suggestionClassName='dadata-antd-input__suggestion'
      currentSuggestionClassName='dadata-antd-input__suggestion--current'
      highlightClassName='organization-name-input__suggestion-highlight'
    />
  );
};
export const OrganizationInput = React.memo(_OrganizationInput);
