import React, { useCallback, useEffect, useState } from 'react';
import { Form, message, Modal } from 'antd';
import { CptFields } from '@/components/modals/cpts/common/CptFields';
import { CptPatchRequest } from '@/api/Dictionary/Cpt/types';
import { useGetCptByIdQ, usePatchCptM } from '@/hooks/directory/cpt';
import { EditCptFormFields } from '@/components/modals/cpts/EditCptModal/types';
import { RecordId } from '@/api/common/types';

export type EditCptModalProps =
  | {
      visible: false;
    }
  | ({ visible: true } & VisibleProps);
type VisibleProps = {
  onClose: () => void;
  cptId: RecordId;
};

export const EditCptModal: React.FC<EditCptModalProps> = React.memo((props) => {
  const { visible } = props;

  if (!visible) {
    return null;
  }

  const { visible: _, ...visibleProps } = props;
  return <VisibleModal {...visibleProps} />;
});

export const VisibleModal: React.FC<VisibleProps> = React.memo((props) => {
  const { onClose, cptId } = props;

  const { data: currentCpt, isLoading } = useGetCptByIdQ(cptId);

  const { mutateAsync: patchCptM, isLoading: isLoadingPatchCpt } = usePatchCptM();
  const [form] = Form.useForm<EditCptFormFields>();
  useEffect(() => {
    if (currentCpt) {
      form.setFieldsValue(currentCpt);
    }
  }, [currentCpt, form]);

  const [changes, setChanges] = useState<EditCptFormFields | null>(null);
  const onFinishFormHandler = useCallback(async () => {
    if (changes) {
      try {
        const patchReq = {
          id: cptId,
          patch: {
            ...changes,
            fileXML: changes.fileXML?._id,
            fileMIF: changes.fileMIF?._id,
          },
        } as CptPatchRequest;
        await patchCptM(patchReq);
        onClose();
        message.success('Объект КПТ изменен');
      } catch {
        message.error('При изменении объекта КПТ произошла ошибка');
      }
    }
  }, [patchCptM, onClose, cptId, changes]);

  return (
    <Modal
      visible={true}
      onCancel={onClose}
      title={isLoading ? 'Загрузка' : 'Изменение КПТ объекта'}
      okText={'Сохранить изменения'}
      onOk={form.submit}
      okButtonProps={{ disabled: changes === null, loading: isLoadingPatchCpt }}
    >
      <Form
        form={form}
        layout={'vertical'}
        onFinish={onFinishFormHandler}
        requiredMark={'optional'}
        onValuesChange={(changes) => setChanges((prevState) => ({ ...prevState, ...changes }))}
      >
        <CptFields />
      </Form>
    </Modal>
  );
});
