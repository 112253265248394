import React from 'react';
import cn from 'classnames';
import './styles.scss';

type Props = {
  className?: string;
  style?: any;
};

const DescriptionTitle: React.FC<Props> = React.memo((props) => {
  const { className, style, children } = props;

  return (
    <div className={cn('import-base__desc-title', className)} style={style}>
      {children}
    </div>
  );
});

const DescriptionExample: React.FC<Props & { img: string }> = React.memo((props) => {
  const { className, style, img } = props;

  return (
    <div className={cn('import-base__desc-example', className)} style={style}>
      <img className={'import-base__desc-example-img'} src={img} alt={'Пример'} />
    </div>
  );
});

const DescriptionListItem: React.FC<Props> = React.memo((props) => {
  const { className, style, children } = props;

  return (
    <li className={cn('import-base__list-item', className)} style={style}>
      {children}
    </li>
  );
});

const DescriptionList: React.FC<Props> & { Item: typeof DescriptionListItem } = (props) => {
  const { className, style, children } = props;

  return (
    <ol className={cn('import-base__list', className)} style={style}>
      {children}
    </ol>
  );
};
DescriptionList.Item = DescriptionListItem;

const Description: React.FC<Props> & {
  Title: typeof DescriptionTitle;
  Example: typeof DescriptionExample;
  List: typeof DescriptionList;
} = (props) => {
  const { className, style, children } = props;

  return (
    <div className={cn('import-base__desc', className)} style={style}>
      {children}
    </div>
  );
};
Description.Title = DescriptionTitle;
Description.Example = DescriptionExample;
Description.List = DescriptionList;

const ImportBase: React.FC<Props> & { Description: typeof Description } = (props) => {
  const { className, style, children } = props;

  return (
    <div className={cn('import-base', className)} style={style}>
      {children}
    </div>
  );
};
ImportBase.Description = Description;

export { ImportBase };
