import React from 'react';
import { DatePicker as AntDatePicker } from 'antd';
import { PickerProps } from 'antd/lib/date-picker/generatePicker';
import moment from 'moment';

type Props = {
  fullWidth?: boolean;
  value?: Date;
  onChange?: (value?: Date) => void;
} & Omit<PickerProps<moment.Moment>, 'value' | 'onChange'>;
const _DatePicker: React.FC<Props> = (props) => {
  const { value, onChange, fullWidth, ...otherProps } = props;

  const momentValue = React.useMemo(() => {
    if (value) {
      return moment(value);
    }
    return null;
  }, [value]);

  const onChangeHandler = React.useCallback(
    (value: moment.Moment | null) => {
      onChange && onChange(value === null ? undefined : value.toDate());
    },
    [onChange],
  );

  return (
    <AntDatePicker
      style={{ ...otherProps.style, width: fullWidth ? '100%' : otherProps.style?.width }}
      value={momentValue}
      onChange={onChangeHandler}
      {...otherProps}
    />
  );
};
export const DatePicker = React.memo(_DatePicker);
