import { CadObjectResponse } from '@/api/CadObjects/types';

export const getRowClassByCadObject = (cadObject: CadObjectResponse): string => {
  switch (cadObject.status) {
    case 'excluded':
      return 'server-config-table__excluded-row';
    case 'remarks':
      return 'server-config-table__remarks-row';
    case 'act':
      return 'server-config-table__done-row';
  }

  return 'server-config-table__default-row';
};
