import { RecordId } from '@/api/common/types';
import { useGetCoordinateSystemsListAllQ } from '@/hooks/coordinates/coordinateSystems';
import { Select, SelectProps } from 'antd';
import React from 'react';

export type SelectCoordinateSystemFieldProps = {
  value?: RecordId[];
  onChange?: (value: RecordId[]) => void;
} & Omit<SelectProps, 'value' | 'onChange' | 'filterOption' | 'optionFilterProp'>;

const _SelectCoordinateSystemField: React.FC<SelectCoordinateSystemFieldProps> = (props) => {
  const { data: coordinateSystemsList, isLoading } = useGetCoordinateSystemsListAllQ();
  return (
    <Select
      mode={'multiple'}
      allowClear
      showSearch
      optionFilterProp={'children'}
      filterOption={(input, option) => {
        const childrenText = option?.children as string | null | undefined;
        return childrenText?.toLowerCase().includes(input.toLowerCase()) || false;
      }}
      loading={isLoading}
      placeholder={'Выберите системы координат'}
      {...props}
    >
      {coordinateSystemsList?.map((i) => (
        <Select.Option key={i._id} value={i._id}>
          {i.title}
        </Select.Option>
      ))}
    </Select>
  );
};
export const SelectCoordinateSystemField = React.memo(_SelectCoordinateSystemField);
