import React from 'react';
import cn from 'classnames';

import './styles.scss';

export type FilterDropdownContentBaseProps = {
  className?: string;
  style?: React.CSSProperties;
  children?: React.ReactNode;
};

const _FilterDropdownContentBase: React.FC<FilterDropdownContentBaseProps> = (props) => {
  const { className, style, children } = props;
  return (
    <div className={cn('filter-dropdown-content-base', className)} style={style}>
      {children}
    </div>
  );
};
export const FilterDropdownContentBase = React.memo(_FilterDropdownContentBase);
