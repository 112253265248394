import { PopoverContainer } from '@/components/PopoverContainer';
import { companyStoreService } from '@/globalStorage/company';
import { Button, Col, Row, Space, Typography } from 'antd';
import { useStore } from 'effector-react';
import moment from 'moment';
import React, { useEffect, useState } from 'react';
import { useHistory } from 'react-router-dom';
import './styles.scss';

const _CompanyIconOverlay: React.FC = () => {
  const history = useHistory();

  const [datePaid, setDatePaid] = useState<string | undefined>();

  const { id, name, inn, ogrn, tariff, paidUpTo, isOverdue } = useStore(
    companyStoreService.$companyStore,
  );

  useEffect(() => {
    if (paidUpTo) {
      setDatePaid(moment(paidUpTo).format('DD.MM.YYYY'));
    } else {
      setDatePaid(moment(new Date()).format('DD.MM.YYYY'));
    }
  }, [paidUpTo]);

  return (
    <PopoverContainer theme='dark' className='company-icon-overlay'>
      <Typography className='company-overlay__title-field'>Активная организация:</Typography>
      <Typography className='company-overlay__title-text'>{name}</Typography>
      <Row gutter={13}>
        <Col span={8} className='company-overlay__subtitle-field'>
          ID в системе:
        </Col>
        <Col className='company-overlay__subtitle-text'>{id}</Col>
      </Row>
      <Row gutter={13}>
        <Col span={8} className='company-overlay__subtitle-field'>
          ИНН/ОГРН:
        </Col>
        <Col span={12} className='company-overlay__subtitle-text'>
          {inn}/{ogrn}
        </Col>
      </Row>
      <Row gutter={13}>
        <Col span={8} className='company-overlay__subtitle-field'>
          Тариф:
        </Col>
        <Col span={12} className='company-overlay__subtitle-text'>
          {tariff}
        </Col>
      </Row>
      <Row gutter={13}>
        <Col span={8} className='company-overlay__subtitle-field'>
          Оплачен до:
        </Col>
        <Col span={12} className='company-overlay__subtitle-text'>
          {isOverdue ? (
            <Button
              className='company-overlay__button-payment'
              type='text'
              danger
              size='small'
              style={{ marginLeft: '-7.5px' }}
            >
              Подписка не оплачена
            </Button>
          ) : (
            datePaid
          )}
        </Col>
      </Row>
      <Space direction='vertical' className='company-overlay__button-wrapper'>
        <Button
          type='primary'
          size='small'
          className='company-overlay__button-control'
          onClick={() => history.push('/management-organization/settings')}
        >
          Управление организацией
        </Button>

        <Button
          className='company-overlay__button-change-company'
          type='text'
          danger
          size='small'
          onClick={() => history.push('/companies')}
        >
          Сменить организацию
        </Button>
      </Space>
    </PopoverContainer>
  );
};
export const CompanyIconOverlay = React.memo(_CompanyIconOverlay);
