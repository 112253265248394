import React, { useMemo } from 'react';
import cn from 'classnames';
import { fileSizeToString } from '@/utils/fileSizeToString';
import { Button } from 'antd';
import { DeleteOutlined } from '@ant-design/icons/lib';
import './styles.scss';
import { formatDateToString } from '@uspect-team/ant-ui-kit';

export type FileCardValue = {
  name: string;
  size: number;
  link?: string;
  uploadAt?: 'now' | Date;
};

type FileCardCoupleProps = {
  className?: string;
  style?: any;
  title?: string;
  couple: [FileCardValue, FileCardValue];
  onDelete?: () => void;
};

type FileCardItemProps = {
  className?: string;
  style?: any;
  file: FileCardValue;
};

const FileCardItem: React.FC<FileCardItemProps> = React.memo((props) => {
  const { className, style, file } = props;
  return (
    <div className={cn('file-card-couple-item', className)} style={style}>
      <div className='file-card-couple-item__name'>{file.name}</div>
      <div className='file-card-couple-item__size'>{fileSizeToString(file.size)}</div>
      {file.uploadAt && (
        <div className='file-card-couple-item__uploadAt'>
          {file.uploadAt === 'now'
            ? 'Загружен только что'
            : `Загружен ${formatDateToString(file.uploadAt)}`}
        </div>
      )}
    </div>
  );
});
export const FileCardCouple: React.FC<FileCardCoupleProps> = React.memo((props) => {
  const { className, style, title, couple, onDelete } = props;

  const firstFile = useMemo(() => {
    return couple[0];
  }, [couple]);

  const secondFile = useMemo(() => {
    return couple[1];
  }, [couple]);

  return (
    <div className={cn('file-card-couple', className)} style={style}>
      {title && <div className='file-card-couple__title'>{title}</div>}
      <FileCardItem className={'file-card-couple__first'} file={firstFile} />
      <FileCardItem className={'file-card-couple__second'} file={secondFile} />
      {onDelete && (
        <Button
          className={'file-card-couple__delete'}
          onClick={onDelete}
          icon={<DeleteOutlined />}
          block
          type={'text'}
          danger
        />
      )}
    </div>
  );
});
