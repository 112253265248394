import React from 'react';
import { Button, message } from 'antd';
import { CopyOutlined } from '@ant-design/icons';
import './styles.scss';

type CopyCadObjNumbersButtonProps = {
  copyNumbers: number[];
};
const _CopyCadObjNumbersButton: React.FC<CopyCadObjNumbersButtonProps> = (props) => {
  const { copyNumbers } = props;
  const onClickHandler = React.useCallback(async () => {
    const text = copyNumbers.map((x) => x.toString()).join('\n');
    await navigator.clipboard.writeText(text);
    message.success('Номера объектов скопированы в буфер обмена');
  }, [copyNumbers]);
  return (
    <Button
      onClick={onClickHandler}
      icon={<CopyOutlined className={'copy-cad-obj-numbers-btn'} />}
      type={'text'}
    />
  );
};
export const CopyCadObjNumbersButton = React.memo(_CopyCadObjNumbersButton);
