import React from 'react';
import { GuestLayoutFooterContainer } from '../FooterContainer';
import { GuestLayoutFooterLink } from '../FooterLink';

const _DefaultGuestLayoutFooter: React.FC = () => {
  return (
    <GuestLayoutFooterContainer>
      <GuestLayoutFooterLink to={'#'}>Политика конфиденциальности</GuestLayoutFooterLink>
      <GuestLayoutFooterLink to={'#'}>Запросить демо-доступ</GuestLayoutFooterLink>
    </GuestLayoutFooterContainer>
  );
};
export const DefaultGuestLayoutFooter = React.memo(_DefaultGuestLayoutFooter);
