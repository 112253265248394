import { LoginPage } from '@/pages/LoginPage';
import { PasswordRestoreConfirmedPage } from '@/pages/PasswordRestoreConfirmedPage';
import { PasswordRestorePage } from '@/pages/PasswordRestorePage';
import { RegisterPage } from '@/pages/RegisterPage';
import { routes } from '@/resources/routes';
import React from 'react';
import { Redirect, Route, Switch } from 'react-router-dom';

const _GuestRouter: React.FC = () => {
  return (
    <Switch>
      <Route path={routes.login} exact>
        <LoginPage />
      </Route>
      <Route path={routes.registration}>
        <RegisterPage />
      </Route>

      <Route path={routes.passwordRestore} exact>
        <PasswordRestorePage />
      </Route>

      <Route path={routes.passwordRestoreConfirmed} exact>
        <PasswordRestoreConfirmedPage />
      </Route>

      <Route>
        <Redirect to={routes.login} />
      </Route>
    </Switch>
  );
};
export const GuestRouter = React.memo(_GuestRouter);
