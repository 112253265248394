import { RecordId } from '@/api/common/types';
import { AntdLabelMimic } from '@/components/formHelpers/AntdLabelMimic';
import { CadObjectNumbersTextarea } from '@/components/inputs/CadObjectNumbersTextarea';
import { StepContent } from '@/components/StepContent';
import { useCadObjectsAutocompleteM } from '@/hooks/cadastralObjects';

import { CheckCircleOutlined, CloseCircleOutlined, LoadingOutlined } from '@ant-design/icons';
import {
  Button,
  Checkbox,
  Col,
  Input,
  Modal,
  Popconfirm,
  Row,
  Space,
  Spin,
  Steps,
  Typography,
} from 'antd';
import moment from 'moment';
import React, { useCallback } from 'react';
import './styles.scss';

export type FieldFillingWizardModalProps =
  | {
      visible: false;
    }
  | ({ visible: true } & VisibleProps);
type VisibleProps = {
  contractId: RecordId;
  initialCadObjectNumbers?: number[];
  onClose: () => void;
};
enum ETechPlanStatus {
  Ready = 'готово',
  Error = 'ошибка',
  InWork = 'в работе',
}
const { Text, Title, Paragraph } = Typography;

const _FieldFillingGenTechPlanModal: React.FC<FieldFillingWizardModalProps> = (props) => {
  const { visible } = props;

  if (!visible) {
    return null;
  }

  const { visible: _, ...visibleProps } = props;
  return <VisibleModal {...visibleProps} />;
};
export const FieldFillingGenTechPlanModal = React.memo(_FieldFillingGenTechPlanModal);

const _VisibleModal: React.FC<VisibleProps> = (props) => {
  const { onClose, initialCadObjectNumbers } = props;
  const autocompleteM = useCadObjectsAutocompleteM();
  const [currentStep, setCurrentStep] = React.useState(0);
  const onClickNextStepHandler = React.useCallback(() => {
    return setCurrentStep((prevState) => prevState + 1);
  }, []);

  const [cadObjectNumbers, setCadObjectNumbers] = React.useState<number[]>([]);
  const [autocompleteAll, setAutocompleteAll] = React.useState<boolean>(false);

  const TempData = cadObjectNumbers.map((item) => ({
    number: item,
    status: ETechPlanStatus.Ready,
    genTime: moment(new Date(100000)).format('hh:mm:ss'),
    addInfo: 'Точки не вошли в диапазон',
  }));

  const totalGenTime = moment(new Date(100000 * TempData.length)).format('hh:mm:ss');

  const footer = React.useMemo(() => {
    switch (currentStep) {
      case 0:
        return (
          <Space>
            <Button onClick={onClose}>Отмена</Button>
            <Button
              type={'primary'}
              onClick={onClickNextStepHandler}
              disabled={!autocompleteAll && cadObjectNumbers.length === 0}
            >
              Следующий шаг
            </Button>
          </Space>
        );
      case 1:
        return (
          <Space>
            <Button onClick={onClose}>Отмена</Button>
            <Button type={'primary'} onClick={onClickNextStepHandler}>
              Следующий шаг
            </Button>
          </Space>
        );
      case 2:
        return (
          <Space>
            <Button onClick={onClose}>Отмена</Button>
            <Popconfirm title={'Вы точно уверены?'}>
              <Button type={'primary'} loading={autocompleteM.isLoading}>
                Завершить
              </Button>
            </Popconfirm>
          </Space>
        );
    }
  }, [
    currentStep,
    onClickNextStepHandler,
    autocompleteAll,
    cadObjectNumbers,
    onClose,
    autocompleteM.isLoading,
  ]);

  const statusItem = (status: ETechPlanStatus) => {
    switch (status) {
      case ETechPlanStatus.Error:
        return (
          <Space style={{ color: '#EC2727' }}>
            <CloseCircleOutlined />
            <Text type='danger'>{status}</Text>
          </Space>
        );

      case ETechPlanStatus.Ready:
        return (
          <Space style={{ color: '#98C997' }}>
            <CheckCircleOutlined />
            <Text type='success'>{status}</Text>
          </Space>
        );

      case ETechPlanStatus.InWork:
        return (
          <Space>
            <Spin indicator={<LoadingOutlined spin />} style={{ fontSize: 24, color: 'gray' }} />
            <Text>{status}...</Text>
          </Space>
        );
    }
  };

  const onCopyHandlerSuccess = useCallback(() => {
    const successNumbers = TempData.filter((item) => item.status === ETechPlanStatus.Ready).map(
      (item) => item.number,
    );

    navigator.clipboard.writeText(successNumbers.toString());
  }, [TempData]);
  const onCopyHandlerError = useCallback(() => {
    const errorNumbers = TempData.filter((item) => item.status === ETechPlanStatus.Error).map(
      (item) => item.number,
    );

    navigator.clipboard.writeText(errorNumbers.toString());
  }, [TempData]);

  const textAreaValue = `[
    { “num”: 100, “id”: “dasdas12312sds2”, “message”: “Точки не попали в диапазон”},
    { “num”: 100, “id”: “dasdas12312sds2”, “message”: “Точки не попали в диапазон”},
    { “num”: 100, “id”: “dasdas12312sds2”, “message”: “Точки не попали в диапазон”},
    ]`;

  return (
    <Modal
      width={1000}
      visible={true}
      onCancel={onClose}
      title={'Мастер генерации тех.плана'}
      footer={footer}
    >
      <Steps size='small' current={currentStep}>
        <Steps.Step title='Выбор объектов' />
        <Steps.Step title='Статус прогона' />
        <Steps.Step title='Результат' />
      </Steps>
      {currentStep === 0 && (
        <StepContent>
          <AntdLabelMimic>Заполните номера объектов (или вставте их из Excel)</AntdLabelMimic>
          <CadObjectNumbersTextarea
            onChange={setCadObjectNumbers}
            initValues={initialCadObjectNumbers}
            disabled={autocompleteAll}
          />
          <div className='field-filling-wizard-modal__selected-items-addition'>
            {autocompleteAll ? (
              <div className='field-filling-wizard-modal__selected-items'>
                Выбраны все объекты в контракте
              </div>
            ) : (
              cadObjectNumbers.length > 0 && (
                <div className='field-filling-wizard-modal__selected-items'>
                  Выбрано объектов: {cadObjectNumbers.length}
                </div>
              )
            )}

            <Checkbox
              className={'field-filling-wizard-modal__autocompleteAll-cbx'}
              checked={autocompleteAll}
              onChange={(e) => setAutocompleteAll(e.target.checked)}
            >
              Выбрать все объекты контракта
            </Checkbox>
          </div>
        </StepContent>
      )}
      {currentStep === 1 && (
        <StepContent>
          <Row>
            <Col span={6}>
              <Title level={5}> № Объекта</Title>
            </Col>
            <Col span={6}>
              <Title level={5}> Статус</Title>
            </Col>
            <Col span={6}>
              <Title level={5}> Время генерации</Title>
            </Col>
            <Col span={6}>
              <Title level={5}> Доп. информация</Title>
            </Col>
          </Row>
          {TempData.map(({ number, genTime, status, addInfo }) => (
            <Row key={number}>
              <Col span={6}>
                <Text>{number}</Text>
              </Col>
              <Col span={6}>{statusItem(status)}</Col>
              <Col span={6}>
                <Text>{genTime}</Text>
              </Col>
              <Col span={6}>
                <Text>{addInfo}</Text>
              </Col>
            </Row>
          ))}
        </StepContent>
      )}
      {currentStep === 2 && (
        <StepContent>
          <Paragraph> Общее время генерации: {totalGenTime}</Paragraph>
          <Paragraph>Всего объектов на генерацию: {TempData.length}</Paragraph>
          <Paragraph copyable={{ onCopy: onCopyHandlerSuccess }}>
            Успешно сгенерировано объектов: {TempData.length}
          </Paragraph>
          <Paragraph copyable={{ onCopy: onCopyHandlerError }}>
            С ошибками сгенерировано объектов: {TempData.length}
          </Paragraph>

          <Text strong>Информация по ошибкам:</Text>
          <Input.TextArea value={textAreaValue} disabled rows={6} />
        </StepContent>
      )}
    </Modal>
  );
};
const VisibleModal = React.memo(_VisibleModal);
