import React, { useCallback, useEffect, useState } from 'react';
import { Button, Form, message, Modal } from 'antd';
import { RecordId } from '@/api/common/types';
import { EditTpFormType } from './types';
import { useGetTpByIdQ, usePatchTpM } from '@/hooks/coordinates/tp';
import { PatchTpRequest } from '@/api/Coordinates/Tp/types';
import { TpFields } from '@/components/modals/tps/common/TpFields';
import {
  CoordsImportModal,
  CoordsImportModalProps,
} from '@/components/modals/tps/CoordsImportModal';

export type EditTpModalProps =
  | {
      visible: false;
    }
  | ({ visible: true } & VisibleProps);

type VisibleProps = {
  tpId: RecordId;
  onClose: () => void;
};

const _EditTpModal: React.FC<EditTpModalProps> = (props) => {
  const { visible } = props;

  if (!visible) {
    return null;
  }
  const { visible: _, ...visibleProps } = props;
  return <VisibleModal {...visibleProps} />;
};
export const EditTpModal = React.memo(_EditTpModal);

const _VisibleModal: React.FC<VisibleProps> = (props) => {
  const { onClose, tpId } = props;

  const [coordsImportModalState, setCoordsImportModalState] =
    React.useState<CoordsImportModalProps>({ visible: false });

  const { mutateAsync: patchTpM, isLoading: isLoadingUpdate } = usePatchTpM();
  const { data: currentTp, isLoading } = useGetTpByIdQ(tpId);

  const [form] = Form.useForm<EditTpFormType>();
  useEffect(() => {
    if (currentTp) {
      form.setFieldsValue({ ...currentTp, contract: currentTp.contract._id });
    }
  }, [currentTp, form]);
  const [changes, setChanges] = useState<EditTpFormType | null>(null);
  const isAutoCalculateWgs = form.getFieldValue('isAutoCalculateWgs');

  const onUpdateHandler = useCallback(async () => {
    if (changes) {
      try {
        const { isAutoCalculateWgs: _, ...changesPatch } = changes;
        const updateReq = {
          id: tpId,
          patch: {
            ...changesPatch,
          },
        } as PatchTpRequest;

        if (changesPatch.coords) {
          updateReq.isAutoCalculateWgs = isAutoCalculateWgs;
        }

        try {
          await patchTpM(updateReq);
          onClose();
          message.success('Запись успешно изменена');
        } catch (e) {
          message.error('При редактировании записи ТП произошла ошибка');
        }
      } catch (e) {
        message.error('Есть невалидные поля');
      }
    }
  }, [changes, tpId, isAutoCalculateWgs, patchTpM, onClose]);

  const onValuesChangeHandler = useCallback((changes: any, allFields: EditTpFormType) => {
    setChanges((prevState) => ({ ...prevState, ...changes }));
    if (changes.coords) {
      setChanges((prevState) => ({ ...prevState, coords: allFields.coords }));
    }
  }, []);

  const onOpenImportCoordsHandler = React.useCallback(() => {
    setCoordsImportModalState({
      visible: true,
      onClose: () => setCoordsImportModalState({ visible: false }),
      onSuccess: (coords) => {
        form.setFieldsValue({ coords });
        setChanges((prevState) => ({ ...prevState, coords }));
      },
    });
  }, [form]);

  return (
    <Modal
      visible={true}
      width={1000}
      onCancel={onClose}
      title={isLoading ? 'Загрузка...' : `${currentTp?.title || ''}: Редактирование записи`}
      okText={'Сохранить изменения'}
      okButtonProps={{ loading: isLoadingUpdate, disabled: changes === null }}
      onOk={form.submit}
    >
      <Form
        form={form}
        layout={'vertical'}
        wrapperCol={{ span: 24 }}
        requiredMark={'optional'}
        onFinish={onUpdateHandler}
        onValuesChange={onValuesChangeHandler}
        initialValues={{ isAutoCalculateWgs: true }}
      >
        <TpFields />
      </Form>
      <Button onClick={onOpenImportCoordsHandler}>Импорт из excel</Button>
      <CoordsImportModal {...coordsImportModalState} />
    </Modal>
  );
};
const VisibleModal = React.memo(_VisibleModal);
