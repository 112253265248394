import { GuestLayout } from '@/components/layouts/GuestLayout';
import React from 'react';
import { PasswordRestorePageForm } from './Form';
import { usePasswordRestorePage } from './hooks';
import './styles.scss';

const _PasswordRestorePage: React.FC = () => {
  const { onSubmitSendHandler, state } = usePasswordRestorePage();

  if (state.state === 'notSubmitted') {
    return (
      <GuestLayout className='password-restore-page'>
        <GuestLayout.ContentWithFooterContainer>
          <GuestLayout.ContentContainer>
            <GuestLayout.ContentHeader>
              <GuestLayout.Title>Восстановление пароля</GuestLayout.Title>

              <GuestLayout.Description>
                Введите свой E-mail, указанный при регистрации. На него придет письмо с дальнейшими
                инструкциями по восстановлению
              </GuestLayout.Description>
            </GuestLayout.ContentHeader>

            <PasswordRestorePageForm isLoading={state.isLoading} onSubmit={onSubmitSendHandler} />
          </GuestLayout.ContentContainer>
          <GuestLayout.DefaultFooter />
        </GuestLayout.ContentWithFooterContainer>
      </GuestLayout>
    );
  }

  return (
    <GuestLayout className='password-restore-page'>
      <GuestLayout.ContentWithFooterContainer>
        <GuestLayout.ContentContainer>
          <GuestLayout.ContentHeader>
            <GuestLayout.Title>Письмо отправлено на почту</GuestLayout.Title>

            <GuestLayout.Description>
              На вашу почту ({state.email}) было отправлено письмо с&nbsp;ссылкой восстановления
            </GuestLayout.Description>
          </GuestLayout.ContentHeader>
        </GuestLayout.ContentContainer>
        <GuestLayout.DefaultFooter />
      </GuestLayout.ContentWithFooterContainer>
    </GuestLayout>
  );
};
export const PasswordRestorePage = React.memo(_PasswordRestorePage);
