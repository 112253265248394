import React from 'react';
import { EditableDocument } from '@uspect-team/ant-ui-kit';
import { EditableDocumentProps } from '@uspect-team/ant-ui-kit/dist/Editable/EditableDocument';
import { Button, Dropdown, Menu } from 'antd';
import { CloudUploadOutlined, UnorderedListOutlined, UploadOutlined } from '@ant-design/icons/lib';

type Props = {
  onLoadClick?: () => void;
  onLoadAlreadyExistClick?: () => void;
  editable?: boolean;
} & Omit<EditableDocumentProps, 'actions'>;
export const EditableDocumentWithUploadAlreadyExist: React.FC<Props> = React.memo((props) => {
  const { onLoadClick, onLoadAlreadyExistClick, editable = true, ...editableDocumentProps } = props;

  return (
    <EditableDocument
      {...editableDocumentProps}
      actions={
        <Dropdown
          disabled={!editable}
          overlay={
            <Menu>
              <Menu.Item key={'load'} icon={<CloudUploadOutlined />} onClick={onLoadClick}>
                Загрузить файл
              </Menu.Item>
              <Menu.Item
                key={'exist'}
                icon={<UnorderedListOutlined />}
                onClick={onLoadAlreadyExistClick}
              >
                Выбрать из существующих
              </Menu.Item>
            </Menu>
          }
        >
          <Button icon={<UploadOutlined />} disabled={!editable} />
        </Dropdown>
      }
    />
  );
});
