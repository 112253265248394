import { CheckOutlined, CloseOutlined } from '@ant-design/icons';
import { FilterTypeOption } from '../common/FilterTypeSelect';
import React from 'react';
import { DecimalFilterType } from './types';

export const equalOption: FilterTypeOption<DecimalFilterType> = {
  value: 'equal',
  icon: '=',
  label: 'Равно',
};
export const betweenOption: FilterTypeOption<DecimalFilterType> = {
  value: 'between',
  icon: '>=',
  label: 'В диапазоне чисел',
};
export const existOption: FilterTypeOption<DecimalFilterType> = {
  value: 'exist',
  icon: <CheckOutlined />,
  label: 'Заполнено',
};
export const notExistOption: FilterTypeOption<DecimalFilterType> = {
  value: 'notExist',
  icon: <CloseOutlined />,
  label: 'Не заполнено',
};
