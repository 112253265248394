import { Api } from '@/api';
import { LoginRequest, UserInfoRecordResponse } from '@/api/Users/types';
import { authLocalStorage } from '@/utils/localStorage/AuthLocalStorageRepo';
import { combine, createDomain, forward } from 'effector';

const userDomain = createDomain('userDomain');

export const setAccessToken = userDomain.createEvent<string>('setAccessToken');
export const removeAccessToken = userDomain.createEvent('removeAccessToken');

export const setCurrentUserInfo =
  userDomain.createEvent<UserInfoRecordResponse>('setCurrentUserInfo');

export const removeCurrentUserInfo = userDomain.createEvent('removeUserInfo');

export const removeAuthInfo = userDomain.createEvent('removeAuthInfo');

const loadCurrentUserInfoFx = userDomain.createEffect(async () => {
  const data = await Api.users.me();
  setCurrentUserInfo(data.data.user);
  return data.data.user;
});

export const refreshAuthFx = userDomain.createEffect(async () => {
  const {
    data: { data },
  } = await Api.users.refresh();
  setAccessToken(data.accessToken);
  setCurrentUserInfo(data.user);

  return data;
});

export const initAuthInfoFx = userDomain.createEffect(async () => {
  const localAccessToken = authLocalStorage.getAccessToken();
  if (localAccessToken) {
    setAccessToken(localAccessToken);
    await loadCurrentUserInfoFx();
  } else {
    removeAccessToken();
  }
});

const updateLocalStorageAccessTokenFx = userDomain.createEffect(($accessToken: string | null) => {
  if ($accessToken) {
    authLocalStorage.setAccessToken($accessToken);
  } else {
    authLocalStorage.removeAccessToken();
  }
});

export const loginUserFx = userDomain.createEffect(async (request: LoginRequest) => {
  const response = await Api.users.login(request);
  setAccessToken(response.data.data.accessToken);
  setCurrentUserInfo(response.data.data.user);
});

export const logoutFx = userDomain.createEffect(async () => {
  await Api.users.logout();
  removeAuthInfo();
});

export const $accessToken = userDomain
  .createStore<string | null>(null)
  .on(setAccessToken, (_, token) => token)
  .reset([removeAccessToken, removeAuthInfo, refreshAuthFx.fail]);

export const $currentUserInfo = userDomain
  .createStore<UserInfoRecordResponse | null>(null)
  .on(setCurrentUserInfo, (_, data) => data)
  .reset([removeAuthInfo, removeCurrentUserInfo, refreshAuthFx.fail]);

export const $isAuth = combine(
  { $accessToken, $currentUserInfo },
  ({ $accessToken, $currentUserInfo }) => $accessToken !== null && $currentUserInfo !== null,
);

export const $isSuperAdmin = $currentUserInfo.map(
  (userInfo) => userInfo?.permissions.role === 'superadmin',
);
export const $isNotGuest = $currentUserInfo.map((userInfo) => {
  if (!userInfo) {
    return false;
  }

  return userInfo.permissions.role !== 'guest';
});

export const $currentUserName = $currentUserInfo.map((x) => x?.name ?? null);
export const $currentUserAvatarLetters = $currentUserInfo.map((user) => {
  if (!user) return null;

  const { name, secondName } = user;

  return `${name[0].toUpperCase()}${secondName[0].toUpperCase()}`;
});
export const $fullUserName = $currentUserInfo.map((user) => {
  if (!user) return null;

  const { name, secondName } = user;

  return `${name} ${secondName}`;
});
export const $userRoleName = $currentUserInfo.map((userInfo) => {
  if (!userInfo) {
    return null;
  }
  switch (userInfo.permissions.role) {
    case 'guest':
      return 'Гость';
    case 'user':
      return 'Пользователь';
    case 'superadmin':
      return 'Суперадминистратор';
    default:
      return 'Неизвестная роль';
  }
});

forward({ from: $accessToken, to: updateLocalStorageAccessTokenFx });
