import { GetColumnGroupsPageRequest } from '@/api/Dictionary/ColumnGroups/types';
import { useMutation, useQuery, useQueryClient } from 'react-query';
import { Api } from '@/api';
import { RecordId } from '@/api/common/types';
import { columnGroupsQueryKeys } from '@/hooks/directory/columnGroups/queryKeys';
import { createUseFetchingStateObserver } from '@/hooks/utils';

export const useGetColumnGroupPageQ = (data: GetColumnGroupsPageRequest) => {
  return useQuery(
    columnGroupsQueryKeys.page(data),
    () => {
      return Api.dictionary.columnGroups.getPage(data);
    },
    {
      select: (res) => res.data,
    },
  );
};

export const useColumnGroupPageFetchingState = createUseFetchingStateObserver(
  columnGroupsQueryKeys.pages(),
);

export const useGetByIdColumnGroupsQ = (id: RecordId) => {
  return useQuery(
    columnGroupsQueryKeys.detail(id),
    () => {
      return Api.dictionary.columnGroups.getById(id);
    },
    {
      select: (data) => data.data.data,
    },
  );
};

export const useGetAllColumnGroupsQ = () => {
  const query = { page: 1, limit: 1000 };
  return useQuery(
    columnGroupsQueryKeys.page(query),
    () => {
      return Api.dictionary.columnGroups.getPage(query);
    },
    {
      select: (data) => data.data.data,
    },
  );
};

export const useCreateColumnGroupM = () => {
  const queryClient = useQueryClient();
  return useMutation(columnGroupsQueryKeys.create(), Api.dictionary.columnGroups.create, {
    onSuccess: async (data) => {
      await queryClient.invalidateQueries(columnGroupsQueryKeys.pages());
      queryClient.setQueriesData(columnGroupsQueryKeys.detail(data.data.data._id), data);
    },
  });
};

export const usePatchColumnGroupM = () => {
  const queryClient = useQueryClient();
  return useMutation(columnGroupsQueryKeys.patch(), Api.dictionary.columnGroups.patch, {
    onSuccess: async (data) => {
      await queryClient.invalidateQueries(columnGroupsQueryKeys.pages());
      queryClient.setQueriesData(columnGroupsQueryKeys.detail(data.data.data._id), data);
    },
  });
};

export const useDeleteColumnGroupM = () => {
  const queryClient = useQueryClient();
  return useMutation(columnGroupsQueryKeys.delete(), Api.dictionary.columnGroups.delete, {
    onSuccess: async (_, variables) => {
      await Promise.all([
        queryClient.invalidateQueries(columnGroupsQueryKeys.pages()),
        queryClient.invalidateQueries(columnGroupsQueryKeys.detail(variables)),
      ]);
    },
  });
};
