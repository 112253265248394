import React from 'react';
import './styles.scss';

export type QueueCadObjectProps = {
  cadObjectNumber: number;
};

const _QueueCadObject: React.FC<QueueCadObjectProps> = ({ cadObjectNumber }) => {
  return <div className='queue-cad-object'>{cadObjectNumber}</div>;
};
export const QueueCadObject = React.memo(_QueueCadObject);
