import { createApi, createStore } from 'effector';

export const createVarStore = <StoreType, SetStoreType extends StoreType = StoreType>(
  initValue: StoreType,
) => {
  const $store = createStore<StoreType>(initValue);
  const { setStore, resetStore } = createApi($store, {
    setStore: (_, payload: SetStoreType) => payload,
    resetStore: () => initValue,
  });

  return {
    $store,
    setStore,
    resetStore,
  };
};
