import { CreateUserRequest } from '@/api/Users/types';
import { useCreateUserM } from '@/hooks/users';
import { Form, message, Modal } from 'antd';
import React, { useCallback } from 'react';
import { UserFields } from '../common/UserFields';

export type CreateUserModalProps =
  | {
      visible: false;
    }
  | ({ visible: true } & VisibleProps);

type VisibleProps = {
  onClose: () => void;
};
export const CreateUserModal: React.FC<CreateUserModalProps> = React.memo((props) => {
  const { visible } = props;

  if (!visible) {
    return null;
  }
  const { visible: _, ...visibleProps } = props;
  return <VisibleModal {...visibleProps} />;
});

export const VisibleModal: React.FC<VisibleProps> = React.memo((props) => {
  const { onClose } = props;

  const { mutateAsync: createUserM, isLoading: isLoadingCreate } = useCreateUserM();

  const [form] = Form.useForm();

  const onFormFinishHandler = useCallback(
    async (values: CreateUserRequest) => {
      try {
        await createUserM(values);
        message.success('Новый пользователь создан');
        onClose();
      } catch {
        message.error('Произошла ошибка при создании пользователя');
      }
    },
    [createUserM, onClose],
  );

  return (
    <Modal
      visible={true}
      width={1000}
      title={'Создание пользователя'}
      okText={'Создать пользователя'}
      onOk={() => {
        form.submit();
      }}
      okButtonProps={{
        disabled: isLoadingCreate,
        loading: isLoadingCreate,
      }}
      cancelText={'Отмена'}
      onCancel={onClose}
    >
      <Form
        form={form}
        onFinish={onFormFinishHandler}
        layout={'vertical'}
        initialValues={{ permissions: { role: 'guest' } }}
      >
        <UserFields />
      </Form>
    </Modal>
  );
});
