import { TariffRecord } from './types';
export const mockTariffs: Record<'demo' | 'professional', TariffRecord> = {
  demo: {
    id: 'mock_demo_id',
    name: 'Демонстрационный',
  },
  professional: {
    id: 'mock_professional_id',
    name: 'Профессиональный',
  },
};
