import {
  BaseServerChildColumnType,
  BooleanServerChildColumnAddition,
  ServerConfigTableContext,
  TableRecordType,
} from '../../types';
import React from 'react';
import { ControlledTableChildType } from '@uspect-team/ant-ui-kit/dist/Tables/ControlledTable/types';
import { dataIndexToStringKey } from '../../utils/dataIndexToStringKey';
import { Checkbox } from 'antd';
import { CheckboxChangeEvent } from 'antd/lib/checkbox';

export const getBooleanColumnAddition = (
  booleanSerCol: BaseServerChildColumnType & BooleanServerChildColumnAddition,
  context: ServerConfigTableContext,
  editable: boolean,
): Partial<ControlledTableChildType<TableRecordType>> => {
  const { dataIndex, isEditable, width } = booleanSerCol;
  const onEdit = context.onEdit;

  const handleOnChange = (event: CheckboxChangeEvent, record: TableRecordType) => {
    if (onEdit) {
      onEdit({
        patch: {
          [dataIndexToStringKey(dataIndex)]: event.target.checked,
        },
        oldRecord: record,
      });
    }
  };

  const onChange = onEdit ? handleOnChange : undefined;

  return {
    align: 'left',
    width: width ?? 150,
    render:
      isEditable && editable
        ? (value, record) => {
            if (record.status === 'excluded') {
              return <Checkbox checked={value} disabled />;
            }

            return (
              <Checkbox checked={value} onChange={(val) => onChange && onChange(val, record)} />
            );
          }
        : (value) => <Checkbox checked={value} disabled />,
  };
};
