import React, { useCallback, useMemo, useState } from 'react';
import { FileRecordResponse, FileType } from '@/api/Files/types';
import { Checkbox, DatePicker, Input, message, Modal, Select, Space } from 'antd';
import { useGetFilesPageQ } from '@/hooks/files';
import { ControlledTableState } from '@uspect-team/ant-ui-kit/dist/Tables/ControlledTable/types';
import { ControlledTable } from '@uspect-team/ant-ui-kit';
import { SelectAlreadyExistBase } from '@/components/modals/common/SelectAlreadyExistBase';
import { getAllowedExtByFileType } from '@/utils/fileUtils';
import { getFileColumns } from '@/components/modals/utils/fileColumns';
import { Moment } from 'moment';

export type SelectAlreadyExistFileModalProps =
  | {
      visible: false;
    }
  | ({
      visible: true;
    } & VisibleProps);
export const SelectAlreadyExistFileModal: React.FC<SelectAlreadyExistFileModalProps> = React.memo(
  (props) => {
    const { visible } = props;
    if (!visible) {
      return null;
    }
    const { visible: _, ...visibleProps } = props;

    return <VisibleModal {...visibleProps} />;
  },
);

type VisibleProps = {
  onClose: () => void;
  onSelectFile: (file: FileRecordResponse) => void;
  title?: string;
  fileType: FileType;
  contractId?: string;
};
const VisibleModal: React.FC<VisibleProps> = React.memo((props) => {
  const { onClose, onSelectFile, title, fileType, contractId } = props;
  const [tableState, setTableState] = useState<ControlledTableState>({
    pagination: { currentPage: 1, pageSize: 20 },
  });
  const [nameSearch, setNameSearch] = useState<string | undefined>(undefined);
  const [searchExt, setSearchExt] = useState<string>('');
  const [searchThisContract, setSearchThisContract] = useState<boolean>(true);
  const [minDateSearch, setMinDateSearch] = useState<Moment | null>(null);
  const [maxDateSearch, setMaxDateSearch] = useState<Moment | null>(null);

  const { data, isLoading } = useGetFilesPageQ({
    page: tableState.pagination?.currentPage,
    limit: tableState.pagination?.pageSize,
    filter: {
      contractId: searchThisContract ? contractId : undefined,
      fileType,
      extention: searchExt ? searchExt : undefined,
      createdAt:
        minDateSearch || maxDateSearch
          ? {
              $gte: minDateSearch?.toJSON(),
              $lte: maxDateSearch?.toJSON(),
            }
          : undefined,
    },
  });
  const [selectedFile, setSelectedFile] = useState<FileRecordResponse | null>(null);

  const selectExtOptions = useMemo(() => {
    const allowedExts = getAllowedExtByFileType(fileType);
    const result = [{ label: 'Все', value: '' }];
    return [...result, ...allowedExts.map((x) => ({ label: x, value: '.' + x }))];
  }, [fileType]);

  const onOkHandler = useCallback(() => {
    if (!selectedFile) {
      message.error('Пожалуйста, выберите файл');
      return;
    }

    onSelectFile(selectedFile);
    onClose();
  }, [selectedFile, onSelectFile, onClose]);

  const columns = useMemo(() => {
    return getFileColumns();
  }, []);

  return (
    <Modal
      visible={true}
      width={1000}
      title={title || `Выбор файла типа ${fileType}`}
      onCancel={onClose}
      okText={'Выбрать'}
      okButtonProps={{ disabled: selectedFile === null }}
      onOk={onOkHandler}
      cancelText={'Отмена'}
    >
      <SelectAlreadyExistBase>
        <SelectAlreadyExistBase.Filters>
          <Space>
            <Input
              placeholder={'Поиск по названию'}
              value={nameSearch}
              onChange={(e) => setNameSearch(e.target.value)}
            />
            {selectExtOptions.length > 2 && (
              <Select options={selectExtOptions} value={searchExt} onChange={setSearchExt} />
            )}
          </Space>
          <Space style={{ marginLeft: 'auto' }}>
            {contractId && (
              <Checkbox
                checked={searchThisContract}
                onChange={(e) => setSearchThisContract(e.target.checked)}
              >
                Файлы этого контракта
              </Checkbox>
            )}

            <DatePicker
              value={minDateSearch}
              onChange={setMinDateSearch}
              placeholder={'Минимальная дата'}
              allowClear
              disabledDate={(date) => {
                if (maxDateSearch) return date.diff(maxDateSearch) === 1;
                return false;
              }}
            />
            <DatePicker
              value={maxDateSearch}
              onChange={setMaxDateSearch}
              placeholder={'Максимальная дата'}
              allowClear
              disabledDate={(date) => {
                if (minDateSearch) return date.diff(maxDateSearch) === -1;
                return false;
              }}
            />
            {/*<DatePicker.RangePicker placeholder={['Начало даты загрузки', 'Конец даты загрузки']} />*/}
          </Space>
        </SelectAlreadyExistBase.Filters>
        <SelectAlreadyExistBase.Table>
          <ControlledTable
            tableState={tableState}
            onChangeTableState={setTableState}
            pagination={{ type: 'server', pageSizeOptions: [20] }}
            dataSource={data?.data.data}
            rowKey={(x) => x._id}
            columns={columns}
            loading={isLoading}
            totalItems={data?.data.meta.count || 0}
            rowSelection={{
              type: 'radio',
              fixed: true,
              selectedRowKeys: selectedFile ? [selectedFile._id] : [],
              onChange: (_, selectedRows) => {
                if (selectedRows[0]) setSelectedFile(selectedRows[0]);
                else setSelectedFile(null);
              },
            }}
          />
        </SelectAlreadyExistBase.Table>
      </SelectAlreadyExistBase>
    </Modal>
  );
});
