import React, { useCallback, useMemo } from 'react';
import { Button, message, Modal } from 'antd';
import { FileRecordResponse, FileType } from '@/api/Files/types';
import { FileLoader } from '@uspect-team/ant-ui-kit';
import { getAllowedExtByFileType } from '@/utils/fileUtils';
import { RcFile } from 'antd/lib/upload';
import { useUploadFileM } from '@/hooks/files';
import { RecordId } from '@/api/common/types';
import { AxiosError } from 'axios';

export type UploadFileModalProps =
  | {
      visible: false;
    }
  | ({ visible: true } & VisibleProps);
type VisibleProps = {
  fileType: FileType;
  contractId?: RecordId;
  onClose: () => void;
  onUpload: (file: FileRecordResponse) => void;
};

export const UploadFileModal: React.FC<UploadFileModalProps> = React.memo((props) => {
  const { visible } = props;

  if (!visible) {
    return null;
  }

  const { visible: _, ...visibleProps } = props;
  return <VisibleModal {...visibleProps} />;
});

export const VisibleModal: React.FC<VisibleProps> = React.memo((props) => {
  const { onClose, onUpload, fileType, contractId } = props;

  const uploadFileM = useUploadFileM();
  const allowedExts = useMemo(() => {
    return getAllowedExtByFileType(fileType);
  }, [fileType]);

  const onUploadHandler = useCallback(
    async (file: RcFile) => {
      try {
        const uploadRes = await uploadFileM.mutateAsync({
          type: fileType,
          files: [file],
          contractId,
        });
        onUpload(uploadRes.data.data[0]);
        onClose();
      } catch (e) {
        if ((e as AxiosError).response?.status === 409) {
          message.error(
            'Файл с таким именем уже загружен. Выберите его или переименуйте и загрузите этот файл',
          );
        } else {
          message.error('При загрузке файла произошла ошибка');
        }
      }
    },
    [contractId, uploadFileM, fileType, onClose, onUpload],
  );

  return (
    <Modal
      visible={true}
      onCancel={onClose}
      title={'Загрузка файла'}
      footer={<Button onClick={onClose}>Отмена</Button>}
    >
      <FileLoader allowedFiles={[allowedExts]} onUpload={onUploadHandler} />
    </Modal>
  );
});
