import { ButtonWithMenu } from '@/components/ButtonWithMenu';
import { DashboardButton } from '@/components/DasboardButton';
import { DropdownButton } from '@/components/DropdownButton';
import { SubHeader } from '@/components/SubHeader';
import { useCadObjectsPageFetchingState } from '@/hooks/cadastralObjects';
import { useDeleteContactM } from '@/hooks/contracts';
import { useGetAllDisplayModsQ } from '@/hooks/directory/displayMods';
import { routes } from '@/resources/routes';
import {
  ExportOutlined,
  EyeOutlined,
  ImportOutlined,
  PlusCircleOutlined,
  ReloadOutlined,
  SnippetsOutlined,
  ToolOutlined,
} from '@ant-design/icons';
import { Menu, message, Modal, Space } from 'antd';
import { useStore } from 'effector-react';
import React from 'react';
import { useHistory } from 'react-router-dom';
import {
  $contractId,
  $isCanEditContract,
  $isTableHasFilters,
  $isTableHasFiltersOrSort,
  $isTableHasSort,
  $selectedCadObjectsNumbers,
  $selectedDisplayModeId,
  openAutoRunWizardModalWithSelectedCadObjects,
  openAutoSignWizardModalWithSelectedCadObjects,
  openBatchFileDownloadWizardModal,
  openCreateCadObjectModalWithContext,
  openEditContractColumnsModalWithContext,
  openEditContractModalWithContext,
  openFieldFillingWizardModalWithContext,
  openGenFileWizardWithSelectedCadObjects,
  openGenTechPlanModalWithContext,
  openImportCadObjectsFromXlsxModalWithContext,
  openImportOsModalWithContext,
  openImportRastrModalWithContext,
  openMonitoringGenWizardModalWithContext,
  resetTableFilters,
  resetTableFiltersAndSort,
  resetTableSort,
  setSelectedDisplayModeId,
} from '../model';

const _MainTablePageSubHeader: React.FC = () => {
  const history = useHistory();

  const contractId = useStore($contractId);
  const selectedDisplayModeId = useStore($selectedDisplayModeId);
  const isCanEditContract = useStore($isCanEditContract);
  const selectedCadNumbers = useStore($selectedCadObjectsNumbers);

  const cadObjectsPageFetchingState = useCadObjectsPageFetchingState();
  const { data: displayMods } = useGetAllDisplayModsQ();
  // const { mutateAsync: loadContractMonitoringM } = useLoadContractMonitoringM();
  const { mutateAsync: deleteContactM } = useDeleteContactM();

  const displayModeOptions = React.useMemo(() => {
    return [
      {
        key: 'show_all',
        text: `Показать все${selectedDisplayModeId === null ? ' (выбрано)' : ''}`,
        onClick: () => setSelectedDisplayModeId(null),
      },
      ...(displayMods?.map((displayMode) => ({
        key: displayMode._id,
        text: `${displayMode.name}${selectedDisplayModeId === displayMode._id ? ' (выбрано)' : ''}`,
        onClick: () => setSelectedDisplayModeId(displayMode._id),
      })) ?? []),
    ];
  }, [displayMods, selectedDisplayModeId]);

  const onDeleteContractHandler = React.useCallback(async () => {
    if (contractId) {
      Modal.confirm({
        title: 'Вы действительно хотите безвозвратно удалить данный контракт?',
        content:
          'Все дочерние кадастровые объекты будут удалены. Данное действие нельзя будет отменить',
        okText: 'Да, удалить',
        okType: 'danger',
        onOk: async () => {
          const currentUrl = history.location.pathname;
          try {
            history.push(routes.contracts);
            await deleteContactM(contractId);
            message.success('Контракт удален');
          } catch {
            history.replace(currentUrl);
            message.error('При удалении контракта возникла ошибка');
          }
        },
      });
    }
  }, [contractId, deleteContactM, history]);

  // const onLoadMonitoringHandler = React.useCallback(async () => {
  //   if (contractId) {
  //     const hideLoadingMessage = message.loading('Генерация мониторинга..', 0);
  //     try {
  //       const response = await loadContractMonitoringM({ contractId });
  //       window.open(response.data.data.fileUri, '_blank');
  //     } catch {
  //       message.error('Произошла ошибка при генерации файла');
  //     } finally {
  //       hideLoadingMessage();
  //     }
  //   }
  // }, [contractId, loadContractMonitoringM]);

  const isTableHasFiltersOrSort = useStore($isTableHasFiltersOrSort);
  const isTableHasFilters = useStore($isTableHasFilters);
  const isTableHasSort = useStore($isTableHasSort);

  return (
    <SubHeader>
      <SubHeader.Left>
        <Space size={10}>
          {isCanEditContract && (
            <DashboardButton
              onClick={openCreateCadObjectModalWithContext}
              icon={<PlusCircleOutlined />}
            >
              Добавить объект
            </DashboardButton>
          )}

          <DashboardButton
            icon={<ReloadOutlined />}
            loading={cadObjectsPageFetchingState.isFetching}
            onClick={() => cadObjectsPageFetchingState.refresh()}
          >
            Обновить таблицу
          </DashboardButton>
          <ButtonWithMenu
            disabled={!isTableHasFiltersOrSort}
            onClick={() => resetTableFiltersAndSort()}
            overlay={
              <Menu>
                {isTableHasFilters && (
                  <Menu.Item key='1' onClick={() => resetTableFilters()}>
                    Сбросить фильтры
                  </Menu.Item>
                )}
                {isTableHasSort && (
                  <Menu.Item key='2' onClick={() => resetTableSort()}>
                    Сбросить сортировку
                  </Menu.Item>
                )}
              </Menu>
            }
          >
            Сбросить фильтры и сортировку
          </ButtonWithMenu>
        </Space>
      </SubHeader.Left>
      <SubHeader.Right>
        <Space size={10}>
          {isCanEditContract && (
            <DropdownButton
              icon={<SnippetsOutlined />}
              items={[
                {
                  key: 'settings',
                  text: 'Изменить параметры',
                  onClick: openEditContractModalWithContext,
                },
                {
                  key: 'columns-settings',
                  text: 'Настроить столбцы',
                  onClick: openEditContractColumnsModalWithContext,
                },

                {
                  key: 'delete-contract',
                  text: 'Удалить контракт',
                  onClick: onDeleteContractHandler,
                  danger: true,
                },
              ]}
            >
              Контракт
            </DropdownButton>
          )}

          {isCanEditContract && (
            <DropdownButton
              icon={<ToolOutlined />}
              items={[
                {
                  key: 'auto-fill-wizard',
                  text: 'Мастер заполнения полей',
                  onClick: () => openFieldFillingWizardModalWithContext(),
                },
                {
                  key: 'gen-files-wizard',
                  text: 'Мастер генерации файлов',
                  onClick: () => openGenFileWizardWithSelectedCadObjects(),
                },
                {
                  key: 'auto-run-wizard',
                  text: 'Мастер автоматического прогона объектов',
                  onClick: () => openAutoRunWizardModalWithSelectedCadObjects(),
                },
                {
                  key: 'auto-sign-wizard',
                  text: 'Мастер автоподписания объектов',
                  onClick: () => openAutoSignWizardModalWithSelectedCadObjects(),
                },
                {
                  key: 'auto-tech-plan',
                  text: 'Мастер генерации тех.плана',
                  onClick: () => openGenTechPlanModalWithContext(),
                },
              ]}
            >
              Инструменты
            </DropdownButton>
          )}

          {isCanEditContract && (
            <DropdownButton
              icon={<ImportOutlined />}
              items={[
                {
                  key: 1,
                  text: 'Импорт объектов контракта',
                  onClick: openImportCadObjectsFromXlsxModalWithContext,
                },
                {
                  key: 2,
                  text: 'Импорт os/sit файлов (геодезии)',
                  onClick: openImportOsModalWithContext,
                },
                {
                  key: 3,
                  text: 'Импорт jpg/tab файлов (растров)',
                  onClick: openImportRastrModalWithContext,
                },
              ]}
            >
              Импорт
            </DropdownButton>
          )}

          <DropdownButton
            icon={<ExportOutlined />}
            items={[
              {
                key: 'load_monitoring',
                text: 'Мастер генерации мониторинга',
                onClick: () => openMonitoringGenWizardModalWithContext(),
              },
              {
                key: 'batch-download-files',
                text: 'Пакетное скачивание файлов',
                onClick: () =>
                  openBatchFileDownloadWizardModal({
                    initialCadObjectNumbers: selectedCadNumbers,
                    contractId: contractId ? contractId : undefined,
                  }),
              },
            ]}
          >
            Экспорт
          </DropdownButton>
          <DropdownButton icon={<EyeOutlined />} items={displayModeOptions}>
            Режим отображения
          </DropdownButton>
        </Space>
      </SubHeader.Right>
    </SubHeader>
  );
};
export const MainTablePageSubHeader = React.memo(_MainTablePageSubHeader);
