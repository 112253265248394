import React from 'react';
import cn from 'classnames';

import './styles.scss';
import { FilterTypeSelectOption } from './FilterTypeSelectOption';

export type FilterTypeOption<FilterType> = {
  value: FilterType;
  icon: React.ReactNode;
  label: string;
};

export type FilterTypeSelectProps<FilterType> = {
  className?: string;
  style?: React.CSSProperties;
  options: FilterTypeOption<FilterType>[];
  filteredType: FilterType | null;
  selectedFilterType: FilterType;
  onChangeSelectedFilterType: (value: FilterType) => void;
};

function _FilterTypeSelect<FilterType extends string>(props: FilterTypeSelectProps<FilterType>) {
  const {
    className,
    style,
    options,
    filteredType,
    selectedFilterType,
    onChangeSelectedFilterType,
  } = props;

  const selectedOptionLabel = React.useMemo(() => {
    return options.find((opt) => opt.value === selectedFilterType)?.label;
  }, [options, selectedFilterType]);

  return (
    <div className={cn('filter-type-select', className)} style={style}>
      <div className='filter-type-select__items-list'>
        {options.map((opt) => {
          const selected = opt.value === selectedFilterType;
          const hasFilter = opt.value === filteredType;
          return (
            <FilterTypeSelectOption
              key={opt.value}
              selected={selected}
              hasFilter={hasFilter}
              icon={opt.icon}
              onClick={() => onChangeSelectedFilterType(opt.value)}
            />
          );
        })}
      </div>
      <div className='filter-type-select__selected-item-label'>{selectedOptionLabel}</div>
    </div>
  );
}
export const FilterTypeSelect = React.memo(_FilterTypeSelect) as typeof _FilterTypeSelect;
