import { coordinateService } from '@/globalStorage/coordinates/newIndex';
import { UiPolylineRecord } from '@/globalStorage/coordinates/types';
import { CloseOutlined } from '@ant-design/icons';
import { Button } from 'antd';
import cn from 'classnames';
import { useStore } from 'effector-react';
import React from 'react';
import { PolylinePointItem } from './PolylinePointItem';
import './styles.scss';

export type PolylineItemProps = {
  polyline: UiPolylineRecord;
  number: number;
  className?: string;
};

const _PolylineItem: React.FC<PolylineItemProps> = (props) => {
  const { polyline, number, className } = props;

  const selectedPolylineId = useStore(coordinateService.$selectedPolylineId);

  const isSelectedPolyline = polyline.id === selectedPolylineId;

  const onClickPolylineHandler = () => {
    if (isSelectedPolyline) {
      coordinateService.resetSelect();
    } else {
      coordinateService.selectPolyline({ polylineId: polyline.id });
    }
  };

  return (
    <div
      className={cn('polyline-item', className, { 'polyline-item--selected': isSelectedPolyline })}
      onClick={onClickPolylineHandler}
    >
      <div className='polyline-item__header'>
        <div className='polyline-item__title'>Линия №{number}</div>
        <div className='polyline-item__actions'>
          <Button
            icon={<CloseOutlined />}
            size='small'
            type='text'
            onClick={() => coordinateService.deletePolyline({ polylineId: polyline.id })}
            title='Удалить линию'
          />
        </div>
      </div>

      <div className={cn('polyline-item__points-container')}>
        {polyline.points.length === 0 ? (
          <Button onClick={() => coordinateService.addPoint({ polylineId: polyline.id })}>
            Добавить точку
          </Button>
        ) : (
          polyline.points.map((point) => {
            return <PolylinePointItem key={point.id} point={point} polyline={polyline} />;
          })
        )}
      </div>
    </div>
  );
};

export const PolylineItem = React.memo(_PolylineItem);
