import { registerStateService } from '@/globalStorage/register';
import { Steps } from 'antd';
import { useStoreMap } from 'effector-react';
import React from 'react';
import cn from 'classnames';
import './styles.scss';

export type RegisterStepsProps = {
  className?: string;
  style?: React.CSSProperties;
};

const steps: { num: number; title: string }[] = [
  {
    num: 1,
    title: 'Шаг 1. Контактное лицо (администратор)',
  },
  {
    num: 2,
    title: 'Шаг 2. Юридическое лицо',
  },
  {
    num: 3,
    title: 'Шаг 3. Информация о компании',
  },
];

const _RegisterSteps: React.FC<RegisterStepsProps> = ({ className, style }) => {
  const stepNum = useStoreMap(registerStateService.$registerState, (state) => state.step);

  const currentStepTitle = steps.find((step) => step.num === stepNum)?.title;

  return (
    <div className={cn('register-steps', className)} style={style}>
      <Steps current={stepNum - 1} className='register-steps__steps'>
        {steps.map((step) => {
          return <Steps.Step key={step.num} />;
        })}
      </Steps>
      {currentStepTitle && (
        <div className='register-steps__current-step-title'>{currentStepTitle}</div>
      )}
    </div>
  );
};
export const RegisterSteps = React.memo(_RegisterSteps);
