import { AppInput } from '@/components/inputs/AppInput';
import { DatePicker } from '@/components/inputs/DatePicker';
import { PhoneNumberInput } from '@/components/inputs/special/PhoneNumberInput';
import { AddressSearchSelect, OrganizationSearchSelect } from '@uspect-team/ant-ui-kit';
import { OrganizationValue } from '@uspect-team/ant-ui-kit/dist/Inputs/OrganizationSearchSelect';
import { Checkbox, Col, Divider, Form, Input, Row, Typography } from 'antd';
import moment from 'moment';
import React, { useCallback } from 'react';
import './styles.scss';

const _ManagementDataFields: React.FC = () => {
  const onChangeHandler = useCallback(
    (values: OrganizationValue | undefined, setFieldsValue: (values: any) => void) => {
      if (values) {
        let result = {
          inn: values.data.inn,
          ogrn: values.data.ogrn,
          dateReg: values?.data.ogrn_date ? moment(values.data.ogrn_date) : undefined,
          addressF: values.data.address,
          addressY: values.data.address,
          phone: values.data.phones,
          name: '',
          surName: '',
          secondName: '',
        };
        if (values?.data.management?.post === 'ГЕНЕРАЛЬНЫЙ ДИРЕКТОР') {
          const fullName = values.data.management.name;
          const [name, secondName, surName] = fullName.split(' ') ?? [null, null, null];

          result = {
            ...result,
            name,
            secondName,
            surName,
          };
        }

        setFieldsValue(result);
      }
    },
    [],
  );
  return (
    <>
      <div className='management-data__wrapper'>
        <div className='management-data__requisites'>
          <Typography className='management-data__main-title'>Реквизиты организации</Typography>

          <Row className='management-data__requisites-row' gutter={40}>
            <Col span={8}>
              <Form.Item dependencies={['title']} noStyle>
                {({ setFieldsValue, getFieldValue }) => {
                  return (
                    <Form.Item
                      name={'title'}
                      label='Наименование организации: '
                      dependencies={['title']}
                      getValueFromEvent={(x) => x?.value}
                      className='management-data__requisites-title'
                    >
                      <OrganizationSearchSelect
                        inputProps={{ placeholder: 'Наименование организации' }}
                        onChange={(values) => onChangeHandler(values, setFieldsValue)}
                      />
                    </Form.Item>
                  );
                }}
              </Form.Item>

              <Row gutter={40}>
                <Col span={12}>
                  <Form.Item name='inn' label='ИНН:'>
                    <AppInput placeholder='ИНН' />
                  </Form.Item>
                </Col>
                <Col span={12}>
                  <Form.Item name='ogrn' label='ОГРН:'>
                    <Input placeholder='ОГРН' />
                  </Form.Item>
                </Col>
              </Row>
            </Col>

            <Col span={8}>
              <Form.Item name='addressY' label='Юридический адрес:'>
                <AddressSearchSelect block />
              </Form.Item>
              <Row gutter={40}>
                <Col span={12}>
                  <Form.Item name='dateReg' label='Дата регистрации организации:'>
                    <DatePicker fullWidth />
                  </Form.Item>
                </Col>
              </Row>
            </Col>
            <Col span={7}>
              <Row gutter={20} className='management-data__requisites-address__row'>
                <Col span={10}>
                  <Typography>Фактический адрес:</Typography>
                </Col>
                <Col span={14}>
                  <Form.Item name='addressMatch' valuePropName='checked'>
                    <Checkbox> Совпадает с юридическим</Checkbox>
                  </Form.Item>
                </Col>
              </Row>
              <Form.Item noStyle dependencies={['addressMatch']}>
                {({ getFieldValue, setFieldValue }) => {
                  const addressMatch = getFieldValue('addressMatch');
                  const addressY = getFieldValue('addressY');

                  if (addressMatch) {
                    setFieldValue('addressF', addressY);

                    return (
                      <Form.Item name='addressF'>
                        <AddressSearchSelect block />
                      </Form.Item>
                    );
                  }
                  return (
                    <Form.Item name='addressF'>
                      <AddressSearchSelect block />
                    </Form.Item>
                  );
                }}
              </Form.Item>
            </Col>
          </Row>
          <Divider />

          <Row gutter={40}>
            <Col span={8}>
              <Form.Item name='bank' label='Банк:'>
                <AppInput placeholder='Банк' />
              </Form.Item>

              <Row gutter={40}>
                <Col span={24}>
                  <Form.Item name='bic' label='БИК:'>
                    <AppInput placeholder='БИК' />
                  </Form.Item>
                </Col>
              </Row>
            </Col>

            <Col span={8}>
              <Form.Item name='accountCheck' label='Расчетный счет:'>
                <AppInput placeholder='Расчетный счет' />
              </Form.Item>
              <Row gutter={40}>
                <Col span={24}>
                  <Typography className='subtitle2'>
                    Этот расчетный счет будет фигурировать в закрывающих и платежных документах
                  </Typography>
                </Col>
              </Row>
            </Col>
            <Col span={7}>
              <Form.Item name='correspondentCheck ' label='Корреспондентский счет:'>
                <AppInput placeholder='Корреспондентский счет' />
              </Form.Item>
            </Col>
          </Row>
          <Divider />
          <Typography className='management-data__main-title'>Генеральный директор</Typography>

          <Row gutter={40}>
            <Col span={8}>
              <Form.Item name='name' label='Имя:'>
                <AppInput placeholder='Имя' />
              </Form.Item>
            </Col>

            <Col span={8}>
              <Form.Item name='secondName' label='Фамилия:'>
                <AppInput placeholder='Фамилия' />
              </Form.Item>
            </Col>
            <Col span={7}>
              <Form.Item name='surName' label='Отчество:'>
                <AppInput placeholder='Отчество' />
              </Form.Item>
            </Col>
          </Row>
        </div>
      </div>

      <div className='management-data__wrapper'>
        <Typography className='management-data__main-title'>Прочая информация</Typography>
        <Row gutter={40}>
          <Col span={8}>
            <Form.Item name='website' label='Веб-сайт компании:'>
              <AppInput placeholder='Веб-сайт компании' />
            </Form.Item>
          </Col>

          <Col span={8}>
            <Form.Item name='email' label='E-mail компании:'>
              <AppInput placeholder='E-mail компании' />
            </Form.Item>
          </Col>
          <Col span={7}>
            <Form.Item name='phone' label='Телефон контактного лица:'>
              <PhoneNumberInput placeholder='Телефон контактного лица' />
            </Form.Item>
          </Col>
        </Row>
      </div>
    </>
  );
};
export const ManagementDataFields = React.memo(_ManagementDataFields);
