import React from 'react';
import cn from 'classnames';
import { Select, SelectProps } from 'antd';
import './styles.scss';

type Props = {
  className?: string;
  style?: any;
  variant?: 'default' | 'dark';
} & SelectProps<any>;
export const SelectSearch: React.FC<Props> = (props) => {
  const { className, style, children, variant = 'default', ...selectProps } = props;

  return (
    <Select
      showSearch
      className={cn(
        {
          'app-select-search': true,
          'app-select-search--dark': variant === 'dark',
        },
        className,
      )}
      optionFilterProp='children'
      size={'small'}
      //Можно будет переопределить
      filterOption={(input, option) => {
        const childrenText = option?.children as string | null | undefined;
        return childrenText?.toLowerCase().includes(input.toLowerCase()) || false;
      }}
      {...selectProps}
    >
      {children}
    </Select>
  );
};
