import { CadObjectResponse } from '@/api/CadObjects/types';
import { ReactElement } from 'react';
import { ControlledTableColumns } from '@uspect-team/ant-ui-kit/dist/Tables/ControlledTable/types';
import { DotsMenu } from '@/components/DotsMenu';
import React from 'react';

export const getClientColumns = (options: {
  actionMenu?: (record: CadObjectResponse) => ReactElement;
}): ControlledTableColumns<CadObjectResponse> | undefined => {
  const actionMenu = options.actionMenu;
  if (!actionMenu) {
    return undefined;
  }
  return [
    {
      key: 'actions',
      dataIndex: 'actions',
      width: 75,
      align: 'center',
      fixed: 'left',
      render: (_, record) => {
        return <DotsMenu menu={actionMenu(record)} />;
      },
    },
  ];
};
