import React from 'react';
import cn from 'classnames';
import './styles.scss';

export type ContentHeaderProps = {
  className?: string;
  style?: React.CSSProperties;
  children?: React.ReactNode;
};

export const GuestLayoutContentHeader: React.FC<ContentHeaderProps> = (props) => {
  const { className, style, children } = props;

  return (
    <div className={cn('guest-layout-content-header', className)} style={style}>
      {children}
    </div>
  );
};
