import { ErrorMessage } from '@/components/formHelpers/ErrorMessage';
import { Input } from 'antd';
import { TextAreaProps } from 'antd/lib/input';
import React, { useEffect } from 'react';

type Props = {
  initValues?: number[];
  onChange: (value: number[]) => void;
} & Omit<TextAreaProps, 'onChange'>;
const _CadObjectNumbersTextarea: React.FC<Props> = (props) => {
  const { initValues, onChange, ...otherProps } = props;
  const [validationError, setValidationError] = React.useState<string | null>(null);
  const [value, setValue] = React.useState<string>('');
  useEffect(() => {
    if (initValues) {
      setValue(initValues.join('\n'));
      onChange(initValues);
    }
  }, [initValues, onChange]);

  const onChangeHandler: React.ChangeEventHandler<HTMLTextAreaElement> = React.useCallback(
    (e) => {
      setValue(e.target.value);
      setValidationError(null);

      if (e.target.value.trim() === '') {
        onChange([]);
        return;
      }

      const lines = e.target.value.split('\n').map((x) => x.trim());

      for (const [index, line] of Array.from(lines.entries())) {
        if (!/^\d+$/.test(line)) {
          setValidationError(`Ошибка на ${index + 1} строке`);
          return;
        }
      }

      const numbers = e.target.value
        .trim()
        .split('\n')
        .map((x) => Number.parseInt(x.trim()));

      const numbersSet = new Set(numbers);
      onChange(Array.from(numbersSet));
    },
    [onChange],
  );

  return (
    <div>
      <Input.TextArea value={value} onChange={onChangeHandler} rows={6} {...otherProps} />
      {!otherProps.disabled && <ErrorMessage message={validationError} />}
    </div>
  );
};
export const CadObjectNumbersTextarea = React.memo(_CadObjectNumbersTextarea);
