import { GuestLayout } from '@/components/layouts/GuestLayout';
import React from 'react';
import { CompaniesPageFooter } from './components/CompaniesPageFooter';
import { CompaniesPageHeader } from './components/CompaniesPageHeader';
import { CompaniesSection } from './components/CompaniesSection';
import './styles.scss';

const _CompaniesPage: React.FC = () => {
  return (
    <GuestLayout className={'companies-page'} noScroll>
      <div className='companies-page__content'>
        <CompaniesPageHeader className='companies-page__header' />
        <div className='companies-page__companies'>
          <CompaniesSection />
        </div>
        <div className='companies-page__footer'>
          <CompaniesPageFooter />
        </div>
      </div>
    </GuestLayout>
  );
};
export const CompaniesPage = React.memo(_CompaniesPage);
