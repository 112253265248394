import React from 'react';
import { autoGenFilesModalService } from '@/globalStorage/autoGenFiles/modal';
import { AutoGenFilesModalState } from '@/globalStorage/autoGenFiles/types';
import { Button, Modal, Space, Steps } from 'antd';
import { createComponent } from 'effector-react';
import { AutoGenFilesModalDone } from './AutoGenFilesModalDone';
import { AutoGenFilesModalIdle } from './AutoGenFilesModalIdle';
import { AutoGenFilesModalInProcess } from './AutoGenFilesModalInProcess';

const mapAutogenFilesStateToCurrentStep = (state: 'idle' | 'inProcess' | 'done') => {
  switch (state) {
    case 'idle':
      return 0;
    case 'inProcess':
      return 1;
    case 'done':
      return 2;
    default:
      return 0;
  }
};

const getFooter = (state: AutoGenFilesModalState) => {
  if (state.visible) {
    switch (state.state) {
      case 'idle':
        return (
          <Space>
            <Button onClick={() => autoGenFilesModalService.closeAutoGenFilesModal()}>
              Отмена
            </Button>
            <Button
              type={'primary'}
              onClick={() => autoGenFilesModalService.startGenFiles()}
              disabled={!state.isCanStartGenFiles}
            >
              Начать генерацию
            </Button>
          </Space>
        );
      case 'inProcess':
        return (
          <Button onClick={() => autoGenFilesModalService.closeAutoGenFilesModal()}>
            Свернуть
          </Button>
        );
      case 'done':
        return (
          <Space>
            <Button onClick={() => autoGenFilesModalService.backToFileTypesInit()}>
              Назад к началу
            </Button>
            <Button
              type={'primary'}
              onClick={() => {
                autoGenFilesModalService.closeAutoGenFilesModal();
                autoGenFilesModalService.backToFileTypesInit();
              }}
            >
              Завершить
            </Button>
          </Space>
        );
    }
  }
};

export const AutoGenFilesModal = createComponent(
  autoGenFilesModalService.$autoGenFilesModalState,
  (props, state) => {
    if (!state.visible) {
      return null;
    }

    return (
      <Modal
        width={1000}
        visible={true}
        onCancel={() => autoGenFilesModalService.closeAutoGenFilesModal()}
        title={'Мастер генерации файлов'}
        footer={getFooter(state)}
      >
        <Steps size='small' current={mapAutogenFilesStateToCurrentStep(state.state)}>
          <Steps.Step title='Выбор объектов' />
          <Steps.Step title='Статус генерации' />
          <Steps.Step title='Результат' />
        </Steps>

        {state.state === 'idle' && <AutoGenFilesModalIdle {...state} />}
        {state.state === 'inProcess' && <AutoGenFilesModalInProcess {...state} />}
        {state.state === 'done' && <AutoGenFilesModalDone {...state} />}
      </Modal>
    );
  },
);
