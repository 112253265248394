import { PopoverContainer } from '@/components/PopoverContainer';
import { BellFilled } from '@ant-design/icons';
import React from 'react';
import { HeaderIconButton } from '../../components/HeaderIconButton';
import './styles.scss';

const _NotificationsIconButton: React.FC = () => {
  const overlay = (
    <PopoverContainer>
      <div className='notifications-icon-button'>У вас нет уведомлений</div>
    </PopoverContainer>
  );

  return <HeaderIconButton Icon={BellFilled} overlay={overlay} />;
};
export const NotificationsIconButton = React.memo(_NotificationsIconButton);
