import { RecordId } from '@/api/common/types';
import { SelectContract } from '@/components/inputs/SelectContract';
import { Form } from 'antd';
import React from 'react';
import { CadObjectNumbersSelector } from '../../cadObjects/common/CadObjectNumbersSelector';
import { StepContent } from '../../../StepContent';
import { autoSignModalService } from '@/globalStorage/autoSign/modal';

export type AutoSignModalIdleProps = {
  contractId: RecordId | null;
  cadObjectNumbers: number[];
};

const _AutoSignModalIdle: React.FC<AutoSignModalIdleProps> = (props) => {
  const { contractId, cadObjectNumbers } = props;
  return (
    <StepContent>
      <Form layout='vertical'>
        <Form.Item label={'Контракт'}>
          <SelectContract
            placeholder='Выберите контракт'
            value={contractId}
            onChange={autoSignModalService.setInitialContractId}
          />
        </Form.Item>
        <Form.Item label='Заполните номера объектов (или вставьте их из Excel)'>
          <CadObjectNumbersSelector
            initNumbers={cadObjectNumbers}
            onChangeNumbers={autoSignModalService.setInitialCadObjectNumbers}
          />
        </Form.Item>
      </Form>
    </StepContent>
  );
};
export const AutoSignModalIdle = React.memo(_AutoSignModalIdle);
