import React, { useMemo } from 'react';
import { Dropdown } from 'antd';
import './styles.scss';
import { DashboardButton } from '../DasboardButton';
import { DownOutlined } from '@ant-design/icons';
import cn from 'classnames';

type OverlayItem = {
  key: React.Key;
  text: string;
  onClick?: () => void;
  danger?: boolean;
};

type Props = {
  className?: string;
  style?: any;
  items: OverlayItem[];
  icon?: React.ReactNode;
  type?: 'default' | 'text';
  variant?: 'default' | 'dark';
};

const DropdownButtonOverlay: React.FC<{ items: OverlayItem[] }> = ({ items }) => {
  return (
    <div className={'app-dropdown-button__overlay'}>
      {items.map((item) => (
        <div
          key={item.key}
          className={cn({
            'app-dropdown-button__overlay-item': true,
            'app-dropdown-button__overlay-item--danger': item.danger,
          })}
          onClick={item.onClick}
        >
          <div className={'app-dropdown-button__overlay-item-text'}>{item.text}</div>
        </div>
      ))}
    </div>
  );
};
export const DropdownButton: React.FC<Props> = (props) => {
  const { className, items, children, icon, type = 'default', variant = 'default' } = props;

  const overlay = useMemo(() => {
    return <DropdownButtonOverlay items={items} />;
  }, [items]);

  return (
    <Dropdown overlay={overlay} className={className}>
      <DashboardButton
        icon={icon}
        className={cn({
          'app-dropdown-button': true,
          'app-dropdown-button--as-text': type === 'text',
          'app-dropdown-button--dark': variant === 'dark',
        })}
      >
        <div className='app-dropdown-button__text'>{children}</div>
        <DownOutlined className={'app-dropdown-button__down'} />
      </DashboardButton>
    </Dropdown>
  );
};
