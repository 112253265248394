import * as yup from 'yup';
import {
  BaseServerChildColumnType,
  ServerConfigTableContext,
  StringServerChildColumnAddition,
  TableRecordType,
} from '../../types';
import React from 'react';
import { ControlledTableChildType } from '@uspect-team/ant-ui-kit/dist/Tables/ControlledTable/types';
import { errorMessages } from '@/utils/errorMessages';
import { dataIndexToStringKey } from '../../utils/dataIndexToStringKey';
import { EditableString } from '@uspect-team/ant-ui-kit';
import { StringFilterDropdown } from '@/components/filterDropdowns/StringFilterDropdown';
import { StringFilterValue } from '@/components/filterDropdowns/StringFilterDropdown/types';
import { Typography } from 'antd';

export const getStringColumnAddition = (
  strSerCol: BaseServerChildColumnType & StringServerChildColumnAddition,
  context: ServerConfigTableContext,
  editable: boolean,
): Partial<ControlledTableChildType<TableRecordType>> => {
  let validationSchema = yup.string();

  validationSchema = strSerCol.isRequired
    ? validationSchema.required(errorMessages.required)
    : validationSchema;

  const { dataIndex, isEditable, isFilterable, width } = strSerCol;
  const onEdit = context.onEdit;

  const handleOnChange = (value: string, record: TableRecordType) => {
    if (onEdit) {
      onEdit({
        patch: {
          [dataIndexToStringKey(dataIndex)]: value && value.length > 0 ? value : null,
        },
        oldRecord: record,
      });
    }
  };

  const onChange = onEdit ? handleOnChange : undefined;

  return {
    align: 'left',
    width: width ?? 200,
    filterDropdown: isFilterable
      ? ({ currentFilterState: _currentFilterState, setFilterValue, removeFilter }) => {
          const filter = _currentFilterState as StringFilterValue;
          return (
            <StringFilterDropdown
              filter={filter}
              onChangeFilter={setFilterValue}
              onResetFilter={removeFilter}
              hasIncludes={dataIndex !== 'num'}
              hasEqual
              hasExist
            />
          );
        }
      : undefined,
    render:
      isEditable && editable
        ? (value, record) => {
            if (record.status === 'excluded') {
              return (
                <Typography.Text title={value} ellipsis>
                  {value}
                </Typography.Text>
              );
            }

            return (
              <EditableString
                block
                value={value}
                validationSchema={validationSchema}
                onChange={(val) => onChange && onChange(val, record)}
              />
            );
          }
        : (value) => (
            <Typography.Text title={value} ellipsis>
              {value}
            </Typography.Text>
          ),
  };
};
