import { RuleObject } from 'antd/lib/form';
import { errorMessages } from '@/utils/errorMessages';

type ValidatorType<T = any> = (
  rule: RuleObject,
  value: T,
  callback: (error?: string | undefined) => void,
) => Promise<void> | undefined;

export const snilsValidator: ValidatorType<string> = async (rule, value) => {
  const regex = /^\d{3}-\d{3}-\d{3} \d{2}$/;
  if (!regex.test(value)) {
    throw new Error(errorMessages.sinils);
  }
};

export const innJuridicalValidator: ValidatorType<string> = async (rule, value) => {
  const regex = /^\d{10}$/;
  if (!regex.test(value)) {
    throw new Error(errorMessages.inn);
  }
};

export const innPhysicalValidator: ValidatorType<string> = async (rule, value) => {
  const regex = /^\d{12}$/;
  if (!regex.test(value)) {
    throw new Error(errorMessages.inn);
  }
};

export const requiredTelValidator: ValidatorType<string> = async (rule, value) => {
  if (!value) {
    throw new Error(errorMessages.required);
  }

  const regex = /^(8|\+7) \(\d{3}\) \d{3}-\d{2}-\d{2}$/;
  if (!regex.test(value)) {
    throw new Error(errorMessages.tel);
  }
};

export const telValidator: ValidatorType<string> = async (rule, value) => {
  const regex = /^(8|\+7) \(\d{3}\) \d{3}-\d{2}-\d{2}$/;
  if (!regex.test(value)) {
    throw new Error(errorMessages.tel);
  }
};

export const passportSeriesValidator: ValidatorType<string> = async (rule, value) => {
  const regex = /^([0-9]{2}\s{1}[0-9]{2})?$/;
  if (!regex.test(value)) {
    throw new Error(errorMessages.passportSeries);
  }
};

export const passportNumberValidator: ValidatorType<string> = async (rule, value) => {
  const regex = /^([0-9]{6})?$/;
  if (!regex.test(value)) {
    throw new Error(errorMessages.passportNumber);
  }
};

export const cadQuarterValidator: ValidatorType<string> = async (rule, value) => {
  const regex = /^(\d{2}):\d{2}:\d{5}\d*$/;
  if (!regex.test(value)) {
    throw new Error(errorMessages.cadQuarter);
  }
};

export const emailListValidator: ValidatorType<string[]> = async (rule, value) => {
  const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
  const wrongEmail = value.find((testEmail) => !emailRegex.test(testEmail));
  if (wrongEmail) {
    throw new Error(`Строка "${wrongEmail}" не является Email адресом`);
  }
};
