import { coordinateService } from '@/globalStorage/coordinates/newIndex';
import { CordsManagerInfo } from '@/pages/CoordsPage/CordsManager/CordsManagerInfo';
import cn from 'classnames';
import { useStore } from 'effector-react';
import React from 'react';
import { CordsManagerCanvas } from './CordsManagerCanvas';
import './styles.scss';

type CordsManagerProps = {
  className?: string;
  style?: any;
};

const _CordsManager: React.FC<CordsManagerProps> = (props) => {
  const { className, style } = props;

  const isLoading = useStore(coordinateService.$loadingCoordinate);

  if (isLoading) {
    return <div>Загрузка...</div>;
  }

  return (
    <div className={cn('coords-manager', className)} style={style}>
      <CordsManagerCanvas className={'coords-manager__canvas'} />

      <CordsManagerInfo className='coords-manager__info' />
    </div>
  );
};

export const CordsManager = React.memo(_CordsManager);
