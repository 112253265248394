import { PolylineRecord } from '@/api/Coordinates/Coords/types';
import { UiPointRecord } from '@/globalStorage/coordinates/types';
import { createApi, createStore } from 'effector';
import { v4 as genUiId } from 'uuid';
import { RecordId } from './../../api/common/types';
import { UiPolylineRecord } from './types';

const createDefaultPoint = () => {
  return { id: genUiId(), mskX: 0, mskY: 0, wgsX: null, wgsY: null } as UiPointRecord;
};

export const createPolylineList = () => {
  const $polylineList = createStore<UiPolylineRecord[]>([]);
  const {
    initPolylineList,
    resetPolylineList,
    addNewPolyline,
    deletePolyline,
    editPolylinePoint,
    addPoint,
    addPointAfterPoint,
    addPointBeforePoint,
    deletePoint,
    addNewPolylineWithCords,
  } = createApi($polylineList, {
    initPolylineList: (_, payload: PolylineRecord[]) => {
      return payload.map((x) => ({
        id: genUiId(),
        points: x.coords.map((p) => ({
          id: genUiId(),
          ...p,
          mskX: p.mskX,
          mskY: p.mskY,
        })),
      })) as UiPolylineRecord[];
    },

    resetPolylineList: () => [],

    addNewPolyline: (polylineList) => {
      return [
        ...polylineList,
        { id: genUiId(), points: [{ id: genUiId(), mskX: 0, mskY: 0, wgsX: null, wgsY: null }] },
      ];
    },
    addNewPolylineWithCords: (polylineList, coords: { x: number; y: number }[]) => {
      return [
        ...polylineList,
        {
          id: genUiId(),
          points: coords.map((c) => ({
            id: genUiId(),
            mskX: c.x,
            mskY: c.y,
            wgsX: null,
            wgsY: null,
          })),
        },
      ];
    },

    deletePolyline: (polylineList, payload: { polylineId: RecordId }) => {
      return polylineList.filter((x) => x.id !== payload.polylineId);
    },

    editPolylinePoint: (
      polylineList,
      payload: {
        polylineId: RecordId;
        pointId: RecordId;
        patch: { mskX?: number; mskY?: number };
      },
    ) => {
      const newPolylineList = polylineList.map((polyline) => {
        if (polyline.id !== payload.polylineId) {
          return polyline;
        }

        return {
          ...polyline,
          points: polyline.points.map((point) => {
            if (point.id !== payload.pointId) {
              return point;
            }
            return { ...point, ...payload.patch } as UiPointRecord;
          }),
        };
      });

      return newPolylineList;
    },

    addPoint: (polylineList, payload: { polylineId: RecordId }) => {
      return polylineList.map((poly) => {
        if (poly.id !== payload.polylineId) {
          return poly;
        }

        return {
          ...poly,
          points: [...poly.points, createDefaultPoint()],
        } as UiPolylineRecord;
      });
    },

    deletePoint: (polylineList, payload: { polylineId: RecordId; pointId: RecordId }) => {
      return polylineList.map((poly) => {
        if (poly.id !== payload.polylineId) {
          return poly;
        }

        return {
          ...poly,
          points: poly.points.filter((point) => point.id !== payload.pointId),
        } as UiPolylineRecord;
      });
    },

    addPointAfterPoint: (polylineList, payload: { polylineId: RecordId; pointId: RecordId }) => {
      const newPolylineList = polylineList.map((polyline) => {
        if (polyline.id !== payload.polylineId) {
          return polyline;
        }

        const newPoints = [...polyline.points];
        const pointIndex = polyline.points.findIndex((x) => x.id === payload.pointId);
        if (pointIndex !== -1) {
          const srcPoint = newPoints[pointIndex];
          newPoints.splice(pointIndex + 1, 0, {
            id: genUiId(),
            mskX: srcPoint.mskX + 1,
            mskY: srcPoint.mskY + 1,
            wgsX: null,
            wgsY: null,
          });
        }

        return {
          ...polyline,
          points: newPoints,
        };
      });

      return newPolylineList;
    },

    addPointBeforePoint: (polylineList, payload: { polylineId: RecordId; pointId: RecordId }) => {
      const newPolylineList = polylineList.map((polyline) => {
        if (polyline.id !== payload.polylineId) {
          return polyline;
        }

        const newPoints = [...polyline.points];
        const pointIndex = polyline.points.findIndex((x) => x.id === payload.pointId);
        if (pointIndex !== -1) {
          const srcPoint = newPoints[pointIndex];
          newPoints.splice(pointIndex, 0, {
            id: genUiId(),
            mskX: srcPoint.mskX + 1,
            mskY: srcPoint.mskY + 1,
            wgsX: null,
            wgsY: null,
          });
        }

        return {
          ...polyline,
          points: newPoints,
        };
      });

      return newPolylineList;
    },
  });

  return {
    $polylineList,
    initPolylineList,
    resetPolylineList,
    editPolylinePoint,
    addPoint,
    addPointAfterPoint,
    addPointBeforePoint,
    addNewPolyline,
    deletePolyline,
    deletePoint,
    addNewPolylineWithCords,
  };
};
