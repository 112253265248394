import { RecordId } from '@/api/common/types';

export const boundaryQueryKeys = {
  all: ['cad_objects'] as const,
  details: () => [...boundaryQueryKeys.all, 'detail'] as const,
  detail: (id: RecordId) => [...boundaryQueryKeys.details(), id] as const,
  detailColumns: (id: RecordId) => [...boundaryQueryKeys.detail(id), 'columns'] as const,

  patch: () => [...boundaryQueryKeys.all, 'patch'] as const,
};
