import { DictCrudProps } from '@/components/DictCrudPage';
import { DisplayModeRecordResponse } from '@/api/Dictionary/DisplayMods/types';
import { useDeleteDisplayModeM, useGetAllDisplayModsQ } from '@/hooks/directory/displayMods';
import React from 'react';
import { Space, Tag } from 'antd';
import { EmptyValue } from '@uspect-team/ant-ui-kit';

export const getDisplayModePageConfig = (params: {
  onCreateButtonClick: () => void;
  onEditButtonClick: (record: DisplayModeRecordResponse) => void;
}): DictCrudProps<DisplayModeRecordResponse> => ({
  getName: (record) => record.name,
  texts: {
    createButtonText: 'Создать режим отображения',
    delete: {
      confirmTitle: (record) =>
        `Вы действительно хотите безвозвратно удалить режим отображения (${record.name})`,
      successMessage: (record) => `Режим отображения успешно удален (${record.name})`,
      errorMessage: (record) => `При удалении объекта произошла ошибка (${record.name})`,
    },
  },
  pagination: { type: 'none', getDataQ: useGetAllDisplayModsQ },
  deleteRecordM: useDeleteDisplayModeM,
  onCreateButtonClick: params.onCreateButtonClick,
  onEditButtonClick: params.onEditButtonClick,
  columns: [
    {
      title: 'Наименование',
      dataIndex: 'name',
      render: (_, record) => {
        return record.name;
      },
    },

    {
      title: 'Колонки',
      dataIndex: 'name',
      render: (_, record) => {
        if (record.listColumns.length === 0) {
          return <EmptyValue>Нет колонок</EmptyValue>;
        }
        return (
          <Space direction={'horizontal'} wrap>
            {record.listColumns.map((col) => (
              <Tag key={col._id}>{col.name}</Tag>
            ))}
          </Space>
        );
      },
    },
    {
      title: 'Порядок',
      dataIndex: 'sort',
    },
  ],
});
