import React from 'react';
import './styles.scss';
import { LoadingOutlined } from '@ant-design/icons';
import { ReactComponent as LogoSvg } from '@/assets/icons/logo_white.svg';

export const LoadingLayout: React.FC = React.memo(() => {
  return (
    <div className={'loading-layout'}>
      <div className={'loading-layout__logo'}>
        <LogoSvg width={'100%'} height={'100%'} />
      </div>
      <div className={'loading-layout__hint'}>
        <div className={'loading-layout__hint-text'}>Загрузка...</div>
        <LoadingOutlined className={'loading-layout__hint-spinner'} spin />
      </div>
    </div>
  );
});
