import { HistoryItem } from '@/globalStorage/utils/type';
import { Table } from 'antd';
import moment from 'moment';
import React from 'react';
import './styles.scss';

const tableColumns = [
  {
    key: 'cadObjectNumber',
    title: 'Номер',
    render: (x: HistoryItem<number>) => x.item,
  },
  {
    key: 'execTimeMilliseconds',
    title: 'Время выполнения',
    render: (x: HistoryItem<number>) => {
      const time = moment.duration(x.execTimeMilliseconds, 'milliseconds');
      return `${time.minutes()} м. ${time.seconds()} c.`;
    },
  },
  {
    key: 'status',
    title: 'Статус',
    render: (x: HistoryItem<number>) => {
      switch (x.status) {
        case 'success':
          return 'Успешно';
        case 'error':
          return 'Ошибка';
      }
    },
  },
  {
    key: 'message',
    title: 'Сообщение',
    render: (x: HistoryItem<number>) => {
      return x.message;
    },
  },
];

const scrollConfig = { y: 300 };

export type HistoryCadObjectExecTableProps = {
  history: HistoryItem<number>[];
};

const _HistoryCadObjectExecTable: React.FC<HistoryCadObjectExecTableProps> = ({ history }) => {
  return (
    <div className='history-table'>
      <div className='history-table__header'>История выполнения</div>
      {history.length > 0 ? (
        <Table
          bordered
          pagination={false}
          scroll={scrollConfig}
          dataSource={history}
          columns={tableColumns}
        />
      ) : (
        'Нет данных в истории'
      )}
    </div>
  );
};
export const HistoryCadObjectExecTable = React.memo(_HistoryCadObjectExecTable);
