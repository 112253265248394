import React from 'react';
import { BrowserRouter } from 'react-router-dom';

import { $isAuth } from '@/globalStorage/user';
import { useStore } from 'effector-react';
import { AuthRouter } from './components/AuthRouter';
import { GuestRouter } from './components/GuestRouter';

const _Router: React.FC = () => {
  const isAuth = useStore($isAuth);

  return <BrowserRouter>{isAuth ? <AuthRouter /> : <GuestRouter />}</BrowserRouter>;
};

export const Router = React.memo(_Router);
