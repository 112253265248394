import { $isInit, initAppFx } from '@/globalStorage/init';
import { useMount } from '@/hooks/useMount';
import 'antd/dist/antd.css';
import { useStore } from 'effector-react';
import moment from 'moment';
import 'moment/locale/ru';
import React from 'react';
import '../../assets/globals.scss';
import { LoadingLayout } from '../layouts/LoadingLayout';
import { GlobalModals } from './GlobalModals';
import '@/globalStorage/init';
import { Router } from '../Router';

const _App: React.FC = () => {
  useMount(() => {
    moment.locale('ru');

    initAppFx();
  });

  const isInit = useStore($isInit);

  return (
    <>
      {isInit ? <Router /> : <LoadingLayout />}
      <GlobalModals />
    </>
  );
};

export const App = React.memo(_App);
