import React from 'react';
import { Col, Form, Input, InputNumber, Row } from 'antd';
import { errorMessages } from '@/utils/errorMessages';
import { ColumnContractTypeSelect } from './ColumnContractTypeSelect';

export const ColumnGroupFields: React.FC = React.memo(() => {
  return (
    <>
      <Form.Item
        name={'name'}
        label={'Наименование'}
        rules={[{ required: true, message: errorMessages.required }]}
      >
        <Input style={{ width: '100%' }} />
      </Form.Item>
      <Row gutter={16}>
        <Col span={12}>
          <Form.Item
            name={'contractType'}
            label={'Для типа контрактов'}
            rules={[{ required: true, message: errorMessages.required }]}
          >
            <ColumnContractTypeSelect />
          </Form.Item>
        </Col>
        <Col span={12}>
          <Form.Item
            name={'order'}
            label={'Порядок'}
            rules={[
              { required: true, message: errorMessages.required },
              { type: 'integer', message: errorMessages.integer },
            ]}
          >
            <InputNumber style={{ width: '100%' }} />
          </Form.Item>
        </Col>
      </Row>
    </>
  );
});
