import React from 'react';
import cn from 'classnames';
import './styles.scss';

export type RegisterHeaderProps = {
  className?: string;
  style?: React.CSSProperties;
};

const _RegisterHeader: React.FC<RegisterHeaderProps> = ({ className, style }) => {
  return (
    <div className={cn('register-form-header', className)} style={style}>
      <div className='register-form-header__title'>Регистрация в «Актусленд»</div>
      <div className='register-form-header__description'>
        Зарегистрируйтесь в 3 простых шага, заполнив форму ниже:
      </div>
    </div>
  );
};
export const RegisterHeader = React.memo(_RegisterHeader);
