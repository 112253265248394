import React, { useCallback, useMemo, useState } from 'react';
import { Button, message, Modal } from 'antd';
import { CoupleFileResponse, CoupleFileType } from '@/api/Files/types';
import { EditFileCollectionBase } from '@/components/modals/common/EditFileCollectionBase';
import { ControlledTable, FileLoader } from '@uspect-team/ant-ui-kit';
import { ControlledTableState } from '@uspect-team/ant-ui-kit/dist/Tables/ControlledTable/types';
import { getCoupleColumns } from '@/components/modals/utils/getCoupleColumns';
import { RcFile } from 'antd/lib/upload';
import { getAllowedExtByFileType } from '@/utils/fileUtils';
import { FileCardCouple, FileCardValue } from '@/components/FileCardCouple';
import { getCouplePseudoId } from '@/components/modals/utils/getCouplePseudoId';
import {
  SelectAlreadyExistCoupleFilesModal,
  SelectAlreadyExistCoupleFilesModalProps,
} from '@/components/modals/cadObjects/SelectAlreadyExistCoupleFilesModal';
import { getFilenameWithoutExt } from '@/utils/getFilenameWithoutExt';
import { useUploadFileM } from '@/hooks/files';
import {
  useCadObjectFileCouplesQ,
  useDeleteFileCoupleInCadObjectCollectionM,
  usePatchCadObjectM,
} from '@/hooks/cadastralObjects';
import { unpackCoupleFileType } from '@/utils/fileTypeUtils';
import { CadObjectResponse } from '@/api/CadObjects/types';
import { RecordId } from '@/api/common/types';
import { AxiosError } from 'axios';

export type EditCoupleFileCollectionInCadModalProps =
  | {
      visible: false;
    }
  | ({
      visible: true;
    } & VisibleProps);

type VisibleProps = {
  contractId: RecordId;
  onClose: () => void;
  fileType: CoupleFileType;
  dataIndex: string;
  name: string;
  cadObjectId: string;
};
export const EditCoupleFileCollectionInCadModal: React.FC<EditCoupleFileCollectionInCadModalProps> =
  React.memo((props) => {
    const { visible } = props;

    if (!visible) {
      return null;
    }

    const { visible: _, ...visibleProps } = props;

    return <VisibleModal {...visibleProps} />;
  });

const VisibleModal: React.FC<VisibleProps> = React.memo((props) => {
  const { onClose, name, dataIndex, cadObjectId, fileType, contractId } = props;
  const coupleFileTypes = useMemo(() => {
    return unpackCoupleFileType(fileType);
  }, [fileType]);

  const uploadFileM = useUploadFileM();
  const patchCadObjectM = usePatchCadObjectM();
  const { mutateAsync: deleteFileCoupleM, isLoading: isLoadingDeleteFileCouple } =
    useDeleteFileCoupleInCadObjectCollectionM();
  const { data: currentCoupleFiles = [], isLoading } = useCadObjectFileCouplesQ({
    id: cadObjectId,
    dataIndex: dataIndex as keyof CadObjectResponse,
  });

  const [tableState, setTableState] = useState<ControlledTableState>({
    pagination: {
      pageSize: 10,
      currentPage: 1,
    },
  });

  const title = React.useMemo(() => {
    return `${name}: Выбор уже загруженных пар файлов`;
  }, [name]);

  const [uploadCoupleFiles, setUploadCoupleFiles] = useState<[RcFile, RcFile] | null>(null);
  const [uploadAlreadyExistCoupleFiles, setUploadAlreadyExistCoupleFiles] =
    useState<CoupleFileResponse | null>(null);
  const [selectAlreadyExistCoupleFilesModalState, setSelectAlreadyExistCoupleFilesModalState] =
    useState<SelectAlreadyExistCoupleFilesModalProps>({ visible: false });

  const uploadCoupleFilesHandler = useCallback((_: RcFile, couple: RcFile[]) => {
    setUploadCoupleFiles(couple as unknown as [RcFile, RcFile]);
    setUploadAlreadyExistCoupleFiles(null);
  }, []);

  const selectAlreadyExistCoupleFilesHandler = useCallback((couple: CoupleFileResponse) => {
    setUploadAlreadyExistCoupleFiles(couple);
    setUploadCoupleFiles(null);
  }, []);

  const clickSelectAlreadyExistHandler = useCallback(() => {
    setSelectAlreadyExistCoupleFilesModalState({
      visible: true,
      contractId,
      title: `${name}: Выбор уже загруженных пар файлов`,
      onClose: () => setSelectAlreadyExistCoupleFilesModalState({ visible: false }),
      fileType: fileType,
      onSelectCouple: selectAlreadyExistCoupleFilesHandler,
    });
  }, [contractId, name, fileType, selectAlreadyExistCoupleFilesHandler]);

  const allowedExts = useMemo(() => {
    return [
      getAllowedExtByFileType(coupleFileTypes[0]),
      getAllowedExtByFileType(coupleFileTypes[1]),
    ];
  }, [coupleFileTypes]);

  const onDeleteCoupleFile = React.useCallback(
    async (record: CoupleFileResponse) => {
      try {
        await deleteFileCoupleM({
          id: cadObjectId,
          dataIndex,
          deletableCoupleFileIds: [record[0]._id, record[1]._id],
        });
        message.success('Пара файлов исключена');
      } catch {
        message.error('Не удалось исключить пару файлов');
      }
    },
    [cadObjectId, dataIndex, deleteFileCoupleM],
  );

  const deletableFileColumns = useMemo(() => {
    return getCoupleColumns(coupleFileTypes, onDeleteCoupleFile, isLoadingDeleteFileCouple);
  }, [coupleFileTypes, isLoadingDeleteFileCouple, onDeleteCoupleFile]);

  const onOkHandler = useCallback(async () => {
    try {
      if (uploadCoupleFiles || uploadAlreadyExistCoupleFiles) {
        if (uploadCoupleFiles) {
          const uploadRes = await uploadFileM.mutateAsync({
            type: fileType,
            files: uploadCoupleFiles,
            contractId,
          });
          const newCoupleIds = currentCoupleFiles.flatMap((coupleFiles) =>
            coupleFiles.map((file) => file._id),
          );
          const uploadIds = uploadRes.data.data.map((x) => x._id);
          newCoupleIds.push(...uploadIds);
          await patchCadObjectM.mutateAsync({
            id: cadObjectId,
            patch: { [dataIndex]: newCoupleIds },
          });
        } else if (uploadAlreadyExistCoupleFiles) {
          const newCoupleIds = currentCoupleFiles.flatMap((coupleFiles) =>
            coupleFiles.map((file) => file._id),
          );
          const uploadIds = uploadAlreadyExistCoupleFiles.map((x) => x._id);
          newCoupleIds.push(...uploadIds);
          await patchCadObjectM.mutateAsync({
            id: cadObjectId,
            patch: { [dataIndex]: newCoupleIds },
          });
          setUploadCoupleFiles(null);
          setUploadAlreadyExistCoupleFiles(null);
        }
      }
    } catch (e) {
      if ((e as AxiosError).response?.status === 409) {
        message.error(
          'Файл с таким именем уже загружен. Выберите его или переименуйте и загрузите этот файл',
        );
      } else {
        message.error('Не удалось загрузить пару файлов');
      }
    }
  }, [
    contractId,
    uploadCoupleFiles,
    uploadAlreadyExistCoupleFiles,
    cadObjectId,
    currentCoupleFiles,
    dataIndex,
    fileType,
    uploadFileM,
    patchCadObjectM,
  ]);

  const currentCoupleFilesFilter = useMemo(() => {
    if (currentCoupleFiles) {
      return currentCoupleFiles.map((item) => {
        switch (item[0].fileType) {
          case 'kptMIF':
            return item.reverse();
          case 'rastrTAB':
            return item.reverse();

          default:
            return item;
        }
      });
    }
  }, [currentCoupleFiles]);

  return (
    <Modal
      visible={true}
      title={title}
      onCancel={onClose}
      width={1000}
      okText={'Сохранить новый файл'}
      onOk={onOkHandler}
      okButtonProps={{
        disabled: !uploadCoupleFiles && !uploadAlreadyExistCoupleFiles,
        loading: uploadFileM.isLoading || patchCadObjectM.isLoading,
      }}
      cancelText={'Отмена'}
    >
      <EditFileCollectionBase>
        <EditFileCollectionBase.Table>
          <ControlledTable
            rowKey={getCouplePseudoId}
            tableState={tableState}
            onChangeTableState={setTableState}
            dataSource={currentCoupleFilesFilter as any}
            columns={deletableFileColumns}
            pagination={{ type: 'client', pageSizeOptions: [10] }}
            loading={isLoading}
          />
        </EditFileCollectionBase.Table>
        {(uploadCoupleFiles || uploadAlreadyExistCoupleFiles) && (
          <EditFileCollectionBase.NewFile>
            {uploadCoupleFiles && (
              <FileCardCouple
                couple={
                  uploadCoupleFiles.map(
                    (x) =>
                      ({
                        name: x.name,
                        size: x.size,
                        uploadAt: 'now',
                      } as FileCardValue),
                  ) as unknown as [FileCardValue, FileCardValue]
                }
              />
            )}
            {uploadAlreadyExistCoupleFiles && (
              <FileCardCouple
                couple={
                  uploadAlreadyExistCoupleFiles.map((x) => ({
                    ...x,
                    name: x.fileName,
                    link: x.fileUri,
                  })) as unknown as [File, File]
                }
              />
            )}
          </EditFileCollectionBase.NewFile>
        )}
        <EditFileCollectionBase.Upload>
          <FileLoader
            allowedFiles={allowedExts}
            onUpload={uploadCoupleFilesHandler}
            validator={(files) => {
              const couple = files as unknown as [RcFile, RcFile];
              const firstFileName = getFilenameWithoutExt(couple[0].name);
              const secondFileName = getFilenameWithoutExt(couple[1].name);
              if (firstFileName !== secondFileName) {
                return `Названия файлов должны быть равны ("${firstFileName}" и "${secondFileName}" не равны)`;
              }
            }}
          />
          <Button block type={'link'} onClick={clickSelectAlreadyExistHandler}>
            Или выберите среди уже загруженных
          </Button>
        </EditFileCollectionBase.Upload>
      </EditFileCollectionBase>
      <SelectAlreadyExistCoupleFilesModal {...selectAlreadyExistCoupleFilesModalState} />
    </Modal>
  );
});
