import { AutoGenFilesModal } from '@/components/modals/AutoGenFilesModal';
import { AutoRunModal } from '@/components/modals/AutoRunModal';
import { AutoSignModal } from '@/components/modals/AutoSignModal';
import { SelectAlreadyExistFileModal } from '@/components/modals/cadObjects/SelectAlreadyExistFileModal';
import { SelectAlreadyExistPdModal } from '@/components/modals/cadObjects/SelectAlreadyExistPdModal';
import { CreatePdModal } from '@/components/modals/CreatePdModal';
import { UploadFileModal } from '@/components/modals/files/UploadFileModal';
import { $selectAlreadyExistFileModalState, $uploadFileModalState } from '@/globalStorage/file';
import { $createPdModalState, $selectAlreadyExistPdModalState } from '@/globalStorage/pd';
import { useStore } from 'effector-react';
import React from 'react';

const _GlobalModals: React.FC = () => {
  const createPdModalState = useStore($createPdModalState);
  const selectAlreadyExistPdModalState = useStore($selectAlreadyExistPdModalState);

  const uploadFileModalState = useStore($uploadFileModalState);
  const selectAlreadyExistFileModalState = useStore($selectAlreadyExistFileModalState);

  return (
    <>
      <UploadFileModal {...uploadFileModalState} />
      <SelectAlreadyExistFileModal {...selectAlreadyExistFileModalState} />

      <SelectAlreadyExistPdModal {...selectAlreadyExistPdModalState} />
      <CreatePdModal {...createPdModalState} />

      <AutoRunModal />
      <AutoSignModal />
      <AutoGenFilesModal />
    </>
  );
};
export const GlobalModals = React.memo(_GlobalModals);
