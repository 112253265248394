import { DictCrudProps } from '@/components/DictCrudPage';
import { DateView, EmptyValue } from '@uspect-team/ant-ui-kit';
import React from 'react';
import { CptRecordResponse } from '@/api/Dictionary/Cpt/types';
import { useDeleteCptM, useGetCptPageQ } from '@/hooks/directory/cpt';
import { FileRecordResponse } from '@/api/Files/types';
import { EditableDocumentWithUploadAlreadyExist } from '@/components/EditableDocumentWithUploadAlreadyExist';
import { MifCell } from '@/pages/Dictionary/CptPage/MifCell';
import { emptyShellMessage } from '@/utils/emptyShellMessage';

const getName = (record: CptRecordResponse): string => {
  return record.cadQuarter;
};

export const getCptPageConfig = (params: {
  onCreateButtonClick: () => void;
  onEditButtonClick: (record: CptRecordResponse) => void;
}): DictCrudProps<CptRecordResponse> => ({
  getName: getName,
  texts: {
    createButtonText: 'Добавить КПТ',
    delete: {
      confirmTitle: (record) => `Вы действительно хотите безвозвратно КПТ (${getName(record)})`,
      successMessage: (record) => `КПТ успешно удален (${getName(record)})`,
      errorMessage: (record) => `При удалении КПТ произошла ошибка (${getName(record)})`,
    },
  },
  pagination: { type: 'server', getPageQ: useGetCptPageQ },
  deleteRecordM: useDeleteCptM,
  onCreateButtonClick: params.onCreateButtonClick,
  onEditButtonClick: params.onEditButtonClick,
  columns: [
    {
      key: 'region',
      dataIndex: 'cadQuarter',
      title: 'Регион',
      sortable: true,
      render: (value: string) => {
        const regex = /(\d{2}):\d{2}:\d{5,}/;
        const matchArr = value ? value.match(regex) : null;
        const region = matchArr ? matchArr[1] : null;
        return region ? region : <EmptyValue />;
      },
    },
    {
      key: 'cadQuarter',
      dataIndex: 'cadQuarter',
      title: 'Кадастровый квартал',
      sortable: true,
    },
    {
      key: 'fileXML',
      dataIndex: 'fileXML',
      title: 'XML',
      render: (value: FileRecordResponse | undefined) => (
        <EditableDocumentWithUploadAlreadyExist
          document={
            value
              ? {
                  name: value.fileName,
                  size: value.size,
                  link: value.fileUri,
                  uploadOn: new Date(value.createdAt),
                }
              : undefined
          }
        />
      ),
    },
    {
      key: 'fileMIF',
      dataIndex: 'fileMIF',
      title: 'MIF',
      render: (value: FileRecordResponse | undefined, record) => (
        <MifCell
          document={
            value
              ? {
                  name: value.fileName,
                  size: value.size,
                  link: value.fileUri,
                  uploadOn: new Date(value.createdAt),
                }
              : undefined
          }
          onGenMif={record.fileXML ? () => {} : undefined}
        />
      ),
    },
    {
      key: 'createdAt',
      dataIndex: 'createdAt',
      title: 'Дата создания',
      sortable: true,

      render: (value) => <DateView value={value} withTime />,
    },
    {
      key: 'updatedAt',
      dataIndex: 'updatedAt',
      title: 'Дата изменения',
      sortable: true,

      render: (value) => <DateView value={value} withTime />,
    },
  ],
  additionalRecordActions: [
    {
      key: 'gen',
      title: 'Генерация',
      children: [
        { key: 'gen_mif', title: 'Сгенирировать MIF', onClick: emptyShellMessage },
        { key: 'gen_xml', title: 'Сгенирировать XML', onClick: emptyShellMessage },
      ],
    },
  ],
  getAdditionalSelectedActions: (_) => {
    return [
      { key: 'gen_mif', title: 'Сгенирировать MIF', onClick: emptyShellMessage },
      { key: 'gen_xml', title: 'Сгенирировать XML', onClick: emptyShellMessage },
    ];
  },
});
