import { RecordId } from '@/api/common/types';
import { SelectContract } from '@/components/inputs/SelectContract';
import { setInitialCadObjectNumbers, setInitialContractId } from '@/globalStorage/autoRun/modal';
import { Form } from 'antd';
import React from 'react';
import { CadObjectNumbersSelector } from '../../cadObjects/common/CadObjectNumbersSelector';
import { StepContent } from '../../../StepContent';

export type AutoRunModalIdleProps = {
  contractId: RecordId | null;
  cadObjectNumbers: number[];
};

const _AutoRunModalIdle: React.FC<AutoRunModalIdleProps> = (props) => {
  const { contractId, cadObjectNumbers } = props;
  return (
    <StepContent>
      <Form layout='vertical'>
        <Form.Item label={'Контракт'}>
          <SelectContract
            placeholder='Выберите контракт'
            value={contractId}
            onChange={setInitialContractId}
          />
        </Form.Item>
        <Form.Item label='Заполните номера объектов (или вставьте их из Excel)'>
          <CadObjectNumbersSelector
            initNumbers={cadObjectNumbers}
            onChangeNumbers={setInitialCadObjectNumbers}
          />
        </Form.Item>
      </Form>
    </StepContent>
  );
};
export const AutoRunModalIdle = React.memo(_AutoRunModalIdle);
