import {
  CreateOrganizationRequest,
  CreateOrganizationResponse,
  OrganizationsGetByIdResponse,
  GetOrganizationsPageRequest,
  OrganizationsGetResponse,
  PatchOrganizationRequest,
  PatchOrganizationResponse,
} from './types';
import { api } from '@/api/axiosInstance';
import { RecordId } from '@/api/common/types';

export class OrganizationsApi {
  get(params: GetOrganizationsPageRequest) {
    return api.get<OrganizationsGetResponse>('/organizations', { params });
  }

  getById(id: RecordId) {
    return api.get<OrganizationsGetByIdResponse>(`/organizations/${id}`);
  }

  create(data: CreateOrganizationRequest) {
    return api.post<CreateOrganizationResponse>('/organizations', data);
  }

  delete(id: RecordId) {
    return api.delete<void>(`/organizations/${id}`);
  }

  patch(data: PatchOrganizationRequest) {
    return api.patch<PatchOrganizationResponse>(`/organizations/${data.id}`, data.patch);
  }
}
