import { CadObjectStatus } from '@/api/CadObjects/types';

export const statusValueLabelMap = new Map<CadObjectStatus, string>([
  ['excluded', 'Исключен'],
  ['remarks', 'С ошибками'],
  ['noData', 'Без данных'],
  ['geodesy', 'С геодезией'],
  ['kptOrRasters', 'С КПТ и растрами'],
  ['scheme', 'Со схемой'],
  ['soglasSend', 'Отправлено на согласование'],
  ['soglasYes', 'Согласовано'],
  ['pd', 'С ПД'],
  ['palataSend', 'Направлено в КП'],
  ['palataGetNotification', 'Получено увед. из КП'],
  ['packageZipWithSignature', 'С подписанным пакетом'],
  ['act', 'Получен акт'],
  ['package', 'С пакетом'],
]);
