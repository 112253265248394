import { DecimalFilterDropdown } from '@/components/filterDropdowns/DecimalFilterDropdown';
import { DecimalFilterValue } from '@/components/filterDropdowns/DecimalFilterDropdown/types';
import { errorMessages } from '@/utils/errorMessages';
import { EditableDecimal } from '@uspect-team/ant-ui-kit';
import { ControlledTableChildType } from '@uspect-team/ant-ui-kit/dist/Tables/ControlledTable/types';
import React from 'react';
import * as yup from 'yup';
import {
  BaseServerChildColumnType,
  DecimalServerChildColumnAddition,
  ServerConfigTableContext,
  TableRecordType,
} from '../../types';
import { dataIndexToStringKey } from '../dataIndexToStringKey';

export const getDecimalColumnAddition = (
  strSerCol: BaseServerChildColumnType & DecimalServerChildColumnAddition,
  context: ServerConfigTableContext,
  editable: boolean,
): Partial<ControlledTableChildType<TableRecordType>> => {
  let validationSchema = yup.number();
  if (strSerCol.isRequired) {
    validationSchema = validationSchema.required(errorMessages.required);
  }
  if (strSerCol.validation?.isPositive) {
    validationSchema = validationSchema.min(0, errorMessages.positive);
  }
  if (strSerCol.validation?.min) {
    validationSchema = validationSchema.min(
      strSerCol.validation.min,
      errorMessages.minNumber(strSerCol.validation.min),
    );
  }
  if (strSerCol.validation?.max) {
    validationSchema = validationSchema.max(
      strSerCol.validation.max,
      errorMessages.maxNumber(strSerCol.validation.max),
    );
  }

  const { dataIndex, isEditable, isFilterable, width } = strSerCol;
  const onEdit = context.onEdit;
  return {
    align: 'left',
    width: width ?? 200,
    filterDropdown: isFilterable
      ? ({ currentFilterState: _currentFilterState, setFilterValue, removeFilter }) => {
          const filter = _currentFilterState as DecimalFilterValue;

          return (
            <DecimalFilterDropdown
              filter={filter}
              onChangeFilter={setFilterValue}
              onResetFilter={removeFilter}
              hasEqual
              hasBetween
              hasExist
            />
          );
        }
      : undefined,
    render:
      isEditable && editable
        ? (value, record) => {
            if (record.status === 'excluded') {
              return value;
            }
            return (
              <EditableDecimal
                block
                value={value}
                validationSchema={validationSchema}
                onChange={
                  onEdit
                    ? (value) =>
                        onEdit({
                          patch: { [dataIndexToStringKey(dataIndex)]: value ?? null },
                          oldRecord: record,
                        })
                    : undefined
                }
              />
            );
          }
        : undefined,
  };
};
