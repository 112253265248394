import { TdRecordResponse } from '@/api/Td/types';
import { getPdColumns } from '@/components/modals/utils/pdColumns';
import { openCreatePdModal, openSelectAlreadyExistPdModal } from '@/globalStorage/pd';
import { ControlledTable } from '@uspect-team/ant-ui-kit';
import { ControlledTableState } from '@uspect-team/ant-ui-kit/dist/Tables/ControlledTable/types';
import { Button, Form, Space } from 'antd';
import React, { useCallback } from 'react';
import { TEditContractForm } from '@/types';
import { TabContent } from '../../TabContent';
import './styles.scss';

type PdTableProps = {
  data?: TdRecordResponse[];
  onChangeData: (newPds: TdRecordResponse[]) => void;
};
const PdTable: React.FC<PdTableProps> = (props) => {
  const { data, onChangeData } = props;

  const [tableState, setTableState] = React.useState<ControlledTableState>({
    pagination: { pageSize: 10, currentPage: 1 },
  });

  const columns = React.useMemo(() => {
    return getPdColumns({
      onDelete: async (record) => {
        onChangeData((data ?? []).filter((pd) => pd._id !== record._id));
      },
    });
  }, [data, onChangeData]);

  const addNewPdInCollection = useCallback(
    (pd: TdRecordResponse) => {
      onChangeData([...(data ?? []), pd]);
    },
    [data, onChangeData],
  );

  const onClickCreateNewPdHandler = useCallback(() => {
    openCreatePdModal({
      onCreate: addNewPdInCollection,
      title: 'Создание нового ПД',
    });
  }, [addNewPdInCollection]);

  const onClickSelectAlreadyExistPdHandler = useCallback(() => {
    openSelectAlreadyExistPdModal({
      title: 'Выбор существующего ПД',
      onSelectPd: addNewPdInCollection,
    });
  }, [addNewPdInCollection]);

  return (
    <>
      <Space>
        <Button type='primary' onClick={onClickCreateNewPdHandler}>
          Создать новый ПД
        </Button>
        <Button onClick={onClickSelectAlreadyExistPdHandler}>Добавить из уже загруженных</Button>
      </Space>
      <ControlledTable
        rowKey={(x) => x._id}
        tableState={tableState}
        onChangeTableState={setTableState}
        columns={columns}
        pagination={{ type: 'client', pageSizeOptions: [10] }}
        dataSource={data}
      />
    </>
  );
};

type SharedPdTabContentProps = {
  onChangeForm: (formPatch: TEditContractForm) => void;
};

const _SharedPdTabContent: React.FC<SharedPdTabContentProps> = ({ onChangeForm }) => {
  const onChangePublicPds = React.useCallback(
    (newPds: TdRecordResponse[]) => {
      onChangeForm({ publicPD: newPds });
    },
    [onChangeForm],
  );
  return (
    <TabContent className='shared-pd-tab-content'>
      <div>Настройте общие Правоустанавливающие документы (ПД) для всего контракта</div>
      <Form.Item noStyle dependencies={['publicPD']}>
        {({ getFieldValue }) => {
          const data = getFieldValue('publicPD');

          return <PdTable data={data} onChangeData={onChangePublicPds} />;
        }}
      </Form.Item>
    </TabContent>
  );
};
export const SharedPdTabContent = React.memo(_SharedPdTabContent);
