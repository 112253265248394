import { TpRecordResponse } from '@/api/Coordinates/Tp/types';
import { coordinateService } from '@/globalStorage/coordinates/newIndex';
import { CloseOutlined } from '@ant-design/icons';
import { Button } from 'antd';
import { useStore } from 'effector-react';
import React from 'react';
import cn from 'classnames';
import './styles.scss';

export type TpItemProps = {
  tp: TpRecordResponse;
};

const _TpItem: React.FC<TpItemProps> = ({ tp }) => {
  const selectedTpId = useStore(coordinateService.$selectedTpId);
  const isSelectedTp = selectedTpId ? tp._id === selectedTpId : false;

  const onItemTitleClickHandler = () => {
    if (isSelectedTp) {
      coordinateService.resetSelect();
    } else {
      coordinateService.selectTp({ tpId: tp._id });
    }
  };

  return (
    <div className={cn('tp-item', { 'tp-item--selected': isSelectedTp })}>
      <div className='tp-item__title' onClick={onItemTitleClickHandler}>
        {tp.title}
      </div>
      <Button
        size='small'
        type='text'
        icon={<CloseOutlined />}
        onClick={(e) => {
          e.stopPropagation();
          coordinateService.deleteTp(tp._id);
        }}
      />
    </div>
  );
};
export const TpItem = React.memo(_TpItem);
