import { RecordId } from '@/api/common/types';
import { autoGenFilesModalService } from '@/globalStorage/autoGenFiles/modal';
import { openAutoRunWithInit } from '@/globalStorage/autoRun/modal';
import { autoSignModalService } from '@/globalStorage/autoSign/modal';
import { useGetContractNotifyQ } from '@/hooks/contracts';
import { ForwardOutlined } from '@ant-design/icons';
import { Button } from 'antd';
import React from 'react';
import { setTableFilterStatus } from '../../../model';
import './styles.scss';

export type NotifyOverlayProps = {
  className?: string;
  style?: React.CSSProperties;

  contractId: RecordId;

  close: () => void;
};

const _NotifyOverlay: React.FC<NotifyOverlayProps> = (props) => {
  const { contractId, close } = props;
  const { data: notifyInfo, isLoading } = useGetContractNotifyQ(contractId);

  const noOneNotify = React.useMemo(() => {
    if (isLoading || !notifyInfo) {
      return false;
    }

    const { genKptRasters, genPackageZip, genScheme, signaturePackageZip } = notifyInfo;
    return (
      genKptRasters.length === 0 &&
      genPackageZip.length === 0 &&
      genScheme.length === 0 &&
      signaturePackageZip.length === 0
    );
  }, [isLoading, notifyInfo]);

  const canGenKptRasters = notifyInfo?.genKptRasters && notifyInfo.genKptRasters.length > 0;
  const canGenPackageZip = notifyInfo?.genPackageZip && notifyInfo.genPackageZip.length > 0;
  const canGenScheme = notifyInfo?.genScheme && notifyInfo.genScheme.length > 0;
  const canSignaturePackageZip =
    notifyInfo?.signaturePackageZip && notifyInfo.signaturePackageZip.length > 0;

  return (
    <div className='notify-overlay'>
      <div className='notify-overlay__header'>Помощник обработки объектов</div>

      <div className='notify-overlay__action-list'>
        {isLoading ? (
          'Загрузка...'
        ) : (
          <>
            {noOneNotify && (
              <div className='notify-overlay__no-one-notify'>Никаких действий не доступно</div>
            )}
            {canGenKptRasters && notifyInfo && (
              <div className='notify-overlay__action-item'>
                <div className='notify-overlay__action-item-header'>
                  Можно сгенерировать растры и КПТ
                </div>
                <div className='notify-overlay__action-item-count'>
                  {notifyInfo.genKptRasters.length} Шт
                </div>
                <div className='notify-overlay__action-item-show-link'>
                  <Button type='link' onClick={() => setTableFilterStatus('geodesy')}>
                    Показать объекты
                  </Button>
                </div>
                <div className='notify-overlay__action-item-launch'>
                  <Button
                    className='notify-overlay__action-item-launch-btn'
                    onClick={() => {
                      autoGenFilesModalService.openAutoGenFilesModalWithInit({
                        initialContractId: contractId,
                        initialCadObjectNumbers: notifyInfo.genKptRasters,
                        initialFileTypes: ['kpt', 'rasters'],
                      });
                      close();
                    }}
                    icon={<ForwardOutlined />}
                    shape='circle'
                  />
                </div>
              </div>
            )}

            {canGenScheme && (
              <div className='notify-overlay__action-item'>
                <div className='notify-overlay__action-item-header'>Можно сгенерировать схемы</div>
                <div className='notify-overlay__action-item-count'>
                  {notifyInfo.genScheme.length} Шт
                </div>
                <div className='notify-overlay__action-item-show-link'>
                  <Button type='link' onClick={() => setTableFilterStatus('kptOrRasters')}>
                    Показать объекты
                  </Button>
                </div>
                <div className='notify-overlay__action-item-launch'>
                  <Button
                    className='notify-overlay__action-item-launch-btn'
                    onClick={() => {
                      openAutoRunWithInit({
                        initialContractId: contractId,
                        initialCadObjectNumbers: notifyInfo.genScheme,
                      });
                      close();
                    }}
                    icon={<ForwardOutlined />}
                    shape='circle'
                  />
                </div>
              </div>
            )}

            {canGenPackageZip && (
              <div className='notify-overlay__action-item'>
                <div className='notify-overlay__action-item-header'>Можно сгенерировать пакеты</div>
                <div className='notify-overlay__action-item-count'>
                  {notifyInfo.genPackageZip.length} Шт
                </div>
                <div className='notify-overlay__action-item-show-link'>
                  <Button type='link' onClick={() => setTableFilterStatus('pd')}>
                    Показать объекты
                  </Button>
                </div>
                <div className='notify-overlay__action-item-launch'>
                  <Button
                    className='notify-overlay__action-item-launch-btn'
                    onClick={() => {
                      openAutoRunWithInit({
                        initialContractId: contractId,
                        initialCadObjectNumbers: notifyInfo.genPackageZip,
                      });
                      close();
                    }}
                    icon={<ForwardOutlined />}
                    shape='circle'
                  />
                </div>
              </div>
            )}

            {canSignaturePackageZip && (
              <div className='notify-overlay__action-item'>
                <div className='notify-overlay__action-item-header'>Можно подписать пакеты</div>
                <div className='notify-overlay__action-item-count'>
                  {notifyInfo.signaturePackageZip.length} Шт
                </div>
                <div className='notify-overlay__action-item-show-link'>
                  <Button type='link' onClick={() => setTableFilterStatus('package')}>
                    Показать объекты
                  </Button>
                </div>
                <div className='notify-overlay__action-item-launch'>
                  <Button
                    className='notify-overlay__action-item-launch-btn'
                    onClick={() => {
                      autoSignModalService.openAutoSignModalWithInit({
                        initialContractId: contractId,
                        initialCadObjectNumbers: notifyInfo.genPackageZip,
                      });

                      close();
                    }}
                    icon={<ForwardOutlined />}
                    shape='circle'
                  />
                </div>
              </div>
            )}
          </>
        )}
      </div>
    </div>
  );
};
export const NotifyOverlay = React.memo(_NotifyOverlay);
