import {
  $autoRunModalState,
  backToAutoRunInit,
  closeAutoRunModal,
  startAutoRunInModal,
} from '@/globalStorage/autoRun/modal';
import { AutoRunModalState } from '@/globalStorage/autoRun/types';
import { Button, Modal, Space, Steps } from 'antd';
import { createComponent } from 'effector-react';
import React from 'react';
import { AutoRunModalDone } from './AutoRunModalDone';
import { AutoRunModalIdle } from './AutoRunModalIdle';
import { AutoRunModalInProcess } from './AutoRunModalInProcess';

const mapAutorunStateToCurrentStep = (state: 'idle' | 'inProcess' | 'done') => {
  switch (state) {
    case 'idle':
      return 0;
    case 'inProcess':
      return 1;
    case 'done':
      return 2;
    default:
      return 0;
  }
};

const getFooter = (state: AutoRunModalState) => {
  if (state.visible) {
    switch (state.state) {
      case 'idle':
        return (
          <Space>
            <Button onClick={() => closeAutoRunModal()}>Отмена</Button>
            <Button
              type={'primary'}
              onClick={() => startAutoRunInModal()}
              disabled={!state.isCanStartAutoRun}
            >
              Начать автопрогон
            </Button>
          </Space>
        );
      case 'inProcess':
        return <Button onClick={() => closeAutoRunModal()}>Свернуть</Button>;
      case 'done':
        return (
          <Space>
            <Button onClick={() => backToAutoRunInit()}>Назад к началу</Button>
            <Button type={'primary'} onClick={() => closeAutoRunModal()}>
              Завершить
            </Button>
          </Space>
        );
    }
  }
};

export const AutoRunModal = createComponent($autoRunModalState, (props, state) => {
  if (!state.visible) {
    return null;
  }

  return (
    <Modal
      width={1000}
      visible={true}
      onCancel={() => closeAutoRunModal()}
      title={'Автоматический прогон объектов'}
      footer={getFooter(state)}
    >
      <Steps size='small' current={mapAutorunStateToCurrentStep(state.state)}>
        <Steps.Step title='Выбор объектов' />
        <Steps.Step title='Статус прогона' />
        <Steps.Step title='Результат' />
      </Steps>

      {state.state === 'idle' && (
        <AutoRunModalIdle contractId={state.contractId} cadObjectNumbers={state.cadObjectNumbers} />
      )}
      {state.state === 'inProcess' && (
        <AutoRunModalInProcess
          contractId={state.contractId}
          execItem={state.execItem}
          history={state.history}
          queue={state.queue}
        />
      )}
      {state.state === 'done' && <AutoRunModalDone {...state} />}
    </Modal>
  );
});
