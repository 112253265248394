import { registerStateService } from '@/globalStorage/register';
import cn from 'classnames';
import { useStoreMap } from 'effector-react';
import React from 'react';
import { RegisterHeader } from './components/RegisterFormHeader';
import { RegisterSteps } from './components/RegisterSteps';
import { RegisterCompanyInfoStep } from './steps/RegisterCompanyInfoStep';
import { RegisterFinishStep } from './steps/RegisterFinishStep';
import { RegisterInProgressStep } from './steps/RegisterInProgressStep';
import { RegisterJuridicalInfoStep } from './steps/RegisterJuridicalInfoStep';
import { RegisterPersonalInfoStep } from './steps/RegisterPersonalInfoStep';
import './styles.scss';

export type RegisterFormProps = {
  className?: string;
  style?: React.CSSProperties;
};

const _RegisterForm: React.FC<RegisterFormProps> = ({ className, style }) => {
  const stepNum = useStoreMap(registerStateService.$registerState, (state) => state.step);

  React.useEffect(() => {
    return () => {
      registerStateService.resetRegisterState();
    };
  }, []);

  return (
    <div className={cn('register-form', className)} style={style}>
      {(stepNum === 1 || stepNum === 2 || stepNum === 3) && (
        <>
          <RegisterHeader className='register-form__header' />

          <RegisterSteps className='register-form__steps' />

          {stepNum === 1 && <RegisterPersonalInfoStep />}
          {stepNum === 2 && <RegisterJuridicalInfoStep />}
          {stepNum === 3 && <RegisterCompanyInfoStep />}
        </>
      )}

      {stepNum === 4 && <RegisterInProgressStep />}
      {stepNum === 5 && <RegisterFinishStep />}
    </div>
  );
};
export const RegisterForm = React.memo(_RegisterForm);
