import { Button, ButtonProps } from 'antd';
import React from 'react';
import cn from 'classnames';
import './styles.scss';
import { RightOutlined } from '@ant-design/icons';

export type ForwardButtonProps = ButtonProps;

const _ForwardButton: React.FC<ForwardButtonProps> = ({ className, children, ...buttonProps }) => {
  return (
    <Button className={cn('forward-btn', className, className)} {...buttonProps}>
      <div className='forward-btn__content'>
        <div className='forward-btn__content-item'>{children}</div>
        <RightOutlined className='forward-btn__forward-icon' />
      </div>
    </Button>
  );
};
export const ForwardButton = React.memo(_ForwardButton);
