import { CoordinateSystemRecordResponse } from '@/api/Coordinates/CoordinateSystems/types';
import { DotsMenu } from '@/components/DotsMenu';
import { handleFilter } from '@/utils/filterUtils';
import { DateView } from '@uspect-team/ant-ui-kit';
import { ControlledTableColumns } from '@uspect-team/ant-ui-kit/dist/Tables/ControlledTable/types';
import { Checkbox } from 'antd';
import { ReactElement } from 'react';

export const getColumns = (options?: {
  actionMenu?: (record: CoordinateSystemRecordResponse) => ReactElement;
}): ControlledTableColumns<CoordinateSystemRecordResponse> => {
  const res: ControlledTableColumns<CoordinateSystemRecordResponse> = [
    {
      key: 'title',
      dataIndex: 'title',
      title: 'Название',
      sortable: true,
      filterDropdown: handleFilter,
    },
    {
      align: 'center',
      key: 'code',
      dataIndex: 'code',
      title: 'Код',
      sortable: true,
      filterDropdown: handleFilter,
    },
    {
      key: 'isEnabled',
      dataIndex: 'isEnabled',
      title: 'Активность',
      align: 'center',
      render: (value) => {
        return <Checkbox checked={value} disabled />;
      },
    },
    {
      key: 'minX',
      dataIndex: 'minX',
      title: 'minX',
      sortable: true,
    },
    {
      key: 'maxX',
      dataIndex: 'maxX',
      title: 'maxX',
      sortable: true,
    },
    {
      key: 'minY',
      dataIndex: 'minY',
      title: 'minY',
      sortable: true,
    },
    {
      key: 'maxY',
      dataIndex: 'maxY',
      title: 'maxY',
      sortable: true,
    },
    {
      key: 'createdAt',
      dataIndex: 'createdAt',
      title: 'Дата создания',
      render: (value) => {
        return <DateView value={value} />;
      },
      sortable: true,
    },

    {
      key: 'updatedAt',
      dataIndex: 'updatedAt',
      title: 'Дата изменения',
      render: (value) => {
        return <DateView value={value} />;
      },
      sortable: true,
    },
  ];

  const actionMenu = options?.actionMenu;
  if (actionMenu) {
    res.push({
      fixed: 'right',
      key: 'actions',
      dataIndex: 'actions',
      align: 'center',
      render: (_, record) => {
        return <DotsMenu menu={actionMenu(record)} />;
      },
    });
  }

  return res;
};
