import { FileRecordResponse } from '@/api/Files/types';
import { FileCard } from '@/components/FileCard';
import React from 'react';
import { StepContent } from '../../../../StepContent';
import './styles.scss';

type Props = {
  secondStepCompleteInfo: FileRecordResponse | null;
};

// const AdditionalContent = (
//   <>
//     <div className='auto-run-result-step__actions-title'>
//       Дальнейшие действия (по успешно прогнанным):
//     </div>
//     <div className='auto-run-result-step__actions-content'>
//       <Link to={'#'}>Выгрузить схемы PDF</Link>
//     </div>
//   </>
// );
const _MonitoringGenResultStep: React.FC<Props> = (props) => {
  const { secondStepCompleteInfo } = props;

  return (
    <StepContent>
      {secondStepCompleteInfo === null ? (
        <div>При генерации произошла ошибка</div>
      ) : (
        <FileCard
          file={{
            name: secondStepCompleteInfo.fileName,
            size: secondStepCompleteInfo.size,
            link: secondStepCompleteInfo.fileUri,
            uploadAt: 'now',
          }}
        />
      )}
    </StepContent>
  );
};
export const MonitoringGenResultStep = React.memo(_MonitoringGenResultStep);
