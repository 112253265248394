import React, { useMemo } from 'react';
import { Input } from 'antd';
import './styles.scss';

type Props = {
  value?: string;
  onChange?: (value?: string) => void;
};
export const CadQuarterInput: React.FC<Props> = React.memo(({ value, onChange }) => {
  const _onChange: React.ChangeEventHandler<HTMLInputElement> | undefined = useMemo(() => {
    if (!onChange) {
      return undefined;
    }

    return ((event) => {
      onChange(event.target.value);
    }) as React.ChangeEventHandler<HTMLInputElement>;
  }, [onChange]);

  const region = useMemo(() => {
    if (!value) {
      return undefined;
    }
    const matchResult = value.match(/^(\d{2}):\d{2}:\d{5}\d*$/);
    return matchResult !== null ? matchResult[1] : undefined;
  }, [value]);

  return (
    <div className={'cad-quarter-input'}>
      <Input
        className={'cad-quarter-input__input'}
        value={value}
        onChange={_onChange}
        placeholder={'11:22:33333'}
      />
      {region && (
        <div className={'cad-quarter-input__quarter'}>
          <strong>Регион: </strong>
          {region}
        </div>
      )}
    </div>
  );
});
