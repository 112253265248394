import { AppButton } from '@/components/AppButton';
import { AppInput } from '@/components/inputs/AppInput';
import { loginUserFx } from '@/globalStorage/user';
import { routes } from '@/resources/routes';
import { Form, Input, message } from 'antd';
import { AxiosError } from 'axios';
import { useStore } from 'effector-react';
import React, { useCallback } from 'react';
import './styles.scss';
import { useHistory } from 'react-router-dom';

type FormData = {
  email: string;
  password: string;
};

export const LoginForm: React.FC = React.memo(() => {
  const [form] = Form.useForm<FormData>();
  const history = useHistory();

  const isLoadingLogin = useStore(loginUserFx.pending);

  const onFinish = useCallback(
    async (formData: FormData) => {
      try {
        await loginUserFx(formData);
        history.push(routes.companies);
      } catch (e) {
        const _e = e as AxiosError;
        if (_e.response?.status === 401) {
          message.error('Неверный логин или пароль');
        } else {
          message.error('Что-то пошло не так');
        }
      }
    },
    [history],
  );

  return (
    <Form form={form} onFinish={onFinish} className={'login-form'}>
      <Form.Item
        name={'email'}
        rules={[
          { required: true, message: 'Введите e-mail' },
          { type: 'email', message: 'Строка не является email' },
        ]}
      >
        <AppInput placeholder={'E-mail'} className='login-form__email' autoComplete={'email'} />
      </Form.Item>
      <Form.Item name={'password'}>
        <Input.Password
          placeholder={'Пароль'}
          type={'password'}
          className='login-form__password'
          autoComplete={'current-password'}
        />
      </Form.Item>
      <div className='login-form__buttons'>
        <AppButton
          className='login-form__login-btn login-form__btn'
          block
          type={'primary'}
          htmlType={'submit'}
          size={'large'}
          loading={isLoadingLogin}
        >
          Войти в систему
        </AppButton>
        <AppButton
          className='login-form__btn'
          type='primary'
          ghost
          block
          size='large'
          to={routes.registration}
        >
          Зарегистрироваться
        </AppButton>
        <AppButton
          type='link'
          className='login-form__forgot-password'
          size='large'
          to={routes.passwordRestore}
        >
          Не помню пароль, восстановить
        </AppButton>
      </div>
    </Form>
  );
});
