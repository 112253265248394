import { MaskedStringInput } from '@uspect-team/ant-ui-kit';
import React from 'react';
import { MaskedStringInputProps } from '@uspect-team/ant-ui-kit/dist/Inputs/MaskedStringInput';
import './styles.scss';

const mask: MaskedStringInputProps['mask'] = (rawValue: string) => {
  const prefix = rawValue[0] === '+' ? ['+', /\d/] : [/\d/];
  return [
    ...prefix,
    ' ',
    '(',
    /\d/,
    /\d/,
    /\d/,
    ')',
    ' ',
    /\d/,
    /\d/,
    /\d/,
    '-',
    /\d/,
    /\d/,
    '-',
    /\d/,
    /\d/,
  ];
};

interface PhoneNumberInputProps extends Omit<MaskedStringInputProps, 'mask'> {}

const _PhoneNumberInput: React.FC<PhoneNumberInputProps> = (props) => {
  const { ...rest } = props;

  return (
    <MaskedStringInput
      mask={mask}
      autoComplete={'phone'}
      placeholder={'+7 (999) 999-99-99'}
      {...rest}
    />
  );
};

export const PhoneNumberInput = React.memo(_PhoneNumberInput);
