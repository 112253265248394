import { api } from '../axiosInstance';
import {
  ContractPatchRequest,
  CreateContractRequest,
  CreateContractResponse,
  GetArchivesResponse,
  GetContractByIdResponse,
  GetContractColumnsRequest,
  GetContractColumnsResponse,
  GetContractsPageRequest,
  GetContractsPageResponse,
  GetNotifyResponse,
  LoadContractMonitoringRequest,
  LoadContractMonitoringResponse,
} from './types';
import { RecordId } from '@/api/common/types';

const baseUrl = '/contracts';

export class ContractsApi {
  public get(params: GetContractsPageRequest) {
    return api.get<GetContractsPageResponse>(baseUrl, { params: params });
  }

  public getById = async (id: RecordId) => {
    return await api.get<GetContractByIdResponse>(`${baseUrl}/${id}`);
  };

  public getContractColumns(request: GetContractColumnsRequest) {
    const { contractId, displayModeId } = request;
    return api.get<GetContractColumnsResponse>(`${baseUrl}/${contractId}/columns`, {
      params: { displayModeId },
    });
  }

  public create(data: CreateContractRequest) {
    return api.post<CreateContractResponse>(baseUrl, data);
  }
  public patch(data: ContractPatchRequest) {
    return api.patch(`${baseUrl}/${data.id}`, data.patch);
  }
  public delete(id: RecordId) {
    return api.delete(`${baseUrl}/${id}`);
  }

  public loadMonitoring(data: LoadContractMonitoringRequest) {
    const { contractId, cadObjectNumbers } = data;
    return api.post<LoadContractMonitoringResponse>(`${baseUrl}/${contractId}/monitoring`, {
      cadObjectNumbers,
    });
  }

  public getNotify(id: RecordId) {
    return api.get<GetNotifyResponse>(`${baseUrl}/${id}/notify`);
  }
  public getArchives(contractId: RecordId) {
    return api.get<GetArchivesResponse>(`${baseUrl}`);
  }
}
