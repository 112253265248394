import React from 'react';
import cn from 'classnames';
import logo from '../../../../../assets/icons/logo_white.svg';
import './styles.scss';

export type HeaderProps = {
  className?: string;
  style?: React.CSSProperties;
};

const _Header: React.FC<HeaderProps> = (props) => {
  const { className, style } = props;
  return (
    <div className={cn('guest-layout-header', className)} style={style}>
      <img className={'guest-layout-header__logo-img'} src={logo} alt='АктусЛенд' />
      <span className={'guest-layout-header__logo-text'}>
        Actusland
        <sup className={'guest-layout-header__logo-beta'}>β</sup>
      </span>
    </div>
  );
};
export const Header = React.memo(_Header);
