import { RecordId } from '@/api/common/types';
import { config } from '@/config';

export const getTerritoryUrl = (params: { actusCode: string; cadObjId: RecordId }) => {
  return (
    `${config.territoryOldUrl}/?type=${params.actusCode}&id=${params.cadObjId}` +
    `&actusURL=${process.env.REACT_APP_API_URL}&isAutoGrid=true`
  );
};

export const getTerritoryUrlNew = (params: { actusCode: string; cadObjId: RecordId }) => {
  return `${config.territoryUrl}/object/${params.cadObjId}`;
};
