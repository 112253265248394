import {
  ColumnGroupsCreateRequest,
  ColumnGroupsCreateResponse,
  ColumnGroupsGetByIdResponse,
  GetColumnGroupsPageRequest,
  ColumnGroupsGetResponse,
  ColumnGroupsPatchRequest,
} from './types';
import { api } from '@/api/axiosInstance';
import { RecordId } from '@/api/common/types';

export class ColumnGroupsApi {
  getPage(params: GetColumnGroupsPageRequest) {
    return api.get<ColumnGroupsGetResponse>('/field_groups', { params });
  }

  getById(id: RecordId) {
    return api.get<ColumnGroupsGetByIdResponse>(`/field_groups/${id}`);
  }

  create(data: ColumnGroupsCreateRequest) {
    return api.post<ColumnGroupsCreateResponse>('/field_groups', data);
  }

  patch(data: ColumnGroupsPatchRequest) {
    return api.patch<ColumnGroupsCreateResponse>(`/field_groups/${data.id}`, data.patch);
  }

  delete(id: RecordId) {
    return api.delete<ColumnGroupsGetByIdResponse>(`/field_groups/${id}`);
  }
}
