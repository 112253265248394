import { IntegerFilterDropdown } from '@/components/filterDropdowns/IntegerFilterDropdown';
import { IntegerFilterValue } from '@/components/filterDropdowns/IntegerFilterDropdown/types';
import { errorMessages } from '@/utils/errorMessages';
import { EditableInt } from '@uspect-team/ant-ui-kit';
import { ControlledTableChildType } from '@uspect-team/ant-ui-kit/dist/Tables/ControlledTable/types';
import React from 'react';
import * as yup from 'yup';
import {
  BaseServerChildColumnType,
  IntServerChildColumnAddition,
  ServerConfigTableContext,
  TableRecordType,
} from '../../types';
import { dataIndexToStringKey } from '../../utils/dataIndexToStringKey';

export const getIntegerColumnAddition = (
  strSerCol: BaseServerChildColumnType & IntServerChildColumnAddition,
  context: ServerConfigTableContext,
  editable: boolean,
): Partial<ControlledTableChildType<TableRecordType>> => {
  let validationSchema = yup.number().integer(errorMessages.integer);
  if (strSerCol.isRequired) {
    validationSchema = validationSchema.required(errorMessages.required);
  }
  if (strSerCol.validation?.isPositive) {
    validationSchema = validationSchema.min(0, errorMessages.positive);
  }
  if (strSerCol.validation?.min) {
    validationSchema = validationSchema.min(
      strSerCol.validation.min,
      errorMessages.minNumber(strSerCol.validation.min),
    );
  }
  if (strSerCol.validation?.max) {
    validationSchema = validationSchema.max(
      strSerCol.validation.max,
      errorMessages.maxNumber(strSerCol.validation.max),
    );
  }

  const { dataIndex, isEditable, isFilterable, width } = strSerCol;
  const onEdit = context.onEdit;
  return {
    align: 'left',
    width: width ?? 200,
    filterDropdown: isFilterable
      ? ({ currentFilterState, setFilterValue, removeFilter }) => {
          const filter = currentFilterState as IntegerFilterValue;

          return (
            <IntegerFilterDropdown
              filter={filter}
              onChangeFilter={setFilterValue}
              onResetFilter={removeFilter}
              hasBetween
              hasEqual
              hasExist
            />
          );
        }
      : undefined,
    render:
      isEditable && editable
        ? (value, record) => {
            if (record.status === 'excluded') {
              return value;
            }
            return (
              <EditableInt
                block
                value={value}
                validationSchema={validationSchema}
                onChange={
                  onEdit
                    ? (value) =>
                        onEdit({
                          patch: { [dataIndexToStringKey(dataIndex)]: value ?? null },
                          oldRecord: record,
                        })
                    : undefined
                }
              />
            );
          }
        : undefined,
  };
};
