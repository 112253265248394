import { AcademicHatIcon } from '@/components/customIcons/AcademicHatIcon';
import { PopoverContainer } from '@/components/PopoverContainer';
import React from 'react';
import { HeaderIconButton } from '../../components/HeaderIconButton';
import './styles.scss';

const _DocumentationIconButton: React.FC = () => {
  const overlay = (
    <PopoverContainer>
      <div className='documentation-icon-button__no-documentation-text'>
        Документация еще не сформирована
      </div>
    </PopoverContainer>
  );

  return <HeaderIconButton Icon={AcademicHatIcon} overlay={overlay} />;
};
export const DocumentationIconButton = React.memo(_DocumentationIconButton);
