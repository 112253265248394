import { RecordId } from '@/api/common/types';
import { GetCoordinateSystemsPageRequest } from '@/api/Coordinates/CoordinateSystems/types';

export const cordSystemsQueryKeys = {
  all: ['coordinate-systems'] as const,
  pages: () => [...cordSystemsQueryKeys.all, 'page'] as const,
  page: (data: GetCoordinateSystemsPageRequest) => [...cordSystemsQueryKeys.pages(), data] as const,

  details: () => [...cordSystemsQueryKeys.all, 'detail'] as const,
  detail: (id: RecordId) => [...cordSystemsQueryKeys.details(), id] as const,

  create: () => [...cordSystemsQueryKeys.all, 'create'] as const,
  patch: () => [...cordSystemsQueryKeys.all, 'patch'] as const,
  delete: () => [...cordSystemsQueryKeys.all, 'delete'] as const,
};
