import { companyService } from '@/hooks/companies';
import React from 'react';
import { CompanyList, CompanyListProps } from './CompanyList';

export type SmartCompanyListProps = Omit<CompanyListProps, 'items' | 'isLoading'>;

const _SmartCompanyList: React.FC<SmartCompanyListProps> = (props) => {
  const { data: companyList, isLoading } = companyService.useGetList();

  return <CompanyList items={companyList ?? []} isLoading={isLoading} {...props} />;
};
export const SmartCompanyList = React.memo(_SmartCompanyList);
