import React from 'react';
import cn from 'classnames';
import './styles.scss';

type Props = {
  className?: string;
  style?: any;
  children?: React.ReactNode;
  rightAddition?: React.ReactNode;
};
const _SectionHeader: React.FC<Props> = (props) => {
  const { className, style, children, rightAddition } = props;

  return (
    <div className={cn('section-header', className)} style={style}>
      <div className='section-header__title'>{children}</div>
      <div className={'section-header__divider'} />
      {rightAddition}
    </div>
  );
};
export const SectionHeader = React.memo(_SectionHeader);
