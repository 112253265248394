import moment from 'moment';
import { mockTariffs } from '../Tariffs/mockData';
import { CompanyRecord } from './types';

const oneDayAfterNowStr = moment(new Date()).add(1, 'days').toString();

export const mockCompaniesList: CompanyRecord[] = [
  {
    id: '1',
    name: 'ООО «Вершина»',
    tariff: mockTariffs.demo,
    inn: '5260396593',
    ogrn: '1145260013843',
    paidUpTo: oneDayAfterNowStr,
  },
];
