import { RecordId } from '@/api/common/types';
import { combine, createDomain, sample, createApi } from 'effector';
import { autoSignService } from './autoSign';
import { AutoSignModalState } from './types';

const autoSingModalDomain = createDomain();

const startAutoSign = autoSingModalDomain.createEvent();

///IS OPEN MODAL

const $isOpenAutoSignModal = autoSingModalDomain.createStore(false);

const { openAutoSignModal, closeAutoSignModal } = createApi($isOpenAutoSignModal, {
  openAutoSignModal: () => true,
  closeAutoSignModal: () => false,
});

/// INIT NUMBERS

const $initialCadObjectNumbers = autoSingModalDomain.createStore<number[]>([]);
const { setInitialCadObjectNumbers, resetInitialCadObjectNumbers } = createApi(
  $initialCadObjectNumbers,
  {
    setInitialCadObjectNumbers: (_, payload: number[]) => payload,
    resetInitialCadObjectNumbers: () => [],
  },
);

///

// INIT CONTRACT ID

const $initialContractId = autoSingModalDomain.createStore<RecordId | null>(null);
const { setInitialContractId, resetInitialContractId } = createApi($initialContractId, {
  setInitialContractId: (_, payload: RecordId) => payload,
  resetInitialContractId: () => null,
});

const $autoSignModalState = combine(
  {
    isOpen: $isOpenAutoSignModal,
    initialCadObjectNumbers: $initialCadObjectNumbers,
    initialContractId: $initialContractId,
    autoRunState: autoSignService.$execState,
    autoRunContractId: autoSignService.$execContractId,
    autoRunExecItem: autoSignService.$execItem,
    autoRunHistory: autoSignService.$history,
    autoRunQueue: autoSignService.$queue,
  },
  ({
    isOpen,
    initialCadObjectNumbers,
    initialContractId,
    autoRunState,
    autoRunContractId,
    autoRunExecItem,
    autoRunHistory,
    autoRunQueue,
  }) => {
    if (!isOpen) {
      return { visible: false } as AutoSignModalState;
    } else {
      if (autoRunState === 'idle') {
        return {
          visible: true,
          state: 'idle',
          contractId: initialContractId,
          cadObjectNumbers: initialCadObjectNumbers,
          isCanStartAutoSign: initialContractId !== null && initialCadObjectNumbers.length > 0,
        } as AutoSignModalState;
      } else if (autoRunState === 'inProcess') {
        return {
          visible: true,
          state: 'inProcess',
          contractId: autoRunContractId,
          execItem: autoRunExecItem,
          history: autoRunHistory,
          queue: autoRunQueue,
        } as AutoSignModalState;
      } else if (autoRunState === 'done') {
        return {
          visible: true,
          state: 'done',
          contractId: initialContractId,
          errorCadObjectNumbers: autoRunHistory
            .filter((x) => x.status === 'error')
            .map((x) => x.item),
          successCadObjectNumbers: autoRunHistory
            .filter((x) => x.status === 'success')
            .map((x) => x.item),
          history: autoRunHistory,
          allObjectsCount: autoRunHistory.length,
          execTimeMilliseconds: autoRunHistory.reduce(
            (acc, history) => acc + history.execTimeMilliseconds,
            0,
          ),
        } as AutoSignModalState;
      } else {
        return {
          visible: true,
          state: 'idle',
          contractId: initialContractId,
          cadObjectNumbers: initialCadObjectNumbers,
        } as AutoSignModalState;
      }
    }
  },
);

sample({
  clock: startAutoSign,
  source: { $initialContractId, $initialCadObjectNumbers },
  filter: $autoSignModalState.map(
    (x) => x.visible === true && x.state === 'idle' && x.isCanStartAutoSign,
  ),
  fn: ({ $initialContractId, $initialCadObjectNumbers }) => ({
    cadObjectNumbers: $initialCadObjectNumbers,
    contractId: $initialContractId as RecordId,
  }),
  target: autoSignService.startAutoSign,
});

/// RESET AUTO RUN MODAL

const resetAutoSignModal = autoSingModalDomain.createEvent();

sample({
  clock: resetAutoSignModal,
  target: [resetInitialCadObjectNumbers, resetInitialContractId],
});

const resetAutoSignModalWithInit = autoSingModalDomain.createEvent<{
  initialCadObjectNumbers: number[];
  initialContractId: RecordId;
}>();

sample({
  clock: resetAutoSignModalWithInit,
  fn: ({ initialCadObjectNumbers }) => initialCadObjectNumbers,
  target: setInitialCadObjectNumbers,
});

sample({
  clock: resetAutoSignModalWithInit,
  fn: ({ initialContractId }) => initialContractId,
  target: setInitialContractId,
});

/// ===================================

const backToAutoSignInit = autoSingModalDomain.createEvent();

sample({
  clock: backToAutoSignInit,
  target: [resetAutoSignModal, autoSignService.resetAutoSign],
});

////

const openAutoSignModalWithInit = autoSingModalDomain.createEvent<{
  initialCadObjectNumbers: number[];
  initialContractId: RecordId;
}>();

sample({
  clock: openAutoSignModalWithInit,
  filter: autoSignService.$execState.map((x) => x === 'idle'),
  target: [resetAutoSignModalWithInit, openAutoSignModal],
});

sample({
  clock: openAutoSignModalWithInit,
  filter: autoSignService.$execState.map((x) => x === 'done' || x === 'inProcess'),
  target: openAutoSignModal,
});

///

const autoSignModalService = {
  $autoSignModalState,
  openAutoSignModal,
  openAutoSignModalWithInit,
  closeAutoSignModal,
  setInitialCadObjectNumbers,
  setInitialContractId,
  startAutoSign,
  backToAutoSignInit,
};

export { autoSignModalService };
