import { RecordId } from '@/api/common/types';
import { GetCptPageRequest } from '@/api/Dictionary/Cpt/types';

export const cptQueryKeys = {
  all: ['cpt'] as const,
  pages: () => [...cptQueryKeys.all, 'page'] as const,
  page: (data: GetCptPageRequest) => [...cptQueryKeys.pages(), data] as const,

  details: () => [...cptQueryKeys.all, 'detail'] as const,
  detail: (id: RecordId) => [...cptQueryKeys.details(), id] as const,

  create: () => [...cptQueryKeys.all, 'create'] as const,
  patch: () => [...cptQueryKeys.all, 'patch'] as const,
  delete: () => [...cptQueryKeys.all, 'delete'] as const,
};
