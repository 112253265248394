import { AppButton } from '@/components/AppButton';
import cn from 'classnames';
import React from 'react';
import { SmartCompanyList } from './SmartCompanyList';
import './styles.scss';

export type CompaniesSectionProps = {
  className?: string;
  style?: React.CSSProperties;
};

const _CompaniesSection: React.FC<CompaniesSectionProps> = (props) => {
  const { className, style } = props;

  return (
    <div className={cn(className, 'companies-section')} style={style}>
      <div className='companies-section__list-wrapper y-scroll'>
        <SmartCompanyList />
      </div>

      <AppButton type='primary' block className='companies-section__add-btn' size='large'>
        Добавить новую организацию
      </AppButton>
    </div>
  );
};
export const CompaniesSection = React.memo(_CompaniesSection);
