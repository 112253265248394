import { IntFilterItem } from '@/components/ServerConfigTable/types';
import { Col, InputNumber, Row } from 'antd';
import React from 'react';
import { FilterDropdownActions } from '../common/FilterDropdownActions';
import { FilterDropdownContentBase } from '../common/FilterDropdownContentBase';
import { FilterDropdownFormContainer } from '../common/FilterDropdownFormContainer';
import { FilterTypeOption, FilterTypeSelect } from '../common/FilterTypeSelect';
import { betweenOption, equalOption, existOption, notExistOption } from './consts';
import { IntegerFilterType, IntegerFilterValue } from './types';

export type IntegerFilterDropdownProps = {
  className?: string;
  style?: React.CSSProperties;

  filter: IntegerFilterValue;
  onChangeFilter: (value: IntFilterItem) => void;
  onResetFilter: () => void;
  hasBetween?: boolean;
  hasEqual?: boolean;
  hasExist?: boolean;
};

const getActualPropValue = <ReturnValue,>(params: {
  currentFilter: IntegerFilterValue;
  changes: IntFilterItem | null;
  getProp: (filter: IntegerFilterValue | null) => ReturnValue;
  defaultValue: ReturnValue;
}) => {
  const { currentFilter, changes, getProp, defaultValue } = params;
  return getProp(changes) ?? getProp(currentFilter) ?? defaultValue;
};

const _IntegerFilterDropdown = (props: IntegerFilterDropdownProps) => {
  const {
    className,
    style,
    filter,
    onChangeFilter,
    onResetFilter,
    hasBetween,
    hasEqual,
    hasExist,
  } = props;

  React.useEffect(() => {
    setUpdatedFilterState(null);
  }, [filter]);

  const [updatedFilterState, setUpdatedFilterState] = React.useState<IntFilterItem | null>(null);

  const filterTypeOptions = React.useMemo(() => {
    const options: FilterTypeOption<IntegerFilterType>[] = [];
    if (hasEqual) {
      options.push(equalOption);
    }
    if (hasBetween) {
      options.push(betweenOption);
    }
    if (hasExist) {
      options.push(existOption, notExistOption);
    }
    return options;
  }, [hasEqual, hasExist, hasBetween]);

  const [selectedFilterType, _setSelectedFilterType] = React.useState<IntegerFilterType>('equal');
  const changeFilterType = React.useCallback((filterType: IntegerFilterType) => {
    _setSelectedFilterType(filterType);
    if (filterType === 'exist' || filterType === 'notExist') {
      setUpdatedFilterState({
        filterAs: 'integer',
        filterType,
      });
    } else {
      setUpdatedFilterState(null);
    }
  }, []);

  const form = React.useMemo(() => {
    switch (selectedFilterType) {
      case 'exist':
      case 'notExist':
        return null;
      case 'equal': {
        const inputValue = getActualPropValue({
          currentFilter: filter,
          changes: updatedFilterState,
          getProp: (filter) => {
            return filter?.filterType === 'equal' ? filter.payload : undefined;
          },
          defaultValue: undefined,
        });
        return (
          <FilterDropdownFormContainer>
            <InputNumber
              style={{ width: '100%' }}
              value={inputValue}
              onChange={(value) =>
                setUpdatedFilterState({
                  filterAs: 'integer',
                  filterType: 'equal',
                  payload: value ? value : 0,
                })
              }
            />
          </FilterDropdownFormContainer>
        );
      }

      case 'between': {
        const value = getActualPropValue({
          currentFilter: filter,
          changes: updatedFilterState,
          getProp: (filter) => {
            return filter?.filterType === 'between' ? filter.payload : undefined;
          },
          defaultValue: undefined,
        });
        return (
          <FilterDropdownFormContainer>
            <Row gutter={8}>
              <Col>
                <InputNumber
                  style={{ width: '100%' }}
                  placeholder='Минимум'
                  value={value?.min}
                  onChange={(value) =>
                    setUpdatedFilterState({
                      filterAs: 'integer',
                      filterType: 'between',
                      payload: {
                        min: value ? value : undefined,
                        max:
                          updatedFilterState?.filterType === 'between'
                            ? updatedFilterState.payload.max
                            : undefined,
                      },
                    })
                  }
                />
              </Col>
              <Col>
                <InputNumber
                  style={{ width: '100%' }}
                  placeholder='Максимум'
                  value={value?.max}
                  onChange={(value) =>
                    setUpdatedFilterState({
                      filterAs: 'integer',
                      filterType: 'between',
                      payload: {
                        min:
                          updatedFilterState?.filterType === 'between'
                            ? updatedFilterState.payload.min
                            : undefined,
                        max: value ? value : undefined,
                      },
                    })
                  }
                />
              </Col>
            </Row>
          </FilterDropdownFormContainer>
        );
      }
    }
  }, [filter, selectedFilterType, updatedFilterState]);

  return (
    <FilterDropdownContentBase className={className} style={style}>
      <FilterTypeSelect
        onChangeSelectedFilterType={changeFilterType}
        selectedFilterType={selectedFilterType}
        filteredType={filter?.filterType ?? null}
        options={filterTypeOptions}
      />
      {form}
      <FilterDropdownActions
        canChangeFilter={updatedFilterState !== null}
        hasFilters={filter !== null && filter !== undefined}
        onSubmitChanges={() => {
          if (updatedFilterState === null) {
            onResetFilter();
          } else {
            onChangeFilter(updatedFilterState);
          }
        }}
        onResetFilter={onResetFilter}
      />
    </FilterDropdownContentBase>
  );
};
export const IntegerFilterDropdown = React.memo(_IntegerFilterDropdown);
