import { RecordId } from '@/api/common/types';
import { useMutation, useQuery, useQueryClient } from 'react-query';
import { boundaryQueryKeys } from './queryKeys';
import { Api } from '@/api';

export const useGetBoundaryByIdQ = (id: RecordId) => {
  return useQuery(boundaryQueryKeys.detail(id), () => Api.cadastralObjects.getById(id), {
    select: (data) => data.data.data,
  });
};

export const usePatchBoundaryM = () => {
  const queryClient = useQueryClient();
  return useMutation(boundaryQueryKeys.patch(), Api.cadastralObjects.patch, {
    onSuccess: async () => {
      await queryClient.invalidateQueries(boundaryQueryKeys.all);
    },
  });
};
