import { RecordId } from '@/api/common/types';
import { GetOrganizationsPageRequest } from '@/api/Dictionary/Organizations/types';

export const organizationsQueryKeys = {
  all: ['organizations'] as const,
  pages: () => [...organizationsQueryKeys.all, 'page'] as const,
  page: (data: GetOrganizationsPageRequest) => [...organizationsQueryKeys.pages(), data] as const,

  details: () => [...organizationsQueryKeys.all, 'detail'] as const,
  detail: (id: RecordId) => [...organizationsQueryKeys.details(), id] as const,

  create: () => [...organizationsQueryKeys.all, 'create'] as const,
  patch: () => [...organizationsQueryKeys.all, 'patch'] as const,
  delete: () => [...organizationsQueryKeys.all, 'delete'] as const,
};
