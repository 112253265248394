import cn from 'classnames';
import React from 'react';
import { PolylineList } from './PolylineList';
import './styles.scss';
import { TpList } from './TpList';

type CordsManagerInfoProps = {
  className?: string;
  style?: React.CSSProperties;
};

const _CordsManagerInfo: React.FC<CordsManagerInfoProps> = (props) => {
  const { className, style } = props;

  return (
    <div className={cn('cords-manager-info', className)} style={style}>
      <PolylineList />
      <TpList />
      {/* <PointInfo /> */}
    </div>
  );
};

export const CordsManagerInfo = React.memo(_CordsManagerInfo);
