import { combine } from 'effector';
import { autoSignService } from './autoSign';
import { AutoSignWidgetState } from './types';

export const $autoSignWidgetState = combine(
  {
    autoRunState: autoSignService.$execState,
    autoRunHistory: autoSignService.$history,
    autoRunQueue: autoSignService.$queue,
    autoRunExecItem: autoSignService.$execItem,
  },
  ({ autoRunState, autoRunHistory, autoRunQueue, autoRunExecItem }) => {
    if (autoRunState === 'idle') {
      return {
        status: 'idle',
      } as AutoSignWidgetState;
    } else if (autoRunState === 'inProcess') {
      return {
        status: 'inProcess',
        doneCount: autoRunHistory.length,
        allCount: autoRunHistory.length + autoRunQueue.length + (autoRunExecItem ? 1 : 0),
      } as AutoSignWidgetState;
    } else {
      return {
        status: 'done',
      } as AutoSignWidgetState;
    }
  },
);
