import { Api } from '@/api';
import { RecordId } from '@/api/common/types';
import { GetColumnsPageRequest } from '@/api/Dictionary/Columns/types';
import { cordSystemsQueryKeys } from '@/hooks/coordinates/coordinateSystems/queryKeys';
import { createUseFetchingStateObserver } from '@/hooks/utils';
import { useMutation, useQuery, useQueryClient } from 'react-query';

export const useGetCoordinateSystemsPageQ = (
  data: GetColumnsPageRequest & {
    filter?: Record<string, any>;
    sort?: Record<string, 'asc' | 'desc'>;
  },
) => {
  return useQuery(
    cordSystemsQueryKeys.page(data),
    () => {
      return Api.coordinates.coordinateSystems.get(data);
    },
    {
      keepPreviousData: true,
      select: (res) => res.data,
    },
  );
};

export const useGetCoordinateSystemsListAllQ = () => {
  const request: GetColumnsPageRequest = { page: 1, limit: 1000 };

  return useQuery(
    cordSystemsQueryKeys.page(request),
    () => {
      return Api.coordinates.coordinateSystems.get(request);
    },
    {
      keepPreviousData: true,
      select: (res) => res.data.data,
    },
  );
};

export const useCoordinateSystemPageFetchingState = createUseFetchingStateObserver(
  cordSystemsQueryKeys.pages(),
);

export const useGetCoordinateSystemByIdQ = (id: RecordId) => {
  return useQuery(
    cordSystemsQueryKeys.detail(id),
    () => {
      return Api.coordinates.coordinateSystems.getById(id);
    },
    { select: (data) => data.data.data },
  );
};

//Mutations

export const useCreateCoordinateSystemM = () => {
  const queryClient = useQueryClient();
  return useMutation(cordSystemsQueryKeys.create(), Api.coordinates.coordinateSystems.create, {
    onSuccess: async (response) => {
      await queryClient.invalidateQueries(cordSystemsQueryKeys.pages());
      queryClient.setQueriesData(cordSystemsQueryKeys.detail(response.data.data._id), response);
    },
  });
};

export const usePatchCoordinateSystemM = () => {
  const queryClient = useQueryClient();
  return useMutation(cordSystemsQueryKeys.patch(), Api.coordinates.coordinateSystems.patch, {
    onSuccess: async (response, variables) => {
      await queryClient.invalidateQueries(cordSystemsQueryKeys.pages());
      queryClient.setQueriesData(cordSystemsQueryKeys.detail(variables.id), response);
    },
  });
};

export const useDeleteCoordinateSystemM = () => {
  const queryClient = useQueryClient();
  return useMutation(cordSystemsQueryKeys.delete(), Api.coordinates.coordinateSystems.delete, {
    onSuccess: async (_, variables) => {
      await Promise.all([
        queryClient.invalidateQueries(cordSystemsQueryKeys.pages()),
        queryClient.invalidateQueries(cordSystemsQueryKeys.detail(variables)),
      ]);
    },
  });
};
