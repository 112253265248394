import { useGetAllNotAlwaysShowColumnsQ } from '@/hooks/directory/columns';
import { Select, SelectProps } from 'antd';
import React from 'react';

type Props = SelectProps<string>;
const _ColumnsMultipleSelect: React.FC<Props> = (props) => {
  const { data: columns, isLoading } = useGetAllNotAlwaysShowColumnsQ();
  return (
    <Select mode={'multiple'} style={{ width: '100%' }} loading={isLoading} {...props}>
      {columns?.map((column) => (
        <Select.Option key={column._id} value={column._id}>
          {column.name}
        </Select.Option>
      ))}
    </Select>
  );
};

export const ColumnsMultipleSelect = React.memo(_ColumnsMultipleSelect);
