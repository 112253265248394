import { RecordId } from '@/api/common/types';
import { FileRecordResponse } from '@/api/Files/types';
import { StepContent } from '@/components/StepContent';
import { useLoadContractMonitoringM } from '@/hooks/contracts';
import { LoadingOutlined } from '@ant-design/icons';
import { Space } from 'antd';
import React, { useEffect } from 'react';

type Props = {
  contractId: RecordId;
  genForAll: boolean;
  cadObjectNumbers: number[];
  onCompleteStep: (completeInfo: FileRecordResponse | null) => void;
};
const _MonitoringGenStatusStep: React.FC<Props> = (props) => {
  const { cadObjectNumbers, onCompleteStep, contractId, genForAll } = props;
  const { mutateAsync: loadMonitoringM } = useLoadContractMonitoringM();

  useEffect(() => {
    const asyncAction = async () => {
      try {
        const {
          data: { data: file },
        } = await loadMonitoringM({
          contractId,
          cadObjectNumbers: genForAll ? undefined : cadObjectNumbers,
        });

        onCompleteStep(file);
      } catch {
        onCompleteStep(null);
      }
    };

    asyncAction();
  }, [cadObjectNumbers, contractId, genForAll, loadMonitoringM, onCompleteStep]);

  return (
    <StepContent>
      <Space align='center' size={'middle'}>
        <LoadingOutlined spin /> Идет генерация файла мониторинга...
      </Space>
    </StepContent>
  );
};

export const MonitoringGenStatusStep = React.memo(_MonitoringGenStatusStep);
