import { Input, InputProps } from 'antd';
import cn from 'classnames';
import React from 'react';
import './styles.scss';

type AntdInputProps = InputProps;

export type AppInputProps = AntdInputProps & {
  block?: boolean;
};

const _AppInput: React.FC<AppInputProps> = (props) => {
  const { block, className, ...antdProps } = props;
  return (
    <Input
      {...antdProps}
      className={cn(className, 'string-input', { 'string-input--block': block })}
    />
  );
};
export const AppInput = React.memo(_AppInput);
