import React, { useCallback, useState } from 'react';
import { DatePicker, Form, Input, message, Modal } from 'antd';
import './styles.scss';
import moment from 'moment';
import { errorMessages } from '@/utils/errorMessages';
import { FileCard } from '@/components/FileCard';
import { FileLoader } from '@uspect-team/ant-ui-kit';
import { RcFile } from 'antd/lib/upload';
import { TdRecordResponse } from '@/api/Td/types';
import { CreatePdFormDataType } from './types';
import { getAllowedExtByFileType } from '@/utils/fileUtils';
import { getFilenameWithoutExt } from '@/utils/getFilenameWithoutExt';
import { RecordId } from '@/api/common/types';
import { AxiosError } from 'axios';
import { useCreateTdM } from '@/hooks/td';

const allowedPdExts = getAllowedExtByFileType('pd');

export type CreatePdModalProps =
  | {
      visible: false;
    }
  | ({ visible: true } & VisibleProps);

type VisibleProps = {
  title: string;
  contractId?: RecordId;
  onClose: () => void;
  onCreate?: (newPd: TdRecordResponse) => void;
};
export const CreatePdModal: React.FC<CreatePdModalProps> = React.memo((props) => {
  const { visible } = props;
  if (!visible) {
    return null;
  }

  const { visible: _, ...visibleProps } = props;
  return <VisibleModal {...visibleProps} />;
});

const VisibleModal: React.FC<VisibleProps> = React.memo((props) => {
  const { title, onClose, onCreate, contractId } = props;
  const createPdM = useCreateTdM();
  const [pdFile, setPdFile] = useState<RcFile | null>(null);
  const [form] = Form.useForm();

  const onFinishHandler = useCallback(
    async (formData: CreatePdFormDataType) => {
      if (!pdFile) {
        message.error('Пожалуйста, загрузите файл или выберите существующий');
        return;
      }

      try {
        const formValues = { ...formData, date: formData.date?.toDate() };
        const createRes = await createPdM.mutateAsync({ ...formValues, file: pdFile, contractId });
        if (onCreate) {
          onCreate(createRes.data.data);
        }
        if (onClose) {
          onClose();
        }
      } catch (e) {
        if ((e as AxiosError).response?.status === 409) {
          message.error(
            'Файл с таким именем уже загружен. Выберите его или переименуйте и загрузите этот файл',
          );
        } else {
          message.error('При создании ПД что-то пошло не так');
        }
      }
    },
    [contractId, pdFile, createPdM, onCreate, onClose],
  );

  return (
    <Modal
      title={title}
      visible={true}
      onCancel={onClose}
      width={1000}
      okText={'Загрузить'}
      okButtonProps={{ loading: createPdM.isLoading }}
      onOk={form.submit}
      cancelText={'Отмена'}
      afterClose={form.resetFields}
      //getContainer={false}
      // forceRender
    >
      <Form
        form={form}
        onFinish={onFinishHandler}
        className={'create-pd-form'}
        initialValues={{
          technokadCode: '558301990000',
          date: moment(new Date(Date.now())),
        }}
        layout={'vertical'}
        labelAlign={'right'}
      >
        <div className={'create-pd-form__form'}>
          <Form.Item
            name={'title'}
            label={'Имя'}
            rules={[
              {
                required: true,
                message: errorMessages.required,
              },
            ]}
          >
            <Input placeholder={'Имя'} />
          </Form.Item>
          <Form.Item name={'series'} label={'Серия'}>
            <Input placeholder={'Серия'} />
          </Form.Item>
          <Form.Item name={'number'} label={'Номер'}>
            <Input placeholder={'Номер'} />
          </Form.Item>
          <Form.Item
            name={'technokadCode'}
            label={'Код Технокад'}
            rules={[
              {
                required: true,
                message: errorMessages.required,
              },
            ]}
          >
            <Input placeholder={'Код документа технокад'} />
          </Form.Item>

          <Form.Item name={'ispOrg'} label={'Исполнительный орган'}>
            <Input placeholder={'Введите исполнительный орган'} />
          </Form.Item>

          <Form.Item
            name={'date'}
            label={'Дата создания'}
            rules={[
              {
                required: true,
                message: errorMessages.required,
              },
            ]}
          >
            <DatePicker placeholder={'Дата создания'} />
          </Form.Item>
        </div>

        <div className='create-pd-form__file-zone'>
          {pdFile && (
            <FileCard
              className={'create-pd-form__file'}
              title={'Новый файл'}
              file={{
                name: pdFile.name,
                size: pdFile.size,
                uploadAt: 'now',
              }}
              onDelete={() => {
                setPdFile(null);
              }}
            />
          )}

          <FileLoader
            className={'create-pd-form__upload'}
            allowedFiles={[allowedPdExts]}
            onUpload={(pdFile) => {
              setPdFile(pdFile);
              form.setFieldsValue({ title: getFilenameWithoutExt(pdFile.name) });
            }}
          />
        </div>

        <Form.ErrorList />
      </Form>
    </Modal>
  );
});
