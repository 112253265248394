const isDevelop = process.env.NODE_ENV === 'development';
export const isDebug = localStorage.getItem('isDebug') || isDevelop;

const filesIndexes = {
  fileOs: 'FileOS',
  fileSit: 'FileSit',
  fileMapsCoords: 'FileMapsCoords',
};

export const tableIndexes = {
  objNum: 'ObjNum',
  inventNumber: 'InventNumber',
  cadastrialNumber: 'CadastrialNumber',
  name: 'Name',
  baseNumber: 'BaseNumber',
  address: 'address',
  length: 'length',
  etap: 'etap',
  ppNum: 'PpNum',
  measure: 'Measure',
  objType: 'ObjType',
  poRes: 'PoRes',
  tubeDiametr: 'TubeDiametr',
  tubeLaying: 'TubeLaying',
  ohrWeight: 'OhrWeight',
  ...filesIndexes,
};

export const tableColumns = [];

export const helperLink =
  'https://proninweb.notion.site/Actusland-v2-ac52f610bada404f9bfc9c154fee8a53';
