/* eslint-disable max-len */
import React from 'react';

const _AcademicHatSvg: React.FC<React.SVGProps<SVGSVGElement>> = (props) => {
  return (
    <svg
      width='24'
      height='18'
      viewBox='0 0 24 18'
      fill='black'
      xmlns='http://www.w3.org/2000/svg'
      {...props}
    >
      <path
        d='M10.3192 0.378066C10.8443 0.129291 11.4181 0.000244141 11.9992 0.000244141C12.5802 0.000244141 13.154 0.129291 13.6792 0.378066L23.4272 4.99607C23.597 5.07644 23.7407 5.20302 23.8419 5.36131C23.9431 5.5196 23.9976 5.70319 23.9993 5.89105C24.001 6.07892 23.9497 6.26345 23.8513 6.42351C23.753 6.58357 23.6115 6.71269 23.4432 6.79607L13.7432 11.6081C13.2011 11.877 12.6042 12.0169 11.9992 12.0169C11.3941 12.0169 10.7972 11.877 10.2552 11.6081L1.99916 7.51207V12.0001C1.99916 12.2653 1.8938 12.5196 1.70626 12.7072C1.51873 12.8947 1.26437 13.0001 0.999156 13.0001C0.733939 13.0001 0.479585 12.8947 0.292049 12.7072C0.104513 12.5196 -0.000844339 12.2653 -0.000844339 12.0001V5.95007C-0.0101416 5.75238 0.0391945 5.55635 0.140965 5.38661C0.242736 5.21688 0.392401 5.081 0.571156 4.99607L10.3192 0.376066V0.378066ZM3.99916 10.7381V14.0001C3.99918 14.1316 4.02517 14.2619 4.07562 14.3834C4.12607 14.5048 4.19999 14.6152 4.29316 14.7081L4.29715 14.7141L4.34315 14.7561L4.46316 14.8681C5.08993 15.4267 5.76755 15.9255 6.48715 16.3581C7.82315 17.1601 9.75316 18.0001 11.9992 18.0001C14.2452 18.0001 16.1752 17.1601 17.5132 16.3581C18.2328 15.9255 18.9104 15.4267 19.5372 14.8681L19.6572 14.7561L19.6892 14.7241L19.7012 14.7121L19.7032 14.7101L19.7072 14.7081C19.8 14.615 19.8735 14.5046 19.9236 14.3831C19.9737 14.2616 19.9994 14.1315 19.9992 14.0001V10.7361L14.6312 13.4001C13.8131 13.8058 12.9123 14.0169 11.9992 14.0169C11.086 14.0169 10.1852 13.8058 9.36716 13.4001L3.99916 10.7361V10.7381Z'
        fill='currentColor'
      />
    </svg>
  );
};
export const AcademicHatSvg = React.memo(_AcademicHatSvg);
