import { ForwardButton } from '@/components/ForwardButton';
import { routes } from '@/resources/routes';
import React from 'react';
import { useHistory } from 'react-router-dom';
import './styles.scss';

const TemtData = {
  name: 'Александр',
  org: new Array(3).fill({
    id: 1,
    name: 'OOO',
    inn: 'INN',
    rate: 'Rate',
    paidTo: new Date().toISOString(),
  }),
};
export type TTempData = {
  id: string;
  name: string;
  inn: string;
  rate: string;
  paidTo: string | Date;
};
const _RegisterFinishStep: React.FC = () => {
  const history = useHistory();

  const onClickBackToLoginHandler = () => {
    history.push(routes.login);
  };
  return (
    <div className='register-finish-step'>
      <div className='register-finish-step__title'>{TemtData.name}, здравствуйте!</div>
      <div className='register-finish-step__description'>
        Выберите компанию, с которой хотите продолжить работать:
      </div>

      <ForwardButton
        className='register-finish-step__back_to-login'
        block
        size='large'
        onClick={onClickBackToLoginHandler}
      >
        Добавить новую организацию
      </ForwardButton>
    </div>
  );
};
export const RegisterFinishStep = React.memo(_RegisterFinishStep);
