const AREA_WIDTH = 700;
const AREA_HEIGHT = 500;

export const calculateAreaParams = (allPoints: { x: number; y: number }[]) => {
  const minX = Math.min(...allPoints.map((x) => x.x));
  const maxX = Math.max(...allPoints.map((x) => x.x));
  const minY = Math.min(...allPoints.map((x) => x.y));
  const maxY = Math.max(...allPoints.map((x) => x.y));

  const lenX = maxX - minX;
  const lenY = maxY - minY;

  const centerX = lenX / 2;
  const centerY = lenY / 2;

  const scaleX = AREA_WIDTH / lenX;
  const scaleY = AREA_HEIGHT / lenY;

  const minScale = Math.min(scaleX, scaleY);
  const scaleOffsetMultiplier = 0.8;

  return {
    scale: minScale * scaleOffsetMultiplier,
    centerPoint: { x: centerX, y: centerY },
    offset: {
      x: minX,
      y: minY,
    },
  };
};

export const optimizePointToView = (
  point: { x: number; y: number },
  offset: { x: number; y: number },
) => {
  return { x: point.x - offset.x, y: point.y - offset.y };
};
