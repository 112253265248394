import { CadObjectStatus } from '@/api/CadObjects/types';
import { OptionFilterDropdown } from '@/components/filterDropdowns/OptionFilterDropdown';
import { StatusTag } from '@/components/ServerConfigTable/StatusTag';
import { statusOptionsList } from '@/components/ServerConfigTable/StatusTag/statusOptionsList';
import {
  BaseServerChildColumnType,
  OptionsFilterItem,
  ServerConfigTableContext,
  StatusChildColumnAddition,
  TableRecordType,
} from '@/components/ServerConfigTable/types';
import { EmptyValue } from '@uspect-team/ant-ui-kit';
import { ControlledTableChildType } from '@uspect-team/ant-ui-kit/dist/Tables/ControlledTable/types';
import React from 'react';

export const getStatusColumnAddition = (
  strSerCol: BaseServerChildColumnType & StatusChildColumnAddition,
  context: ServerConfigTableContext,
): Partial<ControlledTableChildType<TableRecordType>> => {
  const { isFilterable, width } = strSerCol;

  return {
    align: 'center',
    width: width ?? 200,
    filterDropdown: isFilterable
      ? ({ setFilterValue, removeFilter, currentFilterState: _currentFilterState }) => {
          const currFilterState = _currentFilterState as OptionsFilterItem | null | undefined;

          return (
            <OptionFilterDropdown
              hasIn
              hasNotIn
              filter={currFilterState}
              onChangeFilter={setFilterValue}
              options={statusOptionsList}
              onResetFilter={removeFilter}
            />
          );
        }
      : undefined,
    render: (status: CadObjectStatus, record) => {
      if (!status) {
        return <EmptyValue>(Неизвестно)</EmptyValue>;
      }
      return <StatusTag value={status} />;
    },
  };
};
