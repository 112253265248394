import { TableRecordType } from './../types';
export const getCellClassName = (
  record: TableRecordType,
  dataIndex: string,
): string | undefined => {
  const classes: string[] = ['data-cell'];
  if (record[dataIndex]) {
    classes.push('with-data');
  }

  if (dataIndex === 'soglasNo' && record[dataIndex]) {
    classes.push('soglasNo-filled');
  }

  if (classes.length < 1) {
    return undefined;
  } else {
    return classes.join(' ');
  }
};
