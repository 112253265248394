import { routes } from '@/resources/routes';
import cn from 'classnames';
import React from 'react';
import { Link } from 'react-router-dom';
import './styles.scss';

type Props = {
  className?: string;
  style?: any;
};
export const RegisterFooter: React.FC<Props> = React.memo((props) => {
  const { className, style } = props;

  return (
    <div className={cn('register-footer', className)} style={style}>
      <Link className={'register-footer__link'} to={'#'}>
        Политика конфиденциальности
      </Link>

      <Link className={'register-footer__link'} to={routes.login}>
        Войти в систему
      </Link>

      <Link className={'register-footer__link'} to={'#'}>
        Запросить демо-доступ
      </Link>
    </div>
  );
});
