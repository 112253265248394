import { RecordId } from '@/api/common/types';
import { combine, createDomain, sample } from 'effector';
import { autoRunService } from './autoRun';
import { AutoRunModalState } from './types';

const autoRunModalDomain = createDomain();

const startAutoRunInModal = autoRunModalDomain.createEvent();

///IS OPEN MODAL

const openAutoRunModal = autoRunModalDomain.createEvent();
const closeAutoRunModal = autoRunModalDomain.createEvent();

const $isOpenAutoRunModal = autoRunModalDomain.createStore(false);

$isOpenAutoRunModal.on(openAutoRunModal, () => true).reset(closeAutoRunModal);

/// INIT NUMBERS

const setInitialCadObjectNumbers = autoRunModalDomain.createEvent<number[]>();
const resetInitialCadObjectNumbers = autoRunModalDomain.createEvent();

const $initialCadObjectNumbers = autoRunModalDomain.createStore<number[]>([]);

$initialCadObjectNumbers
  .on(setInitialCadObjectNumbers, (_, payload) => payload)
  .reset(resetInitialCadObjectNumbers);

///

// INIT CONTRACT ID
const setInitialContractId = autoRunModalDomain.createEvent<RecordId | null>();
const resetInitialContractId = autoRunModalDomain.createEvent();

const $initialContractId = autoRunModalDomain.createStore<RecordId | null>(null);

$initialContractId.on(setInitialContractId, (_, payload) => payload).reset(resetInitialContractId);

// /// UTILS
// const openAutoRunModalWithInit =
//autoRunModalDomain.createEvent<{ contractId: RecordId; cadObjectNumbers: number[] }>();

// guard({
//     clock: openAutoRunModalWithInit,
//     filter: $autoRunState.map(x => x.status === 'idle'),
//     target:
// })

// ///

const $autoRunModalState = combine(
  {
    isOpen: $isOpenAutoRunModal,
    initialCadObjectNumbers: $initialCadObjectNumbers,
    initialContractId: $initialContractId,
    autoRunState: autoRunService.$execState,
    autoRunContractId: autoRunService.$execContractId,
    autoRunExecItem: autoRunService.$execItem,
    autoRunHistory: autoRunService.$history,
    autoRunQueue: autoRunService.$queue,
  },
  ({
    isOpen,
    initialCadObjectNumbers,
    initialContractId,
    autoRunState,
    autoRunContractId,
    autoRunExecItem,
    autoRunHistory,
    autoRunQueue,
  }) => {
    if (!isOpen) {
      return { visible: false } as AutoRunModalState;
    } else {
      if (autoRunState === 'idle') {
        return {
          visible: true,
          state: 'idle',
          contractId: initialContractId,
          cadObjectNumbers: initialCadObjectNumbers,
          isCanStartAutoRun: initialContractId !== null && initialCadObjectNumbers.length > 0,
        } as AutoRunModalState;
      } else if (autoRunState === 'inProcess') {
        return {
          visible: true,
          state: 'inProcess',
          contractId: autoRunContractId,
          execItem: autoRunExecItem,
          history: autoRunHistory,
          queue: autoRunQueue,
        } as AutoRunModalState;
      } else if (autoRunState === 'done') {
        return {
          visible: true,
          state: 'done',
          contractId: initialContractId,
          errorCadObjectNumbers: autoRunHistory
            .filter((x) => x.status === 'error')
            .map((x) => x.item),
          successCadObjectNumbers: autoRunHistory
            .filter((x) => x.status === 'success')
            .map((x) => x.item),
          history: autoRunHistory,
          allObjectsCount: autoRunHistory.length,
          execTimeMilliseconds: autoRunHistory.reduce(
            (acc, history) => acc + history.execTimeMilliseconds,
            0,
          ),
        } as AutoRunModalState;
      } else {
        return {
          visible: true,
          state: 'idle',
          contractId: initialContractId,
          cadObjectNumbers: initialCadObjectNumbers,
        } as AutoRunModalState;
      }
    }
  },
);

sample({
  clock: startAutoRunInModal,
  source: { $initialContractId, $initialCadObjectNumbers },
  filter: $autoRunModalState.map(
    (x) => x.visible === true && x.state === 'idle' && x.isCanStartAutoRun,
  ),
  fn: ({ $initialContractId, $initialCadObjectNumbers }) => ({
    cadObjectNumbers: $initialCadObjectNumbers,
    contractId: $initialContractId as RecordId,
  }),
  target: autoRunService.startAutoRun,
});

/// RESET AUTO RUN MODAL

const resetAutoRunModal = autoRunModalDomain.createEvent();

sample({
  clock: resetAutoRunModal,
  target: [resetInitialCadObjectNumbers, resetInitialContractId],
});

const resetAutoRunWithInit = autoRunModalDomain.createEvent<{
  initialCadObjectNumbers: number[];
  initialContractId: RecordId;
}>();

sample({
  clock: resetAutoRunWithInit,
  fn: ({ initialCadObjectNumbers }) => initialCadObjectNumbers,
  target: setInitialCadObjectNumbers,
});

sample({
  clock: resetAutoRunWithInit,
  fn: ({ initialContractId }) => initialContractId,
  target: setInitialContractId,
});

/// ===================================

const backToAutoRunInit = autoRunModalDomain.createEvent();

sample({
  clock: backToAutoRunInit,
  target: [resetAutoRunModal, autoRunService.resetAutoRun],
});

////

const openAutoRunWithInit = autoRunModalDomain.createEvent<{
  initialCadObjectNumbers: number[];
  initialContractId: RecordId;
}>();

sample({
  clock: openAutoRunWithInit,
  filter: autoRunService.$execState.map((x) => x === 'idle'),
  target: [resetAutoRunWithInit, openAutoRunModal],
});

sample({
  clock: openAutoRunWithInit,
  filter: autoRunService.$execState.map((x) => x === 'done' || x === 'inProcess'),
  target: openAutoRunModal,
});

///

export {
  $autoRunModalState,
  openAutoRunModal,
  closeAutoRunModal,
  setInitialCadObjectNumbers,
  setInitialContractId,
  startAutoRunInModal,
  backToAutoRunInit,
  openAutoRunWithInit,
};
