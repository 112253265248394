import React from 'react';
import cn from 'classnames';
import './styles.scss';

type Props = {
  className?: string;
  style?: any;
};

const Filters: React.FC<Props> = React.memo((props) => {
  const { className, style, children } = props;

  return (
    <div className={cn('already-loaded-base__filters', className)} style={style}>
      {children}
    </div>
  );
});

const Table: React.FC<Props> = React.memo((props) => {
  const { className, style, children } = props;

  return (
    <div className={cn('already-loaded-base__table', className)} style={style}>
      {children}
    </div>
  );
});

const SelectAlreadyExistBase: React.FC<Props> & {
  Filters: typeof Filters;
  Table: typeof Table;
} = (props) => {
  const { className, style, children } = props;

  return (
    <div className={cn('already-loaded-base', className)} style={style}>
      {children}
    </div>
  );
};

SelectAlreadyExistBase.Filters = Filters;
SelectAlreadyExistBase.Table = Table;

export { SelectAlreadyExistBase };
