/* eslint-disable max-len */
import React from 'react';

export type ReloadSvgProps = React.SVGProps<SVGSVGElement>;

const _ReloadSvg: React.FC<ReloadSvgProps> = (props) => {
  return (
    <svg
      width='16'
      height='16'
      viewBox='0 0 16 16'
      fill='none'
      xmlns='http://www.w3.org/2000/svg'
      {...props}
    >
      <path
        d='M13.4098 13.8925C12.2659 14.943 10.8408 15.6378 9.30848 15.8921C7.77618 16.1463 6.20297 15.9491 4.78093 15.3244C3.35889 14.6997 2.14955 13.6747 1.30055 12.3743C0.451562 11.074 -0.000347821 9.55466 2.00861e-07 8.00184H1.33311C1.33302 9.34403 1.73835 10.655 2.49604 11.763C3.25373 12.871 4.32845 13.7244 5.57948 14.2116C6.83051 14.6987 8.19952 14.7968 9.50726 14.493C10.815 14.1893 12.0005 13.4978 12.9085 12.5091H10.9715C10.7947 12.5091 10.6252 12.4389 10.5002 12.3139C10.3752 12.189 10.3049 12.0195 10.3049 11.8428C10.3049 11.666 10.3752 11.4965 10.5002 11.3716C10.6252 11.2466 10.7947 11.1764 10.9715 11.1764H14.0763C14.2531 11.1764 14.4226 11.2466 14.5476 11.3716C14.6726 11.4965 14.7429 11.666 14.7429 11.8428V14.944C14.7429 15.1207 14.6726 15.2902 14.5476 15.4152C14.4226 15.5402 14.2531 15.6104 14.0763 15.6104C13.8995 15.6104 13.73 15.5402 13.605 15.4152C13.48 15.2902 13.4098 15.1207 13.4098 14.944V13.8912V13.8925ZM3.08882 3.49589H5.02583C5.20261 3.49589 5.37215 3.56609 5.49715 3.69106C5.62216 3.81603 5.69238 3.98552 5.69238 4.16225C5.69238 4.33898 5.62216 4.50847 5.49715 4.63344C5.37215 4.75841 5.20261 4.82861 5.02583 4.82861H1.91968C1.7429 4.82861 1.57336 4.75841 1.44835 4.63344C1.32335 4.50847 1.25312 4.33898 1.25312 4.16225V1.05833C1.25312 0.881597 1.32335 0.712105 1.44835 0.587137C1.57336 0.46217 1.7429 0.391964 1.91968 0.391964C2.09646 0.391964 2.266 0.46217 2.39101 0.587137C2.51601 0.712105 2.58624 0.881597 2.58624 1.05833V2.11118C3.72998 1.0594 5.15544 0.363475 6.68841 0.108463C8.22137 -0.146549 9.79545 0.0503942 11.2183 0.675225C12.6411 1.30006 13.851 2.32571 14.7002 3.62685C15.5494 4.92798 16.0011 6.44824 16 8.00184H14.6669C14.667 6.65947 14.2616 5.34837 13.5037 4.24025C12.7459 3.13213 11.6709 2.27866 10.4197 1.79164C9.16839 1.30462 7.79917 1.20675 6.49133 1.51085C5.18348 1.81494 3.998 2.50683 3.09015 3.49589H3.08882Z'
        fill='#B7B7B7'
      />
    </svg>
  );
};
export const ReloadSvg = React.memo(_ReloadSvg);
