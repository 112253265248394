import { RecordId } from '@/api/common/types';
import { FileRecordResponse, FileType } from '@/api/Files/types';
import { openSelectAlreadyExistFileModal, openUploadFileModal } from '@/globalStorage/file';
import { CloudUploadOutlined, UnorderedListOutlined, UploadOutlined } from '@ant-design/icons';
import { EditableDocument } from '@uspect-team/ant-ui-kit';
import { Button, Dropdown, Menu } from 'antd';
import React, { useCallback } from 'react';

type Props = {
  value?: FileRecordResponse;
  onChange?: (file?: FileRecordResponse) => void;
  fileType: FileType;
  contractId?: RecordId;
};
export const FileInput: React.FC<Props> = React.memo((props) => {
  const { value, onChange, fileType, contractId } = props;

  const onLoadClickHandler = useCallback(() => {
    openUploadFileModal({
      fileType,
      onUpload: (file) => {
        onChange && onChange(file);
      },
      contractId,
    });
  }, [fileType, contractId, onChange]);

  const onSelectAlreadyExistFileHandler = useCallback(() => {
    openSelectAlreadyExistFileModal({
      title: 'Выбор из существующих файлов',
      fileType,
      onSelectFile: (file) => {
        onChange && onChange(file);
      },
    });
  }, [fileType, onChange]);

  return (
    <>
      <EditableDocument
        document={
          value
            ? {
                name: value.fileName,
                link: value.fileUri,
                size: value.size,
                uploadOn: new Date(value.createdAt),
              }
            : undefined
        }
        onDelete={() => {
          if (onChange) {
            onChange(undefined);
          }
        }}
        actions={
          <Dropdown
            overlay={
              <Menu>
                <Menu.Item key={'load'} icon={<CloudUploadOutlined />} onClick={onLoadClickHandler}>
                  Загрузить файл
                </Menu.Item>
                <Menu.Item
                  key={'exist'}
                  icon={<UnorderedListOutlined />}
                  onClick={onSelectAlreadyExistFileHandler}
                >
                  Выбрать из существующих
                </Menu.Item>
              </Menu>
            }
          >
            <Button icon={<UploadOutlined />} />
          </Dropdown>
        }
      />
    </>
  );
});
