import React from 'react';
import { StepContent } from '@/components/StepContent';
import './styles.scss';
import { AntdLabelMimic } from '@/components/formHelpers/AntdLabelMimic';
import { Select } from 'antd';
import { downloadTypeOptions } from './utils';

type Props = {
  fileType: string[];
  onChangeFileType: (fileType: string[]) => void;
};
const _BatchFileDownloadSelectFileTypeStep: React.FC<Props> = (props) => {
  const { fileType, onChangeFileType } = props;

  return (
    <StepContent className={'batch-file-download-select-file-type-step'}>
      <div className={'batch-file-download-select-file-type-step__file-type-field'}>
        <AntdLabelMimic>Выберите тип файла для выгрузки</AntdLabelMimic>
        <Select
          options={downloadTypeOptions}
          value={fileType}
          mode='multiple'
          onChange={onChangeFileType}
          className={'batch-file-download-select-file-type-step__file-type-field-select'}
        />
      </div>
      <div className='batch-file-download-select-file-type-step__hint'>
        После нажатия на кнопку “Начать выгрузку” не закрывайте это окно и вкладку браузера,
        дождитесь окончания процедуры выгрузки.
      </div>
    </StepContent>
  );
};
export const BatchFileDownloadSelectFileTypeStep = React.memo(_BatchFileDownloadSelectFileTypeStep);
