import { useGetContractsAllQ } from '@/hooks/contracts';
import { Select, SelectProps } from 'antd';
import { RefSelectProps } from 'antd/lib/select';
import React, { Ref } from 'react';

type Props = SelectProps<string> & { ref?: Ref<RefSelectProps> | undefined };
export const SelectContracts: React.FC<Props> = React.memo((props) => {
  const { data: contracts, isLoading } = useGetContractsAllQ();
  const { ...otherProps } = props;

  return (
    <Select
      showSearch
      optionFilterProp={'children'}
      filterOption={(input, option) => {
        const childrenText = option?.children as string | null | undefined;
        return childrenText?.toLowerCase().includes(input.toLowerCase()) || false;
      }}
      loading={isLoading}
      placeholder={'Выберите организацию'}
      {...otherProps}
    >
      {contracts?.map((i) => (
        <Select.Option key={i._id} value={i._id}>
          {i.title}
        </Select.Option>
      ))}
    </Select>
  );
});
