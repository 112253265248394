import {
  ColumnsCreateRequest,
  ColumnsCreateResponse,
  ColumnsGetByIdResponse,
  GetColumnsPageRequestNew,
  ColumnsGetResponse,
  ColumnsPatchRequest,
  ColumnsPatchResponse,
} from '@/api/Dictionary/Columns/types';
import { api } from '@/api/axiosInstance';
import { RecordId } from '@/api/common/types';

export class ColumnsApi {
  get(params: GetColumnsPageRequestNew) {
    const { type, ...rest } = params;

    const filter = type ? { $or: [{ contractType: type }, { contractType: 'all' }] } : {};

    const result = { ...rest, filter };

    return api.get<ColumnsGetResponse>('/fields', { params: result });
  }

  getById(id: RecordId) {
    return api.get<ColumnsGetByIdResponse>(`/fields/${id}`);
  }

  create(data: ColumnsCreateRequest) {
    return api.post<ColumnsCreateResponse>('/fields', data);
  }

  patch(data: ColumnsPatchRequest) {
    return api.patch<ColumnsPatchResponse>(`/fields/${data.id}`, data.patch);
  }

  delete(id: RecordId) {
    return api.delete<void>(`/fields/${id}`);
  }
}
