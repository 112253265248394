import { DocumentIcon } from '@/components/customIcons/DocumentIcon';
import { ReloadIcon } from '@/components/customIcons/ReloadIcon';
import { DashboardButton } from '@/components/DasboardButton';
import { Col, Form, Input, Row, Space, Tooltip, Typography } from 'antd';
import React from 'react';
import { Link } from 'react-router-dom';
import './styles.scss';
import { createFinanceTable } from './utils';

const _ManagementFinanceLeft: React.FC = () => {
  return (
    <Col span={8} className='management-finance__left-card paper'>
      <Space size={20} direction='vertical'>
        <div className='management-finance__left-card-item'>
          <Typography className='management-finance__left-card-title'>
            Активный тарифный план
          </Typography>
          <Row>
            <Col className='management-finance__left-card-field' span={10}>
              Текущий тарифный план:
            </Col>
            <Col className='management-finance__left-card-tariff' span={14}>
              «Профессиональный» (20 000 ₽ / мес.)
            </Col>
          </Row>
          <div className='management-finance__left-card-plan'>
            <Row gutter={[10, 10]}>
              <Col span={4}></Col>
              <Col span={5}>
                <Typography className='management-finance__left-card-header'>
                  Активных контрактов
                </Typography>
              </Col>
              <Col span={5}>
                <Typography className='management-finance__left-card-header'>
                  Пользователей организации
                </Typography>
              </Col>
              <Col span={5}>
                <Typography className='management-finance__left-card-header'>
                  Токенов генерации
                </Typography>
              </Col>
              <Col span={5}>
                <Typography className='management-finance__left-card-header'>
                  Место на диске
                </Typography>
              </Col>
              <Col span={4}>
                <Typography className='management-finance__left-card-header'>
                  доступно в тарифе:
                </Typography>
              </Col>
              {createFinanceTable()}
              <Col span={4}>
                <Typography className='management-finance__left-card-header'>
                  используется сейчас:
                </Typography>
              </Col>
              {createFinanceTable({}, true)}
            </Row>
          </div>
          <Typography className='management-finance__left-card-secondary'>
            По вопросам смены тарифного плана и увеличения лимитов напишите в наш &nbsp;
            <Link to={'/help'} className='management-finance__left-card-help'>
              чат поддержки
            </Link>
          </Typography>
        </div>
        <div className='management-finance__left-card-item'>
          <Space size={10} align='baseline'>
            <Typography className='management-finance__left-card-title'>Баланс</Typography>
            <Tooltip title='Reload'>
              <ReloadIcon />
            </Tooltip>
          </Space>
          <Row className='management-finance__left-card-row-flex'>
            <Col span={5}>
              <Typography className='management-finance__left-card-total'>2 500 ₽</Typography>
            </Col>
            <Col>
              <Typography className='management-finance__left-card-date'>
                Оплачено до 12.01.2022 г.
              </Typography>
            </Col>
          </Row>
          <Typography className='management-finance__left-card-secondary'>
            По вопросам пополнения баланса и любым техническим проблемам напишите в наш &nbsp;
            <Link to={'/help'} className='management-finance__left-card-help'>
              чат поддержки
            </Link>
          </Typography>
        </div>
        <div className='management-finance__left-card-item'>
          <Typography className='management-finance__left-card-title'>
            Пополнение баланса
          </Typography>
          <Form layout='vertical'>
            <Row gutter={16} className='management-finance__left-card-row-form'>
              <Col span={14}>
                <Form.Item label='Введите сумму для пополнения:'>
                  <Input />
                </Form.Item>
              </Col>
              <Col span={10}>
                <DashboardButton
                  className='management-finance__left-card-document-button'
                  icon={<DocumentIcon />}
                >
                  Сгенерировать документ
                </DashboardButton>
              </Col>
            </Row>
          </Form>
          <Space className='management-finance__left-card-recommend'>
            <Typography>Рекомендации:</Typography>
            <div className='management-finance__left-card-chip'>
              3 000 ₽
              <span className='management-finance__left-card-chip-subtitle'>
                ежемесячная плата
                <br /> по тарифу
              </span>
            </div>
          </Space>
          <Typography className='management-finance__left-card-secondary'>
            Чтобы запросить акт сверки за период, а также прочие документы напишите в наш &nbsp;
            <Link to={'/help'} className='management-finance__left-card-help'>
              чат поддержки
            </Link>
          </Typography>
        </div>
      </Space>
    </Col>
  );
};
export const ManagementFinanceLeftCard = React.memo(_ManagementFinanceLeft);
