import React from 'react';
import cn from 'classnames';
import './styles.scss';

type Props = {
  className?: string;
  style?: any;
  message?: string | null;
};
const _ErrorMessage: React.FC<Props> = (props) => {
  const { className, style, message } = props;
  if (!message) {
    return null;
  }
  return (
    <div className={cn('error-message', className)} style={style}>
      {message}
    </div>
  );
};
export const ErrorMessage = React.memo(_ErrorMessage);
