import { Checkbox, Col, Form, Input, InputNumber, Row } from 'antd';
import React from 'react';
import { errorMessages } from '@/utils/errorMessages';

export const CoordinateSystemFields: React.FC = React.memo(() => {
  return (
    <>
      <Row gutter={18}>
        <Col span={12}>
          <Form.Item
            name={'title'}
            rules={[{ required: true, message: errorMessages.required }]}
            label={'Название МСК'}
          >
            <Input />
          </Form.Item>
        </Col>
        <Col span={6}>
          <Form.Item
            name={'code'}
            rules={[{ required: true, message: errorMessages.required }]}
            label={'Код'}
          >
            <Input />
          </Form.Item>
        </Col>
        <Col span={6}>
          <Form.Item
            name={'isEnabled'}
            label={' '}
            rules={[{ required: true, message: errorMessages.required }]}
            valuePropName={'checked'}
          >
            <Checkbox>Активность</Checkbox>
          </Form.Item>
        </Col>
      </Row>
      <Row gutter={18}>
        <Col span={6}>
          <Form.Item
            name={'minX'}
            label={'minX'}
            rules={[{ required: true, message: errorMessages.required }]}
          >
            <InputNumber />
          </Form.Item>
        </Col>
        <Col span={6}>
          <Form.Item
            name={'maxX'}
            label={'maxX'}
            rules={[{ required: true, message: errorMessages.required }]}
          >
            <InputNumber />
          </Form.Item>
        </Col>
        <Col span={6}>
          <Form.Item
            name={'minY'}
            label={'minY'}
            rules={[{ required: true, message: errorMessages.required }]}
          >
            <InputNumber />
          </Form.Item>
        </Col>
        <Col span={6}>
          <Form.Item
            name={'maxY'}
            label={'maxY'}
            rules={[{ required: true, message: errorMessages.required }]}
          >
            <InputNumber />
          </Form.Item>
        </Col>
      </Row>
      <Form.Item
        name={'params'}
        label={'Параметры toWGS84'}
        rules={[{ required: true, message: errorMessages.required }]}
      >
        <Input />
      </Form.Item>
    </>
  );
});
