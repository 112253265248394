export const territoryOptions: { value: string; label: string }[] = [
  { value: 'secureZone4', label: 'secureZone4' },
  { value: 'secureZone3', label: 'secureZone3' },
];

export const ozFormatOptions: { value: string; label: string }[] = [
  { value: 'short_square', label: 'Короткие квадратные концы' },
  { value: 'square', label: 'Квадратные концы' },
  { value: 'round', label: 'Скругленные углы' },
];

export const availablePaperFormatsOptions: { value: string; label: string }[] = [
  { value: 'А0', label: 'A0' },
  { value: 'А1', label: 'A1' },
  { value: 'А2', label: 'A2' },
  { value: 'А3', label: 'A3' },
  { value: 'А4', label: 'A4' },
];

export const availableScalesOptions: { value: string; label: string }[] = [
  { value: '1:10000', label: '1:10000' },
  { value: '1:9000', label: '1:9000' },
  { value: '1:8000', label: '1:8000' },
  { value: '1:7000', label: '1:7000' },
  { value: '1:6000', label: '1:6000' },

  { value: '1:5000', label: '1:5000' },
  { value: '1:4000', label: '1:4000' },
  { value: '1:3000', label: '1:3000' },
  { value: '1:2000', label: '1:2000' },
  { value: '1:1000', label: '1:1000' },
  { value: '1:500', label: '1:500' },
];

export const defaultTerritory = 'secureZone4';
