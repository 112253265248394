import { sleep } from '@/utils/sleep';
import React from 'react';
import { PasswordRestoreForm, PasswordRestorePageState } from './types';

export const usePasswordRestorePage = () => {
  const [state, setState] = React.useState<PasswordRestorePageState>({
    state: 'notSubmitted',
    error: null,
    isLoading: false,
  });

  const onSubmitSendHandler = React.useCallback(async (form: PasswordRestoreForm) => {
    if (form.email) {
      setState({
        state: 'notSubmitted',
        error: null,
        isLoading: true,
      });

      await sleep(500);

      setState({
        state: 'submitted',
        email: form.email,
      });
    }
  }, []);

  return { state, onSubmitSendHandler };
};
