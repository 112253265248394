import { api, blank_api } from '../axiosInstance';
import { RecordId } from '../common/types';
import {
  CreateUserRequest,
  CreateUserResponse,
  GetProfileResponse,
  GetUserDetailResponse,
  GetUserPageRequest,
  GetUserPageResponse,
  LoginRequest,
  LoginResponse,
  RefreshResponse,
  UpdateUserRequest,
  UpdateUserResponse,
} from './types';

const rootUrl = '/users';
export class UsersApi {
  public login(data: LoginRequest) {
    return blank_api.post<LoginResponse>(`${rootUrl}/login`, data, { withCredentials: true });
  }

  public logout() {
    return blank_api.get<void>(`${rootUrl}/logout`, { withCredentials: true });
  }

  public refresh() {
    return blank_api.get<RefreshResponse>(`${rootUrl}/refresh`, { withCredentials: true });
  }

  public me() {
    return api.get<GetProfileResponse>(`${rootUrl}/profile`);
  }

  getPage(data: GetUserPageRequest) {
    return api.get<GetUserPageResponse>(rootUrl, { params: data });
  }

  detail(id: RecordId) {
    return api.get<GetUserDetailResponse>(`${rootUrl}/${id}`);
  }

  create(data: CreateUserRequest) {
    return api.post<CreateUserResponse>(rootUrl, data);
  }

  update(data: UpdateUserRequest) {
    const { id, patch } = data;
    return api.patch<UpdateUserResponse>(`${rootUrl}/${id}`, patch);
  }

  delete(id: RecordId) {
    return api.delete(`${rootUrl}/${id}`);
  }
}
