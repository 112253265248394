import { FileType } from '@/api/Files/types';

export const getAllowedExtByFileType = (fileType: FileType): string[] => {
  switch (fileType) {
    case 'rastrJPG':
      return ['jpg'];
    case 'rastrTAB':
      return ['tab'];
    case 'osMif':
      return ['mif'];
    case 'osSit':
      return ['mif'];
    case 'kptXML':
      return ['xml'];
    case 'kptMIF':
      return ['mif'];
    case 'zouit':
      return ['pdf'];
    case 'pd':
      return ['pdf', 'doc', 'docx', 'xls', 'xlsx', 'jpg', 'png', 'jpeg'];
    case 'egrnReceived':
    case 'scheme':
      return ['pdf'];

    case 'coordsCatalog':
    case 'templateMonitoring':
    case 'monitoring':
      return ['xlsx'];
    case 'coordsCatalogXLS':
      return ['xls', 'xlsx'];
    case 'mskFolder':
    case 'wgsFolder':
    case 'packageZIP':
    case 'technokadFolder':
      return ['zip'];

    default:
      return [];
  }
};

export function getFileExtension(filename: string) {
  return filename.split('.').pop();
}
