import React from 'react';
import { TEditContractForm } from '@/types';
import { TabContent } from '../../TabContent';
import { Button, Form, Input, Space } from 'antd';
import { MinusCircleOutlined, PlusOutlined } from '@ant-design/icons';
import './styles.scss';

type TZeroQuarterTabContentProps = {
  onChangeForm: (formPatch: TEditContractForm) => void;
};

export const ZeroQuarterTabContent: React.FC<TZeroQuarterTabContentProps> = ({ onChangeForm }) => {
  return (
    <TabContent>
      <div>Если в контракте используются нулевые кварталы, добавьте их:</div>
      <div className='zero-quarter-form-wrapper'>
        <Form.List name='zeroQuarters' initialValue={[{ cadastralNumber: '' }]}>
          {(fields, { add, remove }) => (
            <>
              {fields.map(({ key, name, ...restField }) => (
                <Space key={key} align='baseline' className='zero-quarter-form-row'>
                  <Form.Item
                    {...restField}
                    name={[name, 'cadastralNumber']}
                    rules={[{ required: true, message: 'Введите кадастровый номер' }]}
                    className='zero-quarter-input'
                  >
                    <Input placeholder='Кадастровый номер' />
                  </Form.Item>
                  <MinusCircleOutlined
                    onClick={() => remove(name)}
                    className='zero-quarter-minus-icon'
                  />
                </Space>
              ))}
              <Form.Item>
                <Button type='dashed' onClick={() => add()} icon={<PlusOutlined />}>
                  Добавить нулевой квартал
                </Button>
              </Form.Item>
            </>
          )}
        </Form.List>
      </div>
    </TabContent>
  );
};
