import { RecordId } from '@/api/common/types';
import { GetColumnGroupsPageRequest } from '@/api/Dictionary/ColumnGroups/types';

export const columnGroupsQueryKeys = {
  all: ['column-groups'] as const,
  pages: () => [...columnGroupsQueryKeys.all, 'page'] as const,
  page: (data: GetColumnGroupsPageRequest) => [...columnGroupsQueryKeys.pages(), data] as const,

  details: () => [...columnGroupsQueryKeys.all, 'detail'] as const,
  detail: (id: RecordId) => [...columnGroupsQueryKeys.details(), id] as const,

  create: () => [...columnGroupsQueryKeys.all, 'create'] as const,
  patch: () => [...columnGroupsQueryKeys.all, 'patch'] as const,
  delete: () => [...columnGroupsQueryKeys.all, 'delete'] as const,
};
