import React from 'react';
import cn from 'classnames';
import './styles.scss';
import { useStore } from 'effector-react';
import { $currentUserName } from '@/globalStorage/user';

export type CompaniesPageHeaderProps = {
  className?: string;
  style?: React.CSSProperties;
};

const _CompaniesPageHeader: React.FC<CompaniesPageHeaderProps> = (props) => {
  const { className, style } = props;

  const userName = useStore($currentUserName);

  const title = userName !== null ? `${userName}, здравствуйте!` : 'Здравствуйте!';

  return (
    <div className={cn(className, 'companies-page-header')} style={style}>
      <div className='companies-page-header__title'>{title}</div>
      <div className='companies-page-header__description'>
        Выберите компанию, с которой хотите продолжить работать:
      </div>
    </div>
  );
};
export const CompaniesPageHeader = React.memo(_CompaniesPageHeader);
