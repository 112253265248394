import { ExclamationCircleOutlined } from '@ant-design/icons';
import { Space } from 'antd';
import React from 'react';

export type TabWithValidProps = {
  children?: React.ReactNode;
  haveError: boolean;
};

export const TabWithValid: React.FC<TabWithValidProps> = (props) => {
  const { haveError, children } = props;

  return (
    <Space>
      {children}
      {haveError && <ExclamationCircleOutlined className={'contract-fields__error-tab-icon'} />}
    </Space>
  );
};
