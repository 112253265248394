import {
  FilesGetByIdResponse,
  GetFilesPageRequest,
  GetFilesPageResponse,
  FilesUploadRequest,
  FilesUploadResponse,
  GetCoupleFilesPageRequest,
  GetCoupleFilesPageResponse,
} from './types';
import { api } from '../axiosInstance';

export class FilesApi {
  public get(params: GetFilesPageRequest) {
    return api.get<GetFilesPageResponse>('/files', { params });
  }

  public getCouples(data: GetCoupleFilesPageRequest) {
    const { fileType: _, ...params } = data;
    return api.get<GetCoupleFilesPageResponse>(`/files/pair/${data.fileType}`, {
      params,
    });
  }

  public getById(id: string) {
    return api.get<FilesGetByIdResponse>(`/files/${id}`);
  }

  public upload(data: FilesUploadRequest) {
    const formData = new FormData();
    if (data.contractId) {
      formData.append('contractId', data.contractId);
    }
    for (const file of data.files) {
      formData.append('files', file);
    }

    return api.post<FilesUploadResponse>(`/files/upload/${data.type}`, formData);
  }
}
