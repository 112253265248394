import {
  OptionFilterDropdown,
  OptionFilterDropdownProps,
} from '@/components/filterDropdowns/OptionFilterDropdown';
import { OptionFilterOptionItem } from '@/components/filterDropdowns/OptionFilterDropdown/types';
import { useGetContractsAllQ } from '@/hooks/contracts';
import React from 'react';

export type ContractFilterDropdownProps = Omit<OptionFilterDropdownProps, 'options'>;

const _ContractFilterDropdown: React.FC<ContractFilterDropdownProps> = (props) => {
  const { data: contracts } = useGetContractsAllQ();
  const options: OptionFilterOptionItem[] = (contracts ?? []).map((contract) => ({
    value: contract._id,
    label: contract.title,
  }));
  return <OptionFilterDropdown hasIn hasNotIn {...props} options={options} />;
};
export const ContractFilterDropdown = React.memo(_ContractFilterDropdown);
