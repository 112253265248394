import React, { useCallback, useState } from 'react';
import { MainLayout } from '@/components/layouts/MainLayout';
import { SubHeader } from '@/components/SubHeader';
import { Space } from 'antd';
import { DashboardButton } from '@/components/DasboardButton';
import { PlusCircleOutlined, ReloadOutlined } from '@ant-design/icons/lib';
import { CreateTpModal, CreateTpModalProps } from '@/components/modals/tps/CreateTpModal';
import { useTpPageFetchingState } from '@/hooks/coordinates/tp';
import { TpTable } from '@/pages/Coordinates/TpPage/TpTable';

const _TpPage: React.FC = () => {
  const [createTpModalState, setCreateTpModalState] = useState<CreateTpModalProps>({
    visible: false,
  });

  const tpPageFetchingState = useTpPageFetchingState();

  const onClickCreateTpHandler = useCallback(() => {
    setCreateTpModalState({
      visible: true,
      onClose: () => setCreateTpModalState({ visible: false }),
    });
  }, []);

  return (
    <MainLayout>
      <SubHeader>
        <SubHeader.Left>
          <Space size={10}>
            <DashboardButton icon={<PlusCircleOutlined />} onClick={onClickCreateTpHandler}>
              Добавить ТП
            </DashboardButton>
            <DashboardButton
              icon={<ReloadOutlined />}
              loading={tpPageFetchingState.isFetching}
              onClick={() => tpPageFetchingState.refresh()}
            >
              Обновить таблицу
            </DashboardButton>
          </Space>
        </SubHeader.Left>
      </SubHeader>
      <TpTable />
      <CreateTpModal {...createTpModalState} />
    </MainLayout>
  );
};
export const TpPage = React.memo(_TpPage);
