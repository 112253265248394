import { RecordId } from '@/api/common/types';
import { useQuery, useMutation } from 'react-query';
import { Api } from '@/api';
import { statisticsQueryKeys } from './queryKeys';

export const useGetContractStatistic = (contractId: RecordId) => {
  return useQuery(
    statisticsQueryKeys.contractStatistic(contractId),
    () => {
      return Api.statistics.get(contractId);
    },
    { select: (data) => data.data },
  );
};

export const useSendContractStatisticEmails = () => {
  return useMutation(Api.statistics.sendEmails);
};
