import { TableFilter } from './../types';
import { ServerConfigTableState } from '@/components/ServerConfigTable/types';
import { ControlledTableState } from '@uspect-team/ant-ui-kit/dist/Tables/ControlledTable/types';
import { getAfterDate, getBeforeDate, getDayRange } from '@/utils/dateUtils';

const serverSortToControlled = (
  sort: ServerConfigTableState['sort'],
): ControlledTableState['sort'] => {
  const res: ControlledTableState['sort'] = [];
  if (!sort) {
    return undefined;
  }
  Object.entries(sort).forEach(([key, value]) => {
    res.push({ dataIndex: key, order: value });
  });
  return res;
};
export const controlledSortToServer = (
  sort: ControlledTableState['sort'],
): ServerConfigTableState['sort'] => {
  const res: ServerConfigTableState['sort'] = {};
  if (!sort) {
    return undefined;
  }

  sort.forEach(({ dataIndex, order }) => {
    res[dataIndex] = order;
  });
  return res;
};

export const mapServerStateToControlled = (
  scState: ServerConfigTableState,
): ControlledTableState => {
  return {
    pagination: { currentPage: scState.page || 1, pageSize: scState.limit || 50 },
    sort: serverSortToControlled(scState.sort),
    filter: { ...scState.filter },
  };
};

export function mapControlledStateToServer(
  controlledState: ControlledTableState,
): ServerConfigTableState {
  const { sort, pagination, filter } = controlledState;

  return {
    sort: controlledSortToServer(sort),
    page: pagination?.currentPage,
    filter,
    limit: pagination?.pageSize,
  };
}

const addNotExistToResult = (result: any, dataIndex: string, existType: string) => {
  result['$and'] = [
    ...(result['$and'] ?? []),
    { $or: [{ [dataIndex]: { $exists: false } }, { [dataIndex]: { $not: { $type: existType } } }] },
  ];
};

export const mapFilterStateToServer = (filter: TableFilter | undefined, noType?: boolean) => {
  if (!filter) {
    return undefined;
  }

  const result: any = {};

  if (noType) {
    Object.entries(filter).forEach(([dataIndex, filterItem]) => {
      switch (filterItem.filterAs) {
        case 'integer': {
          if (filterItem.filterType === 'equal') {
            result[dataIndex] = {
              $eq: filterItem.payload,
            };
          } else if (filterItem.filterType === 'between') {
            result[dataIndex] = {
              $gte: filterItem.payload.min,
              $lte: filterItem.payload.max,
            };
          } else if (filterItem.filterType === 'exist') {
            result[dataIndex] = {
              $ne: null,
            };
          } else if (filterItem.filterType === 'notExist') {
            result[dataIndex] = null;
          }
          return;
        }

        case 'decimal': {
          if (filterItem.filterType === 'equal') {
            result[dataIndex] = {
              $eq: filterItem.payload,
            };
          } else if (filterItem.filterType === 'between') {
            result[dataIndex] = {
              $gte: filterItem.payload.min,
              $lte: filterItem.payload.max,
            };
          } else if (filterItem.filterType === 'exist') {
            result[dataIndex] = {
              $ne: null,
            };
          } else if (filterItem.filterType === 'notExist') {
            result[dataIndex] = null;
          }
          return;
        }

        case 'string': {
          if (filterItem.filterType === 'equal') {
            result[dataIndex] = {
              $eq: filterItem.payload,
            };
          } else if (filterItem.filterType === 'includes') {
            result[dataIndex] = {
              $regex: filterItem.payload,
              $options: 'i',
            };
          } else if (filterItem.filterType === 'exist') {
            result[dataIndex] = {
              $ne: null,
            };
          } else if (filterItem.filterType === 'notExist') {
            result[dataIndex] = null;
          }
          return;
        }

        case 'options': {
          if (filterItem.filterType === 'in') {
            result[dataIndex] = {
              $in: filterItem.payload,
            };
          } else if (filterItem.filterType === 'notIn') {
            result[dataIndex] = {
              $nin: filterItem.payload,
            };
          } else if (filterItem.filterType === 'exist') {
            result[dataIndex] = {
              $type: 'string',
            };
          } else if (filterItem.filterType === 'notExist') {
            addNotExistToResult(result, dataIndex, 'string');
          }

          return;
        }

        case 'date': {
          if (filterItem.filterType === 'equal') {
            const { beforeDate, afterDate } = getDayRange(filterItem.payload);
            result[dataIndex] = {
              $gte: beforeDate,
              $lte: afterDate,
            };
          } else if (filterItem.filterType === 'between') {
            const { min, max } = filterItem.payload;
            result[dataIndex] = {
              $gte: min ? getBeforeDate(min) : undefined,
              $lte: max ? getAfterDate(max) : undefined,
            };
          } else if (filterItem.filterType === 'exist') {
            result[dataIndex] = {
              $type: 'date',
            };
          } else if (filterItem.filterType === 'notExist') {
            addNotExistToResult(result, dataIndex, 'date');
          }
          return;
        }

        case 'address': {
          const valueDataIndex = `${dataIndex}.value`;
          if (filterItem.filterType === 'equal') {
            result[valueDataIndex] = {
              $eq: filterItem.payload,
            };
          } else if (filterItem.filterType === 'includes') {
            result[valueDataIndex] = {
              $regex: filterItem.payload,
              $options: 'i',
            };
          } else if (filterItem.filterType === 'exist') {
            result[dataIndex] = {
              $type: 'object',
            };
          } else if (filterItem.filterType === 'notExist') {
            addNotExistToResult(result, dataIndex, 'object');
          }
          return;
        }

        case 'document': {
          if (filterItem.filterType === 'exist') {
            result[dataIndex] = {
              $type: 'objectId',
            };
          } else if (filterItem.filterType === 'notExist') {
            addNotExistToResult(result, dataIndex, 'objectId');
          }
          return;
        }

        case 'document-collection': {
          if (filterItem.filterType === 'exist') {
            result['$and'] = [
              ...(result['$and'] ?? []),
              {
                [dataIndex]: { $type: 'array' },
              },
              {
                [dataIndex]: { $not: { $size: 0 } },
              },
            ];
          } else if (filterItem.filterType === 'notExist') {
            result['$and'] = [
              ...(result['$and'] ?? []),
              {
                $or: [
                  { [dataIndex]: { $exists: false } },
                  { [dataIndex]: { $not: { $type: 'array' } } },
                  { [dataIndex]: { $size: 0 } },
                ],
              },
            ];
          }
          return;
        }
      }
    });
  }

  if (!noType)
    Object.entries(filter).forEach(([dataIndex, filterItem]) => {
      switch (filterItem.filterAs) {
        case 'integer': {
          if (filterItem.filterType === 'equal') {
            result[dataIndex] = {
              $eq: filterItem.payload,
            };
          } else if (filterItem.filterType === 'between') {
            result[dataIndex] = {
              $gte: filterItem.payload.min,
              $lte: filterItem.payload.max,
            };
          } else if (filterItem.filterType === 'exist') {
            result[dataIndex] = {
              $type: 'int',
            };
          } else if (filterItem.filterType === 'notExist') {
            addNotExistToResult(result, dataIndex, 'int');
          }
          return;
        }

        case 'decimal': {
          if (filterItem.filterType === 'equal') {
            result[dataIndex] = {
              $eq: filterItem.payload,
            };
          } else if (filterItem.filterType === 'between') {
            result[dataIndex] = {
              $gte: filterItem.payload.min,
              $lte: filterItem.payload.max,
            };
          } else if (filterItem.filterType === 'exist') {
            result[dataIndex] = {
              $type: 'double',
            };
          } else if (filterItem.filterType === 'notExist') {
            addNotExistToResult(result, dataIndex, 'double');
          }
          return;
        }

        case 'string': {
          if (filterItem.filterType === 'equal') {
            result[dataIndex] = {
              $eq: filterItem.payload,
            };
          } else if (filterItem.filterType === 'includes') {
            result[dataIndex] = {
              $regex: filterItem.payload,
              $options: 'i',
            };
          } else if (filterItem.filterType === 'exist') {
            result[dataIndex] = {
              $type: 'number',
            };
          } else if (filterItem.filterType === 'notExist') {
            addNotExistToResult(result, dataIndex, 'string');
          }
          return;
        }

        case 'options': {
          if (filterItem.filterType === 'in') {
            result[dataIndex] = {
              $in: filterItem.payload,
            };
          } else if (filterItem.filterType === 'notIn') {
            result[dataIndex] = {
              $nin: filterItem.payload,
            };
          } else if (filterItem.filterType === 'exist') {
            result[dataIndex] = {
              $type: 'string',
            };
          } else if (filterItem.filterType === 'notExist') {
            addNotExistToResult(result, dataIndex, 'string');
          }

          return;
        }

        case 'date': {
          if (filterItem.filterType === 'equal') {
            const { beforeDate, afterDate } = getDayRange(filterItem.payload);
            result[dataIndex] = {
              $gte: beforeDate,
              $lte: afterDate,
            };
          } else if (filterItem.filterType === 'between') {
            const { min, max } = filterItem.payload;
            result[dataIndex] = {
              $gte: min ? getBeforeDate(min) : undefined,
              $lte: max ? getAfterDate(max) : undefined,
            };
          } else if (filterItem.filterType === 'exist') {
            result[dataIndex] = {
              $type: 'date',
            };
          } else if (filterItem.filterType === 'notExist') {
            addNotExistToResult(result, dataIndex, 'date');
          }
          return;
        }

        case 'address': {
          const valueDataIndex = `${dataIndex}.value`;
          if (filterItem.filterType === 'equal') {
            result[valueDataIndex] = {
              $eq: filterItem.payload,
            };
          } else if (filterItem.filterType === 'includes') {
            result[valueDataIndex] = {
              $regex: filterItem.payload,
              $options: 'i',
            };
          } else if (filterItem.filterType === 'exist') {
            result[dataIndex] = {
              $type: 'object',
            };
          } else if (filterItem.filterType === 'notExist') {
            addNotExistToResult(result, dataIndex, 'object');
          }
          return;
        }

        case 'document': {
          if (filterItem.filterType === 'exist') {
            result[dataIndex] = {
              $type: 'objectId',
            };
          } else if (filterItem.filterType === 'notExist') {
            addNotExistToResult(result, dataIndex, 'objectId');
          }
          return;
        }

        case 'document-collection': {
          if (filterItem.filterType === 'exist') {
            result['$and'] = [
              ...(result['$and'] ?? []),
              {
                [dataIndex]: { $type: 'array' },
              },
              {
                [dataIndex]: { $not: { $size: 0 } },
              },
            ];
          } else if (filterItem.filterType === 'notExist') {
            result['$and'] = [
              ...(result['$and'] ?? []),
              {
                $or: [
                  { [dataIndex]: { $exists: false } },
                  { [dataIndex]: { $not: { $type: 'array' } } },
                  { [dataIndex]: { $size: 0 } },
                ],
              },
            ];
          }
          return;
        }
      }
    });

  return result;
};
