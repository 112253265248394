import React, { useMemo } from 'react';
import { ContractItem } from './ContractItem';
import { ContractSkeleton } from './ContractSkeleton';
import { ContractRecordResponse } from '@/api/Contracts/types';
import cn from 'classnames';
import './styles.scss';

type Props = {
  className?: string;
  style?: any;
  data: Array<ContractRecordResponse>;
  favorites: string[];
  onChangeIsFavorite: (id: string, newVal: boolean) => void;
  isLoading?: boolean;
};

export const ContractList: React.FC<Props> = React.memo((props) => {
  const { className, style, data, favorites, onChangeIsFavorite, isLoading } = props;

  const itemsWithFavorite = useMemo(() => {
    return data.map((x) => ({ ...x, isFavorite: favorites.includes(x._id) }));
  }, [data, favorites]);

  if (isLoading) {
    return (
      <div className={cn('contract-list', className)} style={style}>
        {Array.from({ length: 3 }).map((_, index) => (
          <ContractSkeleton key={index} />
        ))}
      </div>
    );
  }

  return (
    <div className={cn('contract-list', className)} style={style}>
      {itemsWithFavorite.map((item) => (
        <ContractItem
          key={item._id}
          item={item}
          onChangeIsFavorite={(newVal) => onChangeIsFavorite(item._id, newVal)}
        />
      ))}
    </div>
  );
});
