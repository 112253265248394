import { BatchFileDownloadWizardModal } from '@/components/modals/cadObjects/BatchFileDownloadWizardModal';
import { CreateCadObjectModal } from '@/components/modals/cadObjects/CreateCadObjectModal';
import { EditPdCollectionInCadModal } from '@/components/modals/cadObjects/EditPdCollectionInCadModal';
import { FieldFillingGenTechPlanModal } from '@/components/modals/cadObjects/FieldFillingGenTechPlanModal';
import { FieldFillingWizardModal } from '@/components/modals/cadObjects/FieldFillingWizardModal';
import { ImportCadObjectsFromXlsxModal } from '@/components/modals/cadObjects/ImportCadObjectsFromXlsxModal';
import { ImportOsModal } from '@/components/modals/cadObjects/ImportOsModal';
import { ImportRastrModal } from '@/components/modals/cadObjects/ImportRastrModal';
import { MonitoringGenModal } from '@/components/modals/cadObjects/MonitoringGenWizardModal';
import { EditContractColumnsModal } from '@/components/modals/contracts/EditContractColumnsModal';
import { EditContractModal } from '@/components/modals/contracts/EditContractModal';
import { MultipleActionModal } from '@uspect-team/ant-ui-kit';
import { useStore } from 'effector-react';
import React from 'react';
import {
  $batchFileDownloadWizardModalState,
  $createCadObjectModalState,
  $editContractColumnsModalState,
  $editContractModalState,
  $editPdCollectionModalState,
  $fieldFillingWizardModalState,
  $genTechPlanModalState,
  $importCadObjectsFromXlsxModalState,
  $importOsModalState,
  $importRastrModalState,
  $monitoringGenWizardModalState,
  $multipleActionModalState,
} from '../model';

const _MainTablePageModals: React.FC = () => {
  const createCadObjectModalState = useStore($createCadObjectModalState);
  const editContractModalState = useStore($editContractModalState);
  const editContractColumnsModalState = useStore($editContractColumnsModalState);
  const importCadObjectsFromXlsxModalState = useStore($importCadObjectsFromXlsxModalState);
  const importOsModalState = useStore($importOsModalState);
  const importRastrModalState = useStore($importRastrModalState);
  const fieldFillingWizardModalState = useStore($fieldFillingWizardModalState);
  const batchFileDownloadWizardModalState = useStore($batchFileDownloadWizardModalState);
  const editPdCollectionModalState = useStore($editPdCollectionModalState);
  const multipleActionModalState = useStore($multipleActionModalState);
  const monitoringGenWizardModalState = useStore($monitoringGenWizardModalState);
  const genTechPlanModalState = useStore($genTechPlanModalState);
  return (
    <>
      <CreateCadObjectModal {...createCadObjectModalState} />
      <EditContractModal {...editContractModalState} />
      <EditContractColumnsModal {...editContractColumnsModalState} />
      <ImportCadObjectsFromXlsxModal {...importCadObjectsFromXlsxModalState} />
      <ImportOsModal {...importOsModalState} />
      <ImportRastrModal {...importRastrModalState} />
      <FieldFillingWizardModal {...fieldFillingWizardModalState} />
      <BatchFileDownloadWizardModal {...batchFileDownloadWizardModalState} />
      <MonitoringGenModal {...monitoringGenWizardModalState} />
      <FieldFillingGenTechPlanModal {...genTechPlanModalState} />
      <EditPdCollectionInCadModal {...editPdCollectionModalState} />
      <MultipleActionModal {...multipleActionModalState} />
    </>
  );
};
export const MainTablePageModals = React.memo(_MainTablePageModals);
