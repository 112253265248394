import React from 'react';
import cn from 'classnames';
import './styles.scss';
import { Dropdown, DropDownProps } from 'antd';

export type HeaderButtonProps = {
  className?: string;
  style?: React.CSSProperties;
  Icon: React.ComponentType<{ className?: string }>;
  size?: 'middle' | 'large';
} & DropDownProps;

export const HeaderIconButton: React.FC<HeaderButtonProps> = (props) => {
  const { className, style, Icon, size = 'middle', ...dropdownProps } = props;

  return (
    <Dropdown {...dropdownProps}>
      <div
        className={cn(
          'header-icon-button',
          { 'header-icon-button--large': size === 'large' },
          className,
        )}
        style={style}
      >
        <Icon className='header-icon-button__icon' />
      </div>
    </Dropdown>
  );
};
