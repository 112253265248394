import { RecordId } from '@/api/common/types';
import { ListWithTitle } from '@/components/ListWithTitle';
import { ExecItem, HistoryItem } from '@/globalStorage/utils/type';
import React from 'react';
import { StepContent } from '../../../StepContent';
import { ExecItemView } from '../../common/ExecItemView';
import { ExecInProcessContainer } from '../../common/ExecInProcessContainer';
import { HistoryCadObjectExecTable } from '../../common/HistoryCadObjectExecTable';
import { QueueCadObject } from '../../common/QueueCadObject';

export type AutoRunModalInProcessProps = {
  execItem: ExecItem<number> | null;
  contractId: RecordId;
  queue: number[];
  history: HistoryItem<number>[];
};

const _AutoRunModalInProcess: React.FC<AutoRunModalInProcessProps> = (props) => {
  const { execItem, queue, history } = props;

  return (
    <StepContent>
      <ExecInProcessContainer>
        <ExecInProcessContainer.Queue>
          <ListWithTitle title='Очередь:'>
            {queue.length > 0
              ? queue.map((x) => <QueueCadObject key={x} cadObjectNumber={x} />)
              : 'Очередь пуста'}
          </ListWithTitle>
        </ExecInProcessContainer.Queue>
        <ExecInProcessContainer.ExecItem>
          {execItem && (
            <ListWithTitle title={'Исполняемый объект:'}>
              <ExecItemView execItem={execItem} renderItem={(i) => i} />
            </ListWithTitle>
          )}
        </ExecInProcessContainer.ExecItem>
        <ExecInProcessContainer.History>
          <HistoryCadObjectExecTable history={history} />
        </ExecInProcessContainer.History>
      </ExecInProcessContainer>
    </StepContent>
  );
};
export const AutoRunModalInProcess = React.memo(_AutoRunModalInProcess);
