import React, { useCallback, useState } from 'react';
import { message, Modal, notification } from 'antd';
import { RcFile } from 'antd/lib/upload';
import { FileLoader } from '@uspect-team/ant-ui-kit';
import { FileCard } from '@/components/FileCard';
import { AntdLabelMimic } from '@/components/formHelpers/AntdLabelMimic';
import { ExternalLink } from '@/components/ExternalLink';
import { useCadObjectsImportRastrM } from '@/hooks/cadastralObjects';
import { RecordId } from '@/api/common/types';
import example_svg from '@/assets/images/jpg_tab_import_example.svg';
import { ImportBase } from '@/components/modals/cadObjects/common/ImportBase';

export type ImportRastrModalProps =
  | {
      visible: false;
    }
  | ({ visible: true } & VisibleProps);
type VisibleProps = {
  contractId: RecordId;
  onClose: () => void;
};

export const ImportRastrModal: React.FC<ImportRastrModalProps> = React.memo((props) => {
  const { visible } = props;

  if (!visible) {
    return null;
  }

  const { visible: _, ...visibleProps } = props;
  return <VisibleModal {...visibleProps} />;
});

export const VisibleModal: React.FC<VisibleProps> = React.memo((props) => {
  const { onClose, contractId } = props;
  const [rastrZipFile, setRastrFile] = useState<RcFile | null>(null);
  const { mutateAsync: importRastrM, isLoading: isLoadingImportRastr } =
    useCadObjectsImportRastrM();

  const onImport = useCallback(async () => {
    if (!rastrZipFile) {
      message.error('Пожалуйста, загрузите zip-файл');
      return;
    }
    try {
      await importRastrM({ contractId, file: rastrZipFile });
      onClose();
      message.success('Jpg/tab успешно импортированны из zip-файла');
    } catch (e) {
      notification.open({
        type: 'error',
        message: 'Произошла ошибка при импроте',
        description: 'Пожалуйста, проверте, правильно ли заполненен zip-файл',
      });
    }
  }, [rastrZipFile, contractId, importRastrM, onClose]);

  return (
    <Modal
      visible={true}
      width={600}
      onCancel={onClose}
      title={'Множественный импорт jpg/tab файлов (растров)'}
      onOk={onImport}
      okText={'Импортировать'}
      okButtonProps={{ disabled: rastrZipFile === null, loading: isLoadingImportRastr }}
    >
      <ImportBase>
        <ImportBase.Description>
          <ImportBase.Description.Example img={example_svg} />
          <ImportBase.Description.Title>
            Какой архив нужен для импорта?
          </ImportBase.Description.Title>
          <ImportBase.Description.List>
            <ImportBase.Description.List.Item>
              Название каждой папки должно соответствовать номеру объекта по договору в контракте
            </ImportBase.Description.List.Item>
            <ImportBase.Description.List.Item>
              В каждой папке должны находиться файлы:
              <br />
              {'{номер}'}.<strong>jpg</strong> – обязательный файл; <br />
              {'{номер}'}.<strong>tab</strong> – обязательный файл; <br />
              <span style={{ fontWeight: 200 }}>
                Номера названий файлов должны совпадать с номером папки
              </span>
            </ImportBase.Description.List.Item>
            <ImportBase.Description.List.Item>
              Архив не должен содержать других файлов
            </ImportBase.Description.List.Item>
            <ImportBase.Description.List.Item>
              Архив не должен быть повреждён или зашифрован
            </ImportBase.Description.List.Item>
          </ImportBase.Description.List>
        </ImportBase.Description>
      </ImportBase>

      <AntdLabelMimic addition={<ExternalLink path={'#'}>Пример ZIP-архива</ExternalLink>}>
        Загрузите архив со структурой, описанной выше
      </AntdLabelMimic>

      {rastrZipFile && (
        <FileCard
          title={'Загружаемый файл'}
          file={{ name: rastrZipFile.name, size: rastrZipFile.size, uploadAt: 'now' }}
          onDelete={() => setRastrFile(null)}
        />
      )}

      <FileLoader allowedFiles={[['zip']]} onUpload={setRastrFile} />
    </Modal>
  );
});
