import { AppSelect } from '@/components/inputs/AppSelect';
import { SelectProps } from 'antd';
import React from 'react';
import { WORKERS_COUNT_SELECT_OPTIONS } from './constants';

export type WorkerCountSelectProps = Omit<SelectProps, 'options'>;

const _WorkerCountSelect: React.FC<WorkerCountSelectProps> = (props) => {
  return <AppSelect options={WORKERS_COUNT_SELECT_OPTIONS} {...props} />;
};
export const WorkerCountSelect = React.memo(_WorkerCountSelect);
