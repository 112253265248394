import cn from 'classnames';
import React from 'react';
import logoImg from '../../../assets/images/logo_bg.png';
import { GuestLayoutContentContainer } from './components/ContentContainer';
import { GuestLayoutContentHeader } from './components/ContentHeader';
import { ContentWithFooterContainer } from './components/ContentWithFooterContainer';
import { DefaultGuestLayoutFooter } from './components/DefaultGuestLayoutFooter';
import { GuestLayoutDescription } from './components/Description';
import { GuestLayoutFooterContainer } from './components/FooterContainer';
import { GuestLayoutFooterLink } from './components/FooterLink';
import { Header } from './components/Header';
import { GuestLayoutTitle } from './components/Title';
import './styles.scss';

export type GuestLayoutProps = {
  className?: string;
  style?: React.CSSProperties;
  children?: React.ReactNode;
  bgElement?: React.ReactNode;
  noScroll?: boolean;
};

const GuestLayout: React.FC<GuestLayoutProps> & {
  ContentWithFooterContainer: typeof ContentWithFooterContainer;
  ContentContainer: typeof GuestLayoutContentContainer;
  FooterContainer: typeof GuestLayoutFooterContainer;
  FooterLink: typeof GuestLayoutFooterLink;
  DefaultFooter: typeof DefaultGuestLayoutFooter;
  Title: typeof GuestLayoutTitle;
  Description: typeof GuestLayoutDescription;
  ContentHeader: typeof GuestLayoutContentHeader;
} = (props) => {
  const { className, style, children, bgElement, noScroll } = props;
  return (
    <div
      className={cn('guest-layout', className, { 'guest-layout--no-scroll': noScroll })}
      style={style}
    >
      <div className='guest-layout__image-container'>
        <img className={'guest-layout__image'} src={logoImg} alt='logo background' />
      </div>

      {bgElement && <div className='guest-layout__bg-container'>{bgElement}</div>}

      <div className='guest-layout__panel'>
        <Header className={'guest-layout__header'} />
        <div className='guest-layout__content'>{children}</div>
      </div>
    </div>
  );
};

GuestLayout.ContentWithFooterContainer = ContentWithFooterContainer;
GuestLayout.ContentContainer = GuestLayoutContentContainer;
GuestLayout.FooterContainer = GuestLayoutFooterContainer;
GuestLayout.FooterLink = GuestLayoutFooterLink;
GuestLayout.DefaultFooter = DefaultGuestLayoutFooter;
GuestLayout.Title = GuestLayoutTitle;
GuestLayout.Description = GuestLayoutDescription;
GuestLayout.ContentHeader = GuestLayoutContentHeader;

export { GuestLayout };
