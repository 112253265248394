import {
  TdCreateRequest,
  TdCreateResponse,
  TdGetByIdResponse,
  GetTdPageRequest,
  GetTdPageResponse,
} from './types';
import { api } from '../axiosInstance';

export class TdApi {
  get(params: GetTdPageRequest) {
    return api.get<GetTdPageResponse>('/td', { params });
  }

  getById(id: string) {
    return api.get<TdGetByIdResponse>(`/td/${id}`);
  }

  async create(data: TdCreateRequest) {
    const formData = new FormData();

    formData.append('file', data.file);

    formData.append('title', data.title);
    formData.append('technokadCode', data.technokadCode);
    data.contractId && formData.append('contractId', data.contractId);
    data.series && formData.append('series', data.series);
    data.number && formData.append('number', data.number);
    data.ispOrg && formData.append('ispOrg', data.ispOrg);
    data.date && formData.append('date', data.date.toDateString());

    return await api.post<TdCreateResponse>('/td', formData, {
      headers: { 'Content-Type': 'multipart/form-data' },
    });
  }
}
