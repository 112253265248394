import { handleFilter } from '@/utils/filterUtils';
import { ControlledTable, DateView } from '@uspect-team/ant-ui-kit';
import { ControlledTableState } from '@uspect-team/ant-ui-kit/dist/Tables/ControlledTable/types';
import { Col, Space, Typography } from 'antd';
import React, { useState } from 'react';
import { Link } from 'react-router-dom';
import './styles.scss';

export interface IManagementFinanceRightCardProps {}

const { Text, Title } = Typography;

enum EManagementFinanceTypeOperation {
  TopUp = '↑ Пополнение',
  WriteOff = '↓ Списание',
}

const TempData = new Array(5).fill({
  type: '↑ Пополнение',
  description: 'description',
  total: 500,
  docConnect: { checkLink: 'check', actLink: 'act' },
  createdAt: new Date(),
});
const _ManagementFinanceRightCard: React.FC<IManagementFinanceRightCardProps> = () => {
  const tableState = useState<ControlledTableState>({
    pagination: { currentPage: 1, pageSize: 10 },
  });

  const columns = [
    {
      title: 'Тип операции',
      dataIndex: 'type',
      key: 'type',
      sortable: true,
      filterDropdown: handleFilter,
      render: (value: EManagementFinanceTypeOperation) => (
        <Text type={value === EManagementFinanceTypeOperation.TopUp ? 'success' : 'danger'}>
          {value}
        </Text>
      ),
    },
    {
      title: 'Описание',
      dataIndex: 'description',
      key: 'description',
      sortable: true,
      filterDropdown: handleFilter,
    },
    {
      title: 'Сумма',
      dataIndex: 'total',
      key: 'total',
      sortable: true,
      filterDropdown: handleFilter,
    },
    {
      title: 'Связанные документы',
      dataIndex: 'docConnect',
      key: 'docConnect',
      sortable: true,
      filterDropdown: handleFilter,
      render: (value: { checkLink: string; actLink: string }) => (
        <Space>
          <Link to={value.checkLink}>{value.checkLink}</Link>
          <Link to={value.actLink}>{value.actLink}</Link>
        </Space>
      ),
    },
    {
      title: 'Дата операции',
      dataIndex: 'createdAt',
      key: 'createdAt',
      sortable: true,
      filterDropdown: handleFilter,
      render: () => <DateView value={new Date()} />,
    },
  ];

  return (
    <Col span={16} className='management-finance__right-card paper'>
      <Title level={3}>История операций</Title>
      <ControlledTable
        columns={columns}
        dataSource={TempData}
        tableState={tableState[0]}
        pagination={{
          type: 'none',
        }}
        rowKey={'_id'}
      />
    </Col>
  );
};
export const ManagementFinanceRightCard = React.memo(_ManagementFinanceRightCard);
