import { TdRecordResponse } from '@/api/Td/types';
import { Checkbox, message, Modal } from 'antd';
import React, { useCallback, useState } from 'react';
import { ControlledTable } from '@uspect-team/ant-ui-kit';
import { ControlledTableState } from '@uspect-team/ant-ui-kit/dist/Tables/ControlledTable/types';
import { getPdColumns } from '@/components/modals/utils/pdColumns';
import { RecordId } from '@/api/common/types';
import { SelectAlreadyExistBase } from '@/components/modals/common/SelectAlreadyExistBase';
import { useGetTdPageQ } from '@/hooks/td';

export type SelectAlreadyExistPdModalProps =
  | {
      visible: false;
    }
  | ({ visible: true } & VisibleProps);

type VisibleProps = {
  title: string;
  contractId?: RecordId;
  onClose: () => void;
  onSelectPd: (pd: TdRecordResponse) => void;
};

const columns = getPdColumns();

export const SelectAlreadyExistPdModal: React.FC<SelectAlreadyExistPdModalProps> = (props) => {
  const { visible } = props;
  if (!visible) {
    return null;
  }
  const { visible: _, ...visibleProps } = props;
  return <VisibleModal {...visibleProps} />;
};

const VisibleModal: React.FC<VisibleProps> = React.memo((props) => {
  const { title, onClose, onSelectPd, contractId } = props;

  const [tableState, setTableState] = useState<ControlledTableState>({
    pagination: {
      currentPage: 1,
      pageSize: 20,
    },
  });

  const [isCurrentContract, setIsCurrentContract] = React.useState<boolean>(true);

  const { data, isLoading } = useGetTdPageQ({
    page: tableState.pagination?.currentPage,
    limit: tableState.pagination?.pageSize,
    filter: contractId && isCurrentContract ? { contractId } : undefined,
  });
  const [selectedItem, setSelectedItem] = useState<TdRecordResponse | null>(null);

  const onOkHandler = useCallback(() => {
    if (selectedItem) {
      onSelectPd(selectedItem);
      onClose();
    } else {
      message.error('Пожалуйста, выберите файл');
    }
  }, [selectedItem, onSelectPd, onClose]);

  return (
    <Modal
      visible={true}
      title={title}
      okText={'Выбрать'}
      onOk={onOkHandler}
      okButtonProps={{ disabled: selectedItem === null }}
      onCancel={onClose}
      cancelText={'Отмена'}
      width={1000}
    >
      <SelectAlreadyExistBase>
        {contractId && (
          <SelectAlreadyExistBase.Filters>
            <Checkbox
              checked={isCurrentContract}
              onChange={(e) => setIsCurrentContract(e.target.checked)}
            >
              Файлы этого контракта
            </Checkbox>
          </SelectAlreadyExistBase.Filters>
        )}
        <SelectAlreadyExistBase.Table>
          <ControlledTable
            rowKey={'_id'}
            tableState={tableState}
            onChangeTableState={setTableState}
            bordered
            columns={columns}
            dataSource={data?.data.data || []}
            totalItems={data?.data.meta.count || 0}
            loading={isLoading}
            pagination={{ type: 'server' }}
            rowSelection={{
              onChange: (_, selectedRows) => {
                if (selectedRows[0]) {
                  setSelectedItem(selectedRows[0]);
                } else {
                  setSelectedItem(null);
                }
              },
              selectedRowKeys: selectedItem ? [selectedItem._id] : undefined,
              fixed: true,
              type: 'radio',
            }}
            tableLayout='fixed'
            scroll={{ x: '100%' }}
          />
        </SelectAlreadyExistBase.Table>
      </SelectAlreadyExistBase>
    </Modal>
  );
});
