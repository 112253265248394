import { RecordId } from '@/api/common/types';
import { GetTpPageRequest } from '@/api/Coordinates/Tp/types';

export const tpQueryKeys = {
  all: ['tp'] as const,
  pages: () => [...tpQueryKeys.all, 'page'] as const,
  page: (data: GetTpPageRequest) => [...tpQueryKeys.pages(), data] as const,

  details: () => [...tpQueryKeys.all, 'detail'] as const,
  detail: (id: RecordId) => [...tpQueryKeys.details(), id] as const,

  create: () => [...tpQueryKeys.all, 'create'] as const,
  patch: () => [...tpQueryKeys.all, 'patch'] as const,
  delete: () => [...tpQueryKeys.all, 'delete'] as const,
};
