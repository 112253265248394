import React, { useCallback } from 'react';
import { Button, Form, message, Modal } from 'antd';
import { useCreateTpM } from '@/hooks/coordinates/tp';
import { CreateTpFormType } from '@/components/modals/tps/CreateTpModal/types';
import { CreateTpRequest } from '@/api/Coordinates/Tp/types';
import { TpFields } from '@/components/modals/tps/common/TpFields';
import {
  CoordsImportModal,
  CoordsImportModalProps,
} from '@/components/modals/tps/CoordsImportModal';

export type CreateTpModalProps =
  | {
      visible: false;
    }
  | ({ visible: true } & VisibleProps);

type VisibleProps = {
  onClose: () => void;
};

const _CreateTpModal: React.FC<CreateTpModalProps> = (props) => {
  const { visible } = props;

  if (!visible) {
    return null;
  }
  const { visible: _, ...visibleProps } = props;
  return <VisibleModal {...visibleProps} />;
};
export const CreateTpModal = React.memo(_CreateTpModal);

const _VisibleModal: React.FC<VisibleProps> = (props) => {
  const { onClose } = props;

  const [coordsImportModalState, setCoordsImportModalState] =
    React.useState<CoordsImportModalProps>({ visible: false });

  const createTpM = useCreateTpM();

  const [form] = Form.useForm<CreateTpFormType>();

  const onOpenImportCoordsHandler = React.useCallback(() => {
    setCoordsImportModalState({
      visible: true,
      onClose: () => setCoordsImportModalState({ visible: false }),
      onSuccess: (coords) => form.setFieldsValue({ coords }),
    });
  }, [form]);

  const onFinishForm = useCallback(
    async (values: CreateTpFormType) => {
      try {
        const createReq = values as CreateTpRequest;
        try {
          await createTpM.mutateAsync(createReq);
          onClose();
        } catch (e) {
          message.error(`При создании ТП произошла ошибка: ${(e as Error).message}`);
        }
      } catch (e) {
        message.error('Есть невалидные поля');
      }
    },
    [createTpM, onClose],
  );

  const { submit } = form;

  return (
    <Modal
      width={1000}
      visible={true}
      onCancel={onClose}
      title={'Создание ТП'}
      okText={'Создать'}
      okButtonProps={{ loading: createTpM.isLoading }}
      onOk={submit}
    >
      <Form
        form={form}
        layout={'vertical'}
        wrapperCol={{ span: 24 }}
        requiredMark={'optional'}
        onFinish={onFinishForm}
        initialValues={{ isActive: true, isAutoCalculateWgs: true }}
      >
        <TpFields />
      </Form>
      <Button onClick={onOpenImportCoordsHandler}>Импорт из excel</Button>
      <CoordsImportModal {...coordsImportModalState} />
    </Modal>
  );
};
const VisibleModal = React.memo(_VisibleModal);
