import { StatisticsGetResponse } from './types';
import { api } from '../axiosInstance';
import { RecordId } from '@/api/common/types';

const rootUrl = '/statistics';

export class StatisticsApi {
  public get(contractId: RecordId) {
    return api.get<StatisticsGetResponse>(`${rootUrl}/${contractId}`);
  }

  public sendEmails(contractId: RecordId) {
    return api.get<StatisticsGetResponse>(`${rootUrl}/${contractId}/email`);
  }
}
