import { CadObjectStatus } from '@/api/CadObjects/types';
import { Tag } from 'antd';
import cn from 'classnames';
import React from 'react';
import { statusValueLabelMap } from './statusValueLabelMap';
import './style.scss';

type Props = {
  value: CadObjectStatus;
  className?: string;
  style?: any;
};

const _StatusTag: React.FC<Props> = (props) => {
  const { className, style, value } = props;

  return (
    <Tag className={cn('status-tag', className)} style={style}>
      {statusValueLabelMap.get(value) ?? 'Неизвестный статус'}
    </Tag>
  );
};

export const StatusTag = React.memo(_StatusTag);
