import { FileRecordResponse } from '@/api/Files/types';
import { openSelectAlreadyExistFileModal, openUploadFileModal } from '@/globalStorage/file';
import { CloudUploadOutlined, UnorderedListOutlined, UploadOutlined } from '@ant-design/icons/lib';
import { EditableDocument } from '@uspect-team/ant-ui-kit';
import { Button, Dropdown, Menu } from 'antd';
import React from 'react';

type Props = {
  className?: string;
  style?: any;
  value?: FileRecordResponse;
  onChange?: (value?: FileRecordResponse) => void;
  block?: boolean;
};
export const TemplateMonitoringField: React.FC<Props> = React.memo((props) => {
  const { className, style, value, onChange, block } = props;

  const onUploadClickHandler = React.useCallback(() => {
    openUploadFileModal({
      fileType: 'templateMonitoring',
      onUpload: (file) => {
        if (onChange) {
          onChange(file);
        }
      },
    });
  }, [onChange]);

  const onSelectAlreadyExistClickHandler = React.useCallback(() => {
    openSelectAlreadyExistFileModal({
      fileType: 'templateMonitoring',
      title: 'Выбор существующего шаблона мониторинга',
      onSelectFile: (file) => {
        if (onChange) {
          onChange(file);
        }
      },
    });
  }, [onChange]);

  return (
    <>
      <EditableDocument
        className={className}
        style={style}
        block={block}
        document={
          value
            ? {
                name: value.fileName,
                uploadOn: new Date(value.createdAt),
                size: value.size,
                link: value.fileUri,
              }
            : undefined
        }
        onDelete={() => {
          if (onChange) {
            onChange(undefined);
          }
        }}
        actions={
          <Dropdown
            overlay={
              <Menu>
                <Menu.Item
                  key={'load'}
                  icon={<CloudUploadOutlined />}
                  onClick={onUploadClickHandler}
                >
                  Загрузить шаблон
                </Menu.Item>
                <Menu.Item
                  key={'exist'}
                  icon={<UnorderedListOutlined />}
                  onClick={onSelectAlreadyExistClickHandler}
                >
                  Выбрать из существующих
                </Menu.Item>
              </Menu>
            }
          >
            <Button icon={<UploadOutlined />} />
          </Dropdown>
        }
      />
    </>
  );
});
