import { TpRecordResponse } from '@/api/Coordinates/Tp/types';
import { coordinateService } from '@/globalStorage/coordinates/newIndex';
import { GroupShape, PointShape, PolygonShape } from '@uspect-team/ant-ui-kit';
import { useStore } from 'effector-react';
import React from 'react';

export type TpViewProps = {
  tp: TpRecordResponse;
};

const _TpView: React.FC<TpViewProps> = ({ tp }) => {
  const points = tp.coords.map((point) => ({ x: point.mskX, y: point.mskY }));

  const selectedTpId = useStore(coordinateService.$selectedTpId);
  const isSelectedTp = selectedTpId ? tp._id === selectedTpId : false;

  return (
    <GroupShape onClick={() => coordinateService.selectTp({ tpId: tp._id })}>
      <PolygonShape points={points} fill={isSelectedTp ? '#2c44d7' : '#7084f3'} fillOpacity={0.3} />
      {points.map((point, index) => {
        return (
          <PointShape
            key={index}
            point={point}
            radius={isSelectedTp ? 5 : 4}
            fill={isSelectedTp ? '#7084f3' : 'white'}
            stroke={isSelectedTp ? '#2c44d7' : '#5771ea'}
            strokeWidth={1}
          />
        );
      })}
    </GroupShape>
  );
};
export const TpView = React.memo(_TpView);
