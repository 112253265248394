import { RecordId } from '@/api/common/types';
import { $isNotGuest } from '@/globalStorage/user';
import { useGetContractStatistic, useSendContractStatisticEmails } from '@/hooks/statistics';
import { Button, message } from 'antd';
import { useStore } from 'effector-react';
import React from 'react';
import { StatusPropItem } from './StatusPropItem';
import './styles.scss';

export type DetailStatisticOverlayProps = {
  className?: string;
  style?: React.CSSProperties;

  contractId: RecordId;
};

const _DetailStatisticOverlay: React.FC<DetailStatisticOverlayProps> = (props) => {
  const { contractId } = props;
  const { data: contractStatistic, isLoading } = useGetContractStatistic(contractId);
  const { mutateAsync: sendStatisticEmailsM, isLoading: isLoadingSendStat } =
    useSendContractStatisticEmails();

  const isNotGuest = useStore($isNotGuest);

  const onSendStatistikEmailClickHandler = React.useCallback(async () => {
    try {
      await sendStatisticEmailsM(contractId);
      message.success('Статистика успешно отправлена по Email');
    } catch {
      message.error('Не удалось отправить статистику по Email');
    }
  }, [contractId, sendStatisticEmailsM]);

  return (
    <div className='contract-statistic-overlay'>
      <div className='contract-statistic-overlay__header'>
        <div className='contract-statistic-overlay__title'>Статистика по статусам объектов</div>
        {isNotGuest && (
          <div className='contract-statistic-overlay__actions'>
            <Button
              onClick={onSendStatistikEmailClickHandler}
              disabled={isLoadingSendStat}
              loading={isLoadingSendStat}
            >
              Отправить статистику по Email
            </Button>
          </div>
        )}
      </div>

      <div className='contract-statistic-overlay__stat-list'>
        {isLoading || !contractStatistic?.statuses
          ? 'Загрузка...'
          : contractStatistic.statuses.map((stat) => {
              return (
                <StatusPropItem
                  key={stat.status}
                  status={stat.status}
                  count={stat.count}
                  percent={stat.percent}
                  comment={stat.comment}
                />
              );
            })}
      </div>
    </div>
  );
};
export const DetailStatisticOverlay = React.memo(_DetailStatisticOverlay);
