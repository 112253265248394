export const getCadastralPlotsKeys = (obj: any) => {
  const keys = Object.keys(obj);
  const result: any[] = [];

  keys.forEach((key) => {
    if (key.startsWith('cadastralPlots')) {
      result.push({ key: key, value: obj[key] });
    }
  });

  return result;
};

export const getCadastralPlots = (obj: any) => {
  const arrMain = getCadastralPlotsKeys(obj);

  const resultMap = arrMain.reduce((acc, obj) => {
    const keyParts = obj.key.split('.');
    const lastKey = keyParts[keyParts.length - 1];
    const arrayIndex = keyParts[0].match(/\[(\d+)\]/)[1];

    if (!acc[arrayIndex]) {
      acc[arrayIndex] = {};
    }

    acc[arrayIndex][lastKey] = obj.value;
    return acc;
  }, []);

  return resultMap;
};

export const convertToFormCadastral = (arr: any[]) => {
  return arr.reduce((acc, obj, index) => {
    acc[`cadastralPlots[${index}].cadastralNumber`] = obj.cadastralNumber;
    if (obj.landLocationInfo) {
      acc[`cadastralPlots[${index}].landLocationInfo`] = obj.landLocationInfo;
    }
    if (obj.landCategoryId) {
      acc[`cadastralPlots[${index}].landCategoryId`] = obj.landCategoryId;
    }
    if (obj.kindUses) {
      acc[`cadastralPlots[${index}].kindUses`] = obj.kindUses;
    }
    if (obj.landUsesInfo) {
      acc[`cadastralPlots[${index}].landUsesInfo`] = obj.landUsesInfo;
    }
    if (obj.reestrBorderNum) {
      acc[`cadastralPlots[${index}].reestrBorderNum`] = obj.reestrBorderNum;
    }
    if (obj.locatedNumberProperty) {
      acc[`cadastralPlots[${index}].locatedNumberProperty`] = obj.locatedNumberProperty;
    }
    if (obj.plotConditionalNumber) {
      acc[`cadastralPlots[${index}].plotConditionalNumber`] = obj.plotConditionalNumber;
    }
    if (obj.projectNumber) {
      acc[`cadastralPlots[${index}].projectNumber`] = obj.projectNumber;
    }
    if (obj.landAccessProvided) {
      acc[`cadastralPlots[${index}].landAccessProvided`] = obj.landAccessProvided;
    }
    if (obj.other) {
      acc[`cadastralPlots[${index}].other`] = obj.other;
    }
    return acc;
  }, {});
};

export const findLabels = (arrSearch: any[], arrStart: any[]) => {
  const outputArray = [];
  const lookupArray = arrSearch;
  const inputArray = arrStart;

  if (!inputArray?.length) {
    return [];
  }
  for (let i = 0; i < inputArray.length; i++) {
    for (let j = 0; j < lookupArray.length; j++) {
      if (lookupArray[j].value === inputArray[i]) {
        outputArray.push(lookupArray[j].label);
      }
    }
  }

  return outputArray;
};

export const findValues = (arrSearch: any[], arrStart: any[]) => {
  const outputArray = [];
  const lookupArray = arrSearch;
  const inputArray = arrStart;
  for (let i = 0; i < inputArray.length; i++) {
    for (let j = 0; j < lookupArray.length; j++) {
      if (lookupArray[j].label === inputArray[i]) {
        outputArray.push(lookupArray[j].value);
      }
    }
  }

  return outputArray;
};
