import { ControlledTableColumns } from '@uspect-team/ant-ui-kit/dist/Tables/ControlledTable/types';
import { DotsMenu } from '@/components/DotsMenu';
import React from 'react';
import { Menu } from 'antd';

export type AdditionalRecordAction<RecordType> = {
  key: React.Key;
  title: React.ReactNode;
  danger?: boolean;
  icon?: React.ReactNode;
  onClick?: (record: RecordType) => void;
};
export type AdditionalGroupRecordAction<RecordType> = {
  key: React.Key;
  title: React.ReactNode;
  children: AdditionalRecordAction<RecordType>[];
};

export type AdditionalAnyRecordAction<RecordType> =
  | AdditionalGroupRecordAction<RecordType>
  | AdditionalRecordAction<RecordType>;

function isGroupAction<RecordType>(
  obj: AdditionalAnyRecordAction<RecordType>,
): obj is AdditionalGroupRecordAction<RecordType> {
  return (obj as Record<string, unknown>).children !== undefined;
}

function mapAnyAction<RecordType>(
  action: AdditionalAnyRecordAction<RecordType>,
  record: RecordType,
) {
  if (isGroupAction(action)) {
    const { children, ...actionWithoutChildren } = action;
    const includedItems = children.map((childAct) => mapAnyAction(childAct, record));

    return <Menu.SubMenu {...actionWithoutChildren}>{includedItems}</Menu.SubMenu>;
  } else {
    return (
      <Menu.Item
        {...action}
        onClick={() => {
          action.onClick?.(record);
        }}
      >
        {action.title}
      </Menu.Item>
    );
  }
}

export function getCrudColumns<RecordType>(params: {
  recordColumns: ControlledTableColumns<RecordType>;
  onEditClick: (record: RecordType) => void;
  onDeleteClick: (record: RecordType) => void;
  additionalRecordActions?: (
    | AdditionalGroupRecordAction<RecordType>
    | AdditionalRecordAction<RecordType>
  )[];
}): ControlledTableColumns<RecordType> {
  return [
    ...params.recordColumns,
    {
      title: ' ',
      dataIndex: 'actions',
      fixed: 'right',
      align: 'center',
      width: 70,
      render: (_, record) => {
        return (
          <DotsMenu
            menu={
              <Menu>
                {params.additionalRecordActions && (
                  <>
                    {params.additionalRecordActions.map((action) => {
                      return mapAnyAction(action, record);
                    })}
                    <Menu.Divider />
                  </>
                )}
                <Menu.Item key={'edit'} onClick={() => params.onEditClick(record)}>
                  Редактировать
                </Menu.Item>
                <Menu.Item key={'delete'} danger onClick={() => params.onDeleteClick(record)}>
                  Удалить
                </Menu.Item>
              </Menu>
            }
          />
        );
      },
    },
  ];
}
