import { useMutation, useQuery, useQueryClient } from 'react-query';
import { GetCptPageRequest } from '@/api/Dictionary/Cpt/types';
import { Api } from '@/api';
import { RecordId } from '@/api/common/types';
import { cptQueryKeys } from '@/hooks/directory/cpt/queryKeys';
import { createUseFetchingStateObserver } from '@/hooks/utils';

export const useGetCptPageQ = (params: GetCptPageRequest) => {
  return useQuery(
    cptQueryKeys.page(params),
    () => {
      return Api.dictionary.cpt.get(params);
    },
    {
      select: (data) => data.data,
    },
  );
};

export const useCptPageFetchingState = createUseFetchingStateObserver(cptQueryKeys.pages());

export const useGetCptByIdQ = (id: RecordId) => {
  return useQuery(
    cptQueryKeys.detail(id),
    () => {
      return Api.dictionary.cpt.getById(id);
    },
    { select: (data) => data.data.data },
  );
};

//Mutations

export const useCreateCptM = () => {
  const queryClient = useQueryClient();
  return useMutation(cptQueryKeys.create(), Api.dictionary.cpt.create, {
    onSuccess: async (data) => {
      await queryClient.invalidateQueries(cptQueryKeys.pages());
      queryClient.setQueriesData(cptQueryKeys.detail(data.data.data._id), data);
    },
  });
};

export const usePatchCptM = () => {
  const queryClient = useQueryClient();

  return useMutation(cptQueryKeys.patch(), Api.dictionary.cpt.patch, {
    onSuccess: async (data) => {
      await queryClient.invalidateQueries(cptQueryKeys.pages());
      queryClient.setQueriesData(cptQueryKeys.detail(data.data.data._id), data);
    },
  });
};

export const useDeleteCptM = () => {
  const queryClient = useQueryClient();

  return useMutation(cptQueryKeys.delete(), Api.dictionary.cpt.delete, {
    onSuccess: async (_, variables) => {
      await Promise.all([
        await queryClient.invalidateQueries(cptQueryKeys.pages()),
        await queryClient.invalidateQueries(cptQueryKeys.detail(variables)),
      ]);
    },
  });
};
