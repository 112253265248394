import React from 'react';
import { Col, Form, Row } from 'antd';
import { CadQuarterInput } from '../CadQuarterInput';
import { errorMessages } from '@/utils/errorMessages';
import { cadQuarterValidator } from '@/utils/validators';
import { FileInput } from '@/components/modals/cpts/common/FileInput';

export const CptFields: React.FC = React.memo(() => {
  return (
    <>
      <Form.Item
        name={'cadQuarter'}
        label={'Кадастровый квартал'}
        rules={[
          { required: true, message: errorMessages.required },
          {
            validator: cadQuarterValidator,
          },
        ]}
      >
        <CadQuarterInput />
      </Form.Item>
      <Row gutter={18}>
        <Col span={12}>
          <Form.Item
            name={'fileXML'}
            label={'Файл XML'}
            rules={[{ required: true, message: errorMessages.required }]}
          >
            <FileInput fileType={'kptXML'} />
          </Form.Item>
        </Col>
        <Col span={12}>
          <Form.Item
            name={'fileMIF'}
            label={'Файл MIF'}
            rules={[{ required: true, message: errorMessages.required }]}
          >
            <FileInput fileType={'kptMIF'} />
          </Form.Item>
        </Col>
      </Row>
    </>
  );
});
