import React from 'react';
import cn from 'classnames';

import './styles.scss';

export type FilterDropdownFormContainerProps = {
  className?: string;
  style?: React.CSSProperties;
  children?: React.ReactNode;
};

const _FilterDropdownFormContainer: React.FC<FilterDropdownFormContainerProps> = (props) => {
  const { className, style, children } = props;
  return (
    <div className={cn('filter-dropdown-form-container', className)} style={style}>
      {children}
    </div>
  );
};
export const FilterDropdownFormContainer = React.memo(_FilterDropdownFormContainer);
