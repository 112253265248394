import type { TBoundaryInfoProps } from './types';
import { Row, Col, Form, Input, Select } from 'antd';
import React from 'react';
import { categoryPlane, typeUse } from '../../constants';

import './styles.scss';

const _BoundaryInfo = ({ data }: TBoundaryInfoProps) => {
  if (!data) {
    return <></>;
  }

  return (
    <div className='boundary-info__layout boundary-cadaster__form-layout'>
      <Row gutter={32}>
        <Col span={12}>
          <Form.Item label='Кад. номер исходного земельного участка:' name={'cadastralNumber'}>
            <Input />
          </Form.Item>
        </Col>
        <Col span={12}>
          <Form.Item label='Площадь исходного земельного участка:' name={'square'}>
            <Input />
          </Form.Item>
        </Col>
      </Row>
      <Row style={{ width: '100%' }}>
        <Col style={{ width: '100%' }}>
          <Form.Item
            label='Межевой план подготовлен в результате выполнения кад. работ в связи с:'
            name={'reason'}
          >
            <Input.TextArea style={{ width: '100%' }} />
          </Form.Item>
        </Col>
      </Row>
      <Row style={{ width: '100%' }}>
        <Col style={{ width: '100%' }}>
          <Form.Item label='Заключение кадастрового инженера:' name={'conclusion'}>
            <Input.TextArea style={{ width: '100%' }} />
          </Form.Item>
        </Col>
      </Row>
      <div className='boundary-cadaster'>
        {new Array(data.countOfEntities).fill(null).map((_, i) => (
          <div key={i} className='boundary-cadaster__layout'>
            <div className='boundary-cadaster__title'>
              <p>
                <span>{i + 1}</span> :ЗУ{i + 1}
              </p>
            </div>
            <>
              <Row gutter={12}>
                <Col span={12}>
                  <Form.Item
                    label='Кад. номер исходного земельного участка:'
                    name={`cadastralPlots[${i}].cadastralNumber`}
                  >
                    <Input />
                  </Form.Item>
                </Col>
                <Col span={12}>
                  <Form.Item
                    label='Доп. сведения о местоположении земельного участка:'
                    name={`cadastralPlots[${i}].landLocationInfo`}
                  >
                    <Input />
                  </Form.Item>
                </Col>
              </Row>
              <Row gutter={12}>
                <Col span={12}>
                  <Form.Item label='Категория земель:' name={`cadastralPlots[${i}].landCategoryId`}>
                    <Select options={categoryPlane} />
                  </Form.Item>
                </Col>
                <Col span={12}>
                  <Form.Item
                    label='Вид(-ы) разрешенного использования:'
                    name={`cadastralPlots[${i}].kindUses`}
                  >
                    <Select mode='multiple' options={typeUse} />
                  </Form.Item>
                </Col>
              </Row>

              <Row gutter={12}>
                <Col span={12}>
                  <Form.Item
                    label='Доп. сведения об использовании участка:'
                    name={`cadastralPlots[${i}].landUsesInfo`}
                  >
                    <Input />
                  </Form.Item>
                </Col>
                <Col span={12}>
                  <Form.Item
                    label='Реестровый номер границ тер. зоны:'
                    name={`cadastralPlots[${i}].reestrBorderNum`}
                  >
                    <Input />
                  </Form.Item>
                </Col>
              </Row>
              <Form.Item
                label='Кад. или иной гос. учетный номер объекта недвижимости, расположенного
        на образуемом зем. участке:'
                name={`cadastralPlots[${i}].locatedNumberProperty`}
              >
                <Input />
              </Form.Item>
              <Row gutter={12}>
                <Col span={12}>
                  <Form.Item
                    label='Условный номер участка:'
                    name={`cadastralPlots[${i}].plotConditionalNumber`}
                  >
                    <Input />
                  </Form.Item>
                </Col>
                <Col span={12}>
                  <Form.Item
                    label='Учетный номер проекта межевания:'
                    name={`cadastralPlots[${i}].projectNumber`}
                  >
                    <Input />
                  </Form.Item>
                </Col>
              </Row>
              <Form.Item
                label='Доп. сведения об образовании земельного участка:'
                name={`cadastralPlots[${i}].landPlotFormedInfo`}
              >
                <Input />
              </Form.Item>
              <Form.Item
                label='Сведения о зем. участках, посредством которых обеспечивается доступ:'
                name={`cadastralPlots[${i}].landAccessProvided`}
              >
                <Input />
              </Form.Item>
              <Form.Item label='Иные сведения:' name={`cadastralPlots[${i}].other`}>
                <Input />
              </Form.Item>
            </>
          </div>
        ))}
      </div>
    </div>
  );
};

export const BoundaryInfo = React.memo(_BoundaryInfo);
