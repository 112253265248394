const accessTokenKey = 'actus__access_token';

export class AuthLocalStorageRepo {
  public getAccessToken(): string | null {
    return localStorage.getItem(accessTokenKey);
  }

  public setAccessToken(token: string) {
    localStorage.setItem(accessTokenKey, token);
  }

  public removeAccessToken() {
    localStorage.removeItem(accessTokenKey);
  }
}

export const authLocalStorage = new AuthLocalStorageRepo();
