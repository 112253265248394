import React, { useCallback } from 'react';
import { Form, message, Modal } from 'antd';
import { useCreateEmployeeM } from '@/hooks/directory/employees';
import { CreateEmployeeFormType } from '@/components/modals/employee/CreateEmployeeModal/types';
import { EmployeesCreateRequest } from '@/api/Dictionary/Employees/types';
import { ManagementEmployeeFormFields } from '../common/EmployeeFormFields';

export type CreateEmployeeModalProps =
  | {
      visible: false;
    }
  | ({ visible: true } & VisibleProps);

type VisibleProps = {
  onClose: () => void;
};

export const CreateManagementEmployeeModal: React.FC<CreateEmployeeModalProps> = (props) => {
  const { visible } = props;

  if (!visible) {
    return null;
  }
  const { visible: _, ...visibleProps } = props;
  return <VisibleModal {...visibleProps} />;
};

const VisibleModal: React.FC<VisibleProps> = React.memo((props) => {
  const { onClose } = props;

  const createEmployeeM = useCreateEmployeeM();

  const [form] = Form.useForm<CreateEmployeeFormType>();

  const onFinishForm = useCallback(
    async (values: CreateEmployeeFormType) => {
      try {
        const createReq = {
          ...values,
          passportDate: values.passportDate ? values.passportDate.toISOString() : undefined,
        } as EmployeesCreateRequest;
        try {
          await createEmployeeM.mutateAsync(createReq);
          onClose();
        } catch (e) {
          message.error('При создании сотрудника произошла ошибка');
        }
      } catch (e) {
        message.error('Есть невалидные поля');
      }
    },
    [createEmployeeM, onClose],
  );

  const { submit } = form;

  return (
    <Modal
      visible={true}
      width={1000}
      onCancel={onClose}
      title={'Создание сотрудника'}
      okText={'Создать'}
      okButtonProps={{ loading: createEmployeeM.isLoading }}
      onOk={submit}
    >
      <Form
        form={form}
        layout={'vertical'}
        wrapperCol={{ span: 24 }}
        requiredMark={'optional'}
        onFinish={onFinishForm}
      >
        <ManagementEmployeeFormFields />
      </Form>
    </Modal>
  );
});
