import { CreateContractFormType } from './types';
import { defaultTerritory } from '../common/ContractFields/constants';

export const DEFAULT_CREATE_CONTRACT_FORM: CreateContractFormType = {
  isArchiveContract: false,
  calculationAccuracy: 0.01,
  actusCode: defaultTerritory,
  minPdCount: 1,
  coordinateSystems: [],
  emails: [],
  ozFormat: 'short_square',
  availableFormats: ['А0', 'А1', 'А2'],
  availableScales: ['1:1000', '1:2000', '1:3000', '1:4000', '1:5000'],
  ozWidth: 7,
  exportFontWeight: 100,
  exportFontOffset: 100,
  lineScalePercent: 72,
  pointScalePercent: 74,
  tolerance: 242,
  enableScheme: true,
  enableDescBorders: true,
};
