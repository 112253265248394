import React, { useCallback, useEffect, useState } from 'react';
import { Form, message, Modal } from 'antd';
import { useGetColumnByIdQ, usePatchColumnM } from '@/hooks/directory/columns';
import { ColumnsPatchRequest } from '@/api/Dictionary/Columns/types';
import { ColumnFields } from '@/components/modals/columns/common/ColumnFields';
import { RecordId } from '@/api/common/types';
import { EditColumnFormType } from '@/components/modals/columns/EditColumnModal/types';

export type EditColumnModalProps =
  | {
      visible: false;
    }
  | ({ visible: true } & VisibleProps);

type VisibleProps = {
  columnId: RecordId;
  onClose: () => void;
};
export const EditColumnModal: React.FC<EditColumnModalProps> = React.memo((props) => {
  const { visible } = props;

  if (!visible) {
    return null;
  }
  const { visible: _, ...visibleProps } = props;
  return <VisibleModal {...visibleProps} />;
});

export const VisibleModal: React.FC<VisibleProps> = React.memo((props) => {
  const { onClose, columnId } = props;
  const patchColumnM = usePatchColumnM();
  const { data: currentColumn, isLoading } = useGetColumnByIdQ(columnId);
  const [form] = Form.useForm<EditColumnFormType>();
  useEffect(() => {
    if (currentColumn) {
      form.setFieldsValue({ ...currentColumn, groupId: currentColumn.groupId?._id });
    }
  }, [currentColumn, form]);
  const [changes, setChanges] = useState<EditColumnFormType | null>(null);

  const onFormFinishHandler = useCallback(async () => {
    try {
      const req = { id: columnId, patch: changes } as ColumnsPatchRequest;
      await patchColumnM.mutateAsync(req);
      onClose();
      message.success('Колонка успешно отредактирована');
    } catch (e) {
      message.error('Произошла ошибка при редактировании колонки');
    }
  }, [patchColumnM, onClose, changes, columnId]);

  const onValuesChangeHandler = React.useCallback((changes: any) => {
    setChanges((prevState) => ({ ...prevState, ...changes }));
  }, []);

  return (
    <Modal
      visible={true}
      title={isLoading ? 'Загрузка...' : `${currentColumn?.name || ''}: Редактирование колонки`}
      okText={'Сохранить изменения'}
      onOk={form.submit}
      okButtonProps={{ disabled: changes === null, loading: patchColumnM.isLoading }}
      cancelText={'Отмена'}
      onCancel={onClose}
    >
      <Form
        form={form}
        onFinish={onFormFinishHandler}
        layout={'vertical'}
        onValuesChange={onValuesChangeHandler}
      >
        <ColumnFields />
      </Form>
    </Modal>
  );
});
