import { ControlledTableColumns } from '@uspect-team/ant-ui-kit/dist/Tables/ControlledTable/types';
import { CoupleFileResponse, FileType } from '@/api/Files/types';
import { fileSizeToString } from '@/utils/fileSizeToString';
import React from 'react';
import { DateView } from '@uspect-team/ant-ui-kit';
import { ExternalLink } from '@/components/ExternalLink';
import { DeleteOutlined } from '@ant-design/icons';
import { Button } from 'antd';
import './style.scss';

const DeleteIcon = <DeleteOutlined />;
const getFileColumns = (parentDataIndex: number): ControlledTableColumns<CoupleFileResponse> => [
  {
    key: 'fileName',
    dataIndex: [parentDataIndex, 'fileName'],
    title: 'Имя файла',

    render: (_, record) => (
      <ExternalLink style={{ maxWidth: '200px' }} path={record[parentDataIndex].fileUri}>
        {record[parentDataIndex].fileName}
      </ExternalLink>
    ),
  },
  {
    key: 'size',
    dataIndex: [parentDataIndex, 'size'],
    title: 'Размер',
    render: (value: number) => fileSizeToString(value),
  },
  {
    key: 'createdAt',
    dataIndex: [parentDataIndex, 'createdAt'],
    title: 'Дата загрузки',
    render: (value: string) => <DateView value={new Date(value)} />,
  },
];

export const getCoupleColumns = (
  coupleTypes: [FileType, FileType],
  onDelete?: (record: CoupleFileResponse) => void,
  isLoadingDelete?: boolean,
): ControlledTableColumns<CoupleFileResponse> => {
  const columns: ControlledTableColumns<CoupleFileResponse> = [
    { title: coupleTypes[0], key: coupleTypes[0], children: getFileColumns(1) },
    { title: coupleTypes[1], key: coupleTypes[1], children: getFileColumns(0) },
  ];
  if (onDelete) {
    columns.push({
      key: 'actions',
      title: '',
      fixed: 'right',
      dataIndex: 'actions',
      align: 'center',
      className: 'vertical-middle-cell',

      render: (_, record) => {
        return (
          <Button
            onClick={() => onDelete(record)}
            icon={DeleteIcon}
            type={'dashed'}
            loading={isLoadingDelete}
            danger
          ></Button>
        );
      },
    });
  }
  return columns;
};
