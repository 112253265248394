import React, { useCallback } from 'react';
import { Form, message, Modal } from 'antd';
import { CreateColumnFormType } from 'src/components/modals/columns/CreateColumnModal/types';
import { useCreateColumnM } from '@/hooks/directory/columns';
import { ColumnsCreateRequest } from '@/api/Dictionary/Columns/types';
import { ColumnFields } from '@/components/modals/columns/common/ColumnFields';

export type CreateColumnModalProps =
  | {
      visible: false;
    }
  | ({ visible: true } & VisibleProps);

type VisibleProps = {
  onClose: () => void;
};
export const CreateColumnModal: React.FC<CreateColumnModalProps> = React.memo((props) => {
  const { visible } = props;

  if (!visible) {
    return null;
  }
  const { visible: _, ...visibleProps } = props;
  return <VisibleModal {...visibleProps} />;
});

export const VisibleModal: React.FC<VisibleProps> = React.memo((props) => {
  const { onClose } = props;
  const createColumnM = useCreateColumnM();
  const [form] = Form.useForm<CreateColumnFormType>();

  const onFormFinishHandler = useCallback(
    async (data: CreateColumnFormType) => {
      try {
        const req = data as ColumnsCreateRequest;
        await createColumnM.mutateAsync(req);
        onClose();
        message.success('Колонка успешно создана');
      } catch (e) {
        message.error('Произошла ошибка при создании колонки');
      }
    },
    [createColumnM, onClose],
  );

  return (
    <Modal
      visible={true}
      title={'Создание колонки'}
      okText={'Создать'}
      onOk={() => {
        form.submit();
      }}
      cancelText={'Отмена'}
      onCancel={onClose}
    >
      <Form
        form={form}
        onFinish={onFormFinishHandler}
        layout={'vertical'}
        initialValues={{ order: 1 }}
      >
        <ColumnFields />
      </Form>
    </Modal>
  );
});
