import React from 'react';

export type PropItemProps = {
  header: string;
  count: number;
  percent: number;
  comment?: string;
};

const _PropItem: React.FC<PropItemProps> = (props) => {
  const { header, count, percent, comment } = props;
  return (
    <div className='statistic-bar__prop'>
      <div className='statistic-bar__prop-name'>{header}:</div>
      <div className='statistic-bar__prop-value'>
        <div className='statistic-bar__prop-value-count'>{count}</div>
        <div className='statistic-bar__prop-value-percent'>({percent}%)</div>
        {comment && <div className='statistic-bar__prop-value-comment'>{comment}</div>}
      </div>
    </div>
  );
};
export const PropItem = React.memo(_PropItem);
