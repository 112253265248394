import { ExecItem } from '@/globalStorage/utils/type';
import moment from 'moment';
import React from 'react';
import './styles.scss';

const getTimeStrByMilliseconds = (m: number) => {
  const time = moment.duration(m, 'milliseconds');
  return `${time.minutes()} м. ${time.seconds()} c.`;
};

export type ExecItemProps = {
  execItem: ExecItem<any>;
  renderItem: (item: any) => React.ReactNode;
};

const _ExecItem: React.FC<ExecItemProps> = ({ execItem, renderItem }) => {
  const [execTimeMilliseconds, setExecTimeMilliseconds] = React.useState<number>(
    Date.now() - execItem.startExecMilliseconds,
  );
  const intervalId = React.useRef<any>();

  React.useEffect(() => {
    intervalId.current = setInterval(() => {
      setExecTimeMilliseconds(Date.now() - execItem.startExecMilliseconds);
    }, 1000);

    return () => {
      if (intervalId.current) {
        clearInterval(intervalId.current);
      }
    };
  }, [execItem.startExecMilliseconds]);

  const timeStr = getTimeStrByMilliseconds(execTimeMilliseconds);

  return (
    <div className='exec-item-view'>
      <div className='exec-item-view__body'>{renderItem(execItem.item)}</div>
      <div className='exec-item-view__time'>{`Время выполнения: ${timeStr}`}</div>
    </div>
  );
};
export const ExecItemView = React.memo(_ExecItem);
