import { RecordId } from '@/api/common/types';
import { MainLayout } from '@/components/layouts/MainLayout';
import { editCadObjectCoordinateService } from '@/globalStorage/cadObjects';
import { coordinateService } from '@/globalStorage/coordinates/newIndex';
import { CordsManager } from '@/pages/CoordsPage/CordsManager';
import { routes } from '@/resources/routes';
import { ArrowLeftOutlined } from '@ant-design/icons';
import { useStore } from 'effector-react';
import React from 'react';
import { Link, useParams } from 'react-router-dom';
import './styles.scss';

const _CadObjectCoordinatesPage: React.FC = () => {
  const params = useParams<{ id: RecordId }>();

  const cadObjectId = params.id;

  React.useEffect(() => {
    editCadObjectCoordinateService.init({ cadObjectId });
  }, [cadObjectId]);

  const initIsLoading = useStore(editCadObjectCoordinateService.initIsLoading);

  const coordinateId = useStore(coordinateService.$coordinateId);

  const contractId = useStore(editCadObjectCoordinateService.$contractId);

  const haveChanges = useStore(coordinateService.$haveChanges);

  return (
    <MainLayout singlePage hideFooter>
      {initIsLoading ? (
        'Загрузка...'
      ) : (
        <div className='cad-object-coord-page'>
          {contractId && (
            <Link
              className='cad-object-coord-page__back_to-contract'
              to={routes.contractDetail(contractId)}
            >
              <ArrowLeftOutlined />
              <span>Вернуться к контракту</span>
            </Link>
          )}

          <div className='cad-object-coord-page__header'>
            <div className='cad-object-coord-page__title'>
              {coordinateId ? 'Редактирование координат' : 'Создание координат'}
            </div>
            {haveChanges && (
              <div className='cad-object-coord-page__have-changes'>
                (есть несохраненные изменения)
              </div>
            )}
          </div>
          <div className='cad-object-coord-page__content'>
            <CordsManager />
          </div>
        </div>
      )}
    </MainLayout>
  );
};

export const CadObjectCoordinatesPage = React.memo(_CadObjectCoordinatesPage);
