import { api } from '@/api/axiosInstance';
import { RecordId } from '@/api/common/types';
import {
  CreateTpRequest,
  CreateTpResponse,
  GetByIdTpResponse,
  GetTpPageRequest,
  GetTpPageResponse,
  PatchTpRequest,
  PatchTpResponse,
} from '@/api/Coordinates/Tp/types';

const rootUrl = '/ts';

export class TpApi {
  get(params: GetTpPageRequest) {
    return api.get<GetTpPageResponse>(rootUrl, { params });
  }

  getById(id: RecordId) {
    return api.get<GetByIdTpResponse>(`${rootUrl}/${id}`);
  }

  create(data: CreateTpRequest) {
    const { isAutoCalculateWgs, ...withoutAutoCalc } = data;
    return api.post<CreateTpResponse>(rootUrl, withoutAutoCalc, {
      params: {
        isAutoCalculateWgs,
      },
    });
  }

  patch(data: PatchTpRequest) {
    return api.patch<PatchTpResponse>(`${rootUrl}/${data.id}`, data.patch, {
      params: { isAutoCalculateWgs: data.isAutoCalculateWgs },
    });
  }

  delete(id: RecordId) {
    return api.delete<void>(`${rootUrl}/${id}`);
  }
}
