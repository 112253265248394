import cn from 'classnames';
import React from 'react';
import { DocumentationIconButton } from './DocumentationIconButton';
import { MenuProfile } from './MenuProfile';
import { NotificationsIconButton } from './NotificationsIconButton';
import './styles.scss';

export type HeaderAdditionalProps = {
  className?: string;
  style?: React.CSSProperties;
};

const _HeaderAdditional: React.FC<HeaderAdditionalProps> = (props) => {
  const { className, style } = props;

  return (
    <div className={cn('header-additional', className)} style={style}>
      <DocumentationIconButton />
      <NotificationsIconButton />
      <div className='header-additional__devider' />
      <MenuProfile />
    </div>
  );
};
export const HeaderAdditional = React.memo(_HeaderAdditional);
