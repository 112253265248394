import { UserRole } from './../../../api/Users/types';
export const roleToView = (role: UserRole) => {
  switch (role) {
    case 'superadmin': {
      return 'Суперадминистратор';
    }
    case 'user': {
      return 'Пользователь';
    }
    case 'guest': {
      return 'Гость';
    }

    default: {
      return 'Неизвестная роль';
    }
  }
};
