import { createApi, createEffect, createEvent, createStore, forward, sample } from 'effector';
import { createList } from './createList';
import { createNot } from './createNot';
import { createQueue } from './createQueue';
import { ExecItem, HistoryItem } from './type';
export const createExecQueue = <Type>() => {
  const $execState = createStore<'idle' | 'inProcess' | 'done'>('idle');

  const { setExecStateIdle, setExecStateInProcess, setExecStateDone } = createApi($execState, {
    setExecStateIdle: () => 'idle',
    setExecStateInProcess: () => 'inProcess',
    setExecStateDone: () => 'done',
  });
  const $execStateInProcess = $execState.map((x) => x === 'inProcess');
  const $execStateInIdle = $execState.map((x) => x === 'idle');

  const {
    $queue,
    $headIsNotEmpty: $queueHeadIsNotEmpty,
    $head: $queueHead,
    resetQueue: clearQueue,
    setQueue,
    removeHead: removeHeadQueue,
  } = createQueue<Type>([]);

  const $execItem = createStore<null | ExecItem<Type>>(null);
  const { setExecItem, clearExecItem } = createApi($execItem, {
    setExecItem: (_, payload: ExecItem<Type>) => payload,
    clearExecItem: () => null,
  });
  const $execItemIsEmpty = $execItem.map((x) => x === null);
  const {
    $list: $history,
    push: pushToHistory,
    resetList: clearHistory,
  } = createList<HistoryItem<Type>>([]);

  const moveQueueHeadToExecFx = createEffect<(payload: { queueHead: Type }) => void>(
    ({ queueHead }) => {
      setExecItem({ item: queueHead, startExecMilliseconds: Date.now() });
      removeHeadQueue();
    },
  );

  const moveExecItemToHistoryWithSuccess = createEvent<string | undefined>();
  const _moveExecItemToHistoryFxWithSuccess = createEffect<
    (payload: { execItem: ExecItem<Type>; message?: string }) => void
  >(({ execItem, message }) => {
    pushToHistory({
      item: execItem.item,
      execTimeMilliseconds: Date.now() - execItem.startExecMilliseconds,
      status: 'success',
      message: message ?? null,
    });
    clearExecItem();
  });

  sample({
    clock: moveExecItemToHistoryWithSuccess,
    source: $execItem,
    filter: createNot($execItemIsEmpty),
    fn: (execItem, message) => ({ execItem: execItem as ExecItem<Type>, message: message }),
    target: _moveExecItemToHistoryFxWithSuccess,
  });

  const moveExecItemToHistoryWithError = createEvent<string | undefined>();
  const _moveExecItemToHistoryFxWithError = createEffect<
    (payload: { execItem: ExecItem<Type>; message?: string }) => void
  >(({ execItem, message }) => {
    pushToHistory({
      item: execItem.item,
      execTimeMilliseconds: Date.now() - execItem.startExecMilliseconds,
      status: 'error',
      message: message ?? null,
    });
    clearExecItem();
  });

  sample({
    clock: moveExecItemToHistoryWithError,
    source: $execItem,
    filter: createNot($execItemIsEmpty),
    fn: (execItem, message) => ({ execItem: execItem as ExecItem<Type>, message: message }),
    target: _moveExecItemToHistoryFxWithError,
  });

  /// move queuehead to exec

  sample({
    source: {
      queueHead: $queueHead,
      execItemIsEmpty: $execItemIsEmpty,
      execStateInProcess: $execStateInProcess,
    },
    filter: ({ queueHead, execItemIsEmpty, execStateInProcess }) =>
      queueHead !== null && execItemIsEmpty && execStateInProcess,
    fn: ({ queueHead }) => ({ queueHead: queueHead as Type }),
    target: moveQueueHeadToExecFx,
  });

  sample({
    source: {
      execItemIsEmpty: $execItemIsEmpty,
      execStateInProcess: $execStateInProcess,
      queueHeadIsEmpty: createNot($queueHeadIsNotEmpty),
    },
    filter: ({ execItemIsEmpty, execStateInProcess, queueHeadIsEmpty }) => {
      console.log(
        `isDoneChecker 
        execItemIsEmpty:${execItemIsEmpty}, 
        execStateInProcess: ${execStateInProcess}, 
        queueHeadIsEmpty: ${queueHeadIsEmpty}`,
      );
      return execItemIsEmpty && execStateInProcess && queueHeadIsEmpty;
    },
    target: setExecStateDone,
  });

  const newExecItem = createEvent<ExecItem<Type>>();

  sample({
    clock: $execItem,
    source: {
      execItemNotEmpty: createNot($execItemIsEmpty),
      execStateInProcess: $execStateInProcess,
    },
    filter: ({ execItemNotEmpty, execStateInProcess }) => execItemNotEmpty && execStateInProcess,
    fn: (_, ex) => ex as ExecItem<Type>,
    target: newExecItem,
  });

  const resetExecQueue = createEvent();
  forward({
    from: resetExecQueue,
    to: [setExecStateIdle, clearQueue, clearExecItem, clearHistory],
  });

  const startExecQueue = createEvent<Type[]>();
  const _startExecQueueFx = createEffect<Type[], void>((initQueue) => {
    resetExecQueue();
    setQueue(initQueue);
    setExecStateInProcess();
  });
  sample({
    clock: startExecQueue,
    filter: $execStateInIdle,
    target: _startExecQueueFx,
  });

  // ///debug
  // $execState.watch((state) => console.log('execStateChanged', state));
  // $execItem.watch((state) => console.log('execItemChanged', state));
  // $queue.watch((state) => console.log('queueChanged', state));
  // $history.watch((state) => console.log('historyChanged', state));

  // newExecItem.watch((state) => console.log('call newExecItem', state));
  // moveExecItemToHistoryWithSuccess.watch((state) => console.log('call successExec', state));
  // moveExecItemToHistoryWithError.watch((state) => console.log('call errorExec', state));

  return {
    $queue,
    $execItem,
    newExecItem,
    moveExecItemToHistoryWithSuccess,
    moveExecItemToHistoryWithError,
    $execState,
    setExecStateIdle,
    setExecStateInProcess,
    setExecStateDone,
    startExecQueue,
    resetExecQueue,
    $history,
  };
};
