import { RecordId } from '@/api/common/types';
import { GetCoupleFilesPageRequest, GetFilesPageRequest } from '@/api/Files/types';

export const filesQueryKeys = {
  all: ['files'] as const,
  pages: () => [...filesQueryKeys.all, 'page'] as const,
  page: (data: GetFilesPageRequest) => [...filesQueryKeys.pages(), data] as const,
  couplePages: () => [...filesQueryKeys.all, 'couple-page'] as const,
  couplePage: (data: GetCoupleFilesPageRequest) => [...filesQueryKeys.couplePages(), data] as const,
  details: () => [...filesQueryKeys.all, 'detail'] as const,
  detail: (id: RecordId) => [...filesQueryKeys.details(), id] as const,

  upload: () => [...filesQueryKeys.all, 'upload'] as const,
};
