import React, { useCallback, useMemo, useState } from 'react';
import { DictCrudPage } from '@/components/DictCrudPage';
import { getDisplayModePageConfig } from '@/pages/Dictionary/DisplayModePage/config';
import {
  CreateDisplayModeModal,
  CreateDisplayModeModalProps,
} from '@/components/modals/displayMods/CreateDisplayModeModal';
import {
  EditDisplayModeModal,
  EditDisplayModeModalProps,
} from '@/components/modals/displayMods/EditDisplayModeModal';
import { DisplayModeRecordResponse } from '@/api/Dictionary/DisplayMods/types';

const _DisplayModePage: React.FC = () => {
  const [createModalState, setCreateModalState] = useState<CreateDisplayModeModalProps>({
    visible: false,
  });
  const [editModalState, setEditModalState] = useState<EditDisplayModeModalProps>({
    visible: false,
  });

  const onCreateButtonClickHandler = useCallback(() => {
    setCreateModalState({
      visible: true,
      onClose: () => {
        setCreateModalState({ visible: false });
      },
    });
  }, []);
  const onEditButtonClickHandler = useCallback((record: DisplayModeRecordResponse) => {
    setEditModalState({
      visible: true,
      displayModeId: record._id,
      onClose: () => {
        setEditModalState({ visible: false });
      },
    });
  }, []);

  const displayModeConfig = useMemo(() => {
    return getDisplayModePageConfig({
      onCreateButtonClick: onCreateButtonClickHandler,
      onEditButtonClick: onEditButtonClickHandler,
    });
  }, [onCreateButtonClickHandler, onEditButtonClickHandler]);
  return (
    <>
      <DictCrudPage {...displayModeConfig} />
      <CreateDisplayModeModal {...createModalState} />
      <EditDisplayModeModal {...editModalState} />
    </>
  );
};

export const DisplayModePage = React.memo(_DisplayModePage);
