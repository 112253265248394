import { RecordId } from '@/api/common/types';
import { TpRecordResponse } from '@/api/Coordinates/Tp/types';
import { createApi, createStore } from 'effector';

export const createTpList = () => {
  const $tpList = createStore<TpRecordResponse[]>([]);
  const { setTpList, resetTpList, addTp, deleteTp } = createApi($tpList, {
    setTpList: (_, payload: TpRecordResponse[]) => payload,
    resetTpList: () => [],
    addTp: (tpList, payload: TpRecordResponse) => [...tpList, payload],
    deleteTp: (tpList, payload: RecordId) => tpList.filter((x) => x._id !== payload),
  });

  return { $tpList, setTpList, resetTpList, addTp, deleteTp };
};
