import { UserPermissions } from '@/api/Users/types';
import React from 'react';
import cn from 'classnames';
import { Col, Row, Select } from 'antd';
import { AntdLabelMimic } from '@/components/formHelpers/AntdLabelMimic';
import { useGetContractsAllQ } from '@/hooks/contracts';
import { RecordId } from '@/api/common/types';
import { InfoPopup } from '@/components/InfoPopup';
import './styles.scss';

export type PermissionSettingsInputProps = {
  className?: string;
  style?: React.CSSProperties;
  value?: UserPermissions;
  onChange?: (value: UserPermissions) => void;
};

const LabelInfoPopup = () => {
  return (
    <InfoPopup
      content={
        <div className='permission-settings-input__info'>
          <div className='permission-settings-input__info-item'>
            <div className='permission-settings-input__info-item-title'>Суперадминистратор</div>
            <div className='permission-settings-input__info-item-desc'>
              Имеет полный доступ к системе, может создавать и назначать других пользователей.
            </div>
          </div>
          <div className='permission-settings-input__info-item'>
            <div className='permission-settings-input__info-item-title'>Пользователь</div>
            <div className='permission-settings-input__info-item-desc'>
              Имеет доступ только к выбранным контрактам на чтение и запись
            </div>
          </div>
          <div className='permission-settings-input__info-item'>
            <div className='permission-settings-input__info-item-title'>Гость</div>
            <div className='permission-settings-input__info-item-desc'>
              Имеет доступ только к выбранным контрактам на чтение
            </div>
          </div>
        </div>
      }
    />
  );
};

const _PermissionSettingsInput: React.FC<PermissionSettingsInputProps> = (props) => {
  const { className, style, value, onChange } = props;

  const { data: contracts, isLoading: isLoadingContracts } = useGetContractsAllQ();

  const onChangeRole = (role: 'user' | 'guest' | 'superadmin') => {
    switch (role) {
      case 'guest': {
        onChange &&
          onChange({
            role: 'guest',
            contracts: [],
          });
        return;
      }
      case 'user': {
        onChange &&
          onChange({
            role: 'user',
            contracts: [],
          });
        return;
      }
      case 'superadmin': {
        onChange &&
          onChange({
            role: 'superadmin',
          });
        return;
      }
    }
  };

  const onChangeContracts = (contracts: RecordId[]) => {
    onChange &&
      value &&
      onChange({
        role: value.role,
        contracts,
      });
  };

  return (
    <div className={cn(className)} style={style}>
      <Row gutter={16}>
        <Col span={8}>
          <AntdLabelMimic addition={<LabelInfoPopup />}>Выберите роль пользователя</AntdLabelMimic>
          <Select value={value?.role} onChange={onChangeRole} placeholder='Выберите роль'>
            <Select.Option value='superadmin'>Суперадминистратор</Select.Option>
            <Select.Option value='user'>Пользователи</Select.Option>
            <Select.Option value='guest'>Гость</Select.Option>
          </Select>
        </Col>

        {value && value.role !== 'superadmin' && (
          <Col span={16}>
            <AntdLabelMimic> Выберите контракты к которым предоставить доступ</AntdLabelMimic>
            <Select
              mode={'multiple'}
              allowClear
              showSearch
              value={value.contracts}
              onChange={onChangeContracts}
              loading={isLoadingContracts}
              placeholder='Выберите контракты'
            >
              {contracts?.map((contract) => (
                <Select.Option key={contract._id} value={contract._id}>
                  {contract.title}
                </Select.Option>
              ))}
            </Select>
          </Col>
        )}
      </Row>

      <div className='permission-settings-input__settings'></div>
    </div>
  );
};
export const PermissionSettingsInput = React.memo(_PermissionSettingsInput);
