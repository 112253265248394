import { Button, ButtonProps } from 'antd';
import React from 'react';
import { useHistory } from 'react-router-dom';
import cn from 'classnames';
import './styles.scss';

export type AppButtonProps = ButtonProps & {
  to?: string | { pathname?: string; search?: string; state?: any };
};

const _AppButton: React.FC<AppButtonProps> = (props) => {
  const { to, className, ...newProps } = props;

  const history = useHistory();

  let onClickHandler = props.onClick;

  if (to) {
    onClickHandler = (e) => {
      props.onClick && props.onClick(e);
      history.push(to);
    };
  }

  return (
    <Button {...newProps} onClick={onClickHandler} className={cn(className, 'app-button')}>
      {/* <div className='app-button__content'>{children}</div> */}
      {/* {children} */}
    </Button>
  );
};
export const AppButton = React.memo(_AppButton);
