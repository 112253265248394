import { Api } from '@/api';
import { useQuery } from 'react-query';
import { companiesQueryKeys } from './queryKeys';

const useGetListCompanies = () => {
  return useQuery(companiesQueryKeys.allList(), () => {
    return Api.companies.getList();
  });
};

export const companyService = {
  useGetList: useGetListCompanies,
};
