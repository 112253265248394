import { Api } from '@/api';
import { RecordId } from '@/api/common/types';
import { GetTpPageRequest } from '@/api/Coordinates/Tp/types';
import { tpQueryKeys } from '@/hooks/coordinates/tp/queryKeys';
import { createUseFetchingStateObserver } from '@/hooks/utils';
import { useMutation, useQuery, useQueryClient } from 'react-query';

export const useGetTpsPageQ = (data: GetTpPageRequest) => {
  return useQuery(
    tpQueryKeys.page(data),
    () => {
      return Api.coordinates.tps.get(data);
    },
    {
      select: (res) => res.data,
    },
  );
};

export const useGetTpsAllQ = () => {
  const req: GetTpPageRequest = { page: 1, limit: 1000 };
  return useQuery(
    tpQueryKeys.page(req),
    () => {
      return Api.coordinates.tps.get(req);
    },
    {
      select: (res) => res.data.data,
    },
  );
};

export const useTpPageFetchingState = createUseFetchingStateObserver(tpQueryKeys.pages());

export const useGetTpByIdQ = (id: RecordId) => {
  return useQuery(
    tpQueryKeys.detail(id),
    () => {
      return Api.coordinates.tps.getById(id);
    },
    { select: (data) => data.data.data },
  );
};

//Mutations

export const useCreateTpM = () => {
  const queryClient = useQueryClient();
  return useMutation(tpQueryKeys.create(), Api.coordinates.tps.create, {
    onSuccess: async (response) => {
      await queryClient.invalidateQueries(tpQueryKeys.pages());
      queryClient.setQueriesData(tpQueryKeys.detail(response.data.data._id), response);
    },
  });
};

export const usePatchTpM = () => {
  const queryClient = useQueryClient();
  return useMutation(tpQueryKeys.patch(), Api.coordinates.tps.patch, {
    onSuccess: async (response, variables) => {
      await queryClient.invalidateQueries(tpQueryKeys.pages());
      queryClient.setQueriesData(tpQueryKeys.detail(variables.id), response);
    },
  });
};

export const useDeleteTpM = () => {
  const queryClient = useQueryClient();
  return useMutation(tpQueryKeys.delete(), Api.coordinates.tps.delete, {
    onSuccess: async (_, variables) => {
      await Promise.all([
        queryClient.invalidateQueries(tpQueryKeys.pages()),
        queryClient.invalidateQueries(tpQueryKeys.detail(variables)),
      ]);
    },
  });
};
