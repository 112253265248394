import { autoSignModalService } from '@/globalStorage/autoSign/modal';
import { AutoSignModalState } from '@/globalStorage/autoSign/types';
import { Button, Modal, Space, Steps } from 'antd';
import { createComponent } from 'effector-react';
import React from 'react';
import { AutoSignModalDone } from './AutoSignModalDone';
import { AutoSignModalIdle } from './AutoSignModalIdle';
import { AutoSignModalInProcess } from './AutoSignModalInProcess';

const mapAutosignStateToCurrentStep = (state: 'idle' | 'inProcess' | 'done') => {
  switch (state) {
    case 'idle':
      return 0;
    case 'inProcess':
      return 1;
    case 'done':
      return 2;
    default:
      return 0;
  }
};

const getFooter = (state: AutoSignModalState) => {
  if (state.visible) {
    switch (state.state) {
      case 'idle':
        return (
          <Space>
            <Button onClick={() => autoSignModalService.closeAutoSignModal()}>Отмена</Button>
            <Button
              type={'primary'}
              onClick={() => autoSignModalService.startAutoSign()}
              disabled={!state.isCanStartAutoSign}
            >
              Начать автоподписание
            </Button>
          </Space>
        );
      case 'inProcess':
        return <Button onClick={() => autoSignModalService.closeAutoSignModal()}>Свернуть</Button>;
      case 'done':
        return (
          <Space>
            <Button onClick={() => autoSignModalService.backToAutoSignInit()}>
              Назад к началу
            </Button>
            <Button type={'primary'} onClick={() => autoSignModalService.closeAutoSignModal()}>
              Завершить
            </Button>
          </Space>
        );
    }
  }
};

export const AutoSignModal = createComponent(
  autoSignModalService.$autoSignModalState,
  (props, state) => {
    if (!state.visible) {
      return null;
    }

    return (
      <Modal
        width={1000}
        visible={true}
        onCancel={() => autoSignModalService.closeAutoSignModal()}
        title={'Автоматическое подписание объектов'}
        footer={getFooter(state)}
      >
        <Steps size='small' current={mapAutosignStateToCurrentStep(state.state)}>
          <Steps.Step title='Выбор объектов' />
          <Steps.Step title='Статус подписания' />
          <Steps.Step title='Результат' />
        </Steps>

        {state.state === 'idle' && <AutoSignModalIdle {...state} />}
        {state.state === 'inProcess' && <AutoSignModalInProcess {...state} />}
        {state.state === 'done' && <AutoSignModalDone {...state} />}
      </Modal>
    );
  },
);
