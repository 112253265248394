import React from 'react';
import cn from 'classnames';
import './styles.scss';

export type PopoverContainerProps = {
  className?: string;
  style?: React.CSSProperties;
  children?: React.ReactNode;
  theme?: 'light' | 'dark';
};

export const PopoverContainer: React.FC<PopoverContainerProps> = (props) => {
  const { className, style, children, theme = 'light' } = props;

  return (
    <div
      className={cn('popover-container', className, {
        'popover-container--dark': theme === 'dark',
      })}
      style={style}
    >
      {children}
    </div>
  );
};
