import { AppButton } from '@/components/AppButton';
import { GuestLayout } from '@/components/layouts/GuestLayout';
import { routes } from '@/resources/routes';
import React from 'react';
import { PasswordRestoreConfirmedPageForm } from './Form';
import { usePasswordRestoreConfirmedPage } from './hooks';

const _PasswordRestoreConfirmedPage: React.FC = () => {
  const { state, onSubmitSendHandler } = usePasswordRestoreConfirmedPage();

  if (state.state === 'invalidLink') {
    return (
      <GuestLayout>
        <GuestLayout.ContentWithFooterContainer>
          <GuestLayout.ContentContainer>
            <GuestLayout.ContentHeader>
              <GuestLayout.Title>Ссылка на восстановление пароля сломана</GuestLayout.Title>

              <GuestLayout.Description>
                Пожалуйста, попробуйте восстановить пароль еще раз.
              </GuestLayout.Description>
            </GuestLayout.ContentHeader>
          </GuestLayout.ContentContainer>
          <GuestLayout.DefaultFooter />
        </GuestLayout.ContentWithFooterContainer>
      </GuestLayout>
    );
  }

  if (state.state === 'notSubmitted') {
    return (
      <GuestLayout>
        <GuestLayout.ContentWithFooterContainer>
          <GuestLayout.ContentContainer>
            <GuestLayout.ContentHeader>
              <GuestLayout.Title>Восстановление пароля (2&nbsp;шаг)</GuestLayout.Title>

              <GuestLayout.Description>
                Введите новый пароль и повторите его
              </GuestLayout.Description>
            </GuestLayout.ContentHeader>

            <PasswordRestoreConfirmedPageForm
              onSubmit={onSubmitSendHandler}
              isLoading={state.isLoading}
            />
          </GuestLayout.ContentContainer>
          <GuestLayout.DefaultFooter />
        </GuestLayout.ContentWithFooterContainer>
      </GuestLayout>
    );
  }

  return (
    <GuestLayout className='password-restore-page'>
      <GuestLayout.ContentWithFooterContainer>
        <GuestLayout.ContentContainer>
          <GuestLayout.ContentHeader>
            <GuestLayout.Title>Пароль успешно изменен</GuestLayout.Title>
          </GuestLayout.ContentHeader>

          <AppButton size='large' type='primary' ghost block to={routes.login}>
            Вернуться к авторизации
          </AppButton>
        </GuestLayout.ContentContainer>
        <GuestLayout.DefaultFooter />
      </GuestLayout.ContentWithFooterContainer>
    </GuestLayout>
  );
};
export const PasswordRestoreConfirmedPage = React.memo(_PasswordRestoreConfirmedPage);
