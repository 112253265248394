import { AddressFilterItem } from '@/components/ServerConfigTable/types';
import { Input } from 'antd';
import React from 'react';
import { FilterDropdownActions } from '../common/FilterDropdownActions';
import { FilterDropdownContentBase } from '../common/FilterDropdownContentBase';
import { FilterDropdownFormContainer } from '../common/FilterDropdownFormContainer';
import { FilterTypeOption, FilterTypeSelect } from '../common/FilterTypeSelect';
import { equalOption, existOption, includesOption, notExistOption } from './consts';
import { AddressFilterType, AddressFilterValue } from './types';

export type AddressFilterDropdownProps = {
  className?: string;
  style?: React.CSSProperties;

  filter: AddressFilterValue;
  onChangeFilter: (value: AddressFilterItem) => void;
  onResetFilter: () => void;
  hasIncludes?: boolean;
  hasEqual?: boolean;
  hasExist?: boolean;
};

const getActualPropValue = <ReturnValue,>(params: {
  currentFilter: AddressFilterValue;
  changes: AddressFilterItem | null;
  getProp: (filter: AddressFilterValue | null) => ReturnValue;
  defaultValue: ReturnValue;
}) => {
  const { currentFilter, changes, getProp, defaultValue } = params;
  if (changes !== null) {
    return getProp(changes);
  }
  return getProp(changes) ?? getProp(currentFilter) ?? defaultValue;
};

const _AddressFilterDropdown = (props: AddressFilterDropdownProps) => {
  const {
    className,
    style,
    filter,
    onChangeFilter,
    onResetFilter,
    hasIncludes,
    hasEqual,
    hasExist,
  } = props;

  React.useEffect(() => {
    setUpdatedFilterState(null);
  }, [filter]);

  const [updatedFilterState, setUpdatedFilterState] = React.useState<AddressFilterItem | null>(
    null,
  );

  const filterTypeOptions = React.useMemo(() => {
    const options: FilterTypeOption<AddressFilterType>[] = [];
    if (hasEqual) {
      options.push(equalOption);
    }
    if (hasIncludes) {
      options.push(includesOption);
    }
    if (hasExist) {
      options.push(existOption, notExistOption);
    }
    return options;
  }, [hasEqual, hasExist, hasIncludes]);

  const [selectedFilterType, _setSelectedFilterType] = React.useState<AddressFilterType>('equal');
  const changeFilterType = React.useCallback((filterType: AddressFilterType) => {
    _setSelectedFilterType(filterType);
    if (filterType === 'exist' || filterType === 'notExist') {
      setUpdatedFilterState({
        filterAs: 'address',
        filterType,
      });
    } else {
      setUpdatedFilterState(null);
    }
  }, []);

  const form = React.useMemo(() => {
    switch (selectedFilterType) {
      case 'exist':
      case 'notExist':
        return null;
      case 'includes': {
        const inputValue = getActualPropValue({
          currentFilter: filter,
          changes: updatedFilterState,
          getProp: (filter) => {
            return filter?.filterType === 'includes' ? filter.payload : undefined;
          },
          defaultValue: '',
        });
        return (
          <FilterDropdownFormContainer>
            <Input
              value={inputValue}
              onChange={(e) =>
                setUpdatedFilterState({
                  filterAs: 'address',
                  filterType: 'includes',
                  payload: e.target.value,
                })
              }
            />
          </FilterDropdownFormContainer>
        );
      }

      case 'equal': {
        const inputValue = getActualPropValue({
          currentFilter: filter,
          changes: updatedFilterState,
          getProp: (filter) => {
            return filter?.filterType === 'equal' ? filter.payload : undefined;
          },
          defaultValue: '',
        });
        return (
          <FilterDropdownFormContainer>
            <Input
              value={inputValue}
              onChange={(e) =>
                setUpdatedFilterState({
                  filterAs: 'address',
                  filterType: 'equal',
                  payload: e.target.value,
                })
              }
            />
          </FilterDropdownFormContainer>
        );
      }
    }
  }, [filter, selectedFilterType, updatedFilterState]);

  return (
    <FilterDropdownContentBase className={className} style={style}>
      <FilterTypeSelect
        onChangeSelectedFilterType={changeFilterType}
        selectedFilterType={selectedFilterType}
        filteredType={filter?.filterType ?? null}
        options={filterTypeOptions}
      />
      {form}
      <FilterDropdownActions
        canChangeFilter={updatedFilterState !== null}
        hasFilters={filter !== null && filter !== undefined}
        onSubmitChanges={() => {
          if (updatedFilterState === null) {
            onResetFilter();
          } else {
            onChangeFilter(updatedFilterState);
          }
        }}
        onResetFilter={onResetFilter}
      />
    </FilterDropdownContentBase>
  );
};
export const AddressFilterDropdown = React.memo(_AddressFilterDropdown);
