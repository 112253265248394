import { Form, message, Modal } from 'antd';
import React, { useCallback, useEffect, useState } from 'react';
import { useGetByIdColumnGroupsQ, usePatchColumnGroupM } from '@/hooks/directory/columnGroups';
import { ColumnGroupsPatchRequest } from '@/api/Dictionary/ColumnGroups/types';
import { RecordId } from '@/api/common/types';
import { EditColumnGroupFormType } from '@/components/modals/columnGroups/EditColumnGroupModal/types';
import { ColumnGroupFields } from '@/components/modals/columnGroups/common/ColumnGroupFields';

export type EditColumnGroupModalProps =
  | {
      visible: false;
    }
  | ({
      visible: true;
    } & VisibleProps);

type VisibleProps = {
  columnId: RecordId;
  onClose: () => void;
};

export const EditColumnGroupModal: React.FC<EditColumnGroupModalProps> = React.memo((props) => {
  const { visible } = props;

  if (!visible) {
    return null;
  }
  const { visible: _, ...visibleProps } = props;
  return <VisibleModal {...visibleProps} />;
});

const VisibleModal: React.FC<VisibleProps> = React.memo((props) => {
  const { onClose, columnId } = props;
  const [form] = Form.useForm<EditColumnGroupFormType>();
  const { mutateAsync: patchColumnGroupM, isLoading: isLoadingPatchGroup } = usePatchColumnGroupM();
  const { data: currentColumnGroup, isLoading } = useGetByIdColumnGroupsQ(columnId);
  useEffect(() => {
    if (currentColumnGroup) {
      form.setFieldsValue(currentColumnGroup);
    }
  }, [currentColumnGroup, form]);
  const [changes, setChanges] = useState<EditColumnGroupFormType | null>(null);

  const onFinishFormHandler = useCallback(async () => {
    try {
      const patchReq = { id: columnId, patch: changes } as ColumnGroupsPatchRequest;
      await patchColumnGroupM(patchReq);
      onClose();
      message.success('Группа колонок успешно изменена');
    } catch (e) {
      message.error('Не удалось изменить группу колонок');
    }
  }, [patchColumnGroupM, onClose, columnId, changes]);
  return (
    <Modal
      visible={true}
      title={
        isLoading ? 'Загрузка...' : `${currentColumnGroup?.name || ''}: Изменение группы колонок`
      }
      okText={'Сохранить изменения'}
      onOk={form.submit}
      okButtonProps={{ loading: isLoadingPatchGroup }}
      cancelText={'Отмена'}
      onCancel={onClose}
    >
      <Form
        layout={'vertical'}
        form={form}
        onFinish={onFinishFormHandler}
        onValuesChange={(changes) => {
          setChanges((prevState) => ({ ...prevState, ...changes }));
        }}
      >
        <ColumnGroupFields />
      </Form>
    </Modal>
  );
});
