import { fileSizeToString } from '@/utils/fileSizeToString';
import { Button, Popconfirm } from 'antd';
import React from 'react';
import { DeleteOutlined } from '@ant-design/icons/lib';
import { ControlledTableColumns } from '@uspect-team/ant-ui-kit/dist/Tables/ControlledTable/types';
import { FileRecordResponse } from '@/api/Files/types';
import { DateView } from '@uspect-team/ant-ui-kit';
import { ExternalLink } from '@/components/ExternalLink';

export const getFileColumns = (options?: { onDelete?: (fileId: string) => void }) => {
  const result: ControlledTableColumns<FileRecordResponse> = [
    {
      key: 'fileName',
      dataIndex: 'fileName',
      title: 'Имя файла',
      render: (_, record) => (
        <ExternalLink path={record.fileUri} className='column-filename'>
          {record.fileName}
        </ExternalLink>
      ),
    },
    {
      key: 'size',
      dataIndex: 'size',
      title: 'Вес файла',
      render: (value) => fileSizeToString(value),
    },
    {
      key: 'createdAt',
      dataIndex: 'createdAt',
      title: 'Дата создания',
      render: (value) => <DateView value={value} />,
    },
  ];
  const onDelete = options?.onDelete;
  if (onDelete) {
    result.push({
      key: 'actions',
      title: 'Действия',
      fixed: 'right',
      dataIndex: 'act',
      render: (_, record) => (
        <div style={{ display: 'flex', justifyContent: 'center' }}>
          <Popconfirm title={'Вы уверены?'} onConfirm={() => onDelete(record._id)}>
            <Button icon={<DeleteOutlined />} type={'dashed'} danger />
          </Popconfirm>
        </div>
      ),
    });
  }
  return result;
};
