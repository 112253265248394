/* eslint-disable max-len */
import React from 'react';

const _DocumentSvg: React.FC<React.SVGProps<SVGSVGElement>> = (props) => {
  return (
    <svg width='11' height='15' viewBox='0 0 11 15' fill='none' xmlns='http://www.w3.org/2000/svg'>
      <path
        d='M3.57143 6.85H7.42857M3.57143 9.45H7.42857M3.57143 4.25H7.42857M1 1.65C1 1.47761 1.06773 1.31228 1.18829 1.19038C1.30885 1.06848 1.47236 1 1.64286 1H9.35714C9.52764 1 9.69115 1.06848 9.81171 1.19038C9.93227 1.31228 10 1.47761 10 1.65V14L7.75 12.375L5.5 14L3.25 12.375L1 14V1.65Z'
        stroke='currentColor'
        strokeLinecap='round'
        strokeLinejoin='round'
      />
    </svg>
  );
};
export const DocumentSvg = React.memo(_DocumentSvg);
