import { combine } from 'effector';
import { autoRunService } from './autoRun';
import { AutoRunWidgetState } from './types';

export const $autoRunWidgetState = combine(
  {
    autoRunState: autoRunService.$execState,
    autoRunHistory: autoRunService.$history,
    autoRunQueue: autoRunService.$queue,
    autoRunExecItem: autoRunService.$execItem,
  },
  ({ autoRunState, autoRunHistory, autoRunQueue, autoRunExecItem }) => {
    if (autoRunState === 'idle') {
      return {
        status: 'idle',
      } as AutoRunWidgetState;
    } else if (autoRunState === 'inProcess') {
      return {
        status: 'inProcess',
        doneCount: autoRunHistory.length,
        allCount: autoRunHistory.length + autoRunQueue.length + (autoRunExecItem ? 1 : 0),
      } as AutoRunWidgetState;
    } else {
      return {
        status: 'done',
      } as AutoRunWidgetState;
    }
  },
);
