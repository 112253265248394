import moment from 'moment';

export const errorMessages = {
  required: 'Поле не должно быть пустым',
  positive: 'Отрицательные значения не доступны',
  integer: 'Доступны только целые числа',
  notEmptyArray: 'Необходимо выбрать хотя-бы одно значение',
  minNumber: (min: number) => `Значение должно быть не меньше чем ${min}`,
  maxNumber: (min: number) => `Значение должно быть не больше чем  ${min}`,

  minDate: (date: Date) => `Дата должна быть не раньше чем ${moment(date).format('YYYY-MM-DD')}`,
  maxDate: (date: Date) => `Дата должна быть не позже чем ${moment(date).format('YYYY-MM-DD')}`,

  email: 'Некорректный e-mail',
  tel: 'Некорректный номер телефона',
  sinils: 'Некорректный СНИЛС',
  inn: 'Некорректный ИНН',
  passportSeries: 'Некорректная серия паспорта',
  passportNumber: 'Некорректный номер паспорта',
  cadQuarter: 'Некорректный кадастровый квартал',
  url: 'Некорректный URL',
};
